import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextPlusIcon from "../../../../assets/images/icons/TextPlusIcon.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import styles from "./EmptyPlayground.module.scss";

const EmptyPlayground = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textResults" });
	const { playgroundStore } = useAppStore();

	useEffect(() => {
		playgroundStore.closeUploadModal();
	}, []);

	return (
		<Box className={`${styles.container}`}>
			<Box className={`${styles.emptyState}`}>
				<Box className={`${styles.textPlusIcon}`}>
					<TextPlusIcon />
				</Box>
				<Box className={`${styles.header}`}>{t("emptyState.header")}</Box>
				<Box className={`${styles.desc}`}>{t("emptyState.desc")}</Box>
			</Box>
		</Box>
	);
};

export default observer(EmptyPlayground);
