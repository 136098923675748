import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";
import StopIcon from "../../../../assets/images/icons/StopIcon.tsx";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import BriaSlider from "../../../../components/common/BriaSlider/BriaSlider.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { MAX_ALLOWED_SELECTED_IMAGES } from "../../../../constants/ImageToImageConstants.ts";
import { useImageToImageConfig } from "../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../hooks/useStores";
import ExpandableMenuButton from "../../../../layout/ExpandableMenu/Button/ExpandableMenuButton.tsx";
import ExpandableMenuLayout from "../../../../layout/ExpandableMenu/Menu/ExpandableMenuLayout.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import SwipableMenuButton from "../../../../layout/SwipableMenu/Button/SwipableMenuButton";
import SwipableMenuLayout from "../../../../layout/SwipableMenu/Menu/SwipableMenuLayout";
import { ImageToImageConfigType } from "../../../../models/image-to-image";
import { isFoxApps } from "../../../../utils";
import ConfigDisplay from "./ConfigDisplay/ConfigDisplay";
import BackgroundConfig from "./Features/Background/BackgroundConfig";
import ForegroundPlacement from "./Features/ForegroundPlacement/ForegroundPlacement";
import SizeConfig from "./Features/Size/SizeConfig";
import StyleConfig from "./Features/Style/StyleConfig";
import styles from "./ImageToImageConfig.module.scss";

const ImageToImageConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore, analyticsStore, authStore } = useAppStore();
	const {
		canExpandBackground,
		canRemoveBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canBlurBackground,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
		isGenerateEnabled,
		updateSelectedBackgroundConfigs,
		updateSelectedSizeConfigs,
		updateSelectedForegroundPlacementConfigs,
	} = useImageToImageConfig();

	const [isGeneratingImages, setIsGeneratingImages] = useState<boolean>(
		imageToImageStore.isGeneratingImages || false,
	);
	const [activeConfig, setActiveConfig] = useState<string>("");
	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

	useEffect(() => {
		if (playgroundStore.selectedConfig !== APPS.IMAGE_TO_IMAGE) {
			playgroundStore.handleAppChange(APPS.IMAGE_TO_IMAGE);
		}

		setIsGeneratingImages(imageToImageStore.isGeneratingImages);
	}, [imageToImageStore.isGeneratingImages]);

	const handleDeleteConfig = (configKey: keyof ImageToImageConfigType, field: string, value: any) => {
		const currentConfig = { ...(imageToImageStore.config[configKey] as Record<string, any> | undefined) };

		imageToImageStore.handleConfigChange(configKey, {
			...currentConfig,
			[field]: value,
		});
	};

	const onSubMenuOpen = (open: boolean) => {
		setIsSubMenuOpen(open);
	};

	const handleGenerateImageToImage = () => {
		const { logEvent } = analyticsStore;

		imageToImageStore.generateImageToImage();
		logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE);

		// Background configs events
		canExpandBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_EXPAND_IMAGE);
		canRemoveBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_REMOVE_BACKGROUND);
		canBlurBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_BLUR_BACKGROUND);
		canApplySolidBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_SOLID_BACKGROUND);
		canGenerateBackgroundByText() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE_BACKGROUND_BY_TEXT);

		// Size configs events
		canApplyAspectRatio() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_ASPECT_RATIO);
		canAppleCustomSize() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_CUSTOM_SIZE);
		canAppleMediumDestination() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_MEDIUM_DESTINATION);

		// Foreground placement configs events
		canGenerateForegroundPlacement() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_GENERATE_FOREGROUND);
	};

	const renderFoxAppsConfig = () => (
		<>
			<SwipableMenuLayout className={styles.config}>
				<SwipableMenuButton
					swipeTo={<StyleConfig />}
					subSwipableMenuProps={{ title: t("features.style.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.style.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
					<BriaSlider
						value={imageToImageStore.config.num_results}
						onChange={(_, value) => imageToImageStore.handleConfigChange("num_results", value as number)}
						min={1}
						step={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({
							value: index + 1,
							label: `${index + 1}`,
						}))}
					/>
				</InputLayout>
			</SwipableMenuLayout>
			<BriaButton
				buttonType="primaryMedium"
				disabled={!isGenerateEnabled()}
				loading={authStore.isLoadingOrgSubscriptions}
				fullWidth
				onClick={handleGenerateImageToImage}
			>
				{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
					? t("tooManySelected")
					: t("button")}
			</BriaButton>
		</>
	);

	const renderStandardAppsConfig = () => (
		<Box className={styles.imageToImageConfigsWrapper}>
			<ExpandableMenuLayout
				className={clsx(styles.config, styles.imageToImageConfigs)}
				setActiveConfig={setActiveConfig}
				isSubMenuOpen={isSubMenuOpen}
			>
				<Box className={styles.configHeader}>
					<Typography variant="h6" className={styles.title}>
						{t("title")}
					</Typography>
					<Typography className={styles.subTitle}>{t("subTitle")}</Typography>
				</Box>
				<ExpandableMenuButton
					expandTo={<BackgroundConfig onSubMenuOpen={onSubMenuOpen} />}
					subMenuPanelProps={{ title: t("features.background.title") }}
					isActive={activeConfig === t("features.background.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.background.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.background}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("background", configKey, value);
						updateSelectedBackgroundConfigs();
					}}
					expandTo={<BackgroundConfig onSubMenuOpen={onSubMenuOpen} />}
					subMenuPanelProps={{ title: t("features.background.title") }}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
					isActive={activeConfig === t("features.size.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.size.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.size}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("size", configKey, value);
						updateSelectedSizeConfigs();
					}}
					expandTo={<SizeConfig />}
					subMenuPanelProps={{ title: t("features.size.title") }}
				/>
				<Divider />
				<ExpandableMenuButton
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
					isActive={activeConfig === t("features.foregroundPlacement.title")}
				>
					<Box className={styles.buttonLabel}>{t("features.foregroundPlacement.button")}</Box>
				</ExpandableMenuButton>
				<ConfigDisplay
					config={imageToImageStore.config.foreground_placement}
					onDelete={(configKey: string, value: string) => {
						handleDeleteConfig("foreground_placement", configKey, value);
						updateSelectedForegroundPlacementConfigs();
					}}
					expandTo={<ForegroundPlacement />}
					subMenuPanelProps={{ title: t("features.foregroundPlacement.title") }}
				/>
			</ExpandableMenuLayout>
			{isGeneratingImages && (
				<BriaButton
					buttonType="primaryMedium"
					className={styles.stopGeneratingBtn}
					disabled={isGenerateEnabled() as boolean}
					fullWidth
					onClick={() => imageToImageStore.abortImageGeneration()}
				>
					<StopIcon /> {t("stopGenerating")}
				</BriaButton>
			)}
			{!isGeneratingImages && (
				<BriaButton
					buttonType="primaryMedium"
					disabled={!isGenerateEnabled()}
					fullWidth
					onClick={handleGenerateImageToImage}
					className="generate-image-to-image"
				>
					{imageToImageStore.getGeneratedImagesCount() > MAX_ALLOWED_SELECTED_IMAGES
						? t("tooManySelected")
						: t("button")}
					{!isFoxApps() &&
						imageToImageStore.getGeneratedImagesCount() > 0 &&
						isGenerateEnabled() &&
						` ${imageToImageStore.getGeneratedImagesCount()} ${t("results")}`}
				</BriaButton>
			)}
		</Box>
	);

	return <Box className={styles.wrapper}>{isFoxApps() ? renderFoxAppsConfig() : renderStandardAppsConfig()}</Box>;
};

export default observer(ImageToImageConfig);
