import { useTranslation } from "react-i18next";
import { DropDownItem } from "../BriaDropDown/BriaDropDown";

type Props = {
	tKeyPrefix: string;
	excludedItems?: string[];
};

const useStaticDropdown = <T>({ tKeyPrefix, excludedItems = [] }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "staticDropdowns" });

	const getDropdownItems = <T>(items: readonly T[]): DropDownItem<T>[] => 
		items
			.filter(item => !excludedItems.includes(item as string))
			.map(item => ({ key: t(`${tKeyPrefix}.${item}`), value: item }));

	const items = getDropdownItems<T>(Object.keys(t(tKeyPrefix, { returnObjects: true })) as T[]);

	return {
		items,
	};
};

export default useStaticDropdown;

export type Status = "Active" | "Pending" | "Inactive" | "Deleted";