import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import BriaDesignEditorPopup from "../../DesignEditor/BriaDesignEditorPopup.tsx";
import ApiLimitReachedPopup from "../../components/Popups/ApiLimitReachedPopup/ApiLimitReachedPopup.tsx";
import BriaButton from "../../components/common/BriaButton/BriaButton.tsx";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../../hooks/useStores";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";
import HubHeader from "../HeaderLayout/HubHeader/HubHeader.tsx";
import styles from "./IframeLayout.module.scss";

const IframeLayout = () => {
	const { t } = useTranslation("translation");
	const { designEditorStore, authStore } = useAppStore();

	return (
		<>
			<LoadingPlaceholder
				className={styles.loaderPlaceholderStyle}
				loaderClassName={styles.loaderStyle}
				isLoading={authStore.isLoadingOrgSubscriptions || !iframeStore.isInitialized}
			>
				{!iframeStore.isPreview() && iframeStore.showIframeAuthErrorPage ? (
					<Box className={styles.iframeAuthErrorContainer}>
						<Typography className={styles.title}>{t("iframeAuthErrorPopup.title")}</Typography>
						<Typography className={styles.description}>{t("iframeAuthErrorPopup.description")}</Typography>
						<Box className={styles.buttonContainer}>
							<BriaButton
								className={styles.button}
								onClick={() => {
									const queryParams = new URLSearchParams(window.location.search);
									if (queryParams.has("iframeId")) {
										window.location.reload();
									} else {
										window.location.replace(
											`${window.location.origin}?${iframeStore.queryParams.toString()}`,
										);
									}
								}}
								buttonType="primaryMedium"
							>
								{t("iframeAuthErrorPopup.buttonText")}
							</BriaButton>
						</Box>
					</Box>
				) : (
					<Box className={styles.iframeLayout}>
						<HubHeader />
						<Outlet />
						{designEditorStore.designEditorPopup && <BriaDesignEditorPopup />}
					</Box>
				)}
				<ApiLimitReachedPopup />
			</LoadingPlaceholder>
		</>
	);
};

export default observer(IframeLayout);
