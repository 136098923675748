import { Alert, Box, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import { IGeneratedImage } from "../../../models/tailoredGeneration.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import EmptyList from "../../common/EmptyList/EmptyList.tsx";
import GalleryImage from "../../common/GalleryImage/GalleryImage.tsx";
import LoadingPlaceholder from "../../common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./TgPlayground.module.scss";

const TgPlayground = () => {
	const { modelId } = useParams();
	const errorToast = useErrorToast();
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });

	useEffect(() => {
		const loadData = async () => {
			if (modelId) {
				const localModel = await tailoredGenerationStore.findModelById(modelId);

				if (localModel?.model_url) {
					tailoredGenerationStore.selectedModel = localModel;
				} else {
					errorToast.showError(t("models.validation.modelNotExists", { modelId: modelId }));
				}
			}
		};
		const initialize = async () => {
			try {
				loadData();
				uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.PLAYGROND);
			} catch (error) {
				console.error(error);
			}
		};

		initialize();
		return () => {
			tailoredGenerationStore.searchPrompt = "";
			tailoredGenerationStore.generatedImages = [];
			tailoredGenerationStore.selectedModel = undefined;
		};
	}, []);

	return (
		<Box className={styles.container}>
			<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={tailoredGenerationStore.isLoading}>
				<Box className={styles.searchForm}>
					<InputLayout showLabel={false} className={styles.fullWidthField}>
						<TextField
							value={tailoredGenerationStore.searchPrompt}
							onChange={(e) => {
								tailoredGenerationStore.searchPrompt = e.target.value;
							}}
							placeholder={t("models.playground.placeholder")}
							fullWidth
							InputProps={{ classes: { root: styles.longTextField } }}
						/>
					</InputLayout>
					<BriaButton
						className={styles.primaryButton}
						requiresApiAccess={true}
						buttonType="primary"
						onClick={async () => {
							if (modelId) {
								if (tailoredGenerationStore.selectedModel?.model_url) {
									tailoredGenerationStore.generateModelImages(
										tailoredGenerationStore.selectedModel.model_url,
									);
								}
							}
						}}
						disabled={
							!tailoredGenerationStore.selectedModel || tailoredGenerationStore.searchPrompt.trim() === ""
						}
					>
						{t("buttons.generate")}
					</BriaButton>
				</Box>
				<Box className={styles.errorMessageBox}>
					{!tailoredGenerationStore.isGenerating && tailoredGenerationStore.isError && (
						<Alert severity="error" variant="standard" className={styles.errorMessage}>
							{tailoredGenerationStore.errorMessage
								? tailoredGenerationStore.errorMessage
								: t("errors.somethingWentWrong")}
						</Alert>
					)}
				</Box>
				{tailoredGenerationStore.generatedImages.length > 0 ? (
					<Box className={styles.gallery}>
						<Typography className={styles.result}>{t("models.playground.results")}</Typography>
						<LoadingPlaceholder
							className={styles.loadingPlaceholder}
							isLoading={tailoredGenerationStore.isGenerating}
						>
							<Grid container spacing={2}>
								{tailoredGenerationStore.generatedImages.map((image: IGeneratedImage) => {
									return (
										<Grid item className={styles.itemGrid}>
											<GalleryImage
												passedImageHasTechnicalError={false}
												retry={true}
												retryMaxAttempts={undefined}
												generatedFlag={false}
												isFakeUrl={image.fake_url}
												key={image.finalUrl}
												handleImgClick={(img: IGeneratedImage) => {
													console.log(img);
												}}
												offensiveGeneration={false}
												image={image}
												imageWidth={35}
												imageLoadedCallback={() => {}}
												imageErrorCallBack={() => {}}
											/>
										</Grid>
									);
								})}
							</Grid>
						</LoadingPlaceholder>
					</Box>
				) : (
					<EmptyList message={t("models.playground.emptyList")} />
				)}
			</LoadingPlaceholder>
		</Box>
	);
};

const ObservedComponent = observer(TgPlayground);
export default ObservedComponent;
