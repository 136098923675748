import { WarningAmberRounded } from "@mui/icons-material";
import { Alert, Box, Collapse, Snackbar, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ERRORS_TO_EXCLUDE } from "../../../config/axios.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import {
	TAILORED_GENERATION_DATASET_STATUS,
	TAILORED_GENERATION_MODEL_STEP,
	TAILORED_GENERATION_TYPES,
} from "../../../constants/TGConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import AlertNotification from "../../common/Alert/Alert.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import CaptionDialog from "../CaptionDialog/CaptionDialog.tsx";
import DatasetImagesGrid from "../DatasetImagesGrid/DatasetImagesGrid.tsx";
import TGPublishToTrainingDialog from "../TGPublishToTrainingDialog/TGPublishToTrainingDialog.tsx";
import styles from "./CreateDataset.module.scss";

const CreateDataset = () => {
	const TGPDataSetCreationDialogAndChangeDatasetNameHandler = (newDatasetName: string) => {
		const handleClick = async () => {
			const isNameEmpty = newDatasetName === "";
			const isDescriptionEmpty = tailoredGenerationStore.modelForm.dataset.description.trim() === "";

			if (isNameEmpty || isDescriptionEmpty) {
				if (isNameEmpty) {
					tailoredGenerationStore.setIsValidDatasetName(false);
				}
				if (isDescriptionEmpty) {
					tailoredGenerationStore.setIsEmptyDescription(true);
					uiStore.hideDialog("TGPDataSetCreationDialogAndChangeDatasetName");
				}
				return;
			} else {
				tailoredGenerationStore.handleFormChange("dataset", {
					...tailoredGenerationStore.modelForm.dataset,
					name: newDatasetName,
				});
			}

			tailoredGenerationStore.isLoading = true;
			tailoredGenerationStore.datasets = [];
			await tailoredGenerationStore.updateDataset(tailoredGenerationStore.modelForm.dataset, true);
			uiStore.hideDialog("TGPDataSetCreationDialogAndChangeDatasetName");
			uiStore.showSnackbar("datasetCreationSuccessSnackbar");
			navigate(RouterConstants.TG_DATASET.fullPath);
		};

		return handleClick;
	};
	const navigate = useSecureNavigate();
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const [newDatasetName, setNewDatasetName] = useState<string>("");
	const [showDeleteImageToast, setShowDeleteImageToast] = useState<boolean>(false);

	const handleDeleteImage = async (asset_id: string | undefined) => {
		try {
			tailoredGenerationStore
				.handleDeleteImage(asset_id)
				.then(() => {
					setShowDeleteImageToast(true);
				})
				.catch((err) => console.log(err));
		} catch (e) {
			console.log(e);
		}
	};

	const handleCloseSnackbar = () => {
		setShowDeleteImageToast(false);
	};

	useEffect(() => {
		uiStore.showTGButtons();
		uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET);
		if (uiStore.duplicateDatasetFlow) {
			if (
				tailoredGenerationStore.modelForm.type == TAILORED_GENERATION_TYPES.SINGLE_SUBJECT &&
				(tailoredGenerationStore.modelForm.dataset.assets.length < 10 ||
					tailoredGenerationStore.modelForm.dataset.assets.length > 20)
			) {
				tailoredGenerationStore.errorMessage = ERRORS_TO_EXCLUDE[3];
			} else if (
				tailoredGenerationStore.modelForm.type == TAILORED_GENERATION_TYPES.STYLE &&
				(tailoredGenerationStore.modelForm.dataset.assets.length < 20 ||
					tailoredGenerationStore.modelForm.dataset.assets.length > 200)
			) {
				tailoredGenerationStore.errorMessage = ERRORS_TO_EXCLUDE[0];
			}
		}

		return () => {
			tailoredGenerationStore.successUploaded = 0;
			tailoredGenerationStore.failedUploaded = 0;
			tailoredGenerationStore.errorMessage = null;
			tailoredGenerationStore.createNewDataset = false;
			uiStore.hideTGButtons();
			if (tailoredGenerationStore.selectedDatasetOption && !uiStore.duplicateDatasetFlow) {
				tailoredGenerationStore.selectedDatasetOption = undefined;
				tailoredGenerationStore.modelForm.dataset = {
					name: "",
					assets: [],
					numberOfAssets: 0,
					description: "",
					brandStyleType: "",
					status: TAILORED_GENERATION_DATASET_STATUS.DRAFT,
				};
			}
		};
	}, []);

	return (
		<Box className={styles.container}>
			<Box className={styles.row}>
				<InputLayout
					showLabel={false}
					validateOn={
						!tailoredGenerationStore.selectedDatasetOption && !tailoredGenerationStore.isValidDatasetName
					}
					validationMessage={t("models.validation.dataSetNameAlreadyExists")}
				>
					<TextField
						variant="outlined"
						value={tailoredGenerationStore.modelForm.dataset.name}
						onChange={(e) => {
							tailoredGenerationStore.handleFormChange("dataset", {
								...tailoredGenerationStore.modelForm.dataset,
								name: e.target.value,
							});
							tailoredGenerationStore.setIsValidDatasetName(true);
						}}
						placeholder={t("models.stepper.sendToTraining.datasetPlaceholder")}
						fullWidth
						InputProps={{ classes: { root: styles.textField } }}
					/>
				</InputLayout>
			</Box>
			<Box className={styles.row}>
				<InputLayout
					validateOn={tailoredGenerationStore.isEmptyDescription}
					validationMessage={t("models.validation.addDescription")}
					className={styles.fullWidthField}
					label={t("models.stepper.sendToTraining.brandStyleDescriptionLabel")}
				>
					<TextField
						value={tailoredGenerationStore.modelForm.dataset.description}
						onChange={(e) => {
							tailoredGenerationStore.handleFormChange("dataset", {
								...tailoredGenerationStore.modelForm.dataset,
								description: e.target.value,
							});
							tailoredGenerationStore.setIsEmptyDescription(false);
						}}
						placeholder={t("models.stepper.sendToTraining.brandStyleDescriptionPlaceholder")}
						fullWidth
						InputProps={{ classes: { root: styles.longTextField } }}
					/>
				</InputLayout>
				{!tailoredGenerationStore.isUploading && (
					<Typography className={styles.uploadedImagesCount}>
						{tailoredGenerationStore.modelForm.dataset.assets.length}{" "}
						{t("models.stepper.sendToTraining.images")}
					</Typography>
				)}
			</Box>

			<Box className={styles.errorsList}>
				{(tailoredGenerationStore.errorMessage || tailoredGenerationStore.exceedsMaxNumberOfAssetsWarning) && (
					<AlertNotification severity="error" icon={<WarningAmberRounded />} className={styles.errorMessage}>
						{tailoredGenerationStore.errorMessage
							? tailoredGenerationStore.errorMessage
							: tailoredGenerationStore.exceedsMaxNumberOfAssetsWarning}
					</AlertNotification>
				)}

				{tailoredGenerationStore.failedUploaded > 0 && (
					<Collapse in={!tailoredGenerationStore.dismissWarning}>
						<AlertNotification
							severity="warning"
							bgColor="#FFE083"
							action={
								<BriaButton
									className={styles.dismissButton}
									color="inherit"
									onClick={() => {
										tailoredGenerationStore.dismissWarning = true;
									}}
								>
									Dismiss
								</BriaButton>
							}
							icon={<WarningAmberRounded style={{ fill: "#1A0638" }} />}
						>
							{t("models.uploadWarning", {
								failed: tailoredGenerationStore.failedUploaded,
							})}
						</AlertNotification>
					</Collapse>
				)}
			</Box>

			<DatasetImagesGrid
				dataset={tailoredGenerationStore.modelForm.dataset}
				handleDeleteImage={handleDeleteImage}
			/>
			<CaptionDialog />

			{/* Create Model and start model training dialog */}
			<TGPublishToTrainingDialog />

			<ConfirmationPopup
				hideCancel={true}
				onClose={() => uiStore.hideDialog("TGPDataSetCreationDialogAndChangeDatasetName")}
				title={t("models.stepper.sendToTraining.TGPDataSetCreationDialogAndChangeDatasetName.title")}
				description={t(
					"models.stepper.sendToTraining.publishToTrainingDialogAndChangeDatasetName.newDataSetMessageInform",
				)}
				confirmButtonText={t(
					"models.stepper.sendToTraining.TGPDataSetCreationDialogAndChangeDatasetName.button",
				)}
				disableConfirm={tailoredGenerationStore.isLoading}
				loading={tailoredGenerationStore.isLoading}
				open={uiStore.TGPDataSetCreationDialogAndChangeDatasetName}
				onClick={TGPDataSetCreationDialogAndChangeDatasetNameHandler(newDatasetName)}
			>
				<Box>
					<InputLayout
						showLabel={false}
						validateOn={!tailoredGenerationStore.isValidDatasetName}
						validationMessage={t("models.validation.dataSetNameAlreadyExists")}
					>
						<TextField
							value={newDatasetName}
							onChange={(e) => {
								setNewDatasetName(e.target.value);
								tailoredGenerationStore.setIsValidDatasetName(true);
							}}
							placeholder={t("models.stepper.sendToTraining.newDataSetName")}
							fullWidth
							InputProps={{ classes: { root: styles.textFieldDataSetCreation } }}
						/>
					</InputLayout>
				</Box>
			</ConfirmationPopup>
			<Snackbar open={showDeleteImageToast} autoHideDuration={2000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{t("models.stepper.sendToTraining.deleteImageMessage")}
				</Alert>
			</Snackbar>
		</Box>
	);
};

const ObservedComponent = observer(CreateDataset);
export default ObservedComponent;
