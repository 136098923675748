import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode, Ref, RefObject, useContext } from "react";
import Selecto, { OnScroll, OnSelectEvent, SelectoProps } from "react-selecto";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor";
import { SecondaryTabTypeEnum } from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems";
import { useAppStore } from "../../../hooks/useStores";
import styles from "./Selectable.module.scss";

type SelectableProps = {
	selectoRef?: Ref<Selecto>;
	onSelect?: (e: OnSelectEvent) => void;
	innerScrollRef?: RefObject<HTMLElement>;
	onInnerScroll?: (e: OnScroll) => void;
	children?: ReactNode;
	disabled?: boolean;
	type?: "single" | "multi";
} & Partial<SelectoProps>;

const Selectable = ({
	selectoRef,
	onSelect,
	innerScrollRef,
	onInnerScroll,
	children,
	disabled,
	className,
	type = "multi",
	...rest
}: SelectableProps) => {
	const { playgroundStore, imagesStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const handleSelect = (element: OnSelectEvent) => {
		if (type === "single") {
			document.querySelectorAll(".selected").forEach((el) => el.classList.remove("selected"));
			playgroundStore.clearSelectedImages();
		}

		if (
			playgroundStore.isText2ImagePopupOpened &&
			(isPopupView || imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE)
		) {
			document.querySelectorAll(".selected").forEach((selectedElement) => {
				selectedElement.classList.remove("selected");
			});

			// Select only the first added element
			const firstAddedElement = element?.added?.[0];
			firstAddedElement?.classList?.add("selected");
			element.added = [firstAddedElement];
			element.selected = [firstAddedElement];
		} else {
			element.added.forEach((el) => {
				el.classList.add("selected");
			});
		}

		element.removed.forEach((el) => {
			el.classList.remove("selected");
		});
		onSelect?.(element);
	};

	const handleInnerScroll = ({ container, direction }: OnScroll) => {
		container.scrollBy(direction[0] * 25, direction[1] * 25);
		onInnerScroll?.({ container, direction });
	};

	return (
		<>
			<Selecto
				ref={selectoRef}
				dragContainer={".selecto-area"}
				dragCondition={() => !disabled}
				onSelect={handleSelect}
				selectByClick
				hitRate={0}
				onInnerScroll={handleInnerScroll}
				innerScrollOptions={{
					throttleTime: 30,
					threshold: 0,
					container: innerScrollRef,
					getScrollPosition: () =>
						innerScrollRef ? [innerScrollRef?.current!.scrollLeft, innerScrollRef?.current!.scrollTop] : [],
				}}
				{...(type === "single"
					? { onDrag: (e) => e.stop(), toggleContinueSelect: [] }
					: { toggleContinueSelect: [["meta"], ["shift"]] })}
				{...rest}
			/>
			<Box className={`selecto-area ${className} ${!disabled && type !== "single" && styles.customCursor}`}>
				{children}
			</Box>
		</>
	);
};

export default observer(Selectable);
