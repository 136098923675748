import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useState } from "react";
import {
	CombinedExpandableMenuProps,
	ExpandableMenuLayoutProps,
	SubMenuPanelProps,
} from "../../../models/expandable-menu";
import SubMenuPanel from "../SubMenuPanel/SubMenuPanel";
import styles from "./ExpandableMenuLayout.module.scss";

const ExpandableMenuLayout = ({ children, className, setActiveConfig, isSubMenuOpen }: ExpandableMenuLayoutProps) => {
	const [subMenuComponent, setSubMenuComponent] = useState<ReactNode>(null);
	const [subMenuProps, setSubMenuProps] = useState<SubMenuPanelProps | null>(null);

	const handleExpand = (props: CombinedExpandableMenuProps) => {
		const subMenuProps = props.subMenuPanelProps ?? null;
		setSubMenuComponent(props.expandTo);
		setSubMenuProps(subMenuProps);
		setActiveConfig?.(props.subMenuPanelProps?.title);
	};

	const handleOnBackButtonClick = () => {
		setSubMenuComponent(null);
		setActiveConfig?.("");
	};

	const detectExpandableButtons = (child: ReactNode): ReactNode => {
		if (React.isValidElement(child) && child.props.expandTo) {
			const props = child.props as CombinedExpandableMenuProps;

			return React.cloneElement(child as React.ReactElement<CombinedExpandableMenuProps>, {
				onClick: () => handleExpand(props),
				children: React.Children.map(child.props.children, detectExpandableButtons),
			});
		}

		return child;
	};

	return (
		<Box className={styles.container}>
			<Box className={clsx(className, styles.expandableMenuLayout)}>
				<Box className={styles.mainMenu}>{React.Children.map(children, detectExpandableButtons)}</Box>
			</Box>
			{subMenuComponent && subMenuProps && (
				<Box
					className={clsx(styles.subMenu, {
						[styles.isOpen]: isSubMenuOpen,
					})}
				>
					<SubMenuPanel onBackButtonClick={handleOnBackButtonClick} title={subMenuProps.title}>
						{subMenuComponent}
					</SubMenuPanel>
				</Box>
			)}
		</Box>
	);
};

export default observer(ExpandableMenuLayout);
