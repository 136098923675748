import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaDropDown from "../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { ImageEditingViewMode } from "../../../../models/image-to-image.ts";
import { capitalizeFirstLetter } from "../../../../utils";
import iframeStore from "../../iframe-store.tsx";

const IframeImageEditingConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.imageEditingConfig" });

	const handleDefaultViewModeChange = (e: SelectChangeEvent<ImageEditingViewMode>) => {
		handleFormChange("config", {
			...iframeForm.config,
			image_editing_config: {
				...(iframeForm.config.image_editing_config ?? {}),
				default_view_mode: e.target.value as ImageEditingViewMode,
			},
		});
	};

	return (
		<InputLayout label={t("defaultViewMode.label")}>
			<BriaDropDown
				value={iframeForm?.config.image_editing_config?.default_view_mode}
				onChange={handleDefaultViewModeChange}
				placeholder={t("defaultViewMode.placeholder")}
				items={Object.values(ImageEditingViewMode).map((mode) => ({
					key: capitalizeFirstLetter(mode),
					value: mode,
				}))}
			/>
		</InputLayout>
	);
};

export default observer(IframeImageEditingConfig);
