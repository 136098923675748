import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import tgModels from "../../../assets/images/svgs/TG_Models_top-header.svg";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants.ts";
import { TailoredModelsFeatures } from "../../../constants/TailoredModelsFeaturesConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { EventsLogEnum } from "../../../models/common.ts";
import { ITailoredGenerationModel } from "../../../models/tailoredGeneration.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import HorizontalLinearStepper from "../../common/HorizontalLinearStepper/HorizontalLinearStepper.tsx";
import LoadingPlaceholder from "../../common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { ModelCard } from "../ModelCard/ModelCard.tsx";
import styles from "./ModelsGrid.module.scss";

const ModelsGrid = () => {
	const navigate = useSecureNavigate();
	const [loading, setIsLoading] = useState<boolean>(false);
	const { tailoredGenerationStore, uiStore, appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });

	useEffect(() => {
		const loadData = async () => {
			try {
				if (!tailoredGenerationStore.isError && tailoredGenerationStore.models.length === 0) {
					await tailoredGenerationStore.loadModels();
				}
			} catch (error) {
				console.error(error);
			}
		};

		const initialize = async () => {
			try {
				loadData();
				uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.LIST_MODELS);
			} catch (error) {
				console.error(error);
			}
		};

		initialize();
	}, []);

	const createModelSteps = [
		t("models.stepper.selectType.stepperName"),
		t("models.stepper.createDataSet.stepperName"),
		t("models.stepper.sendToTraining.stepperName"),
	];

	return (
		<Box className={styles.container}>
			<LoadingPlaceholder className={styles.loaderStyle} isLoading={tailoredGenerationStore.isLoading}>
				{tailoredGenerationStore.models.length > 0 ? (
					<Grid container spacing={4}>
						{tailoredGenerationStore.models.map((model: ITailoredGenerationModel, index: number) => (
							<ModelCard key={index} model={model} />
						))}
					</Grid>
				) : (
					<Box className={styles.emptyModels}>
						<Box className={styles.imgContainer}>
							<img
								className={styles.image}
								src={tgModels}
								alt={t("models.alt.tailoredGenerationModels")}
							/>
						</Box>
						<Box className={styles.stepperContainer}>
							<Typography className={styles.title}>{t("models.createModelIn3Steps")}</Typography>
							<Box className={styles.stepperWrapper}>
								<Box className={styles.stepper}>
									<HorizontalLinearStepper
										steps={createModelSteps}
										activeStep={-1}
										size="large"
										readonly
									/>
								</Box>
							</Box>
							<BriaButton
								buttonType="primary"
								className={styles.button}
								loading={loading}
								requiresApiAccess={true}
								onClick={async () => {
									setIsLoading(true);
									const modelsCount = tailoredGenerationStore.models.length;
									if (modelsCount === 0 && (await tailoredGenerationStore.getModelsCount()) === 0) {
										appStore.logEvent({ event: EventsLogEnum.STARTED_FIRST_TRAINING_MODEL });
									}
									setIsLoading(false);
									navigate(RouterConstants.TG_CREATE_MODEL.fullPath);
								}}
							>
								{t("models.buttons.createYourFirstModel")}
							</BriaButton>
						</Box>
						<Box>
							<Typography className={styles.sectionTitle} variant="h5">
								{t("models.features.sectionTitle")}
							</Typography>
							<Grid container className={styles.featuresWrapper}>
								{TailoredModelsFeatures.map((feature: any, index: number) => (
									<Grid item className={styles.featureItem}>
										<Link className={styles.featureTitle} to={feature.link} key={index}>
											<img className={styles.image} src={feature.image} alt="" />
											<Typography>{t(`models.features.${feature.title}`)}</Typography>
										</Link>
									</Grid>
								))}
							</Grid>
						</Box>
					</Box>
				)}
			</LoadingPlaceholder>
		</Box>
	);
};

const ObservedComponent = observer(ModelsGrid);
export default ObservedComponent;
