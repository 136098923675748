function Images(_size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path
				d="M15 8.5H15.01M4 15.5L8 11.5C8.45606 11.0612 8.97339 10.8301 9.5 10.8301C10.0266 10.8301 10.5439 11.0612 11 11.5L16 16.5M14 14.5L15 13.5C15.4561 13.0612 15.9734 12.8301 16.5 12.8301C17.0266 12.8301 17.5439 13.0612 18 13.5L20 15.5M7 4.5H17C18.6569 4.5 20 5.84315 20 7.5V17.5C20 19.1569 18.6569 20.5 17 20.5H7C5.34315 20.5 4 19.1569 4 17.5V7.5C4 5.84315 5.34315 4.5 7 4.5Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Images;
