import { Box, Typography } from "@mui/material";
import styles from "./PlatformHubSection.module.scss";
import clsx from "clsx";

export type IPlatformHubSectionProps = {
	title: string;
	subtitle?: string;
	className?: string;
	children?: React.ReactNode;
};
const PlatformHubSection = ({ title, subtitle, className, children }: IPlatformHubSectionProps) => {
	return (
		<Box className={clsx(styles.platformSectionContainer, className)}>
			<Box className={styles.platformSectionHeader}>
				<Typography variant="h3" className={styles.title}>
					{title}
				</Typography>
				<Typography className={styles.subtitle}>{subtitle}</Typography>
			</Box>
			{children}
		</Box>
	);
};

export default PlatformHubSection;
