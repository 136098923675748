import { SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import StaticDropdown, { AspectRatio } from "../../../../../components/common/DropDowns/StaticDropdown.tsx";
import InputLayout from "../../../../../layout/InputLayout/InputLayout.tsx";
import iframeStore from "../../../iframe-store.tsx";
import styles from "./IframeImageGenerationConfig.module.scss";
import IframeImageGenerationFoundationModels from "./IframeImageGenerationFoundationModels.tsx";
import IframeImageGenerationTgModels from "./IframeImageGenerationTgModels.tsx";

const IframeImageGenerationConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.imageGenerationConfig" });

	const handleGenAspectRatioChange = (e: SelectChangeEvent<AspectRatio>) => {
		handleFormChange("config", {
			...iframeForm.config,
			image_generation_config: {
				...(iframeForm.config.image_generation_config ?? {}),
				default_aspect_ratio: e.target.value as AspectRatio,
			},
		});
	};

	return (
		<Box className={styles.container}>
			<InputLayout label={t("foundationModels.label")}>
				<IframeImageGenerationFoundationModels />
			</InputLayout>
			<InputLayout label={t("tgModels.label")}>
				<IframeImageGenerationTgModels />
			</InputLayout>
			<InputLayout label={t("defaultGenAspectRatio.label")}>
				<StaticDropdown<AspectRatio>
					type="aspectRatio"
					value={iframeForm?.config.image_generation_config?.default_aspect_ratio}
					onChange={handleGenAspectRatioChange}
					placeholder={t("defaultGenAspectRatio.placeholder")}
					className={styles.dropDown}
					width={"-webkit-fill-available"}
				/>
			</InputLayout>
		</Box>
	);
};

export default observer(IframeImageGenerationConfig);
