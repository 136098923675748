import { NavigateFunction, NavigateOptions, To, useNavigate } from "react-router-dom";
import { APPS } from "../constants/AppsConstants.ts";
import RouterConstants from "../constants/RouterConstants.ts";
import { IframePostMessageTypes, iframeRoutes } from "../models/new-iframe.ts";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";

function useSecureNavigate() {
	const navigate = useNavigate();

	const fireIframeNavigatePostMessage = () => {
		if (iframeStore.isIframe()) {
			iframeStore.sendPostMessage(IframePostMessageTypes.Navigate, {
				to: iframeStore.getIframeAppName(iframeStore.iframe.config.landing_page as APPS),
			});
		}
	};

	const navigateSecurely: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
		if (typeof to === "number") {
			navigate(to);
		} else {
			const relativeUrlRegex = /^\/?(?:[^/]+\/?)+$/;
			const toPath = typeof to === "string" ? to : to.pathname;
			if (toPath && relativeUrlRegex.test(toPath)) {
				fireIframeNavigatePostMessage();
				navigate(to, options);
			} else {
				fireIframeNavigatePostMessage();
				navigate(
					iframeStore.isIframe()
						? iframeRoutes[iframeStore.iframe.config.landing_page] ?? iframeRoutes[APPS.TEXT_TO_IMAGE] ?? ""
						: RouterConstants.CONSOLE.fullPath,
					options,
				);
			}
		}
	};

	return navigateSecurely as NavigateFunction;
}

export default useSecureNavigate;
