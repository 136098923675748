import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollPosition, trackWindowScroll } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CopyToClipboardButton from "../../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../components/common/FullScreenViewer/FullScreenViewer.tsx";
import BriaImage, { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage.tsx";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../models/image-to-image.ts";
import { isCanvasRequiredForThisApp } from "../../../../../utils";
import styles from "./SandboxAPIGallery.module.scss";

type SandboxAPIGalleryProps = {
	images: (BriaImageProps & PlaygroundImage)[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition: ScrollPosition;
	scrollBottom?: () => void;
};

const SandboxAPIGallery = ({
	images,
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
	scrollBottom,
}: SandboxAPIGalleryProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI.result" });
	const { playgroundStore } = useAppStore();
	const [imageToView, setImageToView] = useState(0);
	const [fixedHeightRow, setFixedHeightRow] = useState(true);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	return (
		<>
			<Box
				className={clsx(className, styles.itiGallery)}
				sx={{
					"&  > span": {
						width: !isCanvasRequiredForThisApp() ? `calc( ${100 / maxInLine}% - 0.8vw)` : "100%",
					},
				}}
			>
				{images.map((image, index) => (
					<span
						className={clsx({
							[styles.imageWrapper]: true,
							[styles.fixedHeight]: fixedHeightRow,
						})}
					>
						<BriaImage
							key={index}
							{...image}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{
								...image.lazyImageProps,
								scrollPosition: scrollPosition,
								onLoad: () => {
									index === 0 && scrollBottom?.();
									setFixedHeightRow(false);
								},
							}}
							fullScreenProps={image.fullScreenProps}
							image={image}
							hideDownloadIcon={true}
							displayOverlay="fullScreenHover"
							handleClick={() => playgroundStore.handleSelectSingleImage(image)}
						/>
						{!Number.isNaN(image.seed) && image.seed && !image.loading && (
							<Box className={styles.seedTextStyle}>
								<Box>{t("seed")}</Box>
								<Box className={styles.seedValue}>{image.seed}</Box>
								<CopyToClipboardButton className={styles.copyBtn} textToCopy={image.seed.toString()} />
							</Box>
						)}
					</span>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
				/>
			)}
		</>
	);
};

export default trackWindowScroll(observer(SandboxAPIGallery));
