import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import { APPS } from "../../../constants/AppsConstants.ts";
import { BACKGROUND_CONFIGS, DEFAULT_BRUSH, SIZE_CONFIGS } from "../../../constants/ImageToImageConstants.ts";
import { BrushActions } from "../../../hooks/brush/useBrushActions.tsx";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import {
	ImageToImageConfigType,
	OfflineProps,
	PlaygroundImage,
	PlaygroundResult,
	SelectedConfigsProps,
} from "../../../models/image-to-image.ts";
import { IframePostMessageActionTypes, IframePostMessageItemTypes } from "../../../models/new-iframe.ts";
import { BrushCanvasRefs, IBrushSettings } from "../../../models/sandboxAPI.ts";
import QueryService from "../../../utils/QueryService.ts";
import { isFoxApps } from "../../../utils/index.ts";
import iframeStore from "../../IframeNew/iframe-store.tsx";

export interface IImageToImageStore {
	config: ImageToImageConfigType;
	handleConfigChange: <K extends keyof ImageToImageConfigType>(key: K, value: ImageToImageConfigType[K]) => void;
	handleUploadImages: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	generateImageToImage: (images?: PlaygroundImage[], config?: ImageToImageConfigType) => Promise<void>;
	nflRmbgBeforeStyle: (images: PlaygroundImage[]) => Promise<void>;
	mixFaceBody: () => Promise<void>;
	refineColor: () => Promise<void>;
	addAIEditorImageInARow: (imageUrl: string) => Promise<void>;
	MAX_FILES_LIMIT: number;
	MAX_FILE_SIZE: number;
	selected_configs: SelectedConfigsProps;
	getGeneratedImagesCount: () => number;
	handleSelectedConfigsChange: <K extends keyof SelectedConfigsProps>(key: K, value: SelectedConfigsProps[K]) => void;
	abortImageGeneration: () => void;
	isGeneratingImages: boolean;
	brushCanvasRefs: BrushCanvasRefs[];
	brushConfigs: IBrushSettings;
	eraseObject: (image: PlaygroundImage, config?: ImageToImageConfigType) => Promise<void>;
	activeConfig: string;
	handleActiveConfigChange: (config: string) => void;
}

export default class ImageToImageStore implements IImageToImageStore {
	private queryService: QueryService = new QueryService("/image-to-image");
	private abortController: AbortController = new AbortController();
	rootStore: IRootStore;
	config: ImageToImageConfigType = {
		original_image: { id: "", url: "" },
		num_results: isFoxApps() ? 4 : 1,
		background: BACKGROUND_CONFIGS,
		size: SIZE_CONFIGS,
	};
	errorToast = useErrorToast();

	MAX_FILE_SIZE = 12 * 1024 * 1024;

	MAX_FILES_LIMIT = 35;

	selected_configs: SelectedConfigsProps = {
		background: [],
		size: [],
		foreground_placement: [],
	};

	isGeneratingImages = false;
	brushCanvasRefs: BrushCanvasRefs[] = [];
	brushConfigs = {
		globalCompositeOperation: DEFAULT_BRUSH.globalCompositeOperation,
		strokeStyle: DEFAULT_BRUSH.strokeStyle,
		lineWidth: DEFAULT_BRUSH.lineWidth,
		brushColorOpacity: DEFAULT_BRUSH.brushColorOpacity,
		lineJoin: DEFAULT_BRUSH.lineJoin,
		lineCap: DEFAULT_BRUSH.lineCap,
		globalAlpha: DEFAULT_BRUSH.globalAlpha,
	};
	activeConfig: string = "";

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	handleSelectedConfigsChange = <K extends keyof SelectedConfigsProps>(key: K, value: SelectedConfigsProps[K]) => {
		if (value !== undefined) {
			this.selected_configs[key] = value;
		}
	};

	getGeneratedImagesCount: () => number = () => {
		const { playgroundStore } = this.rootStore;
		return (
			playgroundStore.getSelectedImages().length *
			(this.selected_configs.background?.length || 1) *
			(this.selected_configs.size?.length || 1) *
			(this.selected_configs.foreground_placement?.length || 1) *
			(this.selected_configs.image_reference?.length || 1)
		);
	};

	handleConfigChange = <K extends keyof ImageToImageConfigType>(key: K, value: ImageToImageConfigType[K]) => {
		this.config[key] = value;
	};

	removeNotLoadingImages = () => {
		const { playgroundStore } = this.rootStore;
		playgroundStore.playgroundResults = playgroundStore.playgroundResults
			.map((item) => {
				const hasNonLoadingImages = item?.images.some((image) => !image.loading);
				if (hasNonLoadingImages) {
					return item;
				}

				return null;
			})
			.filter((item): item is PlaygroundResult => item !== null);
	};

	abortImageGeneration = () => {
		if (this.abortController) {
			this.abortController.abort();
			this.removeNotLoadingImages();
		}
	};

	generateImageToImage = async (
		images?: PlaygroundImage[],
		config: ImageToImageConfigType = this.config,
		isFromUpload?: boolean,
	): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		this.abortController = new AbortController();
		const selectedImages: PlaygroundImage[] = images || playgroundStore.getSelectedImages();
		if (!selectedImages.length) return;
		const savedIndex = isFromUpload
			? playgroundStore.playgroundResults.length - 1
			: playgroundStore.playgroundResults.length;
		runInAction(() => {
			this.isGeneratingImages = true;
		});

		if (!isFromUpload) {
			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: { ...config },
				type: APPS.IMAGE_TO_IMAGE,
				images: Array.from({
					length:
						isFoxApps() || isFromUpload
							? selectedImages.length * config.num_results
							: this.getGeneratedImagesCount(),
				}).map((_) => ({
					id: "",
					url: "",
					loading: true,
				})),
			};
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		}

		let resultImages: PlaygroundImage[] = [];
		for (let index = 0; index < selectedImages.length; index++) {
			const selectedImage = selectedImages[index];
			try {
				const formData = new FormData();
				selectedImage.file && formData.append("file", selectedImage.file);
				const existingVariations = playgroundStore.playgroundResults.flatMap((result) =>
					result.images.filter(
						(img) =>
							img.type === "imageToImage" &&
							(img.config as ImageToImageConfigType).original_image.id === selectedImage.id,
					),
				).length;

				formData.append(
					"config",
					JSON.stringify({
						...config,
						original_image: {
							...selectedImage,
							offline_props: {
								...selectedImage.offline_props,
								variation: existingVariations,
							},
						},
					}),
				);
				const {
					results,
					original_url,
				}: {
					results: PlaygroundImage[];
					original_url: string;
				} = await this.queryService.post(
					"/",
					formData,
					{ "Content-Type": "multipart/form-data" },
					{ signal: this.abortController.signal },
				);

				if (results.length == 0) {
					this.removeNotLoadingImages();
				}
				resultImages = resultImages.concat(results);

				runInAction(() => {
					const startIndex = index * config.num_results;

					results.forEach((generatedImage: PlaygroundImage, resultIndex: number) => {
						const finalIndex = startIndex + resultIndex;

						const availableIndex = playgroundStore.playgroundResults[savedIndex].images.findIndex(
							(img) => img.loading && img.id === "" && img.url === "",
						);

						const targetIndex = availableIndex !== -1 ? availableIndex : finalIndex;

						playgroundStore.playgroundResults[savedIndex].images[targetIndex] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							...(!isFoxApps() && { file: selectedImage.file }),
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: { ...selectedImage, url: original_url },
							} as ImageToImageConfigType,
							type: isFromUpload ? "upload" : APPS.IMAGE_TO_IMAGE,
							sessionId: (selectedImage.sessionId ?? original_url).split("?")[0],
							variationNum: existingVariations + resultIndex + 1,
							offline_props: {
								...(selectedImage.offline_props as OfflineProps),
								variation: existingVariations + resultIndex + 1,
							},
						};
						if (isFromUpload) {
							this.rootStore.analyticsStore.logImageToImageEvent(
								ANALYTICS_EVENTS.IMAGE_UPLOAD_ITI,
								playgroundStore.playgroundResults[savedIndex].images[targetIndex],
							);
						} else {
							this.rootStore.analyticsStore.logImageToImageEvent(
								ANALYTICS_EVENTS.ITI_GENERATE,
								playgroundStore.playgroundResults[savedIndex].images[targetIndex],
							);
						}
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			} finally {
				runInAction(() => {
					this.isGeneratingImages = false;
				});
			}
		}

		if (isFromUpload) {
			iframeStore.sendUploadPostMessage(resultImages);
		} else {
			iframeStore.sendGeneratePostMessage(this.config, resultImages);
		}
	};

	callEraseObjectApi = async (imageUrl: string) => {
		if (this.brushCanvasRefs.length > 0 && this.brushCanvasRefs[0].canvasRef) {
			const brushActions = new BrushActions(
				this.brushCanvasRefs[0].canvasRef.current,
				this.brushCanvasRefs[0].canvasOverlayRef.current,
			);
			const maskFileBase64 = await brushActions.handleCanvasDownload();
			if (maskFileBase64 && imageUrl) {
				const newImageUrl = await this.rootStore.sandboxAPIStore.eraseImageObject(imageUrl, maskFileBase64);
				return { newImageUrl, maskFileBase64 };
			}
		}
	};

	eraseObject = async (image: PlaygroundImage, config: ImageToImageConfigType = this.config): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const savedIndex = playgroundStore.playgroundResults.length;

		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config,
			type: APPS.IMAGE_TO_IMAGE,
			images: [
				{
					id: "",
					url: "",
					loading: true,
				},
			],
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		try {
			const res = await this.callEraseObjectApi(image.url);
			if (res) {
				const { newImageUrl, maskFileBase64 } = res;
				playgroundStore.playgroundResults[savedIndex].images = [
					{
						...image,
						...(image.file ? { file: undefined } : {}),
						id: uuidv4(),
						url: newImageUrl,
						loading: true,
						type: APPS.IMAGE_TO_IMAGE,
						config: {
							...playgroundStore.playgroundResults[savedIndex].config,
							original_image: { ...image, url: newImageUrl },
						} as ImageToImageConfigType,
					},
				];
				iframeStore.sendActionPostMessage(IframePostMessageActionTypes.EraseObject, {
					input: {
						image_src: newImageUrl,
						mask_src: maskFileBase64,
					},
					results: [
						{
							type: IframePostMessageItemTypes.Image,
							src: newImageUrl,
						},
					],
				});
			} else {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error erasing object`);
				});
			}
		} catch (e: any) {
			runInAction(() => {
				playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
					(_, index) => index !== savedIndex,
				);
				throw new Error(`Error erasing object: ${e.message || e.toString()}`);
			});
		}
	};

	mixFaceBody = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const selectedImages: PlaygroundImage[] = playgroundStore.getSelectedImages();
		const isMixEnabled =
			selectedImages.length === 2 &&
			selectedImages.every(
				(image) =>
					image.type === APPS.IMAGE_TO_IMAGE &&
					(image.config as ImageToImageConfigType)?.original_image.id ===
						(selectedImages[0].config as ImageToImageConfigType)?.original_image.id,
			);
		if (!isMixEnabled) return;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config, num_results: 1 },
			type: APPS.IMAGE_TO_IMAGE,
			images: Array.from({ length: 2 }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		Array.from({ length: 2 }).forEach(async (_, index) => {
			try {
				const formData = new FormData();
				this.handleConfigChange(
					"original_image",
					(selectedImages[index].config as ImageToImageConfigType).original_image,
				);
				const faceImage = selectedImages[index % 2];
				const bodyImage = selectedImages[(index + 1) % 2];
				formData.append(
					"config",
					JSON.stringify({
						...this.config,
						num_results: 1,
						style: {
							...this.config.style,
							face_seed: faceImage.style_props?.face_seed,
							face_sd_edit: faceImage.style_props?.face_sd_edit,
							face_mask_steps: faceImage.style_props?.face_mask_steps,
							num_inference_steps: faceImage.style_props?.num_inference_steps,
							canny_scale: faceImage.style_props?.canny_scale,
							body_seed: bodyImage.style_props?.body_seed,
							body_sd_edit: bodyImage.style_props?.body_sd_edit,
							logo_enhance: bodyImage.style_props?.logo_enhance,
							box_threshold: bodyImage.style_props?.box_threshold,
							text_threshold: bodyImage.style_props?.text_threshold,
							max_size_height: bodyImage.style_props?.max_size_height,
							max_size_width: bodyImage.style_props?.max_size_width,
							min_size: bodyImage.style_props?.min_size,
						},
					}),
				);
				const { results } = await this.queryService.post("/", formData, {
					"Content-Type": "multipart/form-data",
				});

				runInAction(() => {
					const startIndex = index;
					results.forEach((generatedImage: PlaygroundImage, index: number) => {
						playgroundStore.playgroundResults[savedIndex].images[startIndex + index] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: (selectedImages[index].config as ImageToImageConfigType).original_image,
							} as ImageToImageConfigType,
							type: APPS.IMAGE_TO_IMAGE,
							sessionId: selectedImages[index].sessionId,
						};
						this.rootStore.analyticsStore.logImageToImageEvent(
							ANALYTICS_EVENTS.ITI_MIX_FACE_BODY,
							playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
						);
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}
		});
	};

	refineColor = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const selectedImages: PlaygroundImage[] = playgroundStore.getSelectedImages();
		if (!selectedImages.length) return;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config, num_results: 1 },
			type: APPS.IMAGE_TO_IMAGE,
			images: Array.from({ length: selectedImages.length }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		Array.from({ length: selectedImages.length }).forEach(async (_, index) => {
			try {
				const formData = new FormData();
				this.handleConfigChange(
					"original_image",
					(selectedImages[index].config as ImageToImageConfigType).original_image,
				);
				formData.append(
					"config",
					JSON.stringify({
						...this.config,
						num_results: 1,
						style: {
							...this.config.style,
							is_normalize_face: true,
							face_seed: selectedImages[index].style_props?.face_seed,
							face_sd_edit: selectedImages[index].style_props?.face_sd_edit,
							num_inference_steps: selectedImages[index].style_props?.num_inference_steps,
							face_mask_steps: selectedImages[index].style_props?.face_mask_steps,
							canny_scale: selectedImages[index].style_props?.canny_scale,
							body_seed: selectedImages[index].style_props?.body_seed,
							body_sd_edit: selectedImages[index].style_props?.body_sd_edit,
							logo_enhance: selectedImages[index].style_props?.logo_enhance,
							box_threshold: selectedImages[index].style_props?.box_threshold,
							text_threshold: selectedImages[index].style_props?.text_threshold,
							max_size_height: selectedImages[index].style_props?.max_size_height,
							max_size_width: selectedImages[index].style_props?.max_size_width,
							min_size: selectedImages[index].style_props?.min_size,
						},
					}),
				);
				const { results } = await this.queryService.post("/", formData, {
					"Content-Type": "multipart/form-data",
				});

				runInAction(() => {
					const startIndex = index;
					results.forEach((generatedImage: PlaygroundImage, index: number) => {
						playgroundStore.playgroundResults[savedIndex].images[startIndex + index] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: (selectedImages[index].config as ImageToImageConfigType).original_image,
							} as ImageToImageConfigType,
							type: APPS.IMAGE_TO_IMAGE,
						};
						this.rootStore.analyticsStore.logImageToImageEvent(
							ANALYTICS_EVENTS.ITI_COLOR_REFINE_IMAGE,
							playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
						);
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}
		});
	};

	nflRmbgBeforeStyle = async (images: PlaygroundImage[]): Promise<void> => {
		await this.generateImageToImage(
			images,
			{
				original_image: { id: "", url: "" },
				background: { remove: true, is_fox: true },
				num_results: 1,
			},
			true,
		);
	};

	uploadOriginalImage = async (images: PlaygroundImage[]): Promise<void> => {
		await this.generateImageToImage(
			images,
			{
				original_image: { id: "", url: "" },
				background: { original: true },
				num_results: 1,
			},
			true,
		);
	};

	handleUploadImages = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";

		if (files.length) {
			const { playgroundStore } = this.rootStore;
			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: { ...this.config },
				type: "upload",
				images: Array.from({
					length: files.length,
				}).map((_) => ({
					id: "",
					url: "",
					loading: true,
				})),
			};

			playgroundStore.playgroundResults = await [...playgroundStore.playgroundResults, resultsSkeletons];

			// const compressedFiles: File[] = await Promise.all(
			// 	files.map(async (file: File) => {
			// 		const compressedBlob = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
			// 		const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });
			// 		return compressedFile;
			// 	}),
			// );
			const imagesBeforeUpload: PlaygroundResult = {
				id: uuidv4(),
				config: { ...this.config },
				type: "upload",
				images: await Promise.all(
					files.map(async (file) => {
						return {
							id: uuidv4(),
							url: URL.createObjectURL(file),
							file: file,
							type: "upload",
							offline_props: {
								prefix_id: file.name.split(".")[0],
								variation: 0,
							},
						};
					}),
				),
			};
			isFoxApps()
				? await this.nflRmbgBeforeStyle(imagesBeforeUpload.images)
				: await this.uploadOriginalImage(imagesBeforeUpload.images);

			runInAction(async () => {
				this.rootStore.playgroundStore.selectImages(imagesBeforeUpload.images);
			});
		}
	};

	addAIEditorImageInARow = async (imageUrl: string) => {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
		const { playgroundStore } = this.rootStore;

		const analyticsUploadedImageUrl = new URL(imageUrl);
		const analyticsUploadedImageId = analyticsUploadedImageUrl.searchParams.get("imageId");
		const imagesBeforeUpload: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config },
			type: "save",
			images: [
				{
					id: uuidv4(),
					url: imageUrl,
					file: new File([blob], filename, { type: blob.type }),
					type: APPS.IMAGE_TO_IMAGE,
					config: { ...this.config },
					sessionId:
						imageUrl.split("?")[0] +
						(analyticsUploadedImageId ? `?imageId=${analyticsUploadedImageId}` : ""),
				},
			],
		};

		if (analyticsUploadedImageId) {
			this.rootStore.analyticsStore.logImageToImageEvent(
				ANALYTICS_EVENTS.PLAYGROUND_IMAGE_SAVE_ITI,
				imagesBeforeUpload.images[0],
			);
		} else {
			const existingItiImage = playgroundStore.getAvailableImages().find((image) => imageUrl === image?.url);
			if (existingItiImage) {
				this.rootStore.analyticsStore.logImageToImageEvent(
					ANALYTICS_EVENTS.PLAYGROUND_IMAGE_SAVE_ITI,
					existingItiImage,
				);
			}
		}

		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, imagesBeforeUpload];
	};

	handleActiveConfigChange = (config: string) => {
		runInAction(() => {
			this.activeConfig = config;
		});
	};
}
