import { Box, FormControlLabel, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import InfoIcon from "../../../assets/images/icons/InfoIcon";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./CheckboxInput.module.scss";

interface IProps {
	label?: string;
	labelClassName?: string;
	info?: string;
	className?: string;
	checkbox: React.ReactElement<any, any>;
	validateOn?: boolean;
	validationMessage?: string;
}

const CheckboxInput = ({
	label,
	labelClassName,
	info,
	validateOn = false,
	validationMessage = "",
	className,
	checkbox,
}: IProps) => {
	const [openInfo, setOpenInfo] = useState(false);
	const infoIconRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (infoIconRef.current && !infoIconRef.current.contains(event.target as Node)) {
				setOpenInfo(false);
			}
		};

		document.addEventListener("click", handleClickOutside as unknown as EventListener);
		return () => {
			document.removeEventListener("click", handleClickOutside as unknown as EventListener);
		};
	}, []);

	const handleIconClick = (event: MouseEvent<SVGSVGElement>) => {
		event.stopPropagation();
		setOpenInfo((prev) => !prev);
	};

	return (
		<Box className={`${className} ${styles.container}`}>
			<FormControlLabel
				className={`${labelClassName ? styles[labelClassName] : ""} ${styles.checkboxLabel}`}
				label={label}
				control={checkbox}
			/>
			{info && (
				<BriaTooltip
					title={info}
					open={openInfo}
					className={styles.info}
					componentsProps={{ tooltip: { className: styles.tooltip, ref: infoIconRef } }}
				>
					<InfoIcon className={styles.infoIcon} onClick={handleIconClick} />
				</BriaTooltip>
			)}
			{validateOn && <Typography className={`${styles.inputError}`}>{validationMessage}</Typography>}
		</Box>
	);
};

export default observer(CheckboxInput);
