import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import DownloadIcon from "../../../../assets/images/icons/DownloadIcon";
import FullscreenIcon from "../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip";
import CopyToClipboardButton from "../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import { BriaImageProps, ImageErrorType } from "../../../../components/common/Galleries/BriaImage";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation";
import { useAppStore } from "../../../../hooks/useStores";
import { PlaygroundImage, PlaygroundResult } from "../../../../models/image-to-image";
import { TextToImageConfigType } from "../../../../models/text-to-image";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay";
import TextToImageGallery from "./Gallery/TextToImageGallery";
import styles from "./TextToImageResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
};

const AVG_RESULT_DURATION = 10;

const TextToImageResult = ({ playgroundResult }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.result" });
	const { t: modelTrans } = useTranslation("translation", { keyPrefix: "staticDropdowns.genModels" });
	const config = playgroundResult.config as TextToImageConfigType;
	const { playgroundStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const loading = !!playgroundResult.images.filter((image) => image.loading).length;
	const loadingDuration = AVG_RESULT_DURATION * (config.prompt_enhancement ? 1.2 : 1) * 1.1;

	return (
		<Box className={clsx(styles.ttiGalleryWrapper, "galleryWrapper")}>
			<Box className={styles.header}>
				<Box className={styles.topHeader}>
					<Typography className={styles.modelVersion}>
						{config.model_name ?? modelTrans("bria2_3_fast")}
					</Typography>
					{loading ? (
						<LoadingAnimation loading={loading} progressBarTime={loadingDuration} showAnimation={false} />
					) : (
						<Box className={styles.actionBtns}>
							<CopyToClipboardButton
								className={styles.iconButton}
								textToCopy={config?.prompt}
								tooltipText={t("copyPrompt")}
							/>
							<BriaTooltip title={t("download")}>
								<DownloadIcon
									className={styles.iconButton}
									onClick={() =>
										playgroundStore.downloadImages(playgroundResult.images, "bria_text_to_image")
									}
								/>
							</BriaTooltip>
							<BriaTooltip title={t("delete")}>
								<DeleteIcon
									className={styles.iconButton}
									onClick={() => playgroundStore.deleteResult(playgroundResult.id)}
								/>
							</BriaTooltip>
							<BriaTooltip title={t("fullScreen")}>
								<FullscreenIcon className={styles.iconButton} onClick={() => setOpenFullScreen(true)} />
							</BriaTooltip>
						</Box>
					)}
				</Box>
				<Box className={styles.headerText}>{config?.prompt}</Box>
			</Box>
			<TextToImageGallery
				images={playgroundResult.images.map(
					(image) =>
						({
							ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
							displayOverlay: "customHover",
							aspectRatio: image?.aspect_ratio,
							selectable: true,
							htmlJsonData: image.id,
							async: loading,
							onSuccessPulling: () => playgroundStore.onSuccessResult(image),
							onErrorPulling: (errorType: ImageErrorType) =>
								playgroundStore.onErrorResult(image, errorType),
							fullScreenProps: {
								fileName: playgroundStore.getFileName(image),
								format: (image.config as TextToImageConfigType)?.format,
							},
							...image,
						}) as BriaImageProps & PlaygroundImage,
				)}
				fullScreenProps={{
					open: openFullScreen,
					onClose: () => setOpenFullScreen(false),
					displayMode: "scroll",
				}}
				className={styles.gallery}
			/>
		</Box>
	);
};

export default observer(TextToImageResult);
