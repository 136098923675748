const TextPlusIcon = ({ width = "65", height = "65" }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="text-plus">
				<path
					id="Vector"
					d="M50.8619 27.1667H13.5286M13.5286 16.5H50.8619M37.5286 37.8333H13.5286M13.5286 48.5H29.5286M48.1952 40.5V56.5M40.1952 48.5H56.1952"
					stroke="#5B5B5B"
					stroke-opacity="0.5"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
};

export default TextPlusIcon;
