import { Box, FormControlLabel, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../../../../components/common/BriaCheckbox/BriaCheckbox";
import { DropDownItem } from "../../../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../../../hooks/useStores";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import useDesignEditorContext from "../../../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../../../hooks/useEditor";
import MediaComponent from "../Templates/MediaComponent/MediaComponent";
import styles from "./Resize.module.scss";

interface Size {
	width: number;
	height: number;
}

const MAX_FRAME_WIDTH = 13000;
const MAX_FRAME_HEIGHT = 5000;

const Resize: React.FC = () => {
	const { t } = useTranslation("translation");
	const { t: t1 } = useTranslation("translation", { keyPrefix: "editor.tabs.resize" });
	const editor = useEditor();
	const { currentDesign, setCurrentDesign } = useDesignEditorContext();
	const { campaignStore } = useAppStore();
	const [selectedPlacement, setSelectedPlacement] = useState<string | string[]>("");
	const [customSize, setCustomSize] = useState<boolean>(true);
	const [selectedSize, setSelectedSize] = useState<Size>({ width: 0, height: 0 });
	const [heightValue, setHeightValue] = useState<number | undefined>(currentDesign.frame.height);
	const [widthValue, setWidthValue] = useState<number | undefined>(currentDesign.frame.width);
	const [constrainProportionsChecked, setConstrainProportionsChecked] = useState<boolean>(false);

	const placements = campaignStore.placements.items.map((plac) => ({
		key: plac.name,
		value: plac.name,
		width: plac.width,
		height: plac.height,
	}));

	const items: DropDownItem[] = [...placements];

	useEffect(() => {
		if (selectedSize.width > 0 && selectedSize.height > 0) {
			applyResize();
		}
	}, [selectedSize]);

	const onChangePlacementDropdown = (value: string | string[]) => {
		setCustomSize(false);
		setSelectedPlacement(value);
		const selectedPlac = placements.find((placement) => placement.value === value);
		if (selectedPlac) {
			const { width, height } = selectedPlac;
			setSelectedSize({ width, height });
			setWidthValue(width);
			setHeightValue(height);
		}
	};

	const handleElementsAfterResize = () => {
		if (editor) {
			const { width, height, top } = editor.frame.frame;
			const objects = editor.canvas.canvas.getObjects();
			objects.forEach((refObject) => {
				if (refObject && refObject.width && refObject.height) {
					const scaleX = width / refObject.width;
					const scaleY = height / refObject.height;
					const scaleMin = Math.min(scaleX, scaleY);

					refObject.set({
						scaleX: scaleMin,
						scaleY: scaleMin,
					});

					refObject.center();
					if (scaleY >= scaleX) {
						refObject.set("top", top);
					}
				}
			});
		}
	};

	const applyResize = () => {
		if (editor) {
			const { width, height } = selectedSize;
			editor.frame.resize({ width, height });
			setCurrentDesign({
				...currentDesign,
				frame: { width, height },
			});
			if (constrainProportionsChecked) handleElementsAfterResize();
			editor.scene.name =
				!customSize && typeof selectedPlacement === "string"
					? selectedPlacement
					: t1("key", { width: width, height: height });
			editor.history.save();
		}
	};

	const handleWidthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue.trim() === "") {
			setWidthValue(undefined);
			setSelectedSize({ width: 0, height: heightValue ?? 0 });
			return;
		}
		const numericWidthValue = parseFloat(inputValue);
		if (isNaN(numericWidthValue) || numericWidthValue <= 0 || numericWidthValue > MAX_FRAME_WIDTH) {
			setWidthValue(widthValue);
			return;
		}
		setCustomSize(true);
		setSelectedSize({ width: numericWidthValue, height: heightValue ?? 0 });
		setWidthValue(numericWidthValue);
	};

	const handleHeightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		if (inputValue.trim() === "") {
			setHeightValue(undefined);
			setSelectedSize({ height: 0, width: widthValue ?? 0 });
			return;
		}
		const numericHeightValue = parseFloat(inputValue);
		if (isNaN(numericHeightValue) || numericHeightValue <= 0 || numericHeightValue > MAX_FRAME_HEIGHT) {
			setHeightValue(heightValue);
			return;
		}
		setCustomSize(true);
		setSelectedSize({ width: widthValue ?? 0, height: numericHeightValue });
		setHeightValue(numericHeightValue);
	};

	return (
		<Box className={styles.container}>
			<MainTabHeader title={t("editor.panels.panelsList.resizeCanvas")} />
			<Box className={clsx(styles.tabs, styles.fullHeight)}>
				<Box>
					<MediaComponent
						selectedPlacements={selectedPlacement}
						onChangePlacementDropdown={onChangePlacementDropdown}
						items={items}
						loading={campaignStore.isLoadingPlacements}
						multiple={false}
						selectMultipleCheckbox={false}
					/>
					<Typography className={styles.or}>{t("editor.tabs.resize.or")}</Typography>
					<InputLayout label={t1("customSize")} className={styles.input}>
						<Box className={styles.resolution}>
							<Box className={styles.resolutionContainer}>
								<Typography>{t("editor.tabs.resize.widthLabel")}</Typography>
								<BriaInput
									value={widthValue}
									onChange={handleWidthChange}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
							<Box className={styles.resolutionContainer}>
								<Typography>{t("editor.tabs.resize.heightLabel")}</Typography>
								<BriaInput
									value={heightValue}
									onChange={handleHeightChange}
									type="number"
									customEndAdornmentText="px"
								/>
							</Box>
						</Box>
					</InputLayout>
					<FormControlLabel
						control={
							<BriaCheckbox
								checked={constrainProportionsChecked}
								onChange={(e) => setConstrainProportionsChecked(e.target.checked)}
							/>
						}
						label={t1("ConstrainProportions")}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Resize;
