import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox";
import DebouncedInput from "../../../../components/common/DebouncedInput/DebouncedInput.tsx";
import { GeneralIframeConfig } from "../../../../models/new-iframe";
import iframeStore from "../../iframe-store.tsx";
import styles from "./NewIframeForm.module.scss";

const IframeGeneralConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.generalConfig" });

	const handleControlChange = (configKey: keyof GeneralIframeConfig, checked: boolean) => {
		handleFormChange("config", {
			...iframeForm.config,
			general_config: {
				...iframeForm.config.general_config,
				[configKey]: checked,
			},
		});
	};

	const handleSaveLabelChange = (value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			general_config: {
				...iframeForm.config.general_config,
				save_label: value,
			},
		});
	};

	return (
		<FormGroup>
			{Object.entries(iframeForm.config.general_config || {})
				.filter(
					([key, value]) =>
						typeof value === "boolean" &&
						!(key === "enable_structure_tab" && !iframeForm.config.enabled_pages.textToImage) &&
						!(key === "is_admin" && !iframeForm.config.enabled_pages.campaign) &&
						!(key === "enable_upload" && !iframeForm.config.enabled_pages.textToImage),
				)
				.map(([key, value]) => (
					<Box key={key} className={styles.checkboxWithField}>
						<FormControlLabel
							key={key}
							control={
								<BriaCheckbox
									checked={value as boolean}
									onChange={(e) =>
										handleControlChange(key as keyof GeneralIframeConfig, e.target.checked)
									}
								/>
							}
							label={t(`checkboxes.${key}`)}
						/>
						{key === "enable_save" && value && (
							<DebouncedInput
								value={iframeForm?.config.general_config?.save_label}
								onChange={(e) => handleSaveLabelChange(e.target.value)}
								placeholder={t("saveLabel.placeholder")}
								InputProps={{ classes: { root: styles.smallTextField } }}
							/>
						)}
					</Box>
				))}
		</FormGroup>
	);
};

export default observer(IframeGeneralConfig);
