import { ChevronLeft } from "@mui/icons-material";
import { Alert, Box, CircularProgress, Snackbar, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../../../../assets/images/icons/EditIcon.tsx";
import PlusIcon from "../../../../../../../assets/images/icons/PlusIcon.tsx";
import resetIcon from "../../../../../../../assets/images/svgs/AIEditor/reset.svg";
import BriaIconButton from "../../../../../../../components/common/BriaIconButton/BriaIconButton.tsx";
import LoadingPlaceholder from "../../../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../../../../../../../hooks/useStores.tsx";
import InputLayout from "../../../../../../../layout/InputLayout/InputLayout.tsx";
import { defaultBrandDefinition } from "../../../../../../../models/brandDefinition.ts";
import { CampaignEntityStatus } from "../../../../../../../models/common.ts";
import useScenesUtils from "../../../../../../CustomUtils/ScenesUtils.tsx";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor.tsx";
import useTemplateUtils from "../../../../../../CustomUtils/UseTemplateUtils.tsx";
import Scrollable from "../../../../../../components/Scrollable/index.ts";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader.tsx";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../../../hooks/useEditor.tsx";
import { BrandDefinition } from "../../../../../../models/brandDefinition.ts";
import type { IScene } from "../../../../../../types";
import BrandItem from "./BrandItem/BrandItem.tsx";
import styles from "./Brands.module.scss";
import EditBrand from "./EditBrand/EditBrand.tsx";
const Brands = () => {
	const editor = useEditor();
	const { isPopupView, setScenes, setCurrentScene } = useContext(DesignEditorContext);
	const { brandsDefinitionStore, campaignStore } = useAppStore();
	const [brands, setBrands] = useState<BrandDefinition[]>([]);
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.brands" });
	const [selectedBrand, setSelectedBrand] = useState<BrandDefinition | null>(defaultBrandDefinition);
	const [showSelectedBrand, setShowSelectedBrand] = useState<boolean>(false);
	const [isViewIconClicked, setIsViewIconClicked] = useState<boolean>(true);
	const [activeBrand, setActiveBrand] = useState<BrandDefinition | null>(null);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const isAdminMode = campaignStore.isAdminMode;
	const { applyBrandConfigration, applyBrandConfigrationOnScene } = useDesignEditorUtils();
	const { updateScenes } = useScenesUtils();
	const { exportTemplate } = useTemplateUtils();

	const setInternalBrands = () => {
		if (brandsDefinitionStore.brands) {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === CampaignEntityStatus.ACTIVE));
		}
	};

	useEffect(() => {
		loadBrands();
		const fetchAndStoreTemplateJson = async () => {
			if (!isPopupView) {
				if (!campaignStore?.selectedTemplate?.id && !brandsDefinitionStore.templateScenesBeforeApplyBrand) {
					const updatedJson = await exportTemplate();
					brandsDefinitionStore.templateScenesBeforeApplyBrand = _.cloneDeep(updatedJson);
				}
			}
		};

		campaignStore.isFirstTimeBrandApplied = true;
		if (!isPopupView) campaignStore.originalLogoObjects = {};
		fetchAndStoreTemplateJson();
	}, []);
	useEffect(() => {
		setInternalBrands();
	}, [brandsDefinitionStore.brands]);

	const loadBrands = (forcefetchBrands: boolean = false) => {
		if ((brandsDefinitionStore.brands.items.length === 0 && !brandsDefinitionStore.isLoading) || forcefetchBrands) {
			brandsDefinitionStore.loadBrandsDefinition();
		} else {
			setInternalBrands();
		}
	};

	const addNewBrand = () => {
		brandsDefinitionStore.setProperty("selectedBrand", defaultBrandDefinition);
		setShowSelectedBrand(true);
		setSelectedBrand(defaultBrandDefinition);
	};

	useEffect(() => {
		const ApplySelectedBrand = async () => {
			if (!isViewIconClicked && activeBrand) {
				brandsDefinitionStore.setProperty("isApplyingBrandConfigurations", true);
				const scenes = await updateScenes();
				if (brandsDefinitionStore.selectedBrand) {
					brandsDefinitionStore.setProperty("hasBrandReset", false);
					if (isPopupView) {
						if (!editor) return;
						scenes[0] = await applyBrandConfigrationOnScene(
							brandsDefinitionStore.selectedBrand,
							scenes[0],
							0,
						);
						const updatedPreview = (await editor?.renderer.render(scenes[0])) as string;
						const updatedScene = { ...scenes[0], preview: updatedPreview, duration: 1000 };
						const updatedScenes = scenes.map((scene: IScene) => {
							if (scene.id === updatedScene.id) {
								return { ...updatedScene, preview: updatedPreview };
							}
							return editor.scene.formalizeSceneAttributes(scene);
						}) as IScene[];
						setScenes(updatedScenes);
						setCurrentScene(updatedScene);
					} else {
						await applyBrandConfigration(brandsDefinitionStore.selectedBrand, scenes);
					}
				}
				brandsDefinitionStore.setProperty("isApplyingBrandConfigurations", false);
				campaignStore.isFirstTimeBrandApplied = false;
			}
		};

		ApplySelectedBrand();
	}, [isViewIconClicked, brandsDefinitionStore.selectedBrand, activeBrand]);

	const handleSelectBrand = async (brand: BrandDefinition) => {
		const selectedBrand = brand;
		setSelectedBrand(selectedBrand);
		brandsDefinitionStore.setProperty("selectedBrand", selectedBrand);
	};

	const handleShowBrand = () => {
		setShowSelectedBrand(true);
	};

	const handleShowBrandsList = () => {
		setShowSelectedBrand(false);
	};

	const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		brandsDefinitionStore.formErrors.invalidName = false;
		const newValue = e.target.value;
		brandsDefinitionStore.handleBrandChange("name", newValue);
		setSelectedBrand((prevBrand) => {
			if (prevBrand) {
				return {
					...prevBrand,
					name: newValue,
				};
			} else {
				return null;
			}
		});
	};

	const handleRestoreLayers = async () => {
		brandsDefinitionStore.setProperty("isResettingBrandConfigurations", true);
		const updatedScenes = await updateScenes();
		const clonedOriginalScenes = !isPopupView
			? _.cloneDeep(brandsDefinitionStore.templateScenesBeforeApplyBrand)
			: _.cloneDeep(brandsDefinitionStore.templateScenesBeforeApplyBrandInWizard);
		const newScenes: IScene[] = [];
		if (updatedScenes && clonedOriginalScenes) {
			for (const currentScene of updatedScenes) {
				const originalScene = clonedOriginalScenes.scenes.find((scene: IScene) => scene.id === currentScene.id);

				if (originalScene) {
					const updatedLayers = currentScene.layers.map((layer) => {
						const originalLayer = originalScene.layers.find(
							(origLayer: IScene) => origLayer.id === layer.id,
						);
						if (layer.brandId && originalLayer) {
							originalLayer.brandId = undefined;
							return originalLayer;
						}
						return layer;
					});
					const updatedScene = {
						...currentScene,
						layers: updatedLayers,
					};
					const updatedPreview = (await editor?.renderer.render(updatedScene)) as string;
					updatedScene.preview = updatedPreview;

					newScenes.push(updatedScene);
				}
			}
			setScenes(newScenes);
			const currentScene = editor && editor.scene.exportToJSON();

			const foundScene = newScenes?.find((scene) => scene.id === currentScene?.id);

			if (foundScene) setCurrentScene(foundScene);
		}
		setSelectedBrand(null);
		setActiveBrand(null);
		brandsDefinitionStore.setProperty("hasBrandReset", true);
		brandsDefinitionStore.setProperty("isResettingBrandConfigurations", false);
	};

	const renderResetSelectedBrandButton = () => (
		<Box className={styles.brandResetcontainer} position="relative">
			{brands.length > 0 && (
				<Box
					className={clsx(styles.reset, {
						[styles.disabled]:
							brandsDefinitionStore.isApplyingBrandConfigurations ||
							brandsDefinitionStore.isResettingBrandConfigurations,
					})}
					onClick={handleRestoreLayers}
				>
					<Box className={styles.resetIcon}>
						<img src={resetIcon} alt="reset icon" />
					</Box>
					<Typography>{t("resetBrand")}</Typography>
				</Box>
			)}

			{brandsDefinitionStore.isResettingBrandConfigurations && (
				<Box className={styles.blurLayer}>
					<CircularProgress size={24} className={styles.loader} />
				</Box>
			)}
		</Box>
	);

	return (
		<Box className={styles.brandContainer}>
			<Box className={styles.tapContainer}>
				{!showSelectedBrand ? (
					<MainTabHeader title={t("title")} />
				) : (
					<Box className={styles.subHeader}>
						<Box className={styles.title}>
							<InputLayout
								className={styles.brandNameInput}
								showLabel={false}
								validateOn={!brandsDefinitionStore.selectedBrand.name}
								validationMessage={t("brandNameRequired")}
							>
								<TextField
									required
									variant="outlined"
									value={selectedBrand?.name}
									onChange={(e) => handleTextFieldChange(e)}
									placeholder={t("brandTitle")}
									fullWidth
									InputProps={{
										classes: {
											root: clsx(styles.brandNameTextField, {
												[styles.readOnly]: !isAdminMode,
											}),
										},
									}}
									error={brandsDefinitionStore.formErrors.invalidName}
									helperText={brandsDefinitionStore.formErrors.invalidName && t("invalidName")}
								/>
							</InputLayout>
						</Box>
						<Box className={styles.iconsContainer}>
							{isAdminMode && (
								<BriaIconButton className={styles.editIcon}>
									<EditIcon />
								</BriaIconButton>
							)}

							<BriaIconButton
								onClick={() => {
									setShowSelectedBrand(false);
									loadBrands();
								}}
							>
								<ChevronLeft />
							</BriaIconButton>
						</Box>
					</Box>
				)}
			</Box>

			<Box className={clsx(styles.tabs, styles.brandTab)}>
				{showSelectedBrand ? (
					<EditBrand
						isAdminMode={isAdminMode}
						loadBrands={loadBrands}
						handleShowBrandsList={handleShowBrandsList}
						setSuccessMessage={setSuccessMessage}
					/>
				) : (
					<LoadingPlaceholder
						className={styles.circleLoader}
						size="25px"
						isLoading={brandsDefinitionStore.isLoading}
					>
						<Scrollable>
							<div className={styles.brandsContainer}>
								<div>
									{brands.map((brand: BrandDefinition) => {
										return (
											<BrandItem
												isAdminMode={isAdminMode}
												key={brand.id}
												brand={brand}
												onClick={handleSelectBrand}
												selectedBrand={selectedBrand}
												handleShowBrand={handleShowBrand}
												loadBrands={loadBrands}
												setIsViewIconClicked={setIsViewIconClicked}
												isViewIconClicked={isViewIconClicked}
												setActiveBrand={setActiveBrand}
												activeBrand={activeBrand}
											/>
										);
									})}
								</div>
								{isAdminMode ? (
									<>
										<Box
											className={clsx(styles.addNewBrand, {
												[styles.disabled]:
													brandsDefinitionStore.isApplyingBrandConfigurations ||
													brandsDefinitionStore.isResettingBrandConfigurations,
											})}
											onClick={addNewBrand}
										>
											<Box className={styles.newBrandIcon}>
												<PlusIcon />
											</Box>
											<Typography>{t("addNew")}</Typography>
										</Box>
										{renderResetSelectedBrandButton()}
									</>
								) : (
									<>
										{renderResetSelectedBrandButton()}
										<Box className={styles.adminModeNotice}>
											<Typography>{t("adminModeNotice.part1")}</Typography>
											<Typography className={styles.bold}>
												{t("adminModeNotice.part2")}
											</Typography>
										</Box>
									</>
								)}
							</div>
						</Scrollable>
					</LoadingPlaceholder>
				)}
			</Box>
			<Snackbar
				autoHideDuration={2000}
				open={!!successMessage}
				onClose={() => setSuccessMessage(null)}
				className={styles.saveSuccessToast}
			>
				<Alert severity="success">{successMessage}</Alert>
			</Snackbar>
		</Box>
	);
};
const ObservedComponent = observer(Brands);
export default ObservedComponent;
