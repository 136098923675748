const StopIcon = ({ width = "24", height = "25" }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17 5.5H7C5.89543 5.5 5 6.39543 5 7.5V17.5C5 18.6046 5.89543 19.5 7 19.5H17C18.1046 19.5 19 18.6046 19 17.5V7.5C19 6.39543 18.1046 5.5 17 5.5Z"
				stroke="white"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default StopIcon;
