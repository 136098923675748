function GridView({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.81101 6.07812H6.51689C6.06207 6.07812 5.69336 6.44683 5.69336 6.90165V10.1958C5.69336 10.6506 6.06207 11.0193 6.51689 11.0193H9.81101C10.2658 11.0193 10.6345 10.6506 10.6345 10.1958V6.90165C10.6345 6.44683 10.2658 6.07812 9.81101 6.07812Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.0463 6.07812H14.7522C14.2974 6.07812 13.9287 6.44683 13.9287 6.90165V10.1958C13.9287 10.6506 14.2974 11.0193 14.7522 11.0193H18.0463C18.5011 11.0193 18.8698 10.6506 18.8698 10.1958V6.90165C18.8698 6.44683 18.5011 6.07812 18.0463 6.07812Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.81101 14.3134H6.51689C6.06207 14.3134 5.69336 14.6821 5.69336 15.1369V18.4311C5.69336 18.8859 6.06207 19.2546 6.51689 19.2546H9.81101C10.2658 19.2546 10.6345 18.8859 10.6345 18.4311V15.1369C10.6345 14.6821 10.2658 14.3134 9.81101 14.3134Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.0463 14.3134H14.7522C14.2974 14.3134 13.9287 14.6821 13.9287 15.1369V18.4311C13.9287 18.8859 14.2974 19.2546 14.7522 19.2546H18.0463C18.5011 19.2546 18.8698 18.8859 18.8698 18.4311V15.1369C18.8698 14.6821 18.5011 14.3134 18.0463 14.3134Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default GridView;
