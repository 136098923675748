import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown from "../../../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import {
	FontLabelMappingEnum,
	FontTypeEnum,
	HorizontalEnum,
	LogoTypeMappingEnum,
	VerticalEnum,
} from "../../../../../../models/common.ts";
import useDesignEditorUtils from "../../../../../CustomUtils/UseDesignEditor.tsx";
import {
	createDropdownItems,
	createFontDropdownItems,
	createHorizontalAlignmentItems,
	createLogoDropdownItems,
	createVerticalAlignmentItems,
} from "../../../../../constants/brandsConfigurations.ts";
import { useEditor } from "../../../../../hooks/useEditor.tsx";
import styles from "./BrandSettings.module.scss";

interface Iprops {
	activeObject: any;
	showBrandColorDropdown?: boolean;
	showBrandFontDropdown?: boolean;
	showBrandLogoDropdown?: boolean;
	showStrokeBrandColorDropdown?: boolean;
}

const BrandSettings = ({
	activeObject,
	showBrandColorDropdown = false,
	showBrandFontDropdown = false,
	showBrandLogoDropdown = false,
	showStrokeBrandColorDropdown = false,
}: Iprops) => {
	const editor = useEditor();
	const { campaignStore, designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.brandSettings" });
	const dropdownItems = createDropdownItems(t);
	const fontDropdownItems = createFontDropdownItems(t);
	const logoDropdownItems = createLogoDropdownItems(t);
	const horizontalAlignmentItems = createHorizontalAlignmentItems(t);
	const verticalAlignmentItems = createVerticalAlignmentItems(t);
	const { editBrandConfigrationsInAllAds } = useDesignEditorUtils();
	useEffect(() => {
		if (activeObject) {
			handleUpdateActiveObject();
		}
	}, [activeObject]);

	const modifyActiveObjectProperty = (property: keyof typeof designEditorStore.activeObjectSettings, value: any) => {
		if (activeObject) {
			let newValue = activeObject[property] !== value ? value : undefined;
			activeObject[property] = newValue;
			designEditorStore.setProperty("activeObjectSettings", {
				colorNumber: activeObject?.colorNumber,
				fontType: activeObject?.fontType,
				logoType: activeObject?.logoType,
				horizontalAlignment: activeObject?.horizontalAlignment,
				verticalAlignment: activeObject?.verticalAlignment,
				strokeColorNumber: activeObject?.strokeColorNumber,
				[property]: activeObject[property],
			});
			editBrandConfigrationsInAllAds(activeObject, property, newValue);
			editor?.canvas.canvas.fire("object:modified", {
				target: activeObject,
				invokeSave: !campaignStore.isEditingAllAds,
			});
		}
	};

	const handleChange = (property: keyof typeof designEditorStore.activeObjectSettings, newValue: any) => {
		modifyActiveObjectProperty(property, newValue);
	};

	const handleUpdateActiveObject = () => {
		designEditorStore.setProperty("activeObjectSettings", {
			colorNumber: activeObject?.colorNumber,
			fontType: activeObject?.fontType,
			logoType: activeObject?.logoType,
			horizontalAlignment: activeObject?.horizontalAlignment,
			verticalAlignment: activeObject?.verticalAlignment,
			strokeColorNumber: activeObject?.strokeColorNumber,
		});
	};

	return (
		<Box
			className={clsx({
				[styles.canvasBrandSettingsContainer]: true,
				[styles.hide]: !campaignStore.isAdminMode,
			})}
		>
			<Box className={styles.brandsConfigrations}>
				<Typography className={styles.brandSettingTitle}> {t("title")} </Typography>
				{showBrandColorDropdown && (
					<BriaDropDown
						value={designEditorStore.activeObjectSettings.colorNumber}
						onChange={(e) => handleChange("colorNumber", e.target.value)}
						items={dropdownItems}
						height="20px"
						width="170px"
						className={styles.componentStatus}
						renderValue={(selected: number | undefined) => {
							if (selected) {
								const selectedItem = dropdownItems.find((item) => item.value === selected);
								return (
									<Typography className={styles.renderedValue}>
										{selectedItem ? selectedItem.label : t("configureBrandColor")}
									</Typography>
								);
							} else {
								return (
									<Typography className={styles.renderedValue}>{t("configureBrandColor")}</Typography>
								);
							}
						}}
						placeholder={t("configureBrandColor")}
					/>
				)}
				{showStrokeBrandColorDropdown && (
					<BriaDropDown
						value={designEditorStore.activeObjectSettings.strokeColorNumber}
						onChange={(e) => handleChange("strokeColorNumber", e.target.value)}
						items={dropdownItems}
						height="20px"
						width="170px"
						className={styles.componentStatus}
						renderValue={(selected: number | undefined) => {
							if (selected) {
								const selectedItem = dropdownItems.find((item) => item.value === selected);
								return (
									<Typography className={styles.renderedValue}>
										{selectedItem ? selectedItem.label : t("configureStrokeColor")}
									</Typography>
								);
							} else {
								return (
									<Typography className={styles.renderedValue}>
										{t("configureStrokeColor")}
									</Typography>
								);
							}
						}}
						placeholder={t("configureStrokeColor")}
					/>
				)}
				{showBrandFontDropdown && (
					<BriaDropDown
						value={designEditorStore.activeObjectSettings.fontType}
						onChange={(e) => handleChange("fontType", e.target.value)}
						items={fontDropdownItems}
						height="20px"
						width="170px"
						className={styles.componentStatus}
						renderValue={(selected: FontTypeEnum | undefined) => {
							if (selected) {
								return (
									<Typography className={styles.renderedValue}>
										{FontLabelMappingEnum[selected]}
									</Typography>
								);
							} else {
								return (
									<Typography className={styles.renderedValue}>{t("configureBrandFont")}</Typography>
								);
							}
						}}
						placeholder={t("configureBrandFont")}
					/>
				)}

				{showBrandLogoDropdown && (
					<>
						<BriaDropDown
							value={designEditorStore.activeObjectSettings.logoType}
							onChange={(e) => handleChange("logoType", e.target.value)}
							items={logoDropdownItems}
							height="20px"
							width="170px"
							className={styles.componentStatus}
							renderValue={(selected: keyof typeof LogoTypeMappingEnum | undefined) => {
								if (selected) {
									return (
										<Typography className={styles.renderedValue}>
											{LogoTypeMappingEnum[selected]}
										</Typography>
									);
								} else {
									return (
										<Typography className={styles.renderedValue}>
											{t("configureBrandLogo")}
										</Typography>
									);
								}
							}}
							placeholder={t("configureBrandLogo")}
						/>
						<BriaDropDown
							value={designEditorStore.activeObjectSettings.horizontalAlignment}
							onChange={(e) => handleChange("horizontalAlignment", e.target.value)}
							items={horizontalAlignmentItems}
							height="20px"
							width="170px"
							className={styles.componentStatus}
							renderValue={(selected: HorizontalEnum | undefined) => {
								if (selected) {
									return (
										<Typography className={styles.renderedValue}>
											{horizontalAlignmentItems.find((item) => item.value === selected)?.label}
										</Typography>
									);
								} else {
									return (
										<Typography className={styles.renderedValue}>
											{t("configureHorizontalAlignment")}
										</Typography>
									);
								}
							}}
							placeholder={t("configureHorizontalAlignment")}
						/>
						<BriaDropDown
							value={designEditorStore.activeObjectSettings.verticalAlignment}
							onChange={(e) => handleChange("verticalAlignment", e.target.value)}
							items={verticalAlignmentItems}
							height="20px"
							width="170px"
							className={styles.componentStatus}
							renderValue={(selected: VerticalEnum | undefined) => {
								if (selected) {
									return (
										<Typography className={styles.renderedValue}>
											{verticalAlignmentItems.find((item) => item.value === selected)?.label}
										</Typography>
									);
								} else {
									return (
										<Typography className={styles.renderedValue}>
											{t("configureVerticalAlignment")}
										</Typography>
									);
								}
							}}
							placeholder={t("configureVerticalAlignment")}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

export default observer(BrandSettings);
