import Canvas from "./Canvas/Canvas";
import FabricShapes from "./FabricShapes/FabricShapes";
import Image from "./Image/Image";
import Locked from "./Locked";
import Multiple from "./Multiple/Multiple";
import Path from "./Path";
import Text from "./TextToolBar/TextToolBar";
import Vector from "./Vector/Vector";
export default {
	StaticText: Text,
	StaticPath: Path,
	StaticImage: Image,
	StaticVector: Vector,
	StaticRect: FabricShapes,
	StaticCircle: FabricShapes,
	StaticTriangle: FabricShapes,
	Locked,
	Multiple,
	Canvas,
};
