import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Divider, FormControlLabel } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import BriaInput from "../../../../components/common/BriaInput/BriaInput.tsx";
import BriaSlider from "../../../../components/common/BriaSlider/BriaSlider.tsx";
import StaticDropdown, { AspectRatio } from "../../../../components/common/DropDowns/StaticDropdown.tsx";
import TextToImageMediumsDropdown from "../../../../components/common/DropDowns/TextToImageMediumsDropdown.tsx";
import TextToImageModelsDropdown, {
	getModelKey,
	getModelName,
	getModelVersion,
} from "../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { IMAGE_REFERENCE_CONFIGS } from "../../../../constants/TextToImageConstants.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { modelTypes } from "../../../../models/sandboxAPI.ts";
import iframeStore from "../../../IframeNew/iframe-store.tsx";
import { defaultTextToImageConfig } from "../text-to-image-store.tsx";
import ImageReference from "./ImageReference/ImageReference.tsx";
import styles from "./TextToImageConfig.module.scss";

export const tailoredModelsConfig = {
	steps_num: 8,
	max_results: 4,
	steps: {
		min: 4,
		max: 20,
	},
};

const TextToImageConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.config" });
	const { textToImageStore, playgroundStore } = useAppStore();
	const [hideNegativePrompt, setHideNegativePrompt] = useState<boolean>(true);

	useEffect(() => {
		playgroundStore.handleAppChange(APPS.TEXT_TO_IMAGE);
		if (textToImageStore.isTextToVector()) {
			textToImageStore.handleConfigChange("prompt_enhancement", false);
			textToImageStore.handleConfigChange("format", "svg");
		} else {
			textToImageStore.handleConfigChange("prompt_enhancement", true);
			textToImageStore.handleConfigChange("format", "png");
		}
	}, []);

	useEffect(() => {
		if (textToImageStore.config.model) {
			const selectedModel = textToImageStore.models[textToImageStore.config.model];
			const stepsNum = selectedModel?.steps?.default ?? defaultTextToImageConfig.steps_num;
			const numResults = selectedModel?.max_results ?? defaultTextToImageConfig.num_results;
			textToImageStore.handleConfigChange("steps_num", stepsNum);
			textToImageStore.handleConfigChange("num_results", numResults);
		}

		if (textToImageStore.config.tailored_model_id) {
			textToImageStore.handleConfigChange("medium", "");
		}
	}, [textToImageStore.config.model]);

	useEffect(() => {
		if (textToImageStore.config.model_url) {
			textToImageStore.handleConfigChange("steps_num", tailoredModelsConfig.steps_num);
			textToImageStore.handleConfigChange("num_results", tailoredModelsConfig.max_results);
		}
	}, [textToImageStore.config.model_url]);

	const handleNumResultsChange = (_event: any, numResults: number | number[]) => {
		textToImageStore.handleConfigChange("num_results", numResults as number);
	};

	const handleStepsChange = (_event: any, steps: number | number[]) => {
		textToImageStore.handleConfigChange("steps_num", steps as number);
	};

	const clearFoundationModelsSelections = () => {
		textToImageStore.handleConfigChange("model", "");
		textToImageStore.handleConfigChange("model_version", "");
	};

	const clearTailoredModelsSelections = () => {
		textToImageStore.handleConfigChange("model_url", undefined);
		textToImageStore.handleConfigChange("tailored_model_id", undefined);
	};

	const handleModelChange = (e: any, selectedOption: any) => {
		setHideNegativePrompt(false);
		const isTailoredModel = selectedOption && selectedOption?.extraData?.isTailoredModel;
		textToImageStore.handleConfigChange("model_name", selectedOption.key);

		if (!isTailoredModel) {
			clearTailoredModelsSelections();
			textToImageStore.handleConfigChange("model", getModelName(e.target.value as string));
			textToImageStore.handleConfigChange("model_id", selectedOption?.id);
			textToImageStore.handleConfigChange("model_version", getModelVersion(e.target.value as string));
			if (selectedOption.value.toLowerCase().includes("fast")) {
				setHideNegativePrompt(true);
				textToImageStore.handleConfigChange("negative_prompt", undefined);
			}
			textToImageStore.handleConfigChange("fast", textToImageStore.config.model === modelTypes.Fast);
		} else {
			clearFoundationModelsSelections();
			textToImageStore.handleConfigChange("model_url", selectedOption?.extraData?.endpointModelName);
			textToImageStore.handleConfigChange("model_id", selectedOption?.id);
			textToImageStore.handleConfigChange("tailored_model_id", selectedOption?.extraData?.model_id);
			setHideNegativePrompt(true);
			textToImageStore.handleConfigChange("negative_prompt", undefined);
			textToImageStore.handleConfigChange(
				"steps_num",
				textToImageStore.config.fast
					? IMAGE_REFERENCE_CONFIGS.steps.fast.default
					: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
			);
		}
	};

	const handleFastCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		textToImageStore.handleConfigChange("fast", e.target.checked);
		textToImageStore.handleConfigChange(
			"steps_num",
			e.target.checked
				? IMAGE_REFERENCE_CONFIGS.steps.fast.default
				: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
		);
	};

	const getMinSteps = () => {
		if (textToImageStore.isImageReferenceEnabled() && textToImageStore.config.tailored_model_id) {
			const isFast = textToImageStore.config.fast;
			return isFast ? IMAGE_REFERENCE_CONFIGS.steps.fast.min : IMAGE_REFERENCE_CONFIGS.steps.not_fast.min;
		}
		return textToImageStore.config.model_url
			? tailoredModelsConfig.steps.min
			: textToImageStore.models[textToImageStore.config.model]?.steps.min;
	};

	const getMaxSteps = () => {
		if (textToImageStore.isImageReferenceEnabled() && textToImageStore.config.tailored_model_id) {
			const isFast = textToImageStore.config.fast;
			return isFast ? IMAGE_REFERENCE_CONFIGS.steps.fast.max : IMAGE_REFERENCE_CONFIGS.steps.not_fast.max;
		}
		return textToImageStore.config.model_url
			? tailoredModelsConfig.steps.max
			: textToImageStore.models[textToImageStore.config.model]?.steps.max;
	};

	return (
		<Box className={styles.config}>
			<InputLayout label={t("models.label")} labelClassName={styles.inputLabel} labelSize="large">
				<TextToImageModelsDropdown
					selectedValue={
						textToImageStore.config.model_id ||
						getModelKey(textToImageStore.config.model, textToImageStore.config.model_version)
					}
					handleChange={handleModelChange}
					placeholder={t("models.placeholder")}
					showFoundationModels={true}
				/>
			</InputLayout>
			{textToImageStore.config.tailored_model_id && !textToImageStore.isTextToVector() && (
				<InputLayout label={t("models.tailoredModelInfluence")}>
					<BriaSlider
						value={textToImageStore.config.tailored_model_influence}
						onChange={(_, value) =>
							textToImageStore.handleConfigChange("tailored_model_influence", value as number)
						}
						step={0.05}
						min={0}
						max={1}
						marks={[
							{ value: 0, label: t("imageReference.low") },
							{ value: 1, label: t("imageReference.high") },
						]}
						valueLabelDisplay="auto"
						labelsAlwaysGray
					/>
					{textToImageStore.isImageReferenceEnabled() && (
						<FormControlLabel
							control={
								<BriaCheckbox
									checked={textToImageStore.config?.fast}
									onChange={handleFastCheckboxChange}
								/>
							}
							label={t("fast")}
						/>
					)}
				</InputLayout>
			)}
			<InputLayout label={t("aspectRatio.label")} labelClassName={styles.inputLabel} labelSize="large">
				<StaticDropdown
					type="aspectRatio"
					value={textToImageStore.config?.aspect_ratio}
					onChange={(e) => textToImageStore.handleConfigChange("aspect_ratio", e.target.value as AspectRatio)}
					placeholder={
						textToImageStore.isImageReferenceEnabled()
							? t("aspectRatio.disabledPlaceholder")
							: t("aspectRatio.placeholder")
					}
					disabled={textToImageStore.isImageReferenceEnabled()}
					excludedItems={["original"]}
				/>
			</InputLayout>
			{!textToImageStore.isTextToVector() && !textToImageStore.config.tailored_model_id && (
				<InputLayout label={t("medium.label")} labelClassName={styles.inputLabel} labelSize="large">
					<TextToImageMediumsDropdown
						value={textToImageStore.config?.medium}
						onChange={(e) => textToImageStore.handleConfigChange("medium", e.target.value as string)}
						defaultOption
						placeholder={
							textToImageStore.isImageReferenceEnabled()
								? t("medium.disabledPlaceholder")
								: t("medium.placeholder")
						}
						disabled={textToImageStore.isImageReferenceEnabled()}
					/>
				</InputLayout>
			)}
			<Divider className={styles.divider} />
			{!textToImageStore.isTextToVector() &&
				(!iframeStore.isIframe() ||
					iframeStore.iframe.config.image_generation_config?.enable_structure_guidance) && (
					<>
						<ImageReference />
						<Divider className={styles.divider} />
					</>
				)}

			<Accordion elevation={0} disableGutters TransitionProps={{ timeout: 200 }}>
				<AccordionSummary color="initial" expandIcon={<ExpandMore />} className={styles.accordionSummary}>
					{t("advanced")}
				</AccordionSummary>
				<Box className={styles.advanced}>
					{!hideNegativePrompt && !textToImageStore.hideNegativePrompt && (
						<InputLayout
							label={t("negativePrompt.label")}
							labelClassName={styles.inputLabel}
							info={t("negativePrompt.info")}
						>
							<BriaInput
								value={textToImageStore.config?.negative_prompt}
								onChange={(e) => textToImageStore.handleConfigChange("negative_prompt", e.target.value)}
								multiline
								minRows={4}
								placeholder={t("negativePrompt.placeholder")}
								inputProps={{ className: styles.inputBase }}
							/>
						</InputLayout>
					)}
					<InputLayout
						label={t("numberOfImages.label")}
						labelClassName={styles.inputLabel}
						info={t("numberOfImages.info")}
					>
						<BriaSlider
							value={textToImageStore.config.num_results}
							onChange={handleNumResultsChange}
							step={1}
							min={1}
							max={
								textToImageStore.config.model_url
									? tailoredModelsConfig.max_results
									: textToImageStore.models[textToImageStore.config.model]?.max_results
							}
							marks={Array.from(
								{
									length: textToImageStore.config.model_url
										? tailoredModelsConfig.max_results
										: textToImageStore.models[textToImageStore.config.model]?.max_results,
								},
								(_, index) => ({ value: index + 1, label: `${index + 1}` }),
							)}
						/>
					</InputLayout>
					<InputLayout label={t("steps.label")} labelClassName={styles.inputLabel} info={t("steps.info")}>
						<BriaSlider
							value={textToImageStore.config.steps_num}
							onChange={handleStepsChange}
							step={1}
							min={getMinSteps()}
							max={getMaxSteps()}
							valueLabelDisplay="on"
						/>
					</InputLayout>
					<InputLayout
						label={t("seedNumber.label")}
						labelClassName={styles.inputLabel}
						info={t("seedNumber.info")}
					>
						<BriaInput
							type="number"
							value={textToImageStore.config?.seed}
							onChange={(e) =>
								textToImageStore.handleConfigChange(
									"seed",
									e.target.value ? parseFloat(e.target.value) : undefined,
								)
							}
							placeholder={t("seedNumber.placeholder")}
							height="37px"
							inputProps={{ className: styles.inputBase }}
						/>
						<FormControlLabel
							control={
								<BriaCheckbox
									checked={textToImageStore.config?.displaySeed}
									onChange={(e) =>
										textToImageStore.handleConfigChange("displaySeed", e.target.checked)
									}
								/>
							}
							label={t("seedNumber.checkbox")}
						/>
					</InputLayout>
				</Box>
			</Accordion>
		</Box>
	);
};

export default observer(TextToImageConfig);
