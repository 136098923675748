import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../hooks/useStores.tsx";
import { PanelType } from "../constants/app-options.ts";
import { DesignEditorContext } from "../contexts/DesignEditor.tsx";
import { ILayer, IScene, IStaticImage } from "../types";
import { ObjectsEnum, SecondaryTabTypeEnum } from "../views/DesignEditor/components/Panels/panelItems";
import { findGroupByElementId, useSmartImageUtils } from "../views/DesignEditor/utils/smartImageUtils.ts";
import useAppContext from "./useAppContext.tsx";
import { useEditor } from "./useEditor.tsx";

const useSmartImageHeader = () => {
	const editor = useEditor();
	const { activePanel, setActivePanel } = useAppContext();
	const { resetRectangleInAGroup, findObjectFromScene, uploadAndRegisterBackgroundImages, removeObjectFromScene } =
		useSmartImageUtils();
	const { imagesStore, designEditorStore } = useAppStore();
	const { scenes, setScenes, setCurrentScene, currentScene } = useContext(DesignEditorContext);
	const [disableCanvasMenuActions, setDisableCanvasMenuActions] = useState(false);
	const [isHeaderVisible, setIsHeaderVisible] = useState(false);
	const [showSmartImageHeader, setShowSmartImageHeader] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });
	const hoverSmartImageOn = [t("delete"), t("editSmartImage")];

	useEffect(() => {
		const disable = imagesStore.isUploadingSmartImageMainObject || imagesStore.isGeneratingImages;
		setDisableCanvasMenuActions(disable);
	}, [imagesStore.isUploadingSmartImageMainObject, imagesStore.isGeneratingImages]);

	useEffect(() => {
		const initializer = async () => {
			const _currentScene = editor?.scene.exportToJSON();
			if (_currentScene) {
				const isHeaderVisible = await checkHeaderVisibility(_currentScene);
				const showSmartImageHeader = await checkSmartImageHeader(_currentScene);
				setIsHeaderVisible(isHeaderVisible);
				setShowSmartImageHeader(showSmartImageHeader);
			}
		};
		initializer();
	}, [scenes, currentScene, activePanel]);

	const checkSmartImageHeader = async (_currentScene: IScene): Promise<boolean> => {
		if (!_currentScene || !editor) return false;
		return designEditorStore.isSmartImageObjectSelected;
	};

	const checkHeaderVisibility = async (_currentScene: IScene): Promise<boolean> => {
		if (!_currentScene) return false;
		const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
		const outerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle);
		return !!innerRectangle && !!outerRectangle;
	};

	const updateObjectFill = (objectId: ObjectsEnum, fillColor: string) => {
		if (!editor) return;
		const object = editor?.objects.findOneById(objectId);
		const objectGroup = findGroupByElementId(editor.canvas.canvas, objectId);
		if (!objectGroup && object) {
			object.fill = fillColor;
		}
		return objectGroup ?? object;
	};

	const setActiveObject = (object: fabric.Object | fabric.Group) => {
		if (!editor) return;
		editor.canvas.canvas.discardActiveObject();
		editor.canvas.canvas.setActiveObject(object);
		editor.canvas.canvas.renderAll();
	};

	const handleMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
		if (!editor) return;
		const targetElement = e.currentTarget as HTMLElement;
		const isSmartImageHoverEnabled = hoverSmartImageOn.includes(targetElement.getAttribute("data-value") ?? "");
		const objectId = isSmartImageHoverEnabled ? ObjectsEnum.OuterRectangle : ObjectsEnum.InnerRectangle;
		const fillColor = "rgba(83, 0, 201, 0.05)";
		const activeObject = updateObjectFill(objectId, fillColor);
		setActiveObject(activeObject);
	};

	const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
		if (!editor) return;
		const targetElement = e.currentTarget as HTMLElement;
		const isSmartImageHoverEnabled = hoverSmartImageOn.includes(targetElement.getAttribute("data-value") ?? "");
		const objectId = isSmartImageHoverEnabled ? ObjectsEnum.OuterRectangle : ObjectsEnum.InnerRectangle;
		const fillColor = isSmartImageHoverEnabled ? "rgba(83, 0, 201, 0.02)" : "rgba(83, 0, 201, 0.03)";
		updateObjectFill(objectId, fillColor);
		editor.objects.deselect();
		editor.canvas.canvas.renderAll();
	};

	const updateSceneState = async (updatedScene: IScene) => {
		if (!editor) return;
		const updatedPreview = (await editor?.renderer.render(updatedScene)) as string;
		updatedScene.preview = updatedPreview;
		const updatedScenes = scenes.map((scene: IScene) =>
			scene.id === updatedScene.id
				? { ...updatedScene, preview: updatedScene.preview }
				: editor.scene.formalizeSceneAttributes(scene),
		) as IScene[];
		setScenes(updatedScenes);
		setCurrentScene(updatedScene);
	};

	const onUploadImage = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!editor || !currentScene) return;
		const _currentScene = editor?.scene.exportToJSON();
		imagesStore.setProperty("smartImageBackgroundOptions", []);
		await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
		await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);
		const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
		designEditorStore.setProperty("originalInnerRectangle", innerRectangle as ILayer);
		await uploadAndRegisterBackgroundImages(e, undefined, _currentScene);
		updateSceneState({ ..._currentScene, duration: 1000 } as IScene);
	};

	const handleEditSmartImage = async () => {
		const _currentScene = editor?.scene.exportToJSON();
		if (!editor || !_currentScene) return;
		setActivePanel(PanelType.IMAGES);
		imagesStore.setProperty("secondaryTabType", SecondaryTabTypeEnum.SMART_IMAGE);
		await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
		if (imagesStore.smartImageBackgroundOptions.length === 0) {
			const originalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);
			if (originalImage) {
				const originalImageUrl = (originalImage as IStaticImage)?.src;
				await uploadAndRegisterBackgroundImages(
					{} as ChangeEvent<HTMLInputElement>,
					originalImageUrl,
					_currentScene,
				);
			}
		}
		updateSceneState({ ..._currentScene, duration: 1000 } as IScene);
	};

	const handleDeleteSmartImage = async () => {
		const _currentScene = editor?.scene.exportToJSON();
		if (!editor || !_currentScene) return;
		imagesStore.resetSmartImage();
		await removeObjectFromScene(_currentScene, ObjectsEnum.OuterRectangle);
		await removeObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
		updateSceneState({ ..._currentScene, duration: 1000 });
		const isHeaderVisible = await checkHeaderVisibility(_currentScene);
		const showSmartImageHeader = await checkSmartImageHeader(_currentScene);
		setIsHeaderVisible(isHeaderVisible);
		setShowSmartImageHeader(showSmartImageHeader);
	};

	return {
		isSmartImageHeaderVisible: isHeaderVisible,
		showSmartImageHeader,
		disableCanvasMenuActions,
		handleMouseEnter,
		handleMouseLeave,
		onUploadImage,
		handleEditSmartImage,
		handleDeleteSmartImage,
	};
};

export default useSmartImageHeader;
