import { Box, Divider, Popover, Slider, Typography } from "@mui/material";
import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import Borderdash1 from "../../../../../../assets/svgs/Frame 87208 (1).svg";
import Borderdash2 from "../../../../../../assets/svgs/Frame 87209.svg";
import Borderdash3 from "../../../../../../assets/svgs/Frame 87210.svg";
import Border from "../../../../../../assets/svgs/Line1 (1).svg";
import noBordericon from "../../../../../../assets/svgs/ban.svg";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { LayerType } from "../../../../../../types";
import styles from "./BorderPopover.module.scss";
type Props = {
	open: boolean;
	handleClose?: () => void;
	anchorEl?: HTMLButtonElement;
};

const BorderPopover = ({ open = false, handleClose, anchorEl }: Props) => {
	const { campaignStore } = useAppStore();
	const activeObject = useActiveObject() as Required<any>;
	const [borderWidth, setborderWidth] = useState<number>(activeObject?.strokeWidth);
	const [borderRadius, setBorderRadius] = useState<number>(activeObject?.rx);
	const [dashGap, setDashGap] = useState<number>(activeObject?.strokeDashArray?.[0] ?? 0);
	const [hideSliders, setHideSliders] = useState<boolean>(!activeObject?.strokeWidth);
	const { EditAllAds } = useDesignEditorUtils();
	const editor = useEditor();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const isCircleOrTriangle =
		activeObject?.type === LayerType.STATIC_CIRCLE || activeObject?.type === LayerType.STATIC_TRIANGLE;

	const handleChangeBorderWeight = (value: number[]) => {
		if (editor) {
			setborderWidth(value[0]);

			editor.objects.update(
				{
					strokeWidth: value[0],
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.STROKE_WIDTH, value[0]);
		}
	};

	const handleRadiusChange = (value: number[]) => {
		if (editor) {
			setBorderRadius(value[0]);

			editor.objects.update(
				{
					rx: value[0],
					ry: value[0],
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.RADIUS, value[0]);
		}
	};

	const handleDashGapChange = (value: number[]) => {
		if (editor) {
			setDashGap(value[0]);

			editor.objects.update(
				{
					strokeDashArray: [value[0], value[0]],
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.STROKE_DASH_ARRAY, [value[0], value[0]]);
		}
	};

	const handleChangeBorder = (value: number[], stroke: boolean = true) => {
		if (editor) {
			setHideSliders(!stroke);
			setDashGap(value[0]);
			const strokeWidth = stroke ? (activeObject.strokeWidth !== 0 ? activeObject.strokeWidth : 1) : 0;
			setborderWidth(strokeWidth);
			editor.objects.update(
				{
					strokeDashArray: value,
					strokeWidth: strokeWidth,
					stroke: stroke && !activeObject.stroke ? "#000" : activeObject.stroke,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			if (stroke) {
				EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.STROKE_DASH_ARRAY, value);
			} else {
				setborderWidth(0);
				EditAllAds(activeObject?.type, activeObject.shapeType, FeatureType.STROKE_WIDTH, 0);
			}
		}
	};

	return (
		<Popover
			disableRestoreFocus
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<Box className={styles.allBorderControls}>
				<Typography className={styles.label}>{t("borderStyle")}</Typography>
				<Box className={styles.borderStyles}>
					<Box
						className={clsx({
							[styles.borderControl]: true,
							[styles.selectedItem]: hideSliders,
						})}
						onClick={() => {
							handleChangeBorder([0, 0], false);
						}}
					>
						<img src={noBordericon} alt="" />
					</Box>
					<Box
						className={clsx({
							[styles.border]: true,
							[styles.borderControl]: true,
							[styles.selectedItem]: !hideSliders && dashGap === 0,
						})}
						onClick={() => {
							handleChangeBorder([0, 0]);
						}}
					>
						<img src={Border} alt="" />
					</Box>

					<Box
						className={clsx({
							[styles.borderControl]: true,
							[styles.selectedItem]: !hideSliders && dashGap === 30,
						})}
						onClick={() => {
							handleChangeBorder([30, 30]);
						}}
					>
						<img src={Borderdash1} alt="" />
					</Box>
					<Box
						className={clsx({
							[styles.borderControl]: true,
							[styles.selectedItem]: !hideSliders && dashGap === 20,
						})}
						onClick={() => {
							handleChangeBorder([20, 20]);
						}}
					>
						<img src={Borderdash2} alt="" />
					</Box>
					<Box
						className={clsx({
							[styles.borderControl]: true,
							[styles.selectedItem]: !hideSliders && dashGap === 10,
						})}
						onClick={() => {
							handleChangeBorder([10, 10]);
						}}
					>
						<img src={Borderdash3} alt="" />
					</Box>
				</Box>

				{!hideSliders && (
					<>
						<Divider className={styles.divider} />
						<Box>
							<Typography className={styles.label}>{t("borderWeight")}</Typography>
							<Box className={styles.sliderInput}>
								<Slider
									min={0}
									max={100}
									value={borderWidth}
									onChange={(_event, newValue) => {
										handleChangeBorderWeight(Array.isArray(newValue) ? newValue : [newValue]);
									}}
									className={styles.slider}
								/>

								<BriaInput
									type="number"
									value={Math.round(borderWidth)}
									height="19px"
									className={styles.input}
									inputProps={{ className: styles.inputBase }}
								/>
							</Box>
							<Divider
								className={clsx({
									[styles.divider]: true,
									[styles.hide]: isCircleOrTriangle,
								})}
							/>
							<Typography
								className={clsx({
									[styles.label]: true,
									[styles.hide]: isCircleOrTriangle,
								})}
							>
								{t("cornerRadius")}
							</Typography>
							<Box
								className={clsx({
									[styles.sliderInput]: true,
									[styles.hide]: isCircleOrTriangle,
								})}
							>
								<Slider
									min={0}
									max={100}
									value={borderRadius}
									onChange={(_event, newValue) => {
										handleRadiusChange(Array.isArray(newValue) ? newValue : [newValue]);
									}}
									className={styles.slider}
								/>

								<BriaInput
									type="number"
									value={Math.round(borderRadius)}
									height="19px"
									className={styles.input}
									inputProps={{ className: styles.inputBase }}
								/>
							</Box>
							<Divider className={styles.divider} />
							<Typography className={styles.label}>{t("dashGap")}</Typography>
							<Box className={styles.sliderInput}>
								<Slider
									min={0}
									max={100}
									value={dashGap}
									onChange={(_event, newValue) => {
										handleDashGapChange(Array.isArray(newValue) ? newValue : [newValue]);
									}}
									className={styles.slider}
								/>

								<BriaInput
									type="number"
									value={Math.round(dashGap)}
									height="19px"
									className={styles.input}
									inputProps={{ className: styles.inputBase }}
								/>
							</Box>
						</Box>
					</>
				)}
			</Box>
		</Popover>
	);
};

export default BorderPopover;
