import i18next from "i18next";
import {
	ADVERTISING,
	E_COMMERCE,
	SOCIAL_MEDIA,
} from "../constants/PlatformTypesConstants";

export type CropPresetItem = {
	name: string;
	width: number;
	height: number;
	aspectRatioWidth: number;
	aspectRatioHeight: number;
	resize: { width: number; height: number };
	index: number;
};
export type PlatformType = typeof SOCIAL_MEDIA | typeof E_COMMERCE | typeof ADVERTISING;

export type PlatformData = {
	placement: string;
	image_width: number;
	image_height: number;
	aspect_ratio_width: number;
	aspect_ratio_height: number;
	destination_name: string;
	channel: PlatformType;
};

export const PLATFORM_INDEX: Record<PlatformType, number> = {
	[SOCIAL_MEDIA]: 0,
	[E_COMMERCE]: 1,
	[ADVERTISING]: 2,
};

export const generateCropPresetsByPlatform = (platformDataArray: PlatformData[]): Map<string, CropPresetItem[]>[] => {
	const createPresetItem = (
		presetMapArray: Map<string, CropPresetItem[]>[],
		data: PlatformData,
		platformIndex: number,
	) => {
		const cropPreset: CropPresetItem = {
			name: i18next.t(data.placement),
			width: data.image_width,
			height: data.image_height,
			aspectRatioWidth: data.aspect_ratio_width,
			aspectRatioHeight: data.aspect_ratio_height,
			resize: calculateResize(data.image_width, data.image_height),
			index: 0,
		};

		if (!presetMapArray[platformIndex]) {
			presetMapArray[platformIndex] = new Map();
		}

		const destinationName = i18next.t(data.destination_name);
		if (presetMapArray[platformIndex].has(destinationName)) {
			const presetItems = presetMapArray[platformIndex].get(destinationName)!;
			cropPreset.index = presetItems.length;
			presetItems.push(cropPreset);
			presetMapArray[platformIndex].set(destinationName, presetItems);
		} else {
			presetMapArray[platformIndex].set(destinationName, [cropPreset]);
		}
	};

	const presetMapArray: Map<string, CropPresetItem[]>[] = [new Map(), new Map(), new Map()];

	platformDataArray.forEach((data) => {
		switch (data.channel) {
			case SOCIAL_MEDIA:
				createPresetItem(presetMapArray, data, PLATFORM_INDEX[SOCIAL_MEDIA]);
				break;
			case E_COMMERCE:
				createPresetItem(presetMapArray, data, PLATFORM_INDEX[E_COMMERCE]);
				break;
			case ADVERTISING:
				createPresetItem(presetMapArray, data, PLATFORM_INDEX[ADVERTISING]);
				break;
		}
	});

	return presetMapArray;
};

const calculateResize = (width: number, height: number): { width: number; height: number } => {
	const minWidth = 96;
	const minHeight = 48;

	if (width === height) return { width: minWidth, height: minHeight };

	return width > height
		? { width: minWidth, height: (height * minWidth) / width }
		: { width: (width * minHeight) / height, height: minHeight };
};
