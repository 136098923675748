import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { APPS } from "../../constants/AppsConstants.ts";
import { setSelectedOrganization } from "../../helpers/localStorage";
import { useAuthService } from "../../hooks/useAuthService";
import { useBriaTheme } from "../../hooks/useBriaTheme";
import { useIframe } from "../../hooks/useIframe";
import { useAppStore } from "../../hooks/useStores";
import { ImageEditingViewMode } from "../../models/image-to-image.ts";
import { Iframe, IframePostMessageTypes } from "../../models/new-iframe";
import { UserOrganization } from "../../models/organization";
import iframeStore from "../../pages/IframeNew/iframe-store.tsx";

function InitIframe() {
	const { textToImageStore, campaignStore, playgroundStore, imageToImageStore, authStore } = useAppStore();
	const { getIframeId, authenticateIframe, navigateToLandingPage, getPassedImageUrl } = useIframe();
	const { convertUnderscoreToCssVar, setCssVariableValue } = useBriaTheme();
	const { loginAsGuest } = useAuthService();

	useEffect(() => {
		const handlePreviewMessage = async (event: MessageEvent) => {
			const { type, message } = JSON.parse(event.data);
			if (type === IframePostMessageTypes.InitPreview && message) {
				const { iframe, fb_organization, orgSubscription } = message;
				await updateIframe({ ...iframe, id: "preview" }, fb_organization);
				authStore.orgSubscription = orgSubscription;
				navigateToLandingPage();
			}
		};

		const getIframeAnonymous = async (iframeId: string) => {
			try {
				if (!authStore.user) {
					await loginAsGuest();
				}
				return await iframeStore.getIframeAnonymous(iframeId);
			} catch (error: any) {
				if (error instanceof AxiosError && (error as AxiosError).response?.status === 403) {
					// in case we have different iframes loaded in the same browser
					await loginAsGuest();
					return await iframeStore.getIframeAnonymous(iframeId);
				}
			}
		};

		const initIframe = async () => {
			const iframeId = getIframeId();
			if (!iframeId) return;

			if (iframeStore.isPreview()) {
				window.addEventListener("message", handlePreviewMessage);
			} else {
				const passedImageUrl = getPassedImageUrl();
				if (passedImageUrl) {
					playgroundStore.playgroundResults = [
						{
							id: uuidv4(),
							type: APPS.IMAGE_TO_IMAGE,
							config: imageToImageStore.config,
							images: [
								{
									id: uuidv4(),
									url: "",
									config: imageToImageStore.config,
									type: APPS.IMAGE_TO_IMAGE,
									loading: true,
									selected: true,
								},
							],
						},
					];
				}

				const res = await getIframeAnonymous(iframeId);
				res && (await updateIframe(res.iframe, res.fb_organization));
				navigateToLandingPage();

				if (
					passedImageUrl &&
					playgroundStore.playgroundResults.length > 0 &&
					playgroundStore.playgroundResults[0].images.length > 0
				) {
					playgroundStore.playgroundResults[0].images[0] = {
						...playgroundStore.playgroundResults[0].images[0],
						url: passedImageUrl,
					};
				}
			}
			iframeStore.sendPostMessage(IframePostMessageTypes.InitialLoadComplete);
			iframeStore.isInitialized = true;
		};
		if (authStore.isInitialized && !iframeStore.isInitialized) {
			initIframe();
			// return () => {
			// 	window.removeEventListener("message", handlePreviewMessage);
			// };
		}
	}, [authStore.isInitialized, iframeStore.isInitialized]);

	const updateIframe = async (iframe: Iframe, fb_organization: UserOrganization) => {
		iframeStore.iframe = iframe;
		updateTheme();
		setSelectedOrganization(fb_organization);
		await Promise.all([authenticateIframe(iframe), initConfigs()]);
	};

	const initConfigs = async () => {
		return Promise.all([initImageGenerationConfig(), initImageEditingConfig(), initCampaignConfig()]);
	};

	const initImageGenerationConfig = async () => {
		const defaultAspectRatio = iframeStore.iframe?.config.image_generation_config?.default_aspect_ratio;
		defaultAspectRatio && textToImageStore.handleConfigChange("aspect_ratio", defaultAspectRatio);
	};

	const initImageEditingConfig = async () => {
		playgroundStore.imageEditingViewMode =
			iframeStore.iframe?.config.image_editing_config?.default_view_mode ?? ImageEditingViewMode.GALLERY;
	};

	const initCampaignConfig = async () => {
		campaignStore.handleAdminModeChange(!!iframeStore.iframe?.config.campaign_config?.enable_admin_mode);
	};

	const updateTheme = () => {
		const style = iframeStore.iframe?.config.custom_style;
		Object.entries(style?.cssVars ?? {}).forEach(([cssKey, value]) =>
			setCssVariableValue(convertUnderscoreToCssVar(cssKey), value),
		);
	};
	return null;
}

export default observer(InitIframe);
