import { Box, Divider, SelectChangeEvent, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown, { DropDownItem } from "../../../../components/common/BriaDropDown/BriaDropDown.tsx";
import { getSelectedOrganization } from "../../../../helpers/localStorage.ts";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { IframePages, getDefaultIframe } from "../../../../models/new-iframe.ts";
import iframeStore from "../../iframe-store.tsx";
import IframeAvailablePages from "./IframeAvailablePages.tsx";
import IframeCampaignConfig from "./IframeCampaignConfig.tsx";
import IframeGeneralConfig from "./IframeGeneralConfig.tsx";
import IframeImageEditingConfig from "./IframeImageEditingConfig.tsx";
import IframeImageGenerationConfig from "./IframeImageGenerationConfig/IframeImageGenerationConfig.tsx";
import styles from "./NewIframeForm.module.scss";
import IframeCustomStyleConfig from "./Style/IframeCustomStyleConfig.tsx";

const NewIframeForm = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form" });
	const [landingPageOptions, setLandingPageOptions] = useState<DropDownItem[]>([]);

	useEffect(() => {
		if (iframeForm.config.landing_page) {
			handleFormChange("config", {
				...iframeForm.config,
				landing_page: iframeForm.config.enabled_pages[iframeForm.config.landing_page]
					? iframeForm.config.landing_page
					: ("" as IframePages),
			});
		}
		setLandingPageOptions(
			Object.entries(iframeForm.config.enabled_pages)
				.filter(([, isEnabled]) => isEnabled)
				.map(([page]) => page)
				.sort(iframeStore.sortPages)
				.map((page) => ({
					key: iframeStore.getIframeAppName(page as IframePages),
					value: page,
				})),
		);
	}, [iframeForm.config.enabled_pages]);

	const handleStartingPageChange = (e: SelectChangeEvent<IframePages>) => {
		handleFormChange("config", { ...iframeForm.config, landing_page: e.target.value as IframePages });
	};

	return (
		<Box className={styles.container}>
			<InputLayout label={t("name.label")}>
				<TextField
					value={iframeForm.name}
					onFocus={() => {
						if (
							iframeForm.name === getDefaultIframe(getSelectedOrganization()?.organization.uid ?? "").name
						) {
							handleFormChange("name", "");
						}
					}}
					onBlur={() => {
						if (iframeForm.name === "") {
							handleFormChange(
								"name",
								getDefaultIframe(getSelectedOrganization()?.organization.uid ?? "").name,
							);
						}
					}}
					onChange={(e) => handleFormChange("name", e.target.value)}
					placeholder={t("name.placeholder")}
					fullWidth
					InputProps={{ classes: { root: styles.textField } }}
				/>
			</InputLayout>
			<InputLayout label={t("description.label")}>
				<TextField
					value={iframeForm.description}
					onChange={(e) => handleFormChange("description", e.target.value)}
					placeholder={t("description.placeholder")}
					fullWidth
					multiline
					minRows={3}
					InputProps={{ classes: { root: styles.bigTextField } }}
				/>
			</InputLayout>
			<InputLayout label={t("availablePages.label")}>
				<IframeAvailablePages />
			</InputLayout>
			<InputLayout label={t("startingPage.label")}>
				<BriaDropDown
					value={iframeForm?.config.landing_page}
					onChange={handleStartingPageChange}
					placeholder={t("startingPage.placeholder")}
					items={landingPageOptions}
				/>
			</InputLayout>
			<Divider className={styles.divider} />
			<InputLayout label={t("customStyle.label")}>
				<IframeCustomStyleConfig />
			</InputLayout>
			<Divider className={styles.divider} />
			<InputLayout label={t("generalConfig.label")}>
				<IframeGeneralConfig />
			</InputLayout>
			{(iframeForm.config.enabled_pages.textToImage || iframeForm.config.enabled_pages.textToVector) && (
				<>
					<Divider className={styles.divider} />
					<InputLayout label={t("imageGenerationConfig.label")}>
						<IframeImageGenerationConfig />
					</InputLayout>
				</>
			)}
			{iframeForm.config.enabled_pages.imageToImage && (
				<>
					<Divider className={styles.divider} />
					<InputLayout label={t("imageEditingConfig.label")}>
						<IframeImageEditingConfig />
					</InputLayout>
				</>
			)}
			{iframeForm.config.enabled_pages.campaign && (
				<>
					<Divider className={styles.divider} />
					<InputLayout label={t("campaignConfig.label")}>
						<IframeCampaignConfig />
					</InputLayout>
				</>
			)}
		</Box>
	);
};

const ObservedComponent = observer(NewIframeForm);
export default ObservedComponent;
