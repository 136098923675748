import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef, useState } from "react";
import RouterConstants from "../../../constants/RouterConstants";
import { getSelectedOrganization, setSelectedOrganization } from "../../../helpers/localStorage";
import useOrgNavigation from "../../../hooks/useOrgNavigation";
import { useAppStore } from "../../../hooks/useStores";
import { UserOrganization } from "../../../models/organization";
import { capitalizeFirstLetter, isAppsURL } from "../../../utils";
import BriaDropdown, { BriaDropDownProps } from "../BriaDropDown/BriaDropDown";
import styles from "./OrganizationsDropdown.module.scss";

type OrganizationDropdownProps = {
	reload?: boolean;
	onOrgChange?: (value: UserOrganization | null) => void;
	labelFontWeight?: number;
	labelFontSize?: string;
	placeholder?: string;
	noneOption?: boolean;
	skipGlobalOrgUpdate?: boolean;
} & Partial<BriaDropDownProps<string>>;

const OrganizationsDropdown = ({
	reload = true,
	onOrgChange,
	labelFontWeight = 600,
	labelFontSize = "16px",
	noneOption,
	skipGlobalOrgUpdate,
	placeholder = "Select organization",
	...rest
}: OrganizationDropdownProps) => {
	const { authStore } = useAppStore();
	const { isPrivateRoute } = useOrgNavigation();
	const [value, setValue] = useState(getSelectedOrganization()?.organization.uid);
	const [filteredOrgs, setFilteredOrgs] = useState<UserOrganization[]>(authStore.userOrganizations);
	const orgsDropdownController = useRef<any>();

	useEffect(() => {
		setFilteredOrgs(authStore.userOrganizations);
	}, [authStore.userOrganizations]);

	useEffect(() => {
		!skipGlobalOrgUpdate && setValue(getSelectedOrganization()?.organization.uid);
	}, [getSelectedOrganization()?.organization]);

	const changeHandler = useCallback(
		async (event: SelectChangeEvent<string>) => {
			if (event.target.value === "") {
				setValue(event.target.value);
				onOrgChange && onOrgChange(null);
				return;
			}
			const selOrg = filteredOrgs.find((org) => org.organization.uid === event.target.value);
			if (selOrg) {
				!skipGlobalOrgUpdate && setSelectedOrganization(selOrg);
				setValue(selOrg.organization.uid);
				onOrgChange && onOrgChange(selOrg);
				if (isPrivateRoute(window.location.pathname)) {
					window.location.href = RouterConstants.CONSOLE.path;
				} else {
					reload && window.location.reload();
				}
			}
		},
		[filteredOrgs, reload, isPrivateRoute],
	);

	const onSearchChange = useCallback(
		(searchText: string) => {
			if (orgsDropdownController.current) {
				orgsDropdownController.current.abort();
			}
			orgsDropdownController.current = new AbortController();
			if (searchText && searchText.length > 0) {
				authStore
					.searchOrganizations(searchText, orgsDropdownController.current.signal)
					.then((resOrgs) => {
						const prevSelOrg = getSelectedOrganization();
						if (prevSelOrg) {
							resOrgs = [
								prevSelOrg,
								...resOrgs.filter((x) => x.organization.uid !== prevSelOrg.organization.uid),
							];
						}
						setFilteredOrgs(resOrgs);
					})
					.catch(() => {});
			} else {
				setFilteredOrgs(authStore.userOrganizations);
			}
		},
		[authStore],
	);

	return authStore.userOrganizations.length === 0 || !authStore.isLoggedIn ? null : authStore.userOrganizations
			.length == 1 ? (
		<Typography className={styles.organizationName}>
			{authStore.userOrganizations[0]?.organization?.name}{" "}
		</Typography>
	) : (
		<Box className={styles.orgDropdown}>
			<BriaDropdown
				addNoneOption={noneOption}
				searchable={authStore.user?.isSuperAdmin() ?? false}
				hideArrowIcon={authStore.userOrganizations.length <= 1}
				value={value}
				items={filteredOrgs.map((org) => {
					return { key: capitalizeFirstLetter(org.organization.name ?? ""), value: org.organization.uid };
				})}
				disabled={authStore.userOrganizations.length <= 1}
				onChange={changeHandler}
				width={"100%"}
				height={isAppsURL() ? "32px" : "40px"}
				border={"none"}
				onSearchChange={(e) => onSearchChange(e.target.value)}
				debounceSearch={true}
				labelFontWeight={labelFontWeight}
				labelFontSize={labelFontSize}
				SearchPlaceholder={"Search"}
				searchIcon={true}
				placeholder={placeholder}
				{...rest}
				minHeight="auto !important"
			/>
		</Box>
	);
};

export default observer(OrganizationsDropdown);
