import React from "react";
import useDesignEditorPages from "../hooks/useDesignEditorScenes";
import { useEditor } from "../hooks/useEditor";
import { IScene } from "../types";

type DesignEditorUtils = {
	updateScenes: () => Promise<IScene[]>;
};

const useScenesUtils = (): DesignEditorUtils => {
	const scenes = useDesignEditorPages();
	const editor = useEditor();

	const updateScenes = React.useCallback(async () => {
		const updatedTemplate = editor?.scene.exportToJSON();
		const updatedPreview = updatedTemplate && (await editor?.renderer.render(updatedTemplate));

		const updatedPages = scenes.map((p) => {
			if (updatedTemplate && p.id === updatedTemplate.id) {
				return { ...updatedTemplate, preview: updatedPreview };
			}
			return p;
		});
		const newPages = [...updatedPages] as IScene[];
		return newPages;
	}, [scenes]);

	return {
		updateScenes,
	};
};

export default useScenesUtils;
