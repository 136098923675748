import { Box, Divider, Typography } from "@mui/material";
import { throttle } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SolidGradientColorPicker from "../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { BrandDefinition } from "../../../../../../models/brandDefinition";
import { IStaticText, LayerType } from "../../../../../../types";
import { getTextProperties } from "../../../../utils/text";
import styles from "./TextFill.module.scss";

const TextFill = () => {
	const { t: tEditorTabs } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const editor = useEditor();
	const [brands, setBrands] = useState<BrandDefinition[]>([]);
	const { brandsDefinitionStore, brandFontStore, campaignStore } = useAppStore();
	const activeObject = useActiveObject() as Required<IStaticText>;
	const activeObjectColor = activeObject?.fill;
	const [color, setColor] = useState(activeObjectColor);
	const { EditAllAds, getRgbAndOpacityFromRgba, updateColorOpacity } = useDesignEditorUtils();
	useEffect(() => {
		if (brandsDefinitionStore.brands.items.length == 0) {
			loadBrands();
		} else {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === "Active"));
		}
	}, []);

	useEffect(() => {
		if (editor) {
			if (activeObject && activeObject.type === "StaticText") {
				const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);

				setColor(updateColorOpacity(textProperties.color, activeObject.opacity));
				if (activeObject) {
					activeObject.fill = textProperties.color;
				}
			}
		}
	}, [editor, activeObject]);

	const updateObjectFill = throttle((color: string, brandColor: boolean = false) => {
		if (activeObject) {
			const result = getRgbAndOpacityFromRgba(color);

			editor?.objects.update(
				{
					fill: result?.rgb ?? color,
					opacity: brandColor ? undefined : result?.opacity ?? 1,
					brandId: undefined,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);

			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.COLOR, color);
		}
	}, 100);

	const loadBrands = () => {
		brandsDefinitionStore.loadBrandsDefinition().then(() => {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === "Active"));
		});
	};

	return (
		<>
			<Box className={styles.container}>
				<MainTabHeader title={tEditorTabs("textColor")} />
				<Box className={`${styles.tabs} ${styles.fullHeight}`}>
					<Box className={styles.ColorFillContent}>
						<Box className={styles.colorPickerContainer}>
							<SolidGradientColorPicker
								color={color}
								onChange={(color: string) => {
									setColor(color);
									updateObjectFill(color);
								}}
								hideColorTypeBtns={true}
							/>
						</Box>
						<Divider className={styles.mainDivider} />
						<Typography className={styles.mainLabel}>{tEditorTabs("brandColors")}</Typography>
						<Box className={styles.BrandsContainer}>
							{brands.map((brand: BrandDefinition, idx: number) => {
								const colors = brand?.color_pallete?.colors;
								return (
									<>
										<Box className={styles.brandColorsRow}>
											<Typography className={styles.brandNameLabel}>
												{brand.name.length < 10 ? brand.name : brand.name.slice(0, 10) + "..."}
											</Typography>

											{colors?.map((color: string, index: number) => (
												<Box
													onClick={() => {
														updateObjectFill(color, true);
													}}
													key={index}
												>
													<Box className={styles.itemContent}>
														<Box
															sx={{ background: color }}
															className={styles.itemPreview}
														></Box>
													</Box>
												</Box>
											))}
										</Box>
										{idx != brands.length - 1 && <Divider className={styles.divider} />}
									</>
								);
							})}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default TextFill;
