import { Box, IconButton, TextField } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import { EditIcon } from "../../../assets/images/icons/EditIcon";
import { useCustomColorPicker } from "../../../hooks/useCustomColotPicker.tsx";
import SolidGradientColorPicker from "../SolidGradientColorPicker/SolidGradientColorPicker.tsx";
import styles from "./TextColorPicker.module.scss";

export enum ColorsPickerEnum {
	V1 = "v1",
	V2 = "v2",
}

interface Props {
	color?: string;
	onColorChange?: (color: string) => void;
	disabled?: boolean;
	withPadding?: boolean;
	colorPickerVersion?: ColorsPickerEnum;
}

function TextColorPicker({
	color,
	onColorChange,
	disabled,
	withPadding = true,
	colorPickerVersion = ColorsPickerEnum.V1,
}: Props) {
	const DEFAULT_COLOR = "#FFFFFF";
	const { colorToHex } = useCustomColorPicker();
	const [showPicker, setShowPicker] = useState(false);
	const handleIconClick = () => {
		if (colorPickerVersion === ColorsPickerEnum.V2) {
			setShowPicker(!showPicker);
		}
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value: color },
		} = e;
		onColorChange?.(color);
	};

	return (
		<Box className={styles.container}>
			<TextField
				placeholder="#8800FF"
				fullWidth
				InputProps={{
					classes: {
						root: styles.textField,
					},
				}}
				className={clsx({
					[styles.colorTextfield]: withPadding,
				})}
				value={color}
				onChange={handleChange}
				disabled={disabled}
			/>
			<IconButton
				disabled={disabled}
				onClick={handleIconClick}
				style={{ backgroundColor: colorPickerVersion === ColorsPickerEnum.V2 ? color : "unset" }}
			>
				{colorPickerVersion === ColorsPickerEnum.V1 ? (
					<input
						type="color"
						className={styles.colorInput}
						value={color}
						onChange={handleChange}
						disabled={disabled}
					/>
				) : (
					showPicker && (
						<Box className={styles.colorPickerContainer}>
							<Box className={styles.colorPicker}>
								<SolidGradientColorPicker
									color={color ?? DEFAULT_COLOR}
									onChange={(color: string) => {
										const hexColor = colorToHex(color);
										if (hexColor) {
											onColorChange?.(hexColor.slice(0, 7));
										}
									}}
									hideColorTypeBtns={true}
								/>
							</Box>
						</Box>
					)
				)}
				<EditIcon />
			</IconButton>
		</Box>
	);
}

const ObservedComponent = observer(TextColorPicker);
export default ObservedComponent;
