import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import CloseIcon from "../../../assets/images/icons/CloseIcon";
import BriaIconButton from "../BriaIconButton/BriaIconButton";
import styles from "./SubMenuHeader.module.scss";

type SubMenuHeaderProps = {
	title?: string;
	subTitle?: string;
	onClose?: () => void;
	noBorder?: boolean;
	className?: string;
};

export const SubMenuHeader = ({ title, subTitle, onClose, noBorder = false, className }: SubMenuHeaderProps) => {
	return (
		<Box
			className={clsx(styles.subMenuHeader, className, {
				[styles.noPadding]: subTitle,
				[styles.noBorder]: noBorder,
			})}
		>
			<Box className={styles.titleContainer}>
				<Typography className={styles.subMenuTitle}>{title}</Typography>
				<BriaIconButton onClick={onClose}>
					<CloseIcon />
				</BriaIconButton>
			</Box>
			{subTitle && (
				<Box className={styles.subMenuSubtitle} component="p" dangerouslySetInnerHTML={{ __html: subTitle }} />
			)}
		</Box>
	);
};
