import { SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useIframe } from "../../../hooks/useIframe.tsx";
import { IframePages } from "../../../models/new-iframe.ts";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaDropDown from "../BriaDropDown/BriaDropDown.tsx";
import styles from "./IframeHeaderNavigationDropdown.module.scss";

const IframeHeaderNavigationDropdown = () => {
	const { navigateIframe } = useIframe();
	const enabledPages = Object.entries(iframeStore.iframe.config.enabled_pages)
		.filter(([, enabled]) => enabled)
		.map(([page]) => page)
		.sort(iframeStore.sortPages)
		.map((page) => page as IframePages);

	const onChange = (e: SelectChangeEvent<IframePages>) => {
		navigateIframe(e.target.value as IframePages);
	};

	if (!iframeStore.isIframe() || enabledPages.length === 0) {
		return <></>;
	}
	return enabledPages.length > 1 ? (
		<BriaDropDown
			border={"none"}
			className={styles.navigationDropdown}
			value={iframeStore.iframe.config.landing_page}
			onChange={onChange}
			items={enabledPages.map((page) => ({
				key: iframeStore.getIframeAppName(page),
				value: page,
			}))}
		/>
	) : (
		<Typography className={styles.navigationLabel}>
			{iframeStore.getIframeAppName(iframeStore.iframe.config.landing_page)}
		</Typography>
	);
};

export default observer(IframeHeaderNavigationDropdown);
