import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function SearchIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
				<path
					d="M14.2832 13.9961L10.2832 9.99609M11.6165 6.66276C11.6165 9.24009 9.5272 11.3294 6.94987 11.3294C4.37254 11.3294 2.2832 9.24009 2.2832 6.66276C2.2832 4.08543 4.37254 1.99609 6.94987 1.99609C9.5272 1.99609 11.6165 4.08543 11.6165 6.66276Z"
					stroke="#1A0638"
					strokeWidth="1.25"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</SvgIcon>
	);
}

export default SearchIcon;
