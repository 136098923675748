import { Box, CircularProgress, Container, Grid } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import EditorImageOverlay from "../../../../components/common/EditorImageOverLay/EditorImageOverlay";
import BriaImage, { BriaImageProps } from "../../../../components/common/Galleries/BriaImage";
import { APPS } from "../../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../../hooks/useStores";
import { PlaygroundImage } from "../../../../models/image-to-image.ts";
import useImageUtils from "../../../../utils/useImageUtils";
import { Image } from "../../../models/image";
import { SecondaryTabTypeEnum } from "../../../views/DesignEditor/components/Panels/panelItems";
import { GalleryData, IMenuItem } from "../../../views/DesignEditor/components/Panels/panelItems/Images/Images";
import styles from "./ImageGrid.module.scss";

interface Iprops {
	menuItems: IMenuItem[];
	galleryData: GalleryData;
	addImageObject: (image: Image) => void;
	loadMore?: () => void;
	tabType: SecondaryTabTypeEnum;
	numberOfColumns: number;
	hideButtons?: boolean;
	hideLoadMoreButton?: boolean;
	loading?: boolean;
	hideMenuItems?: boolean;
}

const ImageGrid = ({
	menuItems,
	galleryData,
	addImageObject,
	loadMore,
	tabType,
	numberOfColumns,
	hideLoadMoreButton = false,
	loading = false,
	hideMenuItems = false,
}: Iprops) => {
	const { playgroundStore, imagesStore, campaignStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.images.imageSecondaryTap" });
	const [imagesGallery, setImageGallery] = useState<number[]>([]);
	const { convertS3UriToHttpsCloudFrontUrl, isS3Uri } = useImageUtils();

	useEffect(() => {
		const imageGallary = new Array(numberOfColumns).fill(0);
		setImageGallery(imageGallary);
	}, []);

	return (
		<Box
			className={clsx(styles.imagesContainer, {
				[styles.disabled]: loading,
			})}
		>
			{((tabType == SecondaryTabTypeEnum.SHARED_IMAGES && campaignStore.isAdminMode) ||
				tabType == SecondaryTabTypeEnum.USER_IMAGES) && (
				<Box className={styles.buttonsContainer}>
					<BriaButton
						className={styles.buttons}
						buttonType="primaryMedium"
						onClick={() => {
							imagesStore.uploadProcessDone = false;
							playgroundStore.setSelectedAspectRatio("4:3");
							playgroundStore.setTextToGenerate("");
							playgroundStore.openModal();
							if (tabType == SecondaryTabTypeEnum.USER_IMAGES) {
								imagesStore.uploadToUserGallery = true;
							} else {
								imagesStore.uploadToUserGallery = false;
							}
						}}
					>
						{t("generate")}
					</BriaButton>

					<BriaButton
						buttonType="textMedium"
						className={styles.uploadBtn}
						onClick={() => {
							imagesStore.uploadProcessDone = false;
							imagesStore.openUploadPopup = true;
							if (tabType == SecondaryTabTypeEnum.USER_IMAGES) {
								imagesStore.uploadToUserGallery = true;
							} else {
								imagesStore.uploadToUserGallery = false;
							}
						}}
					>
						{t("uploadImage")}
					</BriaButton>
				</Box>
			)}
			<Grid item className={styles.GalleryGrid}>
				<Box className={styles.galleyImagesBox}>
					<Container disableGutters maxWidth={false} className={styles.gallery}>
						{imagesGallery.map((_, index: number) => (
							<Box
								className={styles.imageContainer}
								key={index}
								marginRight={index < imagesGallery.length - 1 ? 1 : 0}
							>
								{galleryData?.showroomImages?.images
									?.filter((_, i: number) => i % 2 === index)
									.map((image: Image, index2: number) => (
										<Box
											id={image.visual_hash ?? image.visual_id}
											className={styles.imageWrapper}
											key={index2}
										>
											{image.visual_hash === imagesStore.deletedImageVhash &&
												imagesStore.isDeletingImage && (
													<>
														<Box className={styles.blurLayer} />
														<CircularProgress size={24} className={styles.loader} />
													</>
												)}
											<BriaImage
												image={
													{
														id: image.id,
														url: isS3Uri(image.url)
															? convertS3UriToHttpsCloudFrontUrl(image.url)
															: image.url,
														type: APPS.CAMPAIGN,
													} as BriaImageProps & PlaygroundImage
												}
												{...image}
												visualHash={image.visual_hash}
												url={
													isS3Uri(image.url)
														? convertS3UriToHttpsCloudFrontUrl(image.url)
														: image.url
												}
												handleClick={() => addImageObject(image)}
												hideFullScreenButton={true}
												imageErrorCallBack={(visualHash?: string) => {
													const imageToDelete = document.getElementById(visualHash || "");
													if (imageToDelete) {
														imageToDelete.style.visibility = "hidden";
														imageToDelete.style.height = "0";
														imageToDelete.style.margin = "0";
														imageToDelete.style.padding = "0";
														imageToDelete.style.display = "none";
													}
												}}
												ImageOverlay={
													<EditorImageOverlay
														menuItems={menuItems}
														image={image}
														hideMenuItems={hideMenuItems}
														secondaryTabType={tabType}
													/>
												}
												displayOverlay="on"
											/>
										</Box>
									))}
							</Box>
						))}
					</Container>
				</Box>
			</Grid>
			{!hideLoadMoreButton && (
				<BriaButton
					style={{
						margin: "auto",
						bottom: 0,
					}}
					onClick={() => loadMore && loadMore()}
				>
					{t("loadMore")}
				</BriaButton>
			)}
		</Box>
	);
};
const ObservedComponent = observer(ImageGrid);
export default ObservedComponent;
