import { Box } from "@mui/material";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2";
import EyeIcon from "../../../assets/images/icons/EyeIcon";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import { useUserChecks } from "../../../hooks/useUserChecks";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout";
import { StatusEnum } from "../../../models/common";
import { Layout } from "../../../models/layout";
import { EditLayoutLocationState } from "./Editor/LayoutEditor";
import styles from "./Layouts.module.scss";

type LayoutTableRow = {
	name: string | ReactElement;
	channel: string | ReactElement;
	placement: string | ReactElement;
	resolution: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
};

type LayoutProps = {
	templateId: number;
	showPreview: (layout: any) => void;
};
const Layouts = ({ templateId, showPreview }: LayoutProps) => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layouts" });
	const { layoutsStore } = useAppStore();
	const navigate = useSecureNavigate();
	const { canUserEditOrganization } = useUserChecks();
	const [tableData, setTableData] = useState<TableRow<LayoutTableRow>[]>([]);
	const [layoutToDelete, setLayoutToDelete] = useState<Layout>();
	const [loadingRow, setLoadingRow] = useState<{ data: Layout; column: string }>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const headerArray = [
		t("table.headers.name"),
		t("table.headers.channel"),
		t("table.headers.placement"),
		t("table.headers.resolution"),
		t("table.headers.status"),
		t("table.headers.actions"),
	];
	const [filterBy, setFilterBy] = useState<FilterByOptions>();
	const paginationOptions: PaginationOptions = {
		rowsPerPage: layoutsStore.rowsPerPage,
		totalRows: layoutsStore.paginatedLayouts.total,
		loadNextPage: async (pageNumber: number, filterBy?: FilterByOptions) => {
			await layoutsStore.loadLayouts(templateId, pageNumber, filterBy);
		},
	};

	useEffect(() => {
		layoutsStore.configErrors = {};
		layoutsStore.loadLayouts(templateId, 1).then(() => {
			showPreview(layoutsStore.paginatedLayouts.items[0]);
		});
	}, []);

	useEffect(() => {
		if (!layoutsStore.isLoading && templateId) {
			const newTableData: TableRow<LayoutTableRow>[] = layoutsStore.paginatedLayouts.items.map(createRow);
			setTableData([...newTableData]);
		}
	}, [layoutsStore.paginatedLayouts.items]);

	const editLayout = async (layoutToEdit: Layout) => {
		const editLayoutState: EditLayoutLocationState = {
			layoutToEdit: toJS(layoutToEdit),
		};
		navigate(`${RouterConstants.LAYOUT_EDITOR.editPath}/${layoutToEdit.id}`, {
			state: editLayoutState,
		});
	};

	const deleteLayout = async () => {
		if (layoutToDelete) {
			setLoadingRow({ data: layoutToDelete, column: "actions" });
			closeDeletePopup();
			await layoutsStore.updateLayout({ ...layoutToDelete, status: StatusEnum.DELETED });
			setLoadingRow(undefined);
		}
	};

	const updateLayoutStatus = async (layout: Layout, status: StatusEnum) => {
		setLoadingRow({ data: layout, column: "status" });
		await layoutsStore.updateLayout({ ...layout, status });
		setLoadingRow(undefined);
	};

	const createRow = (layout: Layout): TableRow<LayoutTableRow> => {
		const color = layout.status === StatusEnum.DELETED ? "#1A063840" : "";
		const statusContainer = (
			<Box className={styles.statusContainer} sx={{ color }}>
				<div className={`${styles[layout.status.toLowerCase()]} ${styles.circle}`} />
				{layout.status}
			</Box>
		);
		return {
			rowStyle: {
				backgroundColor: layout.status === StatusEnum.DELETED ? "#D9D9D91A" : "",
			},
			key: layout.id,
			data: {
				name: (
					<Box className={styles.nameStyle} style={{ color: color }}>
						{layout.name}
					</Box>
				),
				channel: <Box style={{ color: color }}>{layout.placement?.channel?.name}</Box>,
				placement: <Box style={{ color: color }}>{layout.placement?.name}</Box>,
				resolution: (
					<Box style={{ color: color }}>{`${layout.placement?.width}x${layout.placement?.height}`}</Box>
				),
				status: (
					<Box className={styles.statusContainer}>
						{layout.status !== StatusEnum.DELETED && canUserEditOrganization() ? (
							<ActionsDotsDropdown
								label={statusContainer}
								items={Object.values(StatusEnum)
									.filter((status) => status !== StatusEnum.DELETED)
									.map((status) => ({
										text: status,
										onClick: () => updateLayoutStatus(layout, status),
									}))}
							/>
						) : (
							statusContainer
						)}
					</Box>
				),
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							{
								icon: (
									<EyeIcon
										opacity={1}
										className={styles.actionBtn}
										sx={{ width: "18px !important" }}
									/>
								),
								onClick: () => showPreview(layout),
							},
							{
								icon: <EditIcon2 className={styles.actionBtn} sx={{ width: "18px !important" }} />,
								onClick: () => canUserEditOrganization() && editLayout(layout),
							},
							{
								icon: <DeleteIcon className={styles.actionBtn} />,
								onClick: () => canUserEditOrganization() && handleOpenDeletePopup(layout),
							},
						].map((action, index) => (
							<Box
								key={index}
								onClick={action.onClick}
								className={clsx({
									[styles.disabledOpacity]:
										!canUserEditOrganization() || layout.status === StatusEnum.DELETED,
								})}
							>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			...filterBy,
			search: { text: e.target.value, fields: ["name", "status", "resolution"] },
		};
		setFilterBy(updatedFilterBy);
		await layoutsStore.loadLayouts(templateId, 1, updatedFilterBy);
	};

	const handleOpenDeletePopup = (layout: Layout) => {
		setOpenDeletePopup(true);
		setLayoutToDelete(layout);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setLayoutToDelete(undefined);
	};

	const createLayout = () => {
		templateId &&
			navigate(`${RouterConstants.NEW_LAYOUT_EDITOR.fullPath}`, {
				state: { templateId },
			});
	};

	return (
		<>
			<Box className={styles.container}>
				<TableLayout
					headerArray={headerArray}
					tableData={tableData}
					enableSearch={true}
					searchBoxPlaceholder={t("table.searchPlaceholder")}
					handleSearchChange={handleSearchChange}
					filterBy={filterBy}
					paginationOptions={paginationOptions}
					loading={layoutsStore.isLoading}
					loadingRow={loadingRow}
					hideSecondaryButton
					bottomButtonText={t("table.bottomCreateBtn")}
					bottomButtonIconType="plus"
					bottomButtonClick={createLayout}
				/>
			</Box>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={ct("delete")}
				open={openDeletePopup}
				onClick={deleteLayout}
			/>
		</>
	);
};

export default observer(Layouts);
