import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown.tsx";
import CopyToClipboardButton from "../../../components/common/CopyToClipboardButton/CopyToClipboardButton.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { ImageGenerationsSubAPIType } from "../../../models/sandboxAPI.ts";
import PlaygroundActionsHeader from "../ActionsHeader/PlaygroundActionsHeader.tsx";
import EmptyPlayground from "./EmptyState/EmptyPlayground.tsx";
import styles from "./PlaygroundTextResults.module.scss";

const PlaygroundTextResults = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textResults" });
	const { playgroundStore, sandboxAPIStore } = useAppStore();
	const [lastCountResults, setLastCountResult] = useState(playgroundStore.textResults.length);
	const resultsRef = useRef<HTMLDivElement>(null);
	const navigate = useSecureNavigate();

	useEffect(() => {
		playgroundStore.textResults.length > lastCountResults && scrollBottom();
		setLastCountResult(playgroundStore.textResults.length);
	}, [playgroundStore.textResults.length]);

	const scrollBottom = () => {
		if (resultsRef.current) {
			resultsRef.current.scrollTo({ behavior: "smooth", top: resultsRef.current.scrollHeight });
		}
	};

	const textToImageCall = (text: string) => {
		navigate(`/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_IMAGE_FAST.path}`, { replace: true });
		sandboxAPIStore.handleAPIConfigChange("selectedSubAPI", ImageGenerationsSubAPIType.GenerateImage);
		sandboxAPIStore.setProperty("textResultToUse", text);
		setTimeout(() => {
			sandboxAPIStore.setProperty("textResultToUse", "");
		}, 250); //After samll dealy reset the textResultToUse
	};

	const textToVectorCall = (text: string) => {
		navigate(`/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_VECTOR_FAST.path}`, { replace: true });
		sandboxAPIStore.handleAPIConfigChange("selectedSubAPI", ImageGenerationsSubAPIType.GenerateVector);
		sandboxAPIStore.setProperty("textResultToUse", text);
		setTimeout(() => {
			sandboxAPIStore.setProperty("textResultToUse", "");
		}, 250);
	};

	return (
		<Box className={styles.root}>
			<PlaygroundActionsHeader />
			<Box ref={resultsRef} className={`${styles.results} ${styles.sandboxAPIresults}`}>
				<>
					{!playgroundStore.textResults.length ? (
						<EmptyPlayground />
					) : (
						playgroundStore.textResults.map((result) => (
							<Box key={result.id} className={clsx(styles.resultRow)}>
								<Box className={`${styles.mb1} ${styles.originalRow}`}>
									<Box className={`${styles.badge}`}>{t("original")}</Box>
									<Box className={`${styles.actionButtons}`}>
										<CopyToClipboardButton
											className={styles.copyBtn}
											textToCopy={result.original}
										/>
										<ActionsDotsDropdown
											menuClassName={styles.actionDotsMenu}
											items={[
												{
													text: t("useInTextToImage"),
													onClick: () => textToImageCall(result.original),
												},
												{
													text: t("useInTextToVector"),
													onClick: () => textToVectorCall(result.original),
												},
											]}
										/>
									</Box>
								</Box>
								<Box className={`${styles.mb2} ${styles.paragraph}`}>{result.original}</Box>

								<Box className={`${styles.mb1} ${styles.enhancedRow}`}>
									<Box className={`${styles.badge}`}>{t("enhanced")}</Box>
									<Box className={`${styles.actionButtons}`}>
										<CopyToClipboardButton
											className={styles.copyBtn}
											textToCopy={result.enhanced}
										/>
										<ActionsDotsDropdown
											menuClassName={styles.actionDotsMenu}
											items={[
												{
													text: t("useInTextToImage"),
													onClick: () => textToImageCall(result.enhanced),
												},
												{
													text: t("useInTextToVector"),
													onClick: () => textToVectorCall(result.enhanced),
												},
											]}
										/>
									</Box>
								</Box>
								<Box className={`${styles.paragraph}`}>{result.enhanced}</Box>
							</Box>
						))
					)}
				</>
			</Box>
		</Box>
	);
};

export default observer(PlaygroundTextResults);
