import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2.tsx";
import EyeIcon from "../../../assets/images/icons/EyeIcon";
import PlusIcon from "../../../assets/images/icons/PlusIcon.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../components/common/BriaInput/BriaInput";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip.tsx";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import CopyToClipboardButton from "../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable.tsx";
import { BriaAxios } from "../../../config/axios";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants";
import useBriaAxios from "../../../hooks/useBriaAxios";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout";
import { EventsLogEnum } from "../../../models/common.ts";
import { Iframe } from "../../../models/iframe";
import { EditIframeLocationState } from "../IframeEditor/IframeEditor";
import { IframeStatusEnum, PLAYGROUND_VHASH_EXAMPLE, getIframeHost } from "../iframeTypes";
import styles from "./IframeConfig.module.scss";

type IframeTableRow = {
	name: string | ReactElement;
	updated: string | ReactElement;
	domains: string[] | ReactElement[];
	status: string | ReactElement;
	actions: ReactElement;
};

const IframeConfig = () => {
	const {
		data: orgIframes,
		loading: loadingIframes,
		error: _errorIframes,
	} = useBriaAxios<Iframe[]>({
		method: "get",
		url: `/org_iframes`,
	});
	const { appStore } = useAppStore();
	const [openAllowedDomainsPopup, setOpenAllowedDomainsPopup] = useState(false);
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const [allowedDomainsInput, setAllowedDomainsInput] = useState("");
	const [allowedDomainsError, setAllowedDomainsError] = useState<string | null>(null);
	const [selectedIframe, setSelectedIframe] = useState<Iframe>();
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation");
	const tableHeaderArray = [
		t("IframesTab.config.table.headers.name"),
		t("IframesTab.config.table.headers.updated"),
		t("IframesTab.config.table.headers.domains"),
		t("IframesTab.config.table.headers.status"),
		t("IframesTab.config.table.headers.actions"),
	];

	const [tableData, setTableData] = useState<TableRow<IframeTableRow>[]>([]);

	useEffect(() => {
		if (orgIframes) {
			setTableData(orgIframes.map((iframe) => createRow(iframe)));
		}
	}, [orgIframes]);

	const editIframe = (iframeToEdit: Iframe) => {
		const editIframeState: EditIframeLocationState = {
			iframeToEdit,
		};
		navigate(RouterConstants.IFRAME_EDITOR.fullPath, { state: editIframeState });
	};

	const openIframe = (iframe: Iframe) => {
		window.open(getIframeUrl(iframe), "_blank");
	};

	const deleteIframe = async (iframeToDelete: Iframe) => {
		try {
			await (await BriaAxios()).delete(`/delete_iframe/${iframeToDelete.uid}`);
			setTableData((prevData) => prevData.filter((item) => item.key !== iframeToDelete.uid));
			closeDeletePopup();
		} catch (e) {
			console.error(e);
		}
	};

	const createRow = (iframe: Iframe): TableRow<IframeTableRow> => {
		const name = (
			<Box className={styles.nameContainer}>
				{!iframe.allowed_domains?.length && (
					<BriaButton
						key="add-domain"
						buttonType="primaryMedium"
						className={styles.addDomainBtn}
						startIcon={<PlusIcon fillColor="#fff" />}
						onClick={() => {
							setOpenAllowedDomainsPopup(true);
							setSelectedIframe(iframe);
						}}
					>
						{t("IframesTab.config.addDomain")}
					</BriaButton>
				)}

				<Box
					className={clsx(styles.nameContainer, {
						[styles.isBlurred]: !iframe.allowed_domains?.length,
					})}
				>
					<Box>{iframe.name}</Box>
					<Box className={styles.description}>{iframe.description}</Box>
				</Box>
			</Box>
		);
		const updated = (
			<Box
				className={clsx({
					[styles.isBlurred]: !iframe.allowed_domains?.length,
				})}
			>
				<div />
				{iframe.updated_date}
			</Box>
		);
		const status = (
			<Box className={styles.statusContainer}>
				<div className={`${styles[iframe.status?.toLowerCase()]} ${styles.circle}`} />
				{iframe.status}
			</Box>
		);
		const actions = (
			<Box className={styles.actions}>
				<BriaTooltip title={t("IframesTab.config.table.edit")}>
					<EditIcon2
						className={styles.actionBtn}
						onClick={() => editIframe(iframe)}
						sx={{ width: "18px !important" }}
					/>
				</BriaTooltip>
				<BriaTooltip title={t("IframesTab.config.table.view")}>
					<EyeIcon
						className={styles.actionBtn}
						onClick={() => openIframe(iframe)}
						sx={{ width: "21px !important" }}
					/>
				</BriaTooltip>
				<BriaTooltip title={t("IframesTab.config.table.copy")}>
					<CopyToClipboardButton className={styles.actionBtn} textToCopy={getIframeUrl(iframe)} />
				</BriaTooltip>
				<BriaTooltip title={t("IframesTab.config.table.delete")}>
					<DeleteIcon className={styles.actionBtn} onClick={() => handleOpenDeletePopup(iframe)} />
				</BriaTooltip>
			</Box>
		);
		const domains = [
			...(iframe.allowed_domains || []).map((domain, index) => (
				<Box className={styles.domain}>
					<Typography key={index} textTransform="initial">
						{domain}
					</Typography>
					<DeleteIcon className={styles.actionBtn} onClick={() => deleteAllowedDomain(iframe, index)} />
				</Box>
			)),
			<BriaButton
				key="add-domain"
				startIcon={<PlusIcon />}
				onClick={() => {
					setOpenAllowedDomainsPopup(true);
					setSelectedIframe(iframe);
				}}
			>
				{t("add")}
			</BriaButton>,
		];

		const newRow: TableRow<IframeTableRow> = {
			key: iframe.uid,
			data: {
				name,
				updated,
				domains,
				status,
				actions,
			},
		};

		return newRow;
	};

	const openIframeEditorPage = () => {
		navigate(RouterConstants.IFRAME_EDITOR.fullPath);
	};

	const updateAllowedDomains = async () => {
		try {
			if (selectedIframe) {
				const domains: string[] = allowedDomainsInput.split(",").map((domain) => domain.trim());
				const isValidDomains = domains.every(isValidDomain);
				if (isValidDomains) {
					const updatedIframe: Iframe = {
						...selectedIframe,
						allowed_domains: [...(selectedIframe?.allowed_domains || []), ...domains],
						status: IframeStatusEnum.ACTIVE,
					};
					await (await BriaAxios()).put(`/update_iframe/${selectedIframe?.uid}`, updatedIframe);
					appStore.logEvent({ event: EventsLogEnum.ACTIVATED_IFRAME });
					setTableData((prevData) =>
						prevData.map((item) => (item.key === selectedIframe.uid ? createRow(updatedIframe) : item)),
					);
					closeAllowedDomainsPopup();
				} else {
					setAllowedDomainsError(t("IframesTab.config.allowedDomainsPopup.invalidDomains"));
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const deleteAllowedDomain = async (iframe: Iframe, domainIndex: number) => {
		try {
			const filteredDomains: string[] = iframe?.allowed_domains.filter((_domain, index) => index !== domainIndex);
			const updatedIframe: Iframe = {
				...iframe,
				allowed_domains: filteredDomains,
				status: filteredDomains.length === 0 ? IframeStatusEnum.INACTIVE : IframeStatusEnum.ACTIVE,
			};
			await (await BriaAxios()).put(`/update_iframe/${iframe?.uid}`, updatedIframe);
			setTableData((prevData) =>
				prevData.map((item) => (item.key === iframe.uid ? createRow(updatedIframe) : item)),
			);
		} catch (e) {
			console.error(e);
		}
	};

	function isValidDomain(domain: string) {
		const domainPattern =
			/^(http(s?):\/\/localhost(:[0-9]{1,5})?|((http(s?)):\/\/)?[*a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?$/;
		return domainPattern.test(domain);
	}

	const getIframeUrl = (iframe: Iframe) => {
		let iframeUrl = `${getIframeHost()}/?iframeId=${iframe.uid}`;
		if (iframe.target === "playground") {
			iframeUrl += `&vhash=${PLAYGROUND_VHASH_EXAMPLE}`;
		}
		return iframeUrl;
	};

	const closeAllowedDomainsPopup = () => {
		setOpenAllowedDomainsPopup(false);
		setAllowedDomainsInput("");
		setSelectedIframe(undefined);
	};

	const handleOpenDeletePopup = (iframe: Iframe) => {
		setOpenDeletePopup(true);
		setSelectedIframe(iframe);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setSelectedIframe(undefined);
	};

	return (
		<>
			<TableLayout
				title={t("IframesTab.config.table.title")}
				description={t("IframesTab.config.table.description")}
				primaryButtonText={t("createNew")}
				primaryButtonClick={openIframeEditorPage}
				secondaryButtonText={t("documentation")}
				secondaryButtonClick={() => window.open(LinkConstants.IFRAME_DOCS, "_blank")}
				headerArray={tableHeaderArray}
				tableData={tableData}
				emptyStateComponent={<EmptyTable message={t("IframesTab.config.table.emptyState")} />}
				loading={loadingIframes}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("IframesTab.config.deletePopup.title")}
				description={t("IframesTab.config.deletePopup.description")}
				confirmButtonText={t("IframesTab.config.deletePopup.confirmText")}
				open={openDeletePopup}
				onClick={() => selectedIframe && deleteIframe(selectedIframe)}
			/>
			<ConfirmationPopup
				onClose={closeAllowedDomainsPopup}
				title={t("IframesTab.config.allowedDomainsPopup.title")}
				description={t("IframesTab.config.allowedDomainsPopup.description")}
				confirmButtonText={t("IframesTab.config.allowedDomainsPopup.confirmText")}
				open={openAllowedDomainsPopup}
				onClick={updateAllowedDomains}
				errorMessage={allowedDomainsError}
				disableConfirm={!allowedDomainsInput || allowedDomainsError !== null}
			>
				<BriaInput
					alignment={"flex-start"}
					multiline
					className={styles.textFieldStyle}
					height={"108px"}
					type="text"
					placeholder={t("IframesTab.config.allowedDomainsPopup.textFieldPlaceholder")}
					value={allowedDomainsInput}
					onChange={(e) => {
						setAllowedDomainsInput(e.target.value);
						setAllowedDomainsError(null);
					}}
				/>
			</ConfirmationPopup>
		</>
	);
};

export default observer(IframeConfig);
