import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import generateImages from "../../assets/images/svgs/Generate Images.svg";
import refineImages from "../../assets/images/svgs/Refine images.svg";

import HubCardsComponent from "../../components/PlatformHub/HubCardsComponent/HubCardsComponent";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { HomeCard } from "../../constants/HubGalleryImagesConstants";
import RouterConstants from "../../constants/RouterConstants";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import styles from "./FoxHub.module.scss";

const FoxHub = () => {
	const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0);
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "FoxHub" });

	const cardsList: HomeCard[] = [
		{
			title: "Cards.generateImages.title",
			description: "Cards.generateImages.description",
			image: generateImages,
			onClick: () => navigate(RouterConstants.IMAGE_TO_IMAGE.path),
		},
		{
			title: "Cards.refineImages.title",
			description: "Cards.refineImages.description",
			image: refineImages,
			onClick: () => navigate(RouterConstants.AI_EDITOR.path),
		},
	];

	return (
		<>
			<Box className={styles.container}>
				<Typography className={styles.foxHubTitle}> {t("title")}</Typography>
				<LoadingPlaceholder
					className={styles.loaderStyle}
					isLoading={numberOfLoadedImages < cardsList.length}
					alwaysRenderChildren={true}
				>
					<Box className={styles.cardsContainer}>
						<Grid container className={styles.gridContainer}>
							{cardsList.map((card: HomeCard) => (
								<HubCardsComponent
									title={t(card.title)}
									description={t(card.description)}
									image={card.image}
									onClick={card.onClick}
									onImageLoad={() => {
										setNumberOfLoadedImages((prevCount) => prevCount + 1);
									}}
								/>
							))}
						</Grid>
					</Box>
				</LoadingPlaceholder>
			</Box>
		</>
	);
};

export default FoxHub;
