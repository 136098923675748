import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SolidGradientColorPicker from "../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { CampaignEntityStatus } from "../../../../../../../models/common.ts";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { BrandDefinition } from "../../../../../../models/brandDefinition";
import { LayerType } from "../../../../../../types";
import styles from "./StrokeColor.module.scss";

const StrokeColor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs" });
	const [brands, setBrands] = useState<BrandDefinition[]>([]);
	const { brandsDefinitionStore, campaignStore } = useAppStore();
	const activeObject = useActiveObject() as any;
	const [color, setColor] = useState(activeObject?.stroke ?? "#000");
	const { EditAllAds, getRgbAndOpacityFromRgba, updateColorOpacity } = useDesignEditorUtils();
	const editor = useEditor();
	const isFabricShape =
		activeObject?.type === LayerType.STATIC_RECT ||
		activeObject?.type === LayerType.STATIC_CIRCLE ||
		activeObject?.type === LayerType.STATIC_TRIANGLE;

	useEffect(() => {
		if (brandsDefinitionStore.brands.items.length == 0) {
			loadBrands();
		} else {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === CampaignEntityStatus.ACTIVE));
		}
	}, []);

	useEffect(() => {
		if (activeObject?.stroke) setColor(updateColorOpacity(activeObject.stroke, activeObject.opacity));
	}, [activeObject]);

	const loadBrands = () => {
		brandsDefinitionStore.loadBrandsDefinition().then(() => {
			setBrands(brandsDefinitionStore.brands.items.filter((item) => item.status === CampaignEntityStatus.ACTIVE));
		});
	};

	const changeBackgroundColor = (color: string, brandColor: boolean = false) => {
		if (isFabricShape) {
			const result = getRgbAndOpacityFromRgba(color);
			editor?.objects.update(
				{
					stroke: color,
					opacity: brandColor ? undefined : result?.opacity ?? 1,
					brandId: undefined,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(activeObject?.type, activeObject?.shapeType, FeatureType.STROKE, result?.rgb ?? color);
		}
	};

	return (
		<>
			<Box className={styles.container}>
				<MainTabHeader title={t("strokeColor")} />
				<Box className={`${styles.tabs} ${styles.fullHeight}`}>
					<Box className={styles.ColorFillContent}>
						<Box className={styles.colorPickerContainer}>
							<SolidGradientColorPicker
								color={color}
								onChange={(color: string) => {
									setColor(color);
									changeBackgroundColor(color);
								}}
								hideColorTypeBtns={true}
							/>
						</Box>
						<Divider className={styles.mainDivider} />
						<Typography className={styles.mainLabel}>{t("brandColors")}</Typography>
						<Box className={styles.BrandsContainer}>
							{brands.map((brand: BrandDefinition, idx: number) => {
								const colors = brand?.color_pallete?.colors;
								return (
									<>
										<Box className={styles.brandColorsRow}>
											<Typography className={styles.brandNameLabel}>
												{brand.name.length < 10 ? brand.name : brand.name.slice(0, 10) + "..."}
											</Typography>

											{colors?.map((color: string, index: number) => (
												<Box
													onClick={() => {
														setColor(color);
														changeBackgroundColor(color, true);
													}}
													key={index}
												>
													<Box className={styles.itemContent}>
														<Box
															sx={{ background: color }}
															className={styles.itemPreview}
														></Box>
													</Box>
												</Box>
											))}
										</Box>
										{idx != brands.length - 1 && <Divider className={styles.divider} />}
									</>
								);
							})}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

const ObservedComponent = observer(StrokeColor);
export default ObservedComponent;
