import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "../../../DesignEditor/models/image";
import {
	IMenuItem,
	imageCarouselType,
} from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems/Images/Images";
import Uploaded from "../../../assets/images/svgs/Uppload.svg";
import { APPS } from "../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import { PlaygroundImage } from "../../../models/image-to-image.ts";
import useImageUtils from "../../../utils/useImageUtils";
import BriaButton from "../BriaButton/BriaButton";
import EditorImageImageOverlay from "../EditorImageOverLay/EditorImageOverlay";
import BriaImage, { BriaImageProps } from "../Galleries/BriaImage";
import styles from "./ImageCarousel.module.scss";

interface IProps {
	title?: string;
	images?: Image[];
	hideUploadIcon?: boolean;
	onClick?: () => void;
	type?: imageCarouselType;
	menuItems?: IMenuItem[];
	addImageObject?: (image: Image) => void;
}

const ImageCarousel = ({ title, images = [], hideUploadIcon, onClick, type, menuItems, addImageObject }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.images.imageCarousel" });
	const [currentIndex, setCurrentIndex] = useState(0);
	const [loading, setLoading] = useState(true);
	const { imagesStore } = useAppStore();
	const { convertS3UriToHttpsCloudFrontUrl, isS3Uri } = useImageUtils();
	const [validImages, setValidImages] = useState<Image[]>([]);

	useEffect(() => {
		const checkImageUrls = async () => {
			if (type === imageCarouselType.Logos) {
				setValidImages(images);
				setLoading(false);
				return;
			}
			const validImagePromises = images.map(async (image) => {
				try {
					const response = await fetch(image.url, { method: "HEAD" });
					if (response.ok) {
						return image;
					}
				} catch (error) {
					console.error(`Image at URL ${image.url} is not accessible:`, error);
				}
				return null;
			});

			const results = await Promise.all(validImagePromises);
			setValidImages(results.filter(Boolean) as Image[]);
			setLoading(false);
		};

		if (images.length > 0) {
			checkImageUrls();
		}
	}, [images]);

	const handlePrevious = () => {
		setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, images?.length - 1));
	};

	return (
		<Box className={styles.Container}>
			<Box className={styles.carousalHeader}>
				<Typography className={styles.title}>{title}</Typography>
				<Box className={styles.buttonsContainer}>
					{!hideUploadIcon && (
						<Box>
							<img
								className={styles.uploadIcon}
								onClick={() => {
									imagesStore.uploadProcessDone = false;
									imagesStore.openUploadPopup = true;
									if (type == imageCarouselType.userImages) {
										imagesStore.uploadToUserGallery = true;
									} else if (type == imageCarouselType.sharedImages) {
										imagesStore.uploadToUserGallery = false;
									} else if (type == imageCarouselType.Logos) {
										imagesStore.isLogoUploading = true;
									}
								}}
								src={Uploaded}
								alt="upload"
							/>
						</Box>
					)}
					<BriaButton
						disabled={images.length == 0}
						className={styles.link}
						buttonType="textMedium"
						onClick={onClick}
					>
						{t("seeAll")}
					</BriaButton>
				</Box>
			</Box>
			<Box className={styles.carousel}>
				{currentIndex !== 0 && (
					<IconButton className={styles.leftArrow} onClick={handlePrevious} disabled={currentIndex === 0}>
						<ArrowBackIosIcon />
					</IconButton>
				)}

				<Box className={styles.imagesContainer}>
					{loading
						? Array.from({ length: 4 }).map((_, index) => (
								<Box key={index} className={styles.imageWrapper}>
									<Skeleton
										variant="rectangular"
										width="64px"
										height="64px"
										className={styles.imageSkeleton}
									/>
								</Box>
						  ))
						: validImages.length >= 1 &&
						  validImages.slice(currentIndex, currentIndex + 4).map((image, index) => (
								<Box
									key={index}
									className={styles.imageWrapper}
									id={image.visual_hash ?? image.visual_id}
								>
									{image.visual_hash === imagesStore.deletedImageVhash &&
										imagesStore.isDeletingImage && (
											<>
												<Box className={styles.blurLayer} />
												<CircularProgress size={24} className={styles.loader} />
											</>
										)}
									<BriaImage
										image={
											{
												id: "",
												url: isS3Uri(image.url)
													? convertS3UriToHttpsCloudFrontUrl(image.url)
													: image.url,
												type: APPS.CAMPAIGN,
											} as BriaImageProps & PlaygroundImage
										}
										url={
											isS3Uri(image.url) ? convertS3UriToHttpsCloudFrontUrl(image.url) : image.url
										}
										hideFullScreenButton={true}
										className={styles.image}
										ImageOverlay={
											<EditorImageImageOverlay
												menuItems={menuItems ?? []}
												image={image}
												type={type}
											/>
										}
										displayOverlay="on"
										handleClick={() => addImageObject && addImageObject(image)}
										visualHash={image.visual_hash ?? image.visual_id}
									/>
								</Box>
						  ))}
				</Box>
				<IconButton
					className={styles.rightArrow}
					onClick={handleNext}
					disabled={currentIndex + 3 >= images.length}
				>
					<ArrowForwardIosIcon />
				</IconButton>
			</Box>
		</Box>
	);
};

export default observer(ImageCarousel);
