import { useTranslation } from "react-i18next";
import foxLogo from "../../../assets/images/pngs/FS HIGH RES.png";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import HeaderLayout from "../HeaderLayout.tsx";

import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import SupportButton from "../../../components/common/SupportButton/SupportButton.tsx";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import styles from "./FoxHeader.module.scss";
import { supportedImageFileTypes } from "../../../utils/useImageUtils.tsx";

const defaultAcceptTypes = supportedImageFileTypes.map((type) => `image/${type}`).join(", ");

const FoxHeader = () => {
	const navigate = useSecureNavigate();
	const { uiStore, authStore, playgroundStore, imageToImageStore, analyticsStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "headers.fox" });
	const selectedImages = playgroundStore.getSelectedImages();
	const availableImages = playgroundStore.getAvailableImages();
	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleFoxLogoClick = () => {
		navigate(RouterConstants.FOX_APPS.path);
	};

	const handleUploadButtonClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			imageToImageStore.handleUploadImages(e);
		}
	};

	const downloadImages = async () => {
		selectedImages.length
			? playgroundStore.downloadImages(selectedImages)
			: playgroundStore.downloadImages(availableImages);

		(selectedImages.length ? selectedImages : availableImages).forEach((image) => {
			if (image.type === "imageToImage") {
				analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_ITI, image);
			}
		});
	};

	return (
		<HeaderLayout className={styles.header} logo={foxLogo} onLogoClick={handleFoxLogoClick}>
			<>
				<BriaButton buttonType="textSmall" onClick={downloadImages} size="small">
					{t("export")}
				</BriaButton>
				<BriaButton buttonType="textSmall" onClick={handleUploadButtonClick} size="small">
					{t("upload")}
				</BriaButton>
				<input
					ref={fileInputRef}
					type="file"
					accept={defaultAcceptTypes}
					style={{ display: "none" }}
					onChange={handleFileUpload}
					multiple
				/>
				<BriaButton
					buttonType="textMedium"
					onClick={() => uiStore.showDialog("FoxGuidelinesPopup")}
					size="small"
				>
					{t("userGuide")}
				</BriaButton>
				{authStore.user && <SupportButton page={"Fox"} />}
			</>
			<OrganizationsDropdown labelFontSize="14px" />
		</HeaderLayout>
	);
};

export default observer(FoxHeader);
