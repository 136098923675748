import styles from "./BriaBadge.module.scss";

interface BriaBadgeProps {
	children: React.ReactNode;
	label: string;
	color?: string;
}

const BriaBadge: React.FC<BriaBadgeProps> = ({ children, label, color }) => {
	return (
		<>
			{children}
			<span className={styles.label} style={{ backgroundColor: color }}>
				{label}
			</span>
		</>
	);
};

export default BriaBadge;
