const LeftTop = () => (
	<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M32.7158 29.444V4.556C32.7158 2.592 31.1238 1 29.1598 1H4.27182C2.30782 1 0.71582 2.592 0.71582 4.556V29.444C0.71582 31.408 2.30782 33 4.27182 33H29.1598C31.1238 33 32.7158 31.408 32.7158 29.444Z"
			stroke="#5B5B5B"
			strokeOpacity="0.3"
			strokeWidth="1.42857"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<mask id="path-2-inside-1_1579_4517" fill="white">
			<rect x="5.28711" y="5" width="12" height="6" rx="1" />
		</mask>
		<rect
			x="5.28711"
			y="5"
			width="12"
			height="6"
			rx="1"
			fill="#5B5B5B"
			fillOpacity="0.5"
			stroke="#5B5B5B"
			strokeOpacity="0.5"
			strokeWidth="2.85714"
			strokeLinecap="round"
			strokeLinejoin="round"
			mask="url(#path-2-inside-1_1579_4517)"
		/>
	</svg>
);

export default LeftTop;
