import { Environment, getCurrentEnv, isLocal } from "../config/env.ts";
import { firebaseAuth } from "../config/firebase.ts";
import { APPS } from "../constants/AppsConstants.ts";
import { AESCipher } from "../helpers/encryption.ts";
import { Iframe, IframePages, iframeRoutes } from "../models/new-iframe.ts";
import { SsoConfigTypes } from "../models/organization.ts";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";
import { navigateWithParams } from "../utils";
import { useAuthService } from "./useAuthService.tsx";
import useSecureNavigate from "./useSecureNavigate.tsx";

export const useIframe = () => {
	const navigate = useSecureNavigate();
	const { loginWithEmail, loginWithIframeSso } = useAuthService();
	const queryParams = new URLSearchParams(location.search);

	const getIframeHost = () => {
		switch (getCurrentEnv()) {
			case Environment.local:
				return "http://localhost:3001";
			case Environment.integration:
				return "https://int.iframe.platform.bria.ai";
			default:
				return "https://prod.iframe.platform.bria.ai";
		}
	};

	const getIframePreviewUrl = () => {
		return `${getIframeHost()}/?iframeId=preview`;
	};

	const getIframeId = () => {
		return iframeStore.iframe?.id || queryParams.get("iframeId");
	};

	const getPassedImageUrl = () => {
		return iframeStore.passedImageUrl || queryParams.get("imageUrl");
	};

	const createIframeUrl = (iframe: Iframe) => {
		return `${getIframeHost()}/?iframeId=${iframe.id}`;
	};

	const navigateToLandingPage = () => {
		if (iframeStore.iframe) {
			if (!Object.keys(iframeRoutes).includes(iframeStore.iframe.config.landing_page)) {
				iframeStore.iframe.config.landing_page = APPS.TEXT_TO_IMAGE;
			}
			const landing_route = iframeRoutes[iframeStore.iframe.config.landing_page] ?? "";
			isLocal() ? navigateWithParams(navigate, landing_route) : navigate(landing_route);
		} else {
			throw new Error("Iframe does not exists");
		}
	};

	const authenticateIframe = async (iframe: Iframe) => {
		if (!iframeStore.isPreview()) {
			const iframeSsoConfig = iframeStore.iframe.organization.sso_config?.find(
				(conf) => conf.type === SsoConfigTypes.Iframe,
			);
			if (iframeSsoConfig) {
				if (
					firebaseAuth.currentUser?.isAnonymous ||
					firebaseAuth.currentUser?.providerData?.[0]?.providerId !== iframeSsoConfig.providerId
				) {
					return await loginWithIframeSso(iframeSsoConfig.providerId);
				}
			} else {
				const aesCipher = new AESCipher(iframe.org_id);
				const [decryptedEmail, decryptedPassword] = await Promise.all([
					aesCipher.decrypt(iframe.username),
					aesCipher.decrypt(iframe.password),
				]);

				return await loginWithEmail(decryptedEmail, decryptedPassword);
			}
		}
	};

	const navigateIframe = (to: IframePages) => {
		iframeStore.iframe.config.landing_page = to;
		navigateToLandingPage();
	};

	return {
		getIframeHost,
		getIframePreviewUrl,
		getIframeId,
		getPassedImageUrl,
		createIframeUrl,
		navigateToLandingPage,
		authenticateIframe,
		navigateIframe,
	};
};
