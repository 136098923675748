import { fabric } from "fabric";
export class StaticCircleObject extends fabric.Circle {
	static type = "StaticCircle";
	// @ts-ignore
	public colorNumber: number;
	public brandId?: number = undefined;
	public shapeType = "";
	// @ts-ignore
	public strokeColorNumber;
	initialize(options: StaticCircleOptions) {
		const { ...circleOptions } = options;
		//@ts-ignore
		super.initialize({ ...circleOptions });

		return this;
	}

	toObject(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}

	toJSON(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}

	static fromObject(options: any, callback?: (obj: StaticCircleObject) => void) {
		const instance = new fabric.StaticCircle(options);
		if (callback) {
			callback(new fabric.StaticCircle(options));
		}
		return instance;
	}
}

fabric.StaticCircle = fabric.util.createClass(StaticCircleObject, {
	type: StaticCircleObject.type,
});
fabric.StaticCircle.fromObject = StaticCircleObject.fromObject;

export type StaticCircleOptions = fabric.ITriangleOptions;

declare module "fabric" {
	namespace fabric {
		class StaticCircle extends StaticCircleObject {
			constructor(options: StaticCircleOptions);
		}
	}
}
