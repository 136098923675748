import { Box } from "@mui/material";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { EditIcon2 } from "../../../assets/images/icons/EditIcon2";
import ActionsDotsDropdown from "../../../components/common/ActionsDotsDropdown/ActionsDotsDropdown";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores";
import { useUserChecks } from "../../../hooks/useUserChecks";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout";
import { Brand } from "../../../models/brand";
import { StatusEnum } from "../../../models/common";
import styles from "./Brands.module.scss";
import { EditBrandLocationState } from "./Editor/BrandEditor";

type BrandTableRow = {
	name: string | ReactElement;
	logoPreview: string | ReactElement;
	colorPreview: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
};

const Brands = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.brands" });
	const { brandsStore } = useAppStore();
	const navigate = useSecureNavigate();
	const { canUserEditOrganization } = useUserChecks();
	const [brandToDelete, setBrandToDelete] = useState<Brand>();
	const [tableData, setTableData] = useState<TableRow<BrandTableRow>[]>();
	const [loadingRow, setLoadingRow] = useState<{ data: Brand; column: string }>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const headerArray = [
		t("table.headers.name"),
		t("table.headers.logoPreview"),
		t("table.headers.colorPreview"),
		t("table.headers.status"),
		t("table.headers.actions"),
	];
	const [filterBy, setFilterBy] = useState<FilterByOptions>();
	const paginationOptions: PaginationOptions = {
		rowsPerPage: brandsStore.rowsPerPage,
		totalRows: brandsStore.paginatedBrands.total,
		loadNextPage: brandsStore.loadBrands,
	};

	useEffect(() => {
		brandsStore.loadBrands(1).then(() => {
			setTableData(brandsStore.paginatedBrands.items.map(createRow));
		});
	}, []);

	useEffect(() => {
		if (!brandsStore.isLoading) {
			const newTableData: TableRow<BrandTableRow>[] = brandsStore.paginatedBrands.items.map(createRow);
			setTableData([...newTableData]);
		}
	}, [brandsStore.paginatedBrands.items]);

	useEffect(() => {
		if (tableData) {
			brandsStore.isLoading = false;
		}
	}, [tableData]);

	const editBrand = async (brandToEdit: Brand) => {
		const editBrandState: EditBrandLocationState = {
			brandToEdit: toJS(brandToEdit),
		};
		navigate(`${RouterConstants.BRAND_EDITOR.editPath}/${brandToEdit.id}`, {
			state: editBrandState,
		});
	};

	const openBrandEditorPage = async () => {
		navigate(RouterConstants.NEW_BRAND_EDITOR.fullPath);
	};

	const deleteBrand = async () => {
		if (brandToDelete) {
			const updatedBrand: Brand = {
				...brandToDelete,
				status: StatusEnum.DELETED,
				logo_id: brandToDelete?.logo?.id,
				color_pallete_id: brandToDelete?.color_pallete?.id,
			};
			setLoadingRow({ data: brandToDelete, column: "actions" });
			closeDeletePopup();

			await brandsStore.updateBrand(updatedBrand);
			setLoadingRow(undefined);
		}
	};

	const updateBrandStatus = async (brand: Brand, status: StatusEnum) => {
		const updatedBrand: Brand = {
			...brand,
			status,
			logo_id: brand?.logo?.id,
			color_pallete_id: brand?.color_pallete?.id,
		};
		setLoadingRow({ data: brand, column: "status" });

		await brandsStore.updateBrand(updatedBrand);
		setLoadingRow(undefined);
	};

	const convertS3UriToHttpsUrl = (s3Uri: string) => {
		const parts = s3Uri?.split("/");
		const bucketName = parts && parts[2];
		const partsArray = bucketName.split("-"); // Split the string by '-'
		const bucketNameUpdated = partsArray.slice(1).join("-");
		const filePath = parts && parts.slice(3).join("/");
		return `https://${bucketNameUpdated}.bria.ai/${filePath}`;
	};
	const createRow = (brand: Brand): TableRow<BrandTableRow> => {
		const src = brand?.logo?.src ? convertS3UriToHttpsUrl(brand?.logo?.src) : "";
		const color = brand.status === StatusEnum.DELETED ? "#1A063840" : "";
		const statusContainer = (
			<Box className={styles.statusContainer} sx={{ color }}>
				<div className={`${styles[brand.status?.toLowerCase()]} ${styles.circle}`} />
				{brand.status}
			</Box>
		);
		return {
			rowStyle: {
				backgroundColor: brand.status === StatusEnum.DELETED ? "#D9D9D91A" : "",
			},
			key: brand.id,
			data: {
				name: (
					<Box className={styles.nameStyle} style={{ color: color }}>
						{brand.name}
					</Box>
				),
				logoPreview: (
					<Box className={styles.imageContainer}>
						{src && <img className={styles.logoPreview} src={src}></img>}
					</Box>
				),

				colorPreview: (
					<Box className={styles.nameStyle} style={{ color: color, flexDirection: "row" }}>
						{brand?.color_pallete?.colors?.map((color: string, index: number) => (
							<Box
								className={styles.colorPreview}
								key={index}
								style={{
									backgroundColor: color,
									border: "3px solid #E7E7E7",
								}}
							></Box>
						))}
					</Box>
				),

				status: (
					<Box className={styles.statusContainer}>
						{brand.status !== StatusEnum.DELETED && canUserEditOrganization() ? (
							<ActionsDotsDropdown
								label={statusContainer}
								items={Object.values(StatusEnum)
									.filter((status) => status !== StatusEnum.DELETED)
									.map((status) => ({
										text: status,
										onClick: () => updateBrandStatus(brand, status),
									}))}
							/>
						) : (
							statusContainer
						)}
					</Box>
				),
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							{
								icon: (
									<BriaTooltip title={t("edit")}>
										<EditIcon2 className={styles.actionBtn} sx={{ width: "18px !important" }} />
									</BriaTooltip>
								),
								onClick: () => canUserEditOrganization() && editBrand(brand),
							},
							{
								icon: (
									<BriaTooltip title={t("delete")}>
										<DeleteIcon className={styles.actionBtn} />
									</BriaTooltip>
								),
								onClick: () => canUserEditOrganization() && handleOpenDeletePopup(brand),
							},
						].map((action, index) => (
							<Box
								key={index}
								onClick={action.onClick}
								className={clsx({
									[styles.disabledOpacity]:
										!canUserEditOrganization() || brand.status === StatusEnum.DELETED,
								})}
							>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			...filterBy,
			search: { text: e.target.value, fields: ["name", "status"] },
		};
		setFilterBy(updatedFilterBy);
		await brandsStore.loadBrands(1, updatedFilterBy);
	};

	const handleOpenDeletePopup = (brand: Brand) => {
		setOpenDeletePopup(true);
		setBrandToDelete(brand);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setBrandToDelete(undefined);
	};

	return (
		<>
			<TableLayout
				title={t("table.title")}
				description={t("table.description")}
				primaryButtonText={ct("createNew")}
				primaryButtonClick={openBrandEditorPage}
				headerArray={headerArray}
				tableData={tableData}
				enableSearch={true}
				filterBy={filterBy}
				paginationOptions={paginationOptions}
				emptyStateComponent={<EmptyTable message={t("table.emptyState")} />}
				loading={brandsStore.isLoading}
				loadingRow={loadingRow}
				hideSecondaryButton={true}
				handleSearchChange={handleSearchChange}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={ct("delete")}
				open={openDeletePopup}
				onClick={deleteBrand}
			/>
		</>
	);
};

export default observer(Brands);
