function AngleDoubleLeft({ size = 16 }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
			<path
				d="M11.2674 4.04102L4.12451 11.1839M4.12451 4.04102L11.2674 11.1839"
				stroke="#5B5B5B"
				strokeWidth="0.892857"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AngleDoubleLeft;
