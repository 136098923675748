import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function CloseIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="6" height="6" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9.5 3.64062L3.5 9.64062M3.5 3.64062L9.5 9.64062"
				stroke="#1A0638"
				strokeOpacity="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default CloseIcon;
