import { useEffect } from "react";
import { IBrushSettings } from "../../models/sandboxAPI.ts";
import { useBrushCore } from "./useBrushCore.tsx";

export const useBrushOnCanvas = (brushConfig: IBrushSettings, canvasRef: any, brushingAreaCanvasRef: any) => {
	const clearBrushOverlayCanvasRef = () => {
		if (brushingAreaCanvasRef.current && brushingAreaCanvasRef.current.getContext) {
			const context = brushingAreaCanvasRef.current.getContext("2d");
			if (context) {
				context.clearRect(0, 0, brushingAreaCanvasRef.current.width, brushingAreaCanvasRef.current.height);
			}
		}
	};

	useEffect(() => {
		clearBrushOverlayCanvasRef();
	}, [brushConfig]);

	useBrushCore(brushConfig, canvasRef, brushingAreaCanvasRef);

	return {
		clearBrushOverlayCanvasRef,
	};
};
