import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaUpgradeOverlay from "../../../../../components/common/BriaUpgradeOverlay/BriaUpgradeOverlay.tsx";
import ColorPicker from "../../../../../components/common/ColorPicker/ColorPicker";
import TextColorPicker from "../../../../../components/common/TextColorPicker/TextColorPicker";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { OrgFeatures } from "../../../../../models/billing.ts";
import iframeStore from "../../../iframe-store.tsx";
import styles from "./IframeCustomStyleConfig.module.scss";

const IframeCustomStyleConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.customStyle" });

	const handleCssVarChange = async (cssVar: string, value: string) => {
		handleFormChange("config", {
			...iframeForm.config,
			custom_style: {
				...iframeForm.config.custom_style,
				cssVars: {
					...iframeForm.config.custom_style?.cssVars,
					[cssVar]: value,
				},
			},
		});
	};

	return (
		<InputLayout label={t("primaryColor.label")}>
			<BriaUpgradeOverlay feature={OrgFeatures.IFRAME_WHITE_LABEL}>
				<Box className={styles.colorWrapper}>
					<ColorPicker
						circleWidth="33px"
						color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
						onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
					/>
					<TextColorPicker
						color={iframeForm?.config.custom_style?.cssVars?.["primary_main_color"]}
						onColorChange={(color: string) => handleCssVarChange("primary_main_color", color)}
					/>
				</Box>
			</BriaUpgradeOverlay>
		</InputLayout>
	);
};

export default observer(IframeCustomStyleConfig);
