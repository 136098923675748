import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaInput from "../../components/common/BriaInput/BriaInput";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import styles from "./EditorLayout.module.scss";
type Props = {
	loading?: boolean;
	name: string;
	onNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	errorName?: boolean;
	primaryButton: string | ReactNode;
	primaryButtonClick?: () => void;
	loadingPrimaryButton?: boolean;
	disablePrimary?: boolean;
	secondaryButton?: string | ReactNode;
	secondaryButtonClick?: () => void;
	loadingSecondaryButton?: boolean;
	disableSecondary?: boolean;
	children?: ReactNode;
};

const EditorLayout: React.FC<Props> = ({
	loading = false,
	name,
	onNameChange = () => {},
	errorName,
	primaryButton,
	primaryButtonClick,
	loadingPrimaryButton = false,
	disablePrimary = false,
	secondaryButton,
	secondaryButtonClick,
	loadingSecondaryButton = false,
	disableSecondary = false,
	children,
}: Props) => {
	return (
		<LoadingPlaceholder isLoading={loading} className={styles.loadingPlaceholder}>
			<Box className={styles.container}>
				<Box className={styles.headerWrapper}>
					<BriaInput
						value={name}
						onChange={onNameChange}
						InputProps={{ className: styles.titleStyle, disableUnderline: true }}
						variant="standard"
						fullWidth
						error={errorName}
						helperText={errorName && "Invalid or existing name"}
						FormHelperTextProps={{
							style: {
								height: "4px",
								paddingLeft: "4px",
							},
						}}
					/>
					<BriaButtonGroup>
						{typeof secondaryButton === "string" ? (
							<BriaButton
								onClick={secondaryButtonClick}
								buttonType="textMedium"
								disabled={disableSecondary}
								loading={loadingSecondaryButton}
							>
								{secondaryButton}
							</BriaButton>
						) : (
							secondaryButton
						)}
						{typeof primaryButton === "string" ? (
							<BriaButton
								onClick={primaryButtonClick}
								buttonType="primaryMedium"
								disabled={disablePrimary}
								loading={loadingPrimaryButton}
							>
								{primaryButton}
							</BriaButton>
						) : (
							primaryButton
						)}
					</BriaButtonGroup>
				</Box>
				{children}
			</Box>
		</LoadingPlaceholder>
	);
};

export default EditorLayout;
