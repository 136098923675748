import {
	ColorTypeMappingEnum,
	FontTypeEnum,
	HorizontalEnum,
	LogoTypeMappingEnum,
	VerticalEnum,
} from "../../models/common";

const colorKey = Object.keys(ColorTypeMappingEnum) as Array<keyof typeof ColorTypeMappingEnum>;
const logoKeys = Object.keys(LogoTypeMappingEnum) as Array<keyof typeof LogoTypeMappingEnum>;

export const createDropdownItems = (t: Function) => [
	{ label: t("configureBrandColor"), value: undefined, key: t("configureBrandColor") },
	...colorKey.map((key, index) => ({
		label: ColorTypeMappingEnum[key],
		value: index + 1,
		key: ColorTypeMappingEnum[key],
	})),
];

export const createFontDropdownItems = (t: Function) => [
	{ label: t("configureBrandFont"), value: undefined, key: t("configureBrandFont") },
	{ label: `${t("font")} #1`, value: FontTypeEnum.FONT_1, key: `${t("font")} #1` },
	{ label: `${t("font")} #2`, value: FontTypeEnum.FONT_2, key: `${t("font")} #2` },
	{ label: `${t("font")} #3`, value: FontTypeEnum.FONT_3, key: `${t("font")} #3` },
	{ label: `${t("font")} #4`, value: FontTypeEnum.FONT_4, key: `${t("font")} #4` },
	{ label: `${t("font")} #5`, value: FontTypeEnum.FONT_5, key: `${t("font")} #5` },
];

export const createLogoDropdownItems = (t: Function) => [
	{ label: t("configureBrandLogo"), value: undefined, key: t("configureBrandLogo") },
	...logoKeys.map((key, _index) => ({
		label: key,
		value: key,
		key: LogoTypeMappingEnum[key],
	})),
];

export const createHorizontalAlignmentItems = (t: Function) => [
	{ label: t("configureHorizontalAlignment"), value: undefined, key: t("configureHorizontalAlignment") },
	{ label: t("alignLeft"), value: HorizontalEnum.LEFT, key: t("alignLeft") },
	{ label: t("alignCenter"), value: HorizontalEnum.CENTER, key: t("alignCenter") },
	{ label: t("alignRight"), value: HorizontalEnum.RIGHT, key: t("alignRight") },
];

export const createVerticalAlignmentItems = (t: Function) => [
	{ label: t("configureVerticalAlignment"), value: undefined, key: t("configureVerticalAlignment") },
	{ label: t("alignTop"), value: VerticalEnum.TOP, key: t("alignTop") },
	{ label: t("alignMiddle"), value: VerticalEnum.CENTER, key: t("alignMiddle") },
	{ label: t("alignBottom"), value: VerticalEnum.BOTTOM, key: t("alignBottom") },
];
