import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DotsVertical(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<path
				d="M2.99984 7.58301C3.322 7.58301 3.58317 7.32184 3.58317 6.99967C3.58317 6.67751 3.322 6.41634 2.99984 6.41634C2.67767 6.41634 2.4165 6.67751 2.4165 6.99967C2.4165 7.32184 2.67767 7.58301 2.99984 7.58301Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.99984 11.6663C3.322 11.6663 3.58317 11.4052 3.58317 11.083C3.58317 10.7608 3.322 10.4997 2.99984 10.4997C2.67767 10.4997 2.4165 10.7608 2.4165 11.083C2.4165 11.4052 2.67767 11.6663 2.99984 11.6663Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.99984 3.49967C3.322 3.49967 3.58317 3.23851 3.58317 2.91634C3.58317 2.59418 3.322 2.33301 2.99984 2.33301C2.67767 2.33301 2.4165 2.59418 2.4165 2.91634C2.4165 3.23851 2.67767 3.49967 2.99984 3.49967Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.99984 7.58301C7.322 7.58301 7.58317 7.32184 7.58317 6.99967C7.58317 6.67751 7.322 6.41634 6.99984 6.41634C6.67767 6.41634 6.4165 6.67751 6.4165 6.99967C6.4165 7.32184 6.67767 7.58301 6.99984 7.58301Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.99984 11.6663C7.322 11.6663 7.58317 11.4052 7.58317 11.083C7.58317 10.7608 7.322 10.4997 6.99984 10.4997C6.67767 10.4997 6.4165 10.7608 6.4165 11.083C6.4165 11.4052 6.67767 11.6663 6.99984 11.6663Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.99984 3.49967C7.322 3.49967 7.58317 3.23851 7.58317 2.91634C7.58317 2.59418 7.322 2.33301 6.99984 2.33301C6.67767 2.33301 6.4165 2.59418 6.4165 2.91634C6.4165 3.23851 6.67767 3.49967 6.99984 3.49967Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="1.4"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default DotsVertical;
