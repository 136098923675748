import { get, ref, update } from "firebase/database";
import { firebaseDatabase } from "../config/firebase.ts";
import { USER_ORGANIZATION_ROLES, USER_ROLES, USER_SETTINGS } from "../constants/UserConstants.ts";
import { getSelectedOrganization } from "../helpers/localStorage.ts";

class User {
	userName: string;
	uid: string;
	profilePicture: string;
	role: string;
	company: string;
	userRole: string;
	getInfo: boolean;
	email: string;
	settings?: any;
	user_organizations?: any;

	constructor(
		userName: string,
		uid: string,
		profilePicture: string,
		role: string,
		company: string,
		userRole: string,
		getInfo: boolean,
		email: string,
		settings?: any,
		user_organizations?: any,
	) {
		this.uid = uid;
		this.userName = userName;
		this.profilePicture = profilePicture;
		this.role = role;
		this.company = company;
		this.userRole = userRole;
		this.getInfo = getInfo;
		this.email = email;
		this.settings = settings;
		this.user_organizations = user_organizations;
	}

	isSuperAdmin() {
		return this.role === USER_ROLES.ADMIN;
	}

	isAdminOrOwnerInTheSelectedOrganization() {
		const userRole = getSelectedOrganization()?.role;
		return (
			userRole &&
			[
				USER_ORGANIZATION_ROLES.OWNER,
				USER_ORGANIZATION_ROLES.ADMIN,
				USER_ORGANIZATION_ROLES.ADMIN_HIDDEN,
			].includes(userRole as USER_ORGANIZATION_ROLES)
		);
	}

	isSuperAdminOrAdminOrOwner() {
		return this.isSuperAdmin() || this.isAdminOrOwnerInTheSelectedOrganization();
	}

	async updateSettings(key: USER_SETTINGS, newValue: boolean) {
		const settingsRef = ref(firebaseDatabase, `users/${this.uid}/settings/`);
		await update(settingsRef, {
			...this.settings,
			[key]: newValue,
		});
	}

	getSettings(key: USER_SETTINGS) {
		return this.settings ? this.settings[key] : undefined;
	}

	async getFreshSettingsCopy(key: USER_SETTINGS) {
		const settingsRef = ref(firebaseDatabase, `users/${this.uid}/settings/`);
		return get(settingsRef).then((snapshot) => {
			const settingsData = snapshot.val();
			if (settingsData && Object.prototype.hasOwnProperty.call(settingsData, key)) {
				return settingsData[key];
			}
			return undefined;
		});
	}
}

export default User;
