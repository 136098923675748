const LeftCenter = () => (
	<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M32.7158 29.444V4.556C32.7158 2.592 31.1238 1 29.1598 1H4.27182C2.30782 1 0.71582 2.592 0.71582 4.556V29.444C0.71582 31.408 2.30782 33 4.27182 33H29.1598C31.1238 33 32.7158 31.408 32.7158 29.444Z"
			stroke="#5B5B5B"
			strokeOpacity="0.3"
			strokeWidth="1.42857"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M5.28711 15C5.28711 14.4477 5.73482 14 6.28711 14H16.2871C16.8394 14 17.2871 14.4477 17.2871 15V19C17.2871 19.5523 16.8394 20 16.2871 20H6.28711C5.73482 20 5.28711 19.5523 5.28711 19V15Z"
			fill="#5B5B5B"
			fillOpacity="0.5"
		/>
		<path
			d="M6.0014 15C6.0014 14.8422 6.12931 14.7143 6.28711 14.7143H16.2871C16.4449 14.7143 16.5728 14.8422 16.5728 15V19C16.5728 19.1578 16.4449 19.2857 16.2871 19.2857H6.28711C6.12931 19.2857 6.0014 19.1578 6.0014 19V15Z"
			stroke="#5B5B5B"
			strokeOpacity="0.5"
			strokeWidth="1.42857"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default LeftCenter;
