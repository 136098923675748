import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import { API_PLACEMENT_TYPES } from "../../../../../constants/foregroundPlacementConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { ConfigDisplayProps, ConfigKeys, PlatformDestination } from "../../../../../models/image-to-image";
import styles from "./ConfigDisplay.module.scss";
import ConfigItem from "./ConfigItem";

const ConfigDisplay = observer(({ config, onDelete, onClick }: ConfigDisplayProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features" });
	const { tailoredGenerationStore } = useAppStore();
	const placements = API_PLACEMENT_TYPES(t);
	const [modelsMap, setModelsMap] = useState(new Map());

	useEffect(() => {
		if (tailoredGenerationStore.models && tailoredGenerationStore.models.length > 0) {
			setModelsMap(new Map(tailoredGenerationStore.models.map((model) => [model.id, model])));
		}
	}, [tailoredGenerationStore.models]);

	const renderConfigItem = (configKey: ConfigKeys | string, value: any): JSX.Element | null => {
		if (typeof value === "boolean" && configKey !== ConfigKeys.Selected) {
			return value ? (
				<ConfigItem
					configKey={configKey}
					value={configKey}
					label={t(`mainPage.${configKey}`)}
					onDelete={onDelete}
					onClick={onClick}
				/>
			) : null;
		}

		if (configKey === ConfigKeys.Prompt && typeof value === "string" && value.trim().length) {
			return (
				<ConfigItem configKey={configKey} value={value} label={value} onDelete={onDelete} onClick={onClick} />
			);
		}

		if (configKey === ConfigKeys.TailoredModelId) {
			const selectedTailoredModel = modelsMap.get(value);
			if (!selectedTailoredModel) return null;
			return (
				<ConfigItem
					configKey={configKey}
					value={value}
					label={selectedTailoredModel?.name}
					onDelete={onDelete}
					onClick={onClick}
				/>
			);
		}

		if (typeof value === "object" && value !== null && "selected" in value) {
			if (!value.selected) return null;
			switch (configKey) {
				case ConfigKeys.Solid:
					return (
						<ConfigItem configKey={configKey} onDelete={onDelete} onClick={onClick} value={value}>
							<Box className={styles.colorPreviewBox}>
								<Box className={styles.colorBox} sx={{ background: (value as any).color_code }} />
								<Typography className={styles.label}>{t("background.backgroundColor")}</Typography>
							</Box>
						</ConfigItem>
					);
				case ConfigKeys.GenerateByText:
					if (!value.prompt) return null;
					return (
						<ConfigItem
							configKey={configKey}
							value={(value as any).prompt}
							onDelete={onDelete}
							onClick={onClick}
							label={(value as any).prompt}
						/>
					);
				case ConfigKeys.AspectRatio:
					return (
						<>
							{(value as any)?.value?.map((item: string) => (
								<ConfigItem
									key={item} // Add a key prop for unique identification
									configKey={configKey}
									value={item}
									label={`${t("size.aspectRatio")}: ${item}`}
									onDelete={onDelete}
									onClick={onClick}
								/>
							))}
						</>
					);
				case ConfigKeys.CustomSize:
					if (!value.width || !value?.height) return null;
					return (
						<ConfigItem
							configKey={configKey}
							value={value}
							onDelete={onDelete}
							onClick={onClick}
							label={`${t("size.customSize")}: ${(value as any).width}x${(value as any).height}`}
						/>
					);
				case ConfigKeys.MediumDestination:
					return (
						<>
							{(value as any)?.destinations?.map((item: PlatformDestination) => (
								<ConfigItem
									key={item.name} // Add a key prop for unique identification
									configKey={configKey}
									value={item}
									onDelete={onDelete}
									onClick={onClick}
									label={`${item.name} ${item.width}x${item.height}`}
								/>
							))}
						</>
					);
				case ConfigKeys.Placements:
					return (
						<>
							{value.values?.map((item: string) => (
								<ConfigItem
									key={item}
									configKey={configKey}
									value={item}
									onDelete={onDelete}
									onClick={onClick}
								>
									<Box className={styles.placementItem}>
										<IconButton
											icon={placements?.find((placement) => placement.value === item)?.icon()}
											className={styles.placementIcon}
										/>
										<Typography className={styles.label}>{item.replace("_", " ")}</Typography>
									</Box>
								</ConfigItem>
							))}
						</>
					);
				default:
					return null;
			}
		}

		return null;
	};

	if (!config) return null;

	return (
		<Box className={styles.configDisplayWrapper}>
			{Object.keys(config).map((key) => renderConfigItem(key as ConfigKeys, config[key]))}
		</Box>
	);
});

ConfigDisplay.displayName = "ConfigDisplay";

export default ConfigDisplay;
