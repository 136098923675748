import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../assets/images/icons/EditIcon.tsx";
import FileUploader from "../../../../components/common/FileUploader/FileUploader.tsx";
import ShowAt from "../../../../components/common/ShowAt/ShowAt.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { PanelType } from "../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../contexts/DesignEditor.tsx";
import useAppContext from "../../../hooks/useAppContext.tsx";
import useSmartImageHeader from "../../../hooks/useSmartImageHeader.tsx";
import { SecondaryTabTypeEnum } from "../../../views/DesignEditor/components/Panels/panelItems";
import Icons from "../../Icons";
import styles from "./SmartImageHeader.module.scss";

interface IProps {
	buttonPosition?: { left: number; top: number; right?: number };
}

const SmartImageHeader = ({ buttonPosition }: IProps) => {
	const { setActivePanel } = useAppContext();
	const { imagesStore, designEditorStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });
	const {
		isSmartImageHeaderVisible,
		showSmartImageHeader,
		disableCanvasMenuActions,
		handleMouseEnter,
		handleMouseLeave,
		onUploadImage,
		handleEditSmartImage,
	} = useSmartImageHeader();

	return (
		<>
			<ShowAt condition={isSmartImageHeaderVisible}>
				<Box
					className={styles.smartImageBox}
					style={{
						right: buttonPosition ? `${buttonPosition.left}px` : "",
						top: buttonPosition ? `${buttonPosition.top - 35}px` : "",
					}}
				>
					<ShowAt
						condition={
							imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE && !showSmartImageHeader
						}
						disabled={disableCanvasMenuActions}
					>
						<Box
							data-value={t("upload")}
							className={clsx(styles.fileUploaderButtonWrapper, {
								[styles.disabled]: disableCanvasMenuActions,
							})}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<FileUploader
								buttonText={t("upload")}
								buttonType="textSmall"
								inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
								buttonProps={{
									startIcon: <Icons.Uploads1 size={16} />,
									onClick: () => {
										setActivePanel(PanelType.IMAGES);
										imagesStore.setProperty("secondaryTabType", SecondaryTabTypeEnum.SMART_IMAGE);
									},
									disabled: imagesStore.isUploadingSmartImageMainObject,
								}}
								onUpload={onUploadImage}
								disabled={imagesStore.isUploadingSmartImageMainObject}
								buttonClassName={styles.buttonLabel}
							/>
						</Box>
						<Box
							data-value={t("generate")}
							className={clsx(styles.buttonWrapper, {
								[styles.disabled]: disableCanvasMenuActions,
							})}
							onClick={() => {
								if (!disableCanvasMenuActions) {
									setActivePanel(PanelType.IMAGES);
									imagesStore.setProperty("secondaryTabType", SecondaryTabTypeEnum.SMART_IMAGE);
									designEditorStore.openText2ImagePopup(isPopupView ?? false);
								}
							}}
							onMouseEnter={handleMouseEnter}
							onMouseLeave={handleMouseLeave}
						>
							<>
								<Icons.EditWithAI size={16} />
								<Typography className={styles.buttonLabel}>{t("generate")}</Typography>
							</>
						</Box>
					</ShowAt>
					<ShowAt condition={showSmartImageHeader} disabled={disableCanvasMenuActions}>
						<Box
							className={styles.buttonWrapper}
							data-value={t("editSmartImage")}
							onClick={handleEditSmartImage}
						>
							<>
								<EditIcon className={styles.size_16} />
								<Typography className={styles.buttonLabel}>{t("editSmartImage")}</Typography>
							</>
						</Box>
					</ShowAt>
				</Box>
			</ShowAt>
		</>
	);
};

const ObservedComponent = observer(SmartImageHeader);
export default ObservedComponent;
