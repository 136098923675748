import { Box, Grid, Tab, Tabs, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { SandboxAPIsConstants } from "../../../constants/SandboxAPIsConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate";
import { APICardComponent, IAPICardsProps } from "../../API/APICardComponent";
import CustomTabPanel from "./CustomTabPanel";
import styles from "./SandboxAPIs.module.scss";

const StyledTabs = styled(Tabs)({
	"& .MuiTabs-indicator": {
		height: "4px",
		backgroundColor: "var(--primary-light-color)",
		borderRadius: "var(--small-border-radius)",
	},
});

const StyledTab = styled(Tab)({
	fontWeight: "400",
	textTransform: "none",
	fontSize: "16px",
	color: "var(--secondary-color)",
	"&.Mui-selected": {
		color: "var(--black-color)",
		fontWeight: "600",
	},
	"&:hover": {
		color: "var(--black-color)",
	},
});

const SandboxAPIs = () => {
	const { t } = useTranslation("translation", { keyPrefix: "platformHub.sandboxAPI" });
	const navigate = useSecureNavigate();
	const location = useLocation();

	const tabMapping = {
		imageGeneration: t("tabs.imageGeneration"),
		imageModification: t("tabs.imageEditing"),
		eCommerceSuite: t("tabs.eCommerceSuite"),
	};

	const queryParams = new URLSearchParams(location.search);
	const initialTab = queryParams.get("tab") || tabMapping.imageGeneration;

	const [activeTab, setActiveTab] = useState<string>(initialTab);

	const handleChange = (_: React.SyntheticEvent, newValue: string) => {
		setActiveTab(newValue);
		navigate(`?tab=${newValue}`, { replace: true });
	};

	const renderTabPanel = (apiConstants: IAPICardsProps[], index: string) => (
		<CustomTabPanel activeTab={activeTab} index={index}>
			<Grid container spacing={3}>
				{apiConstants.map((card, idx) => (
					<APICardComponent {...card} key={idx} />
				))}
			</Grid>
		</CustomTabPanel>
	);

	return (
		<Box className={styles.sandboxApisContainer}>
			<StyledTabs value={activeTab} centered onChange={handleChange}>
				<StyledTab label={t("tabs.imageGeneration")} value={tabMapping.imageGeneration} />
				<StyledTab label={t("tabs.imageEditing")} value={tabMapping.imageModification} />
				<StyledTab label={t("tabs.eCommerceSuite")} value={tabMapping.eCommerceSuite} />
			</StyledTabs>

			{renderTabPanel(SandboxAPIsConstants.imageGeneration, tabMapping.imageGeneration)}
			{renderTabPanel(SandboxAPIsConstants.imageModification, tabMapping.imageModification)}
			{renderTabPanel(SandboxAPIsConstants.eCommerceSuite, tabMapping.eCommerceSuite)}
		</Box>
	);
};

export default SandboxAPIs;
