import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { updateUserInfo } from "../../../helpers/firebase.ts";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import classes from "./UserInfo.module.scss";

const UserInfo = () => {
	const initialValues = {
		firstname: "",
		lastname: "",
	};
	const {
		authStore: { user, setUser },
	} = useAppStore();
	const navigate = useSecureNavigate();
	const { t } = useTranslation();
	const [errorMessage, _setErrorMessage] = useState<boolean | string>(false);
	const [loading, setIsLoading] = useState<boolean>(false);

	const handleSubmit = async (values: any) => {
		if (user) {
			setIsLoading(true);
			const username = `${values.firstname} ${values.lastname}`;
			const userObject = await updateUserInfo(user, username);
			if (userObject) {
				await setUser(userObject);
			}
			setIsLoading(false);
			navigate(RouterConstants.CONSOLE.path);
		}
	};

	return (
		<Box className={classes.content}>
			<Typography className={classes.mainTitle}>{t("welcomeUserInfo")}</Typography>
			<Formik
				initialValues={initialValues}
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={Yup.object().shape({
					firstname: Yup.string().required(t("firstNameIsRequired")),
					lastname: Yup.string().required(t("lastNameIsRequired")),
				})}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur }) => (
					<Form className={classes.form} noValidate>
						<Box className={classes.formElements}>
							<BriaInput
								id="firstname"
								label={t("firstName")}
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.firstname}
								error={(Boolean(errors.firstname) && touched.firstname) as boolean}
								helperText={touched.firstname ? errors.firstname : ""}
								className={classes.textField}
							/>

							<BriaInput
								id="lastname"
								label={t("lastName")}
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.lastname}
								error={(Boolean(errors.lastname) && touched.lastname) as boolean}
								helperText={touched.lastname ? errors.lastname : ""}
								className={classes.textField}
							/>

							{errorMessage && (
								<Typography className={classes.error} color="secondary">
									<ErrorIcon className={classes.errorIcon} />
									{t(`${errorMessage}`)}
								</Typography>
							)}
							<BriaButton
								buttonType="primary"
								type="submit"
								variant="contained"
								color="secondary"
								fullWidth={true}
							>
								{t("getStarted")}
								<Box className={classes.circleLoader}>
									{loading && <CircularProgress color="inherit" size={25} />}
								</Box>
							</BriaButton>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default observer(UserInfo);
