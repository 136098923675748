import { fabric } from "fabric";
import _ from "lodash";
import throttle from "lodash/throttle";
import { IScene } from "../../types";
import Base from "./Base";

class History extends Base {
	private redos: any[] = [];
	private undos: any[] = [];
	private current: any[] = [];
	private isActive: boolean = false;
	private setScenes: ((scenes: IScene[]) => void) | undefined;
	private setCurrentScene: ((scenes: IScene, invpkeSave: boolean) => void) | undefined;
	private UpdatedScenes: (() => Promise<IScene[]>) | undefined;
	public setScenesFunc(func: (value: ((prevState: IScene[]) => IScene[]) | IScene[]) => void) {
		this.setScenes = func;
	}
	public getUpdatedScenes(func: () => Promise<IScene[]>) {
		this.UpdatedScenes = func;
	}
	public setCurrentScenesFunc(func: (scene: IScene | null, invokeSave: boolean) => void) {
		this.setCurrentScene = func;
	}
	public getRedos() {
		return this.redos;
	}
	public getUndos() {
		return this.undos;
	}

	public initialize = () => {
		const canvasJSON = this.canvas.toJSON(this.config.propertiesToInclude) as any;
		this.current = _.cloneDeep(canvasJSON.objects);
		canvasJSON.objects.forEach((object: fabric.Object) => {
			if (object.clipPath) {
				fabric.util.enlivenObjects(
					[object.clipPath],
					function (arg1: any) {
						object.clipPath = arg1[0];
					},
					"",
				);
			}
		});
	};

	public getStatus = () => {
		return {
			hasUndo: this.undos.length >= 1,
			hasRedo: this.undos.length > 0,
			undos: this.undos,
			redos: this.redos,
			state: this.current,
		};
	};

	public save = async (updatedScenes?: IScene[]) => {
		try {
			const UpdatedScenes = updatedScenes ? updatedScenes : await this?.UpdatedScenes!();
			const currentSceneId = this.editor.scene.exportToJSON().id;
			// If an action occurs while undoing, reset the redo history
			// to prevent redoing outdated actions.
			if (this.undos.length > 1 && this.redos.length > 0) {
				this.redos = [];
			}
			if (UpdatedScenes.length > 0) {
				const clonedUpdatedScenes = JSON.parse(JSON.stringify(UpdatedScenes));

				this.undos.push({
					type: "UPDATE",
					UpdatedScenes: clonedUpdatedScenes,
					currentSceneId: currentSceneId,
				});
			}
		} catch (err) {
			console.log(err);
		}
		this.emitStatus();
	};

	public undo = throttle(async () => {
		if (this.undos.length > 1) {
			const undo = this.undos.pop();
			if (!undo) {
				return;
			}
			this.redos.push({
				type: "redo",
				UpdatedScenes: undo.UpdatedScenes,
				currentSceneId: undo.currentSceneId,
			});
			this.restore(this.undos[this.undos.length - 1]);
		}
	}, 100);

	public redo = throttle(async () => {
		const redo = this.redos.pop();
		if (!redo) {
			return;
		}
		this.restore(redo);
		this.undos.push({
			type: "undo",
			UpdatedScenes: redo.UpdatedScenes,
			currentSceneId: redo.currentSceneId,
		});
	}, 100);

	private restore = async (transaction: any) => {
		if (!this.isActive) {
			this.isActive = true;
			const scenes = transaction.UpdatedScenes;
			this.setScenes!(scenes);
			const sceneToUpdate = scenes.find((scene: any) => scene.id === transaction.currentSceneId);
			this.setCurrentScene!(sceneToUpdate, false);
			this.isActive = false;
		}
	};

	public reset = () => {
		this.redos = [];
		this.undos = [];
		this.emitStatus();
	};

	public emitStatus = () => {
		this.editor.emit("history:changed", {
			hasUndo: this.undos.length >= 1,
			hasRedo: this.redos.length > 0,
		});
	};

	public get status() {
		return {
			undos: this.undos,
			redos: this.redos,
			state: this.current,
		};
	}
}

export default History;
