import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Divider, FormControl, MenuItem, Select, Typography, styled } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { loadFont } from "../../../utils";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./SingleSelectGroupedDropDown.module.scss";

export type IGroupOption<T = any> = {
	id: string;
	value?: T;
	label: string;
	description?: string;
	extraData?: any;
	icon?: any;
	key?: string;
};

export type IGroup<T = any> = {
	name: T;
	options: IGroupOption[];
	loading?: boolean;
};

export type SingleSelectGroupedDropDownIProps<T> = {
	selectedValue?: T;
	handleChange?: any;
	maxOptionLabelLength?: number;
	groups: IGroup<T>[];
	className?: string;
	placeholder?: string;
	selectStyle?: string;
	loading?: boolean;
	fontOptions?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	sortAlphabetically?: boolean;
	menuMaxHeight?: string;
	paddingClass?: string;
	hideEmptyGroups?: boolean;
	showFoundationModels?: boolean;
};
const CustomSingleSelect = <T,>({
	selectedValue,
	handleChange,
	groups = [],
	paddingClass,
	placeholder,
	selectStyle,
	menuMaxHeight,
	loading = false,
	fontOptions = false,
	hasError,
	sortAlphabetically = true,
	className,
	hideEmptyGroups = true,
}: SingleSelectGroupedDropDownIProps<T>) => {
	const [selectedVal, setSelectedVal] = useState<any>();

	const sortOptionsAlphabetically = (options: any) => {
		return options.sort((a: any, b: any) => a.key.localeCompare(b.key));
	};

	useEffect(() => {
		// Load fonts when component mounts
		fontOptions && groups?.forEach((group: any) => group.options.forEach(loadFont));
	}, [groups]);

	useEffect(() => {
		selectedValue && setSelectedVal(getSelectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if (groups.length) {
			setSelectedVal(getSelectedValue);
		}
	}, [groups]);

	const getSelectedValue = () =>
		groups
			.map((group: any) => group.options)
			.flat()
			.find((option: any) => option.value === selectedValue);
	return (
		<FormControl variant="outlined">
			<Select
				className={selectStyle}
				disabled={loading}
				renderValue={() => (
					<Typography className={styles.placeHolder}>{selectedVal?.label || placeholder}</Typography>
				)}
				value={selectedValue}
				onChange={handleChange}
				displayEmpty
				classes={{ root: paddingClass, select: `${selectStyle} ${hasError ? styles.errorBorder : ""}` }}
				IconComponent={
					loading || groups.every((group) => group.loading) ? DropDownLoading : ExpandMoreRoundedIcon
				}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					PaperProps: {
						style: {
							backgroundColor: "white",
							maxHeight: menuMaxHeight,
							overflowY: "auto",
						},
					},
				}}
			>
				{groups
					.filter((group: IGroup) => !hideEmptyGroups || group.loading || group.options.length > 0)
					.map((group: IGroup, groupIndex: number, visibleGroups: IGroup[]) => [
						<MenuItem
							className={styles.disabledOptions}
							key={`group_${groupIndex}`}
							value={group.name}
							disabled
						>
							{group.name}
						</MenuItem>,
						<LoadingPlaceholder className={styles.loading} isLoading={group.loading} />,
						...(sortAlphabetically ? sortOptionsAlphabetically(group.options) : group.options).map(
							(option: any, optionIndex: any) => (
								<MenuItem
									className={clsx(className, styles.optionWrapper, {
										[styles.disabledOptions]: option.disabled,
									})}
									style={{ fontFamily: fontOptions && option.label, padding: "10px 12px" }}
									key={optionIndex}
									value={option.value}
								>
									{fontOptions ? (
										option.label
									) : (
										<Box className={styles.optionContainer}>
											<Box className={`${option.icon && styles.iconLabelContainer}`}>
												{option.icon ? option.icon : null}
												<Typography
													className={clsx(styles.optionsLabel, {
														[styles.bold]: option.description !== undefined,
													})}
												>
													{option.label}
												</Typography>
											</Box>
											{option.description && (
												<Typography className={styles.optionsDescription}>
													{option.description}
												</Typography>
											)}
										</Box>
									)}
								</MenuItem>
							),
						),
						groupIndex !== visibleGroups.length - 1 && (
							<Divider key={`divider_${groupIndex}`} className={styles.divider} />
						),
					])}
			</Select>
		</FormControl>
	);
};
export default CustomSingleSelect;

const DropDownLoading = styled(CircularProgress)(({ theme }) => ({
	color: `${theme.palette.primary.light} !important`,
	width: "1em !important",
	height: "1em !important",
	right: "13px !important",
}));
