import { RefObject, useCallback, useEffect, useRef } from "react";
import { IBrushSettings } from "../../models/sandboxAPI.ts";

export const useBrushCore = (
	brushConfig: IBrushSettings,
	canvasRef: RefObject<HTMLCanvasElement>,
	brushingAreaCanvasRef: RefObject<HTMLCanvasElement>,
) => {
	const pathRef = useRef<Path2D | null>(null);
	const isDrawingRef = useRef(false);
	const previousCoordinatesRef = useRef([0, 0]);
	const overlayContextRef = useRef<CanvasRenderingContext2D | null>(null);
	const updateCoordinates = useCallback((e: any) => {
		previousCoordinatesRef.current = [e.offsetX, e.offsetY];
	}, []);

	const drawLine = useCallback(
		(e: any) => {
			if (!overlayContextRef.current || !pathRef.current || !isDrawingRef.current) {
				return;
			}
			const yValue = e.offsetY;
			const xValue = e.offsetX;

			updateCoordinates(e);
			pathRef.current.lineTo(xValue, yValue);
			overlayContextRef.current.stroke(pathRef.current);
		},
		[updateCoordinates, brushConfig],
	);
	const drawEnd = useCallback(() => {
		isDrawingRef.current = false;
		pathRef.current = null;
		canvasRef.current?.removeEventListener("mousemove", drawLine);
		canvasRef.current?.removeEventListener("mouseup", drawEnd);
	}, [drawLine, brushConfig, canvasRef]);

	const drawStart = useCallback(
		(e: any) => {
			isDrawingRef.current = true;
			updateCoordinates(e);
			pathRef.current = new Path2D();
			pathRef.current.moveTo(previousCoordinatesRef.current[0], previousCoordinatesRef.current[1]);
			canvasRef.current?.addEventListener("mousemove", drawLine);
			canvasRef.current?.addEventListener("mouseup", drawEnd);
			if (overlayContextRef.current && brushConfig) {
				overlayContextRef.current.strokeStyle = brushConfig.strokeStyle;
				overlayContextRef.current.lineWidth = brushConfig.lineWidth;
				overlayContextRef.current.lineCap = brushConfig.lineCap;
				overlayContextRef.current.lineJoin = brushConfig.lineJoin;
				overlayContextRef.current.globalAlpha = brushConfig.globalAlpha;
				overlayContextRef.current.globalCompositeOperation = brushConfig.globalCompositeOperation;
			}
			return () => {
				if (canvasRef.current) {
					canvasRef.current.removeEventListener("mousemove", drawLine);
					canvasRef.current.removeEventListener("mouseup", drawEnd);
				}
			};
		},
		[drawEnd, drawLine, brushConfig, overlayContextRef.current],
	);

	const drawCursor = useCallback(
		(e: any) => {
			const cursor = document.querySelector("#customBrushCursor") as HTMLElement;
			if (canvasRef && canvasRef.current && cursor) {
				canvasRef.current.style.cursor = "none";
				cursor.style.display = "block";
				cursor.style.transform = `translate3d(${e.offsetX - (canvasRef.current?.width ?? 0) / 2}px, ${
					e.offsetY - brushConfig.lineWidth / 2
				}px, 0)`;
				canvasRef.current?.addEventListener("mouseout", () => {
					if (canvasRef && canvasRef.current && cursor) {
						cursor.style.display = "none";
						canvasRef.current.style.cursor = "default";
					}
				});
			}
		},
		[brushConfig, canvasRef],
	);

	const handleMouseEnter = useCallback(() => {
		canvasRef.current?.addEventListener("mousemove", drawCursor);
	}, [drawCursor, canvasRef, brushConfig]);

	useEffect(() => {
		if (canvasRef.current) {
			canvasRef.current.addEventListener("mousedown", drawStart);
			canvasRef.current.addEventListener("mouseenter", handleMouseEnter);
		}

		return () => {
			if (canvasRef.current) {
				canvasRef.current.removeEventListener("mousedown", drawStart);
				canvasRef.current.removeEventListener("mouseenter", handleMouseEnter);
			}
		};
	}, [canvasRef, brushConfig, drawStart, handleMouseEnter]);

	useEffect(() => {
		if (brushingAreaCanvasRef && brushingAreaCanvasRef.current && canvasRef.current) {
			brushingAreaCanvasRef.current.style.position = "absolute";
			brushingAreaCanvasRef.current.style.pointerEvents = "none";
			brushingAreaCanvasRef.current.style.width = canvasRef.current.style.width;
			brushingAreaCanvasRef.current.style.height = canvasRef.current.style.height;
			brushingAreaCanvasRef.current.style.opacity = String(brushConfig.brushColorOpacity);
			overlayContextRef.current = brushingAreaCanvasRef.current.getContext("2d");
		}
	}, [canvasRef]);

	return null;
};
