import { Box, SelectChangeEvent, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../../../assets/images/icons/InfoIcon.tsx";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import StaticDropdown, { AspectRatio } from "../../../../../components/common/DropDowns/StaticDropdown";
import TextToImageModelsDropdown, {
	getModelKey,
} from "../../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown";
import ShowAt from "../../../../../components/common/ShowAt/ShowAt.tsx";
import { useAppStore } from "../../../../../hooks/useStores";
import styles from "./GenerateImagesSection.module.scss";

interface IProps {
	textToGenerate: string;
	setTextToGenerate: (args: string) => void;
	selectedAspectRatio: AspectRatio;
	handleGenAspectRatioChange: (args: SelectChangeEvent<AspectRatio | AspectRatio[]>) => void;
	handleGenSubmit: () => void;
	hideTextField?: boolean;
	hideModelsDropdownSelector?: boolean;
	hideResolutionDropdownSelector?: boolean;
	tooltip: string;
	title?: string;
}

const GenerateImagesSection = ({
	textToGenerate,
	setTextToGenerate,
	selectedAspectRatio,
	handleGenAspectRatioChange,
	handleGenSubmit,
	hideTextField = false,
	hideModelsDropdownSelector = false,
	hideResolutionDropdownSelector = false,
	tooltip,
	title,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });
	const { textToImageStore } = useAppStore();
	const infoIconRef = useRef<HTMLDivElement | null>(null);
	const [openInfo, setOpenInfo] = useState(false);
	const isSimpleStyle = hideTextField && hideResolutionDropdownSelector && hideModelsDropdownSelector;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (infoIconRef.current && !infoIconRef.current.contains(event.target as Node)) {
				setOpenInfo(false);
			}
		};

		document.addEventListener("click", handleClickOutside as unknown as EventListener);
		return () => {
			document.removeEventListener("click", handleClickOutside as unknown as EventListener);
		};
	}, []);
	const handleIconClick = (event: MouseEvent<SVGSVGElement>) => {
		event.stopPropagation();
		setOpenInfo((prev) => !prev);
	};
	return (
		<Box
			className={clsx(styles.generateImage, {
				[styles.simpleStyle]: isSimpleStyle,
			})}
		>
			<Box className={styles.titleContainer}>
				<Typography className={styles.title}>{title ? title : t("imageGeneration.title")}</Typography>

				<BriaTooltip
					title={tooltip}
					open={openInfo}
					className={styles.info}
					componentsProps={{ tooltip: { className: styles.tooltip, ref: infoIconRef } }}
				>
					<InfoIcon className={styles.infoIcon} onClick={handleIconClick} />
				</BriaTooltip>
			</Box>
			<ShowAt condition={!isSimpleStyle}>
				<ShowAt condition={!hideModelsDropdownSelector}>
					<TextToImageModelsDropdown
						selectedValue={
							textToImageStore.config.tailored_model_id ||
							getModelKey(textToImageStore.config.model, textToImageStore.config.model_version)
						}
						handleChange={textToImageStore.handleText2ImagePopupChange}
						placeholder={t("models.placeholder")}
					/>
				</ShowAt>

				<ShowAt condition={!hideTextField}>
					<BriaInput
						multiline
						fullWidth={true}
						type="text"
						placeholder={t("imageGeneration.placeholderText")}
						value={textToGenerate}
						onEnterPress={handleGenSubmit}
						onChange={(e) => {
							setTextToGenerate(e.target.value);
						}}
						InputProps={{
							className: styles.textFieldStyle,
						}}
					/>
				</ShowAt>
				<ShowAt condition={!hideResolutionDropdownSelector}>
					<StaticDropdown<AspectRatio>
						type="aspectRatio"
						value={selectedAspectRatio}
						onChange={handleGenAspectRatioChange}
						placeholder={t("imageGeneration.defaultGenAspectRatio.placeholder")}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
						height="32px"
						inputProps={{ className: styles.dropdownAspectRatio }}
					/>
				</ShowAt>
			</ShowAt>

			<BriaButton
				className={styles.primaryButton}
				buttonType="primary"
				onClick={handleGenSubmit}
				disabled={!isSimpleStyle && !textToGenerate}
			>
				{t("imageGeneration.button")}
			</BriaButton>
		</Box>
	);
};

const ObservedComponent = observer(GenerateImagesSection);
export default ObservedComponent;
