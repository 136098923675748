import { Box, Slider, Typography } from "@mui/material";
import { StatefulPopover } from "baseui/popover";
import { PLACEMENT } from "baseui/tooltip";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../../../components/common/BriaInput/BriaInput";
import BriaTooltip from "../../../../../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils from "../../../../../../CustomUtils/UseDesignEditor";
import spacing from "../../../../../../assets/svgs/line-height.svg";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText, LayerType } from "../../../../../../types";
import styles from "./TextSpacing.module.scss";

const TextSpacing = () => {
	const [textSpacing, setTextSpacing] = useState<{
		charSpacing: number;
		lineHeight: number;
	}>({ charSpacing: 0, lineHeight: 0 });

	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });
	const editor = useEditor();
	const activeObject = useActiveObject() as Required<IStaticText>;
	const { EditAllAds } = useDesignEditorUtils();
	const { campaignStore } = useAppStore();

	useEffect(() => {
		if (activeObject) {
			// @ts-ignore
			const { charSpacing, lineHeight } = activeObject;
			setTextSpacing({ ...textSpacing, charSpacing: charSpacing / 10, lineHeight: lineHeight * 10 });
		}
	}, [activeObject]);

	const handleChange = (type: string, value: number[]) => {
		if (editor) {
			if (type === "charSpacing") {
				setTextSpacing({ ...textSpacing, [type]: value[0] });

				editor.objects.update(
					{
						[type]: value[0] * 10,
					},
					undefined,
					!campaignStore.isEditingAllAds,
				);
				EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, type, value[0] * 10);
			} else {
				setTextSpacing({ ...textSpacing, [type]: value[0] });

				editor.objects.update(
					{
						[type]: value[0] / 10,
					},
					undefined,
					!campaignStore.isEditingAllAds,
				);
				EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, type, value[0] / 10);
			}
		}
	};

	return (
		<StatefulPopover
			showArrow={true}
			placement={PLACEMENT.bottom}
			overrides={{
				Body: {
					props: {
						className: styles.popoverBody,
					},
				},
			}}
			content={() => (
				<Box className={styles.textSpacingContent}>
					<Box>
						<Typography className={styles.title}>{t("letterSpacing")}</Typography>

						<Box className={styles.sliderInput}>
							<Slider
								min={-20}
								max={100}
								value={textSpacing.charSpacing}
								onChange={(_event, newValue) => {
									if (Array.isArray(newValue)) {
										handleChange("charSpacing", newValue);
									} else {
										handleChange("charSpacing", [newValue]);
									}
								}}
								className={styles.slider}
							/>

							<BriaInput
								type="number"
								value={Math.round(textSpacing.charSpacing)}
								height="19px"
								className={styles.input}
								inputProps={{ className: styles.inputBase }}
							/>
						</Box>
					</Box>
					<Box>
						<Typography className={styles.title}>{t("lineSpacing")}</Typography>

						<Box className={styles.sliderInput}>
							<Slider
								min={0}
								max={100}
								value={textSpacing.lineHeight}
								onChange={(_event, newValue) => {
									if (Array.isArray(newValue)) {
										handleChange("lineHeight", newValue);
									} else {
										handleChange("lineHeight", [newValue]);
									}
								}}
								className={styles.slider}
							/>
							<BriaInput
								type="number"
								value={Math.round(textSpacing.lineHeight)}
								onChange={() => {}}
								height="19px"
								className={styles.input}
								inputProps={{ className: styles.inputBase }}
							/>
						</Box>
					</Box>
				</Box>
			)}
		>
			<Box>
				<BriaTooltip title={t("Spacing")} placement="bottom">
					<img className={styles.textControl} src={spacing} alt="spacing" />
				</BriaTooltip>
			</Box>
		</StatefulPopover>
	);
};

export default TextSpacing;
