import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import FullscreenIcon from "../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip.tsx";
import BriaCanvas from "../../../../components/common/Galleries/BriaCanvas.tsx";
import { BriaImageProps } from "../../../../components/common/Galleries/BriaImage.tsx";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation.tsx";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { ImageToImageConfigType, PlaygroundImage, PlaygroundResult } from "../../../../models/image-to-image";
import { isCanvasRequiredForThisApp } from "../../../../utils";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay";
import CodeGeneratorModal from "../CodeGeneratorModal/CodeGeneratorModal";
import SandboxAPIGallery from "./Gallery/SandboxAPIGallery";
import styles from "./SandboxAPIResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
	scrollBottom?: () => void;
};

const SandboxAPIResult = ({ playgroundResult, scrollBottom }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI.result" });
	const { playgroundStore, sandboxAPIStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const loading = !!playgroundResult.images.filter((image) => image.loading).length;

	return (
		<Box
			className={clsx(styles.itiGalleryWrapper, styles.resultRow, "galleryWrapper", {
				[styles.fullHeightGallery]: isCanvasRequiredForThisApp(),
			})}
		>
			{isCanvasRequiredForThisApp() ? (
				<LoadingPlaceholder isLoading={sandboxAPIStore.isUploadingImage} className={styles.loadingContainer}>
					<BriaCanvas
						key={0}
						{...playgroundResult.images[0]}
						brushConfigs={sandboxAPIStore.brushConfigs}
						onCanvasSizeUpdate={() => {
							sandboxAPIStore.setProperty("isLoading", false);
						}}
						isLoading={sandboxAPIStore.isLoading}
						canvasRef={(canvas: HTMLCanvasElement | null) => {
							if (!sandboxAPIStore.brushCanvasRefs[0]) {
								sandboxAPIStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}

							sandboxAPIStore.brushCanvasRefs[0].canvasRef.current = canvas;
						}}
						canvasOverlayRef={(overlayCanvas: HTMLCanvasElement | null) => {
							if (!sandboxAPIStore.brushCanvasRefs[0]) {
								sandboxAPIStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}
							sandboxAPIStore.brushCanvasRefs[0].canvasOverlayRef.current = overlayCanvas;
						}}
					/>
				</LoadingPlaceholder>
			) : (
				<>
					<Box className={styles.header}>
						{!isCanvasRequiredForThisApp() && (
							<>
								{playgroundResult.isFromUpload ? (
									<Box className={styles.headerText}>{t("uploadHeader")}</Box>
								) : (
									<Box className={styles.headerText}>{t("header")}</Box>
								)}

								{loading ? (
									<LoadingAnimation loading={loading} progressBarTime={6} showAnimation={false} />
								) : (
									<Box className={styles.actionBtns}>
										<CodeGeneratorModal playgroundResult={playgroundResult} />
										<BriaTooltip title={t("delete")}>
											<DeleteIcon
												className={styles.iconButton}
												onClick={() => {
													if (playgroundResult.isFromUpload) {
														sandboxAPIStore.uploadImageSrc = undefined;
													}
													playgroundStore.deleteResult(playgroundResult.id);
												}}
											/>
										</BriaTooltip>
										<BriaTooltip title={t("fullScreen")}>
											<FullscreenIcon
												className={styles.iconButton}
												onClick={() => setOpenFullScreen(true)}
											/>
										</BriaTooltip>
									</Box>
								)}
							</>
						)}
					</Box>
					<SandboxAPIGallery
						images={playgroundResult.images.map(
							(image) =>
								({
									ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
									displayOverlay: "customHover",
									selectable: true,
									htmlJsonData: image.id,
									fullScreenProps: {
										compareTo: (image.config as ImageToImageConfigType)?.original_image.url,
										fileName: playgroundStore.getFileName(image),
									},
									onSuccessPulling: async () => {
										playgroundStore.onSuccessResult(image);
										playgroundResult?.isFromUpload &&
											playgroundStore.handleSelectSingleImage(playgroundResult.images[0]);
									},
									onErrorPulling: (errorType) => playgroundStore.onErrorResult(image, errorType),
									async: loading,
									asyncProps: { maxAttempts: 240 },
									...image,
								}) as BriaImageProps & PlaygroundImage,
						)}
						fullScreenProps={{
							open: openFullScreen,
							onClose: () => setOpenFullScreen(false),
							displayMode: "scroll",
							hideDownloadIcon: true,
						}}
						className={styles.gallery}
						scrollBottom={scrollBottom}
					/>
				</>
			)}
		</Box>
	);
};

export default observer(SandboxAPIResult);
