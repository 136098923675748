import imageCompression from "browser-image-compression";
import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import { IMAGE_COMPRESSION_CONFIG } from "../../../config/image.ts";
import { APPS } from "../../../constants/AppsConstants.ts";
import { PLACEMENTS } from "../../../constants/ProductPlacementConstants.ts";
import { IRootStore } from "../../../mobx/root-store.tsx";
import { PlaygroundImage, PlaygroundResult } from "../../../models/image-to-image.ts";
import { ProductPlacementConfigType } from "../../../models/product-placement.ts";
import QueryService from "../../../utils/QueryService.ts";

export interface IProductPlacementStore {
	config: ProductPlacementConfigType;
	handleConfigChange: <K extends keyof ProductPlacementConfigType>(
		key: K,
		value: ProductPlacementConfigType[K],
	) => void;
	errorMessage: string;

	handleUploadImage(e: ChangeEvent<HTMLInputElement>): Promise<string | void>;

	isUploadingImage: boolean;
	isLoading: boolean;
	fetchImagesFromS3: () => Promise<void>;
	images: any;
	imageToGenerate: string | null;
	setImageToGenerate: (image: string) => void;
	setPlaygroundResult: (image: { id: string; url: string }) => void;
	generateProductPlacement: (images?: PlaygroundImage[], config?: ProductPlacementConfigType) => Promise<void>;
}

export default class ProductPlacementStore implements IProductPlacementStore {
	private queryService: QueryService = new QueryService("/product-placement");
	rootStore: IRootStore;
	config: ProductPlacementConfigType = defaultConfig;
	errorMessage = "";
	isUploadingImage: boolean = false;
	isLoading: boolean = false;
	images: any = [];
	imageToGenerate: string = "";

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	clearConfig = () => {
		this.config = defaultConfig;
	};

	handleConfigChange = <K extends keyof ProductPlacementConfigType>(key: K, value: ProductPlacementConfigType[K]) => {
		this.config[key] = value;
	};

	handleUploadImage = async (e: ChangeEvent<HTMLInputElement>): Promise<string | void> => {
		runInAction(() => {
			this.isUploadingImage = true;
		});

		const file: File | null = e.target.files && e.target.files[0];
		e.target.value = "";
		const { playgroundStore } = this.rootStore;

		if (file) {
			const compressedFile = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
			const image: PlaygroundImage = {
				id: uuidv4(),
				url: URL.createObjectURL(file),
				file: compressedFile,
				type: "upload",
			};
			const config = {
				original_image: { id: "", url: "" },
				num_results: 1,
			};
			const savedIndex = playgroundStore.playgroundResults.length;

			const resultsSkeletons: PlaygroundResult = {
				id: uuidv4(),
				config: { ...config },
				type: APPS.PRODUCT_PLACEMENT,
				isFromUpload: true,
				images: [
					{
						id: uuidv4(),
						url: "",
						loading: true,
					},
				],
			};
			playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

			try {
				const formData = new FormData();
				image.file && formData.append("file", image.file);
				if (image && image.file) {
					formData.append("file_name", URL.createObjectURL(image.file));
				}
				formData.append("config", JSON.stringify({ ...config, original_image: image }));
				const { result, original_url } = await this.queryService.post("/upload", formData, {
					"Content-Type": "multipart/form-data",
				});
				runInAction(() => {
					playgroundStore.playgroundResults[savedIndex].images[0] = {
						...result,
						id: uuidv4(),
						loading: true,
						config: {
							...playgroundStore.playgroundResults[savedIndex].config,
							original_image: { ...image, url: original_url },
						} as ProductPlacementConfigType,
						type: APPS.PRODUCT_PLACEMENT,
					};
					this.rootStore.analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_UPLOAD_PP);
				});
			} catch (e: any) {
				runInAction(() => {
					this.isUploadingImage = false;
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}

			const imageUrl = playgroundStore.playgroundResults[savedIndex].images?.[0].url;
			if (imageUrl) {
				this.setImageToGenerate(imageUrl);
			}

			runInAction(() => {
				this.isUploadingImage = false;
			});
		}
	};

	fetchImagesFromS3 = async (): Promise<void> => {
		try {
			this.isLoading = true;
			const response = await this.queryService.get("/");
			runInAction(() => {
				this.images = response;
				this.isLoading = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isLoading = false;
				this.errorMessage = `Error fetching images: ${e.message || e.toString()}`;
			});
		}
	};

	setImageToGenerate = (image: string) => {
		runInAction(() => {
			this.imageToGenerate = image;
		});
	};

	setPlaygroundResult = (image: { id: string; url: string }) => {
		const { playgroundStore } = this.rootStore;

		const playgroundResults: any = [
			{
				config: {
					num_results: 1,
					background: { remove: true },
				},
				type: APPS.PRODUCT_PLACEMENT,
				images: [
					{
						selected: false,
						id: image.id,
						url: image.url,
						type: APPS.PRODUCT_PLACEMENT,
						config: {
							num_results: 1,
							background: { remove: true },
						},
						bg_props: {
							remove: {
								result_url: image.url,
							},
						},
						loading: false,
					},
				],
			},
		];

		playgroundStore.playgroundResults = playgroundResults;
	};

	generateProductPlacement = async (
		images?: PlaygroundImage[],
		config: ProductPlacementConfigType = this.config,
	): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		let selectedImages: PlaygroundImage[] = images || playgroundStore.getSelectedImages();
		if (selectedImages.length === 0) {
			selectedImages = playgroundStore.playgroundResults[0].images;
		}

		const savedIndex = playgroundStore.playgroundResults.length;
		const isGeneratedImage = selectedImages.every(
			(image) => image.placement_selection !== undefined && image.placement_selection !== null,
		);
		const numResults = isGeneratedImage ? 4 : 1;
		const original_image = playgroundStore.getAvailableImages()[0];

		const selectedPlacements = selectedImages
			.map((image) => image.placement_selection)
			.filter((placement): placement is string => !!placement);

		this.handleConfigChange("placement_type", "manual_placement");
		this.handleConfigChange(
			"manual_placement_selection",
			selectedPlacements?.length > 0 ? selectedPlacements : PLACEMENTS,
		);

		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...config },
			type: APPS.PRODUCT_PLACEMENT,
			images: Array.from({ length: selectedImages.length * numResults }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};

		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

		const formData = new FormData();
		formData.append(
			"config",
			JSON.stringify({
				...config,
				original_image: original_image,
				num_results: numResults,
				prompt: this.config.prompt,
				prompt_enhancement: this.config.prompt_enhancement,
				width: this.config.width,
				height: this.config.height,
				fast: this.config.fast,
			}),
		);

		this.rootStore.analyticsStore.logEvent(
			isGeneratedImage ? ANALYTICS_EVENTS.MANUAL_PP_GENERATE : ANALYTICS_EVENTS.PP_GENERATE,
		);
		const { result } = await this.queryService.post("/", formData, {
			"Content-Type": "multipart/form-data",
		});

		runInAction(() => {
			result.forEach((resultImage: any[], index: number) => {
				const generatedImage: PlaygroundImage = {
					id: resultImage[1],
					url: resultImage[0],
				};
				playgroundStore.playgroundResults[savedIndex].images[index] = {
					...generatedImage,
					id: uuidv4(),
					loading: true,
					config: {
						...playgroundStore.playgroundResults[savedIndex].config,
						original_image: { ...original_image },
					} as ProductPlacementConfigType,
					type: APPS.PRODUCT_PLACEMENT,
					placement_selection: isGeneratedImage
						? selectedImages?.length === 1
							? selectedImages[0].placement_selection
							: index <= 3
							? selectedImages[0].placement_selection
							: selectedImages[1].placement_selection
						: PLACEMENTS[index],
				};
			});
		});
	};
}

const defaultConfig: ProductPlacementConfigType = {
	prompt: "",
	width: 1000,
	height: 1000,
	num_results: 1,
	prompt_enhancement: true,
	original_image: { id: "", url: "" },
	placement_type: "manual_placement",
	manual_placement_selection: [],
	fast: true,
};
