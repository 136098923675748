import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import { CampaignIframeConfig } from "../../../../models/new-iframe.ts";
import iframeStore from "../../iframe-store.tsx";
import styles from "./NewIframeForm.module.scss";

const IframeCampaignConfig = () => {
	const { iframeForm, handleFormChange } = iframeStore;
	const { t } = useTranslation("translation", { keyPrefix: "newIframes.editor.form.campaignConfig" });

	const handleChange = (configKey: keyof CampaignIframeConfig, checked: boolean) => {
		handleFormChange("config", {
			...iframeForm.config,
			campaign_config: {
				...iframeForm.config.campaign_config,
				[configKey]: checked,
			},
		});
	};

	return (
		<FormGroup>
			{Object.entries(iframeForm.config.campaign_config || {}).map(([key, value]) => (
				<Box key={key} className={styles.checkboxWithField}>
					<FormControlLabel
						key={key}
						control={
							<BriaCheckbox
								checked={value}
								onChange={(e) => handleChange(key as keyof CampaignIframeConfig, e.target.checked)}
							/>
						}
						label={t(`checkboxes.${key}`)}
					/>
				</Box>
			))}
		</FormGroup>
	);
};

export default observer(IframeCampaignConfig);
