import { useState } from "react";
import { useTranslation } from "react-i18next";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton from "../BriaButton/BriaButton";
import BriaDropdown from "../BriaDropDown/BriaDropDown.tsx";
import ExportImagePopup from "../ExportImagePopup/ExportImagePopup.tsx";
import styles from "./ExportButton.module.scss";

interface IProps {
	onDownload?: () => Promise<void>;
	onIframeSave?: () => Promise<void>;
	disabled?: boolean;
	loading?: boolean;
}

const ExportButton = ({ onDownload, onIframeSave, disabled, loading }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "exportButton" });
	const [isExportImagePopupOpen, setIsExportImagePopupOpen] = useState<boolean>(false);

	const dropdownItems = [];
	if (iframeStore.isIframe()) {
		if (iframeStore.iframe.config.general_config?.enable_download) {
			dropdownItems.push({
				key: t("download"),
				value: "download",
			});
		}
		if (iframeStore.iframe.config.general_config?.enable_save) {
			dropdownItems.push({
				key: iframeStore.iframe.config.general_config?.save_label ?? t("save"),
				value: "save",
			});
		}
	}

	const onExport = async (action: string) => {
		if (disabled) {
			return;
		}
		if (action === "save") {
			onIframeSave?.();
		} else if (action === "download") {
			onDownload?.();
		}
	};
	return iframeStore.isIframe() ? (
		dropdownItems.length === 0 ? (
			<></>
		) : (
			<BriaDropdown
				loading={loading}
				height={"32px"}
				onChange={(e) => onExport(e.target.value)}
				disabled={disabled}
				items={dropdownItems}
				className={styles.exportDropdown}
				placeholder={t("export")}
			/>
		)
	) : (
		<>
			<BriaButton
				buttonType="textSmall"
				onClick={() => setIsExportImagePopupOpen(true)}
				size="small"
				disabled={disabled}
				loading={loading}
			>
				{t("export")}
			</BriaButton>
			{isExportImagePopupOpen && (
				<ExportImagePopup open={isExportImagePopupOpen} onClose={() => setIsExportImagePopupOpen(false)} />
			)}
		</>
	);
};

export default ExportButton;
