import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import BriaTooltip from "../../../../../../components/common/BriaTooltip/BriaTooltip";
import { API_PLACEMENT_TYPES } from "../../../../../../constants/foregroundPlacementConstants";
import { useImageToImageConfig } from "../../../../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../../../../hooks/useStores";
import Config from "../../Config/Config";
import styles from "./ForegroundPlacement.module.scss";

const ForegroundPlacement = () => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.imageToImage.config.features.foregroundPlacement",
	});
	const { imageToImageStore } = useAppStore();
	const { updateSelectedForegroundPlacementConfigs } = useImageToImageConfig();

	const selectedPlacements = imageToImageStore.config.foreground_placement?.placements?.values || [];
	const [activeConfig, setActiveConfig] = useState<string>(t("allPlacements"));

	const placements = API_PLACEMENT_TYPES(t);

	const handlePlacementClick = (placement: string | string[]) => {
		const updatedPlacements = imageToImageStore.config.foreground_placement?.placements?.values || [];

		const isSelected = Array.isArray(placement)
			? placement.every((p) => updatedPlacements.includes(p))
			: updatedPlacements.includes(placement);

		let newPlacements;
		if (isSelected) {
			newPlacements = updatedPlacements.filter((p: string) =>
				Array.isArray(placement) ? !placement.includes(p) : p !== placement,
			);
		} else {
			newPlacements = Array.isArray(placement)
				? [...updatedPlacements, ...placement]
				: [...updatedPlacements, placement];
		}

		imageToImageStore.handleConfigChange("foreground_placement", {
			placements: {
				selected: newPlacements.length > 0,
				values: newPlacements,
			},
		});
		updateSelectedForegroundPlacementConfigs();
	};

	return (
		<Box className={styles.ForegroundPlacementContainer}>
			<Config
				title={t("allPlacements")}
				checked={
					imageToImageStore.config.foreground_placement?.placements?.selected || selectedPlacements.length > 0
				}
				onClick={(e) => {
					const target = e.target as HTMLInputElement;
					imageToImageStore.handleConfigChange("foreground_placement", {
						placements: {
							selected: target.checked,
							values: target.checked ? selectedPlacements : [],
						},
					});
					updateSelectedForegroundPlacementConfigs();
				}}
				isActive={activeConfig === t("allPlacements")}
				setIsActive={setActiveConfig}
			>
				<Box className={styles.placementsList}>
					{placements.map((placement, index) => {
						const isSelected = selectedPlacements?.includes(placement.value);

						return (
							<BriaTooltip title={placement.label} className={styles.placementItem}>
								<IconButton
									key={index}
									icon={placement.icon()}
									className={clsx(styles.placementItemBtn, { [styles.selected]: isSelected })}
									onClick={() => handlePlacementClick(placement.value)}
								/>
							</BriaTooltip>
						);
					})}
				</Box>
			</Config>
		</Box>
	);
};

export default observer(ForegroundPlacement);
