import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import { isProduction } from "../../../../config/env";
import { USER_ORGANIZATION_ROLES } from "../../../../constants/UserConstants.ts";
import { getSelectedOrganization } from "../../../../helpers/localStorage";
import { useIframe } from "../../../../hooks/useIframe";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { IframePostMessageTypes } from "../../../../models/new-iframe";
import iframeStore from "../../iframe-store.tsx";
import styles from "./NewIframePreview.module.scss";

const NewIframePreview = () => {
	const { t } = useTranslation("translation", { keyPrefix: "iframeEditor.preview" });
	const [iframeKey, setIframeKey] = useState(0);
	const iframeRef = useRef<any>();
	const { getIframePreviewUrl, getIframeHost } = useIframe();
	const { authStore } = useAppStore();
	const refreshIframe = () => setIframeKey((prevKey) => prevKey + 1);

	useEffect(() => {
		if (iframeRef.current) {
			window.addEventListener("message", (event) => {
				if (
					event.data &&
					event.data["type"] &&
					event.data["type"] === IframePostMessageTypes.InitialLoadComplete
				) {
					!isProduction() && console.log(`Received post message from iFrame ${getIframeHost()}`, event.data);

					const postMessageData = {
						type: IframePostMessageTypes.InitPreview,
						message: {
							iframe: {
								...iframeStore.iframeForm,
								id: iframeStore.iframeForm.id ? iframeStore.iframeForm.id : "preview",
							},
							fb_organization: { ...getSelectedOrganization(), role: USER_ORGANIZATION_ROLES.USER },
							orgSubscription: authStore.orgSubscription,
						},
					};
					!isProduction() &&
						console.log(`Sending preview post message to iFrame ${getIframeHost()}`, postMessageData);
					iframeRef.current.contentWindow.postMessage(JSON.stringify(postMessageData), getIframeHost());
				}
			});
		}
	}, [iframeRef.current]);

	// useEffect(() => {
	// 	if (iframeStore.iframeForm) {
	//		!isProduction() && console.log(`Sending update post message to iFrame ${getIframeHost()}`, iframeStore.iframeForm);
	// 		iframeRef.current.contentWindow.postMessage(
	// 			JSON.stringify({
	// 				type: IframePostMessageTypes.InitPreview,
	// 				message: {
	// 					iframe: iframeStore.iframeForm,
	// 					fb_organization: getSelectedOrganization(),
	// 				},
	// 			}),
	// 			getIframeHost(),
	// 		);
	// 	}
	// }, [iframeStore.iframeForm]);

	// TODO: for smoother experience, replace this use effect with the one above
	//  the above commented code works
	//  but updateIframe function doesn't re-render updated elements such as TextToImage models dropdown
	useEffect(() => {
		if (iframeStore.iframeForm) {
			refreshIframe();
		}
	}, [iframeStore.iframeForm]);

	return (
		<Box className={styles.container}>
			<Box className={styles.iframePreviewWrapper}>
				<Box className={styles.headerWithButtons}>
					<Typography className={styles.subHeader}>{t("iframePreview")}</Typography>
					<BriaButton onClick={refreshIframe} buttonType="textSmall">
						{t("refreshBtn")}
					</BriaButton>
				</Box>
				<iframe
					id="iframePreview"
					key={iframeKey}
					src={getIframePreviewUrl()}
					className={styles.iframePreview}
					frameBorder="0"
					ref={iframeRef}
				></iframe>
			</Box>
		</Box>
	);
};

export default observer(NewIframePreview);
