import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { IframePostMessageTypes } from "../../../models/new-iframe.ts";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import styles from "./IframeCloseButton.module.scss";

const IframeCloseButton = () => {
	return iframeStore.isIframe() && iframeStore.iframe.config.general_config?.enable_close_window_btn ? (
		<CloseIcon
			onClick={() => {
				iframeStore.sendPostMessage(IframePostMessageTypes.CloseClicked);
			}}
			className={styles.closeIcon}
		/>
	) : (
		<></>
	);
};

export default IframeCloseButton;
