import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EmailButtonIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 25 24" {...props}>
			<path
				d="M3.98828 7H6.98828M3.98828 11H5.98828M10.7883 7.5L13.7703 10.78C14.0353 11.0715 14.3552 11.308 14.7116 11.476C15.068 11.6439 15.454 11.74 15.8475 11.7588C16.241 11.7775 16.6344 11.7186 17.0052 11.5853C17.3759 11.452 17.7168 11.247 18.0083 10.982L21.2883 8M10.0082 8.801L9.40824 14.801C9.38044 15.079 9.4112 15.3598 9.49855 15.6252C9.5859 15.8906 9.7279 16.1348 9.91539 16.342C10.1029 16.5492 10.3317 16.7148 10.5871 16.8281C10.8425 16.9414 11.1188 17 11.3982 17H19.3782C19.8742 17 20.3525 16.8157 20.7202 16.4829C21.0879 16.1501 21.3189 15.6925 21.3682 15.199L21.9682 9.199C21.996 8.92097 21.9653 8.64019 21.8779 8.37478C21.7906 8.10936 21.6486 7.86519 21.4611 7.65801C21.2736 7.45083 21.0448 7.28524 20.7894 7.17191C20.534 7.05857 20.2577 7.00001 19.9782 7H11.9982C11.5023 7.00002 11.024 7.18432 10.6563 7.51712C10.2885 7.84993 10.0576 8.30749 10.0082 8.801Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default EmailButtonIcon;
