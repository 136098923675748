function Campaigns(_size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path
				d="M11.933 5.5H5V21.5H18V13.5M14 17.5H9M15 5.5V3.5M18 6.5L20 4.5M19 9.5H21M9 13.5H14V9.5H9V13.5Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				stroke-miterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Campaigns;
