import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ANALYTICS_EVENTS } from "../../analytics-store.tsx";
import AIEditorPopup from "../../components/AIEditor/AIEditorPopup/AIEditorPopup.tsx";
import FoxGuidelinesPopup from "../../components/Popups/FoxGuidelinesPopup/FoxGuidelinesPopup.tsx";
import ImageUploadPopup from "../../components/Popups/ImageUploadPopup/ImageUploadPopup.tsx";
import { APPS } from "../../constants/AppsConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import { ImageToImageConfigType, PlaygroundImage } from "../../models/image-to-image.ts";
import FoxHeader from "../HeaderLayout/FoxHeader/FoxHeader.tsx";

const FoxLayout = () => {
	const { aiEditorStore, analyticsStore, objectsStore } = useAppStore();

	return (
		<>
			<FoxHeader />
			<AIEditorPopup />
			<ImageUploadPopup
				open={aiEditorStore.openImageUploadPopup}
				loading={aiEditorStore.isUploadingImage}
				onUpload={async (e) => {
					await aiEditorStore.handleUploadImageToEngine(e);
					const imageUuid = uuidv4();
					const imageId = new URL(aiEditorStore.selectedImageUrl).searchParams.get("imageId") ?? imageUuid;
					const analyticsImage: PlaygroundImage = {
						id: imageUuid,
						url: aiEditorStore.selectedImageUrl,
						type: APPS.IMAGE_TO_IMAGE,
						config: { ...objectsStore.refine } as ImageToImageConfigType,
						sessionId: aiEditorStore.selectedImageUrl.split("?")[0] + `?imageId=${imageId}`,
					};
					analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.PLAYGROUND_IMAGE_UPLOAD, analyticsImage);
				}}
				handleCloseClick={aiEditorStore.handleCloseImageUploadPopup}
			/>
			<FoxGuidelinesPopup />
			<Outlet />
		</>
	);
};

export default observer(FoxLayout);
