import { useCallback } from "react";
import { useAppStore } from "../hooks/useStores";
import { getRouteConfigs, hasOrganization } from "../utils";
import useSecureNavigate from "./useSecureNavigate.tsx";

type AnyFunction = (...args: any[]) => void;

const useOrgNavigation = () => {
	const { uiStore } = useAppStore();
	const navigate = useSecureNavigate();
	const hasOrg = hasOrganization();
	const isRouteRequireOrg = (route: string) => {
		const routeConfigs = getRouteConfigs(route);
		if (!routeConfigs) {
			return false;
		}
		return !!routeConfigs.requiresOrg && !hasOrg;
	};

	const isPrivateRoute = (route: string) => {
		const routeConfigs = getRouteConfigs(route);
		if (!routeConfigs) {
			return false;
		}
		return !!routeConfigs.private;
	};

	const orgNavigationHandler = useCallback(
		(url: string, additionalMethod?: AnyFunction, newTab = false) => {
			if (!isRouteRequireOrg(url)) {
				if (newTab) {
					window.open(url, "_blank");
				} else {
					navigate(url);
				}
			} else {
				uiStore.showDialog("CreateOrganizationDialog");
			}
			localStorage.setItem("destination_route", url);
			if (additionalMethod) {
				additionalMethod();
			}
		},
		[navigate, hasOrg, uiStore],
	);

	return { orgNavigationHandler, isRouteRequireOrg, isPrivateRoute };
};

export default useOrgNavigation;
