import { Box } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../assets/images/icons/CrownIcon.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { OrgFeatures } from "../../../models/billing.ts";
import BriaButton from "../BriaButton/BriaButton.tsx";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./BriaUpgradeOverlay.module.scss";

interface IProps {
	children: React.ReactNode;
	feature: OrgFeatures;
}

const BriaUpgradeOverlay: React.FC<IProps> = ({ children, feature }) => {
	const { t } = useTranslation("translation", { keyPrefix: "upgradeButton" });
	const { authStore } = useAppStore();
	const [showUpgrade, setShowUpgrade] = useState(false);

	useEffect(() => {
		if (!authStore.isLoadingOrgSubscriptions) {
			setShowUpgrade(!authStore.isFeatureEnabled(feature));
		}
	}, [authStore.isLoadingOrgSubscriptions]);

	return (
		<Box className={styles.container}>
			{(authStore.isLoadingOrgSubscriptions || showUpgrade) && (
				<Box className={styles.overlay}>
					<LoadingPlaceholder
						size={30}
						className={styles.loading}
						isLoading={authStore.isLoadingOrgSubscriptions}
					>
						<BriaButton
							buttonType="textSmall"
							className={styles.button}
							disableRipple
							onClick={() => window.open(`${RouterConstants.CONSOLE.path}?pricing=true`, "_blank")}
						>
							<CrownIcon /> {t("label")}
						</BriaButton>
					</LoadingPlaceholder>
				</Box>
			)}
			<Box className={clsx(styles.children, { [styles.upgrade]: showUpgrade })}>{children}</Box>
		</Box>
	);
};

export default BriaUpgradeOverlay;
