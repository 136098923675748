import { Box, useTheme } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Swiper, SwiperSlide } from "swiper/react";
import BriaImage, { BriaImageProps } from "../../../../components/common/Galleries/BriaImage.tsx";
import styles from "./SingleImageToImage.module.scss";

// @ts-expect-error "React couldn't see Navigation inside swiper/modules while I ensured it's there"
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";
import type { Swiper as SwiperClass } from "swiper/types";
import FullScreenViewer from "../../../../components/common/FullScreenViewer/FullScreenViewer.tsx";
import BriaCanvas from "../../../../components/common/Galleries/BriaCanvas.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../models/image-to-image.ts";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay.tsx";
import EraserDraggablePopup from "../EraserDraggablePopup/EraserDraggablePopup.tsx";
import { ANALYTICS_EVENTS } from "../../../../analytics-store.tsx";

type IProps = {
	className?: string;
	swiperClassName?: string;
};

const SingleImageToImage = ({ className, swiperClassName }: IProps) => {
	const theme = useTheme();
	const { playgroundStore, imageToImageStore, analyticsStore } = useAppStore();
	const images = playgroundStore.playgroundResults.flatMap((results) =>
		results.images.filter((image) => !image.errorType),
	) as (BriaImageProps & PlaygroundImage)[];
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const [imageIndexToView, setImageIndexToView] = useState<number>();
	const [numImages, setNumImages] = useState<number>(images.length);
	const [selectedImageIndex, setSelectedImageIndex] = useState<number>(images.findIndex((img) => img.selected));
	const [swiper, setSwiper] = useState<SwiperClass>();
	const loading = !!images.filter((image) => image.loading).length;
	const [isLoading, setIsLoading] = useState(false);

	const handleOpenFullScreen = (index: number) => {
		setImageIndexToView(index);
		setOpenFullScreen(true);
	};

	const handleCloseFullScreen = () => {
		setOpenFullScreen(false);
		playgroundStore.selectImages([images[selectedImageIndex]]);
	};

	const initSwiper = (swiper: SwiperClass) => {
		setSwiper(swiper);
		onSlideChange(swiper);
	};

	const slideTo = (index: number) => {
		if (swiper) swiper.slideTo(index);
		playgroundStore.selectImages([images[index]]);
		setSelectedImageIndex(index);
	};

	const onSlideChange = (swiper: SwiperClass) => {
		const activeImage = images[swiper.realIndex];
		playgroundStore.selectImages([activeImage]);
		setSelectedImageIndex(swiper.realIndex);
	};

	useEffect(() => {
		if (numImages !== images.length && swiper) {
			slideTo(images.length - 1);
		}
		setNumImages(images.length);
	}, [images, swiper]);

	useEffect(() => {
		if (playgroundStore.enablePlaygroundBrushMode) {
			setSwiper(undefined);
		}
	}, [playgroundStore.enablePlaygroundBrushMode]);

	return (
		<>
			<Box className={clsx(className, styles.container)}>
				{playgroundStore.enablePlaygroundBrushMode ? (
					<BriaCanvas
						key={0}
						url={images[selectedImageIndex]?.url ?? ""}
						enableBrush={true}
						brushConfigs={{
							...imageToImageStore.brushConfigs,
							strokeStyle: theme.palette.primary.main,
						}}
						isLoading={isLoading}
						onCanvasSizeUpdate={() => {
							setIsLoading(false);
						}}
						canvasRef={(canvas: HTMLCanvasElement | null) => {
							if (!imageToImageStore.brushCanvasRefs[0]) {
								imageToImageStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}

							imageToImageStore.brushCanvasRefs[0].canvasRef.current = canvas;
						}}
						canvasOverlayRef={(overlayCanvas: HTMLCanvasElement | null) => {
							if (!imageToImageStore.brushCanvasRefs[0]) {
								imageToImageStore.brushCanvasRefs[0] = {
									canvasRef: { current: null },
									canvasOverlayRef: { current: null },
								};
							}
							imageToImageStore.brushCanvasRefs[0].canvasOverlayRef.current = overlayCanvas;
						}}
					/>
				) : (
					<Swiper
						speed={1000}
						onSlideChange={onSlideChange}
						initialSlide={selectedImageIndex}
						onSwiper={initSwiper}
						className={clsx(swiperClassName, styles.swiper)}
						direction={"vertical"}
						spaceBetween={8}
						slidesPerView={1}
						mousewheel={true}
						loop={false}
						keyboard={{ enabled: true }}
						pagination={{ clickable: false }}
						modules={[Navigation, Keyboard, Mousewheel]}
					>
						{images.map((image, index) => {
							return (
								<SwiperSlide className={styles.swiperSlideContainer} key={index}>
									<BriaImage
										{...image}
										ImageOverlay={<PlaygroundImageOverlay {...{ image }} />}
										displayOverlay={"customHover"}
										selectable={true}
										htmlJsonData={image.id}
										fullScreenProps={{
											...image.fullScreenProps,
											fileName: playgroundStore.getFileName(image),
										}}
										onSuccessPulling={async () => {
											index === swiper?.realIndex && playgroundStore.selectImages([image]);
											playgroundStore.onSuccessResult(image);
										}}
										onErrorPulling={(errorType) => playgroundStore.onErrorResult(image, errorType)}
										async={loading}
										asyncProps={{ maxAttempts: 360 }}
										downloadProps={{
											...image.downloadProps,
											fileName: playgroundStore.getFileName(image),
										}}
										handleFullScreen={() => handleOpenFullScreen && handleOpenFullScreen(index)}
										lazyImageProps={{ ...image.lazyImageProps }}
										image={image}
										hideDownloadIcon={false}
										skeletonClassName={styles.briaSkeletonWrapper}
										wrapperClassName={styles.briaWrapper}
										className={styles.briaImage}
									/>
								</SwiperSlide>
							);
						})}
					</Swiper>
				)}
			</Box>
			<EraserDraggablePopup
				open={playgroundStore.enablePlaygroundBrushMode}
				onClose={() => playgroundStore.togglePlaygroundBrushMode()}
				handleEraseClick={() => {
					playgroundStore.togglePlaygroundBrushMode();
					imageToImageStore.eraseObject(images[selectedImageIndex]);
					analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_ERASE);
				}}
				brushConfig={imageToImageStore.brushConfigs}
			/>
			{openFullScreen && (
				<FullScreenViewer
					open={openFullScreen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageIndexToView}
					onClose={handleCloseFullScreen}
				/>
			)}
		</>
	);
};

export default observer(SingleImageToImage);
