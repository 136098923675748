import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EmptyListIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="42" height="41" viewBox="0 0 42 41" fill="none" {...props}>
			<path
				d="M33.0997 17.0833H8.89844M8.89844 10.25H33.0997M24.4564 23.9167H8.89844M8.89844 30.75H19.2704M31.3711 25.625V35.875M26.1851 30.75H36.5571"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default EmptyListIcon;
