import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, CircularProgress, TextField } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../../../../components/common/BriaButton/BriaButton.tsx";
import TextColorPicker, {
	ColorsPickerEnum,
} from "../../../../../../../../../components/common/TextColorPicker/TextColorPicker.tsx";
import { useAppStore } from "../../../../../../../../../hooks/useStores.tsx";
import { useEditor } from "../../../../../../../../hooks/useEditor.tsx";
import { Image } from "../../../../../../../../models/image.ts";
import { useSmartImageUtils } from "../../../../../../utils/smartImageUtils.ts";
import { BackgroundPanelEnum, BackgroundRoute } from "../../../index.ts";
import styles from "./ReplaceBackground.module.scss";

interface Iprops {
	addImageObject: (image: Image) => void;
}
const ReplaceBackground = ({ addImageObject }: Iprops) => {
	const editor = useEditor();
	const { imagesStore } = useAppStore();
	const { getSmartImageSize } = useSmartImageUtils();
	const [expanded, setExpanded] = useState<string | false>(false);
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });
	const isGenerateButtonDisabled = imagesStore.isUploadingSmartImageMainObject;

	const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	const handleGenerateBackground = async () => {
		const currentScene = editor && editor.scene.exportToJSON();
		if (!currentScene) return;
		const smartImageSize = await getSmartImageSize(currentScene);
		if (smartImageSize) {
			await imagesStore.generateOrExpandImageBackground(
				BackgroundRoute.Replace,
				smartImageSize,
				imagesStore.smartImageForm,
			);
			addImageObject(imagesStore.smartImagesHistory[0]);
		}
	};

	return (
		<Box className={styles.replaceBackgroundContainer}>
			<Accordion
				className={clsx({
					[styles.accordion]: true,
				})}
				elevation={0}
				disableGutters
				TransitionProps={{ timeout: 200 }}
				expanded={expanded === BackgroundPanelEnum.GeneratePanel}
				onChange={handleChange(BackgroundPanelEnum.GeneratePanel)}
			>
				<AccordionSummary className={styles.accordionTitle} color="initial" expandIcon={<ExpandMore />}>
					{t("smartImage.background.generateTitle")}
				</AccordionSummary>

				<Box className={styles.colorWrapper}>
					<>
						{imagesStore.isGeneratingImages && (
							<>
								<Box className={styles.blurLayer} />
								<Box className={styles.generateloader}>
									<CircularProgress size={24} />
								</Box>
							</>
						)}
					</>
					<Box className={styles.bigTextFieldContainer}>
						<TextField
							value={imagesStore.smartImageForm.backgroundDescription}
							onChange={(e) => {
								if (imagesStore.smartImageForm.colorCode.trim() !== "") {
									imagesStore.handleSmartImageChange("colorCode", "");
								}
								imagesStore.handleSmartImageChange("backgroundDescription", e.target.value as string);
							}}
							placeholder={t("smartImage.bgDescription")}
							fullWidth
							multiline
							minRows={3}
							InputProps={{
								classes: {
									root: styles.bigTextField,
								},
							}}
						/>
						<BriaButton
							className={styles.primaryButton}
							buttonType="primary"
							onClick={handleGenerateBackground}
							disabled={
								(expanded === BackgroundPanelEnum.GeneratePanel &&
									imagesStore.smartImageForm.backgroundDescription.trim() === "") ||
								isGenerateButtonDisabled
							}
						>
							{t("smartImage.generateButton")}
						</BriaButton>
					</Box>
				</Box>
			</Accordion>
			<Box className={styles.separator_20} />
			<Accordion
				className={clsx({
					[styles.accordion]: true,
				})}
				elevation={0}
				disableGutters
				TransitionProps={{ timeout: 200 }}
				expanded={expanded === BackgroundPanelEnum.SolidPanel}
				onChange={handleChange(BackgroundPanelEnum.SolidPanel)}
			>
				<AccordionSummary className={styles.accordionTitle} color="initial" expandIcon={<ExpandMore />}>
					{t("smartImage.background.solidTitle")}
				</AccordionSummary>
				<Box className={styles.colorWrapper}>
					{imagesStore.isGeneratingImages && (
						<>
							<Box className={styles.blurLayer} />
							<Box className={styles.solidLoader}>
								<CircularProgress size={24} />
							</Box>
						</>
					)}

					<TextColorPicker
						withPadding={false}
						color={imagesStore.smartImageForm.colorCode}
						onColorChange={(color) => {
							if (imagesStore.smartImageForm.backgroundDescription.trim() !== "") {
								imagesStore.handleSmartImageChange("backgroundDescription", "");
							}
							imagesStore.handleSmartImageChange("colorCode", color as string);
						}}
						colorPickerVersion={ColorsPickerEnum.V2}
					/>
					<BriaButton
						onClick={handleGenerateBackground}
						className={styles.primaryButton}
						buttonType="primary"
						disabled={
							(expanded === BackgroundPanelEnum.SolidPanel &&
								imagesStore.smartImageForm.colorCode.trim() === "") ||
							isGenerateButtonDisabled
						}
					>
						{t("smartImage.generateButton")}
					</BriaButton>
				</Box>
			</Accordion>
			<Box className={styles.separator_20} />
		</Box>
	);
};

const ObservedComponent = observer(ReplaceBackground);
export default ObservedComponent;
