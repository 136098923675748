import React, { RefObject } from "react";

function useOutsideClick<T extends HTMLElement = HTMLElement>(
	ref: RefObject<T>,
	callback: () => void
) {
	const handleClick = (e: Event) => {
		if (ref.current && !ref.current.contains(e.target as Node)) {
			callback();
		}
	};

	React.useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
}

export default useOutsideClick;
