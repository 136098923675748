import { WarningAmberRounded } from "@mui/icons-material";
import { Box, CircularProgress, Collapse, Grid, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Trans, useTranslation } from "react-i18next";
import ModelAccess from "../../../components/Models/ModelAccess/ModelAccess.tsx";
import { ModelCardComponent } from "../../../components/Models/ModelCardComponent";
import AlertNotification from "../../../components/common/Alert/Alert.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";
import { ModelCardType, ModelCards, ModelIntros } from "../../../constants/ModelCardsConstants";
import { useAppStore } from "../../../hooks/useStores.tsx";
import styles from "./BriaModels.module.scss";

interface IProps {
	type?: ModelCardType;
}

const BriaModels = ({ type = ModelCardType.FOUNDATION_MODEL }: IProps) => {
	const { appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "models_page" });

	const BriaIntro = ({ type }: IProps) => {
		const modelIntro = ModelIntros.find((model) => model.type === type);
		return (
			<Box className={styles.introduction}>
				<Typography className={styles.title}>{t(modelIntro?.title ?? "")}</Typography>
				<Box className={styles.descriptionsBox}>
					{modelIntro?.descriptions.map((desc) => (
						<Typography className={styles.description}>
							<Trans i18nKey={t(desc)} />
						</Typography>
					))}
				</Box>
			</Box>
		);
	};

	function getAlertBox() {
		return (
			// !appStore.dismissWarningAgentMessage this prop will control the collapse in future
			<Collapse in={false}>
				<AlertNotification
					className={styles.alert}
					severity="warning"
					bgColor="#FFF"
					boxShadow="0px 0px 10px 5px rgba(50, 50, 93, 0.05);"
					action={
						<BriaButton
							buttonType={appStore.agentInstallation?.isInstalled ? "textSmall" : "primaryMedium"}
							className={clsx({
								[styles.dismissButton]: true,
								[styles.blueButton]:
									appStore.agentInstallation && appStore.agentInstallation.isInstalled,
							})}
							onClick={() => {
								if (!appStore.agentInstallation) {
									appStore.verifyAgentInstallation();
								} else if (!appStore.agentInstallation.isInstalled) {
									window.open(LinkConstants.BRIA_AGENT, "_blank");
								} else if (appStore.agentInstallation.isInstalled) {
									appStore.dismissWarningAgentMessage = true;
								}
							}}
						>
							{!appStore.agentInstallation
								? t("notifications.buttons.verifyAgent")
								: appStore.agentInstallation.isInstalled
								? t("notifications.buttons.dismiss")
								: t("notifications.buttons.installAgent")}

							{appStore.isVerifyingAgentInstallation && (
								<Box className={styles.circleLoader}>
									{appStore.isVerifyingAgentInstallation && (
										<CircularProgress color="inherit" size={25} />
									)}
								</Box>
							)}
						</BriaButton>
					}
					icon={<WarningAmberRounded style={{ fill: "#FFC107" }} />}
				>
					{!appStore.agentInstallation ? t("notifications.mainMessage") : appStore.agentInstallation.message}
				</AlertNotification>
			</Collapse>
		);
	}

	return (
		<Box className={styles.container}>
			{getAlertBox()}
			<BriaIntro type={type} />
			{type === ModelCardType.ACCESS_MODEL ? (
				<ModelAccess />
			) : (
				<Grid container spacing={4}>
					{ModelCards.filter((model) => model.type === type).map((card: any, index: number) => (
						<ModelCardComponent key={index} {...card} />
					))}
				</Grid>
			)}
		</Box>
	);
};

export default observer(BriaModels);
