import { Box, Divider } from "@mui/material";
import clsx from "clsx";
import { throttle } from "lodash";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import StopIcon from "../../../../assets/images/icons/StopIcon.tsx";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import { IGroupOption } from "../../../../components/common/CustomSingleSelectDropdown/SingleSelectGroupedDropDown";
import GenericInput from "../../../../components/common/GenericInput/GenericInput.tsx";
import { APPS } from "../../../../constants/AppsConstants.ts";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { BrushActions } from "../../../../hooks/brush/useBrushActions.tsx";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import { imageDimensions } from "../../../../models/image-to-image.ts";
import {
	APIParameter,
	ImageGenerationsSubAPIType,
	SandboxSubAPITypes,
	modelTypes,
} from "../../../../models/sandboxAPI.ts";
import { isCanvasRequiredForThisApp, truncateToTwoDecimals } from "../../../../utils";
import { validateField } from "../../../../utils/validators";
import APIFamilyDropdown from "../APIFamilyDropdown/APIFamilyDropdown";
import styles from "./SandboxAPIConfig.module.scss";

export enum PlacementTypeEnum {
	Original = "original",
	Automatic = "automatic",
	ManualPlacement = "manual_placement",
	ManualPadding = "manual_padding",
}

export enum ShadowTypeEnum {
	Regular = "regular",
	Float = "float",
}

const SandboxAPIConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI" });
	const navigate = useSecureNavigate();
	const { sandboxAPIStore, playgroundStore, tailoredGenerationStore } = useAppStore();
	const [payload, setPayload] = useState<Record<string, any>>({});
	const { subAPI, model } = useParams<{ subAPI: SandboxSubAPITypes; model: string }>();

	useEffect(() => {
		if (!sandboxAPIStore.config.apiConfig) {
			navigate(`/${RouterConstants.PAGE_NOT_FOUND.path}`, { replace: true });
		}

		playgroundStore.clearResults();
		playgroundStore.handleAppChange(APPS.SANDBOX_API);

		return () => {
			playgroundStore.clearResults();
			playgroundStore.handleAppChange(APPS.TEXT_TO_IMAGE);
			sandboxAPIStore.config.selectedSubAPI = undefined;
		};
	}, []);

	useEffect(() => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		const initialPayload: Record<string, any> = {};

		if (model) {
			handelModelChange(model);
		}

		// Check if there is a prompt value to be used form the promptEnahncement app
		if (
			subAPI === ImageGenerationsSubAPIType.GenerateImage ||
			subAPI === ImageGenerationsSubAPIType.GenerateVector
		) {
			const promptField = sandboxAPIStore.config.apiConfig?.parameters.find((item) => item.name === "prompt");
			if (promptField && sandboxAPIStore.textResultToUse !== "") {
				promptField.defaultValue = sandboxAPIStore.textResultToUse;
			}
		}

		parameters?.forEach((field: APIParameter) => {
			if (field.defaultValue !== undefined) {
				initialPayload[field.name] = field.defaultValue;
			} else if (field.type === "intArray" && field.numberOfInputs) {
				initialPayload[field.name] = Array.from({ length: field.numberOfInputs });
			} else {
				initialPayload[field.name] = null;
			}
		});

		setPayload(() => ({
			...initialPayload,
		}));
	}, [sandboxAPIStore.config.selectedSubAPI]);

	useEffect(() => {
		if (sandboxAPIStore.config.apiConfig?.id === "IMG_MODIFICATIONS_API_3" && playgroundStore.singleSelectedImage) {
			handleExpandImageApiDimensionsUpdate();
		}
	}, [playgroundStore.selectedSingleImage]);

	const handelModelChange = async (value: string) => {
		const { selectedSubAPI, apiConfig } = sandboxAPIStore.config;

		if (!Object.values(modelTypes).includes(model as modelTypes)) {
			navigate(`/${RouterConstants.PAGE_NOT_FOUND.path}`, { replace: true });
			return;
		}

		if (!apiConfig) return;
		let { pathParameter } = apiConfig;

		const parameters = apiConfig.parameters;
		const getFieldByName = (name: string) => parameters.find((field) => field.name === name);

		const modelField = getFieldByName("model");
		const stepsNumField = getFieldByName("steps_num");
		const promptEnhancementField = getFieldByName("prompt_enhancement");
		const textGuidanceScaleField = getFieldByName("text_guidance_scale");
		const negativePromptField = getFieldByName("negative_prompt");
		const modelInfluenceField = getFieldByName("model_influence");
		const guidanceMethodsField = getFieldByName("guidance_methods");

		if (!modelField || !stepsNumField || !pathParameter) return;

		const setStepsNumField = (defaultValue: number, min: number, max: number) => {
			stepsNumField.defaultValue = defaultValue;
			stepsNumField.min = min;
			stepsNumField.max = max;
			stepsNumField.labeledMarks = [
				{ value: min, label: String(min) },
				{ value: max, label: String(max) },
			];
			setPayload((prevPayload) => ({ ...prevPayload, steps_num: defaultValue }));
		};

		const isFast = value === "fast";
		const isHD = value === "hd";
		const isTailored = value === "tailored";
		const isVector = selectedSubAPI === ImageGenerationsSubAPIType.GenerateVector;

		modelInfluenceField && (modelInfluenceField.hidden = !isTailored);
		textGuidanceScaleField && (textGuidanceScaleField.hidden = (isVector && isFast) || isTailored);
		negativePromptField && (negativePromptField.hidden = isFast || isTailored);
		guidanceMethodsField && (guidanceMethodsField.hidden = isHD);
		promptEnhancementField && (promptEnhancementField.hidden = isTailored);
		if (isFast) {
			setStepsNumField(8, 4, 10);
			setPayload((prevPayload) => ({
				...prevPayload,
				steps_num: 8,
			}));
		} else if (isTailored) {
			setStepsNumField(8, 4, 20);
			setPayload((prevPayload) => ({
				...prevPayload,
				steps_num: 8,
			}));
		} else {
			setStepsNumField(30, 20, 50);
			setPayload((prevPayload) => ({
				...prevPayload,
				steps_num: 30,
			}));
		}

		let tag = "Image-Generation";
		modelField.defaultValue = value === "hd" ? `${value}_2.2` : `${value}_2.3`;
		if (isTailored) {
			modelField.defaultValue = pathParameter.selected_model || "0";
			tag = "Tailored-Generation";
		}

		apiConfig.docLink = pathParameter.initialDocLink.replace("${model}", value).replace("${tag}", tag);

		pathParameter.model = value;
	};

	const handleColorChange = (field: APIParameter, value: string) => {
		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value);
		}

		setPayload((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));
	};

	const handleExpandImageApiDimensionsUpdate = throttle((index?: number, value?: number) => {
		const originalImageSize = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "original_image_size",
		);

		if (!originalImageSize) return;

		const isConstAspectRatio = payload["constrain_proportions"];
		const imageUrl = playgroundStore.getSelectedImages()?.[0]?.url;
		const cachedDimensions = playgroundStore.getSelectedImages()[0]?.dimensions;

		if (isConstAspectRatio && imageUrl) {
			const updatePayload = (dimensions: imageDimensions) => {
				setPayload((prevPayload) => {
					const updatedPayload = { ...prevPayload };

					if (index !== undefined && value !== undefined) {
						updatedPayload[originalImageSize.name] =
							index === 0
								? [value, truncateToTwoDecimals(value / dimensions.numerical_aspect_ratio)]
								: [truncateToTwoDecimals(value * dimensions.numerical_aspect_ratio), value];
					} else {
						updatedPayload[originalImageSize.name] = [dimensions.width, dimensions.height];
					}

					return updatedPayload;
				});
			};

			if (cachedDimensions) {
				updatePayload(cachedDimensions);
			} else {
				const img = new Image();
				img.onload = () => {
					const dimensions = {
						width: img.width,
						height: img.height,
						numerical_aspect_ratio: img.width / img.height,
					};
					playgroundStore.getSelectedImages()[0].dimensions = dimensions;
					updatePayload(dimensions);
				};
				img.src = imageUrl;
			}
		} else if (index !== undefined && value !== undefined) {
			setPayload((prevPayload) => {
				const updatedPayload = { ...prevPayload };
				updatedPayload[originalImageSize.name][index] = value;
				return updatedPayload;
			});
		}
	}, 200);

	const handleIntArrayChange = (field: APIParameter, index: number, value: number | null) => {
		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value);
		}

		if (
			value !== null &&
			sandboxAPIStore.config.apiConfig?.id === "IMG_MODIFICATIONS_API_3" &&
			field.name === "original_image_size"
		) {
			handleExpandImageApiDimensionsUpdate(index, value);
		} else {
			setPayload((prevPayload) => {
				// To initiate the updated inputs that were hidden
				if (prevPayload[field.name] === undefined) {
					prevPayload[field.name] = field.defaultValue;
				}
				prevPayload[field.name][index] = value;
				return { ...prevPayload };
			});
		}
	};

	const handlePlacementType = (placement: string) => {
		const manualPlacement = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "manual_placement_selection",
		);
		const paddingValues = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "padding_values",
		);
		const numResults = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "num_results");
		const shotSize = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "shot_size");
		const originalQuality = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "original_quality",
		);
		if (!manualPlacement || !paddingValues || !numResults || !shotSize || !originalQuality) return;
		shotSize.hidden = placement === PlacementTypeEnum.Original || placement === PlacementTypeEnum.ManualPadding;
		numResults.hidden =
			placement === PlacementTypeEnum.Automatic || placement === PlacementTypeEnum.ManualPlacement;
		manualPlacement.hidden = placement !== PlacementTypeEnum.ManualPlacement;
		paddingValues.hidden = placement !== PlacementTypeEnum.ManualPadding;
		originalQuality.hidden = placement !== PlacementTypeEnum.Original;
	};

	const handleShadowType = (type: string) => {
		const shadowWidth = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "shadow_width");
		const shadowHeight = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "shadow_height",
		);
		if (!shadowWidth || !shadowHeight) return;

		switch (type) {
			case ShadowTypeEnum.Regular:
				shadowWidth.hidden = true;
				shadowHeight.hidden = true;
				break;
			case ShadowTypeEnum.Float:
				shadowWidth.hidden = false;
				shadowHeight.hidden = false;
				break;
		}
	};

	const handleGenerationMode = (mode: boolean) => {
		const negativePromptField = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "negative_prompt",
		);

		const excludeElementsField = sandboxAPIStore.config.apiConfig?.parameters.find(
			(field) => field.name === "exclude_elements",
		);

		negativePromptField && (negativePromptField.hidden = mode);
		excludeElementsField && (excludeElementsField.hidden = mode);
	};

	const handleInputChange = (field: APIParameter, e: ChangeEvent<HTMLInputElement>) => {
		const apiConfig = sandboxAPIStore.config.apiConfig;
		if (!apiConfig) {
			return;
		}

		let value: string | number | boolean | null;
		const type = e.target.type;
		switch (type) {
			case "checkbox":
				value = e.target.checked;
				break;
			case "number":
				if (e.target.value === null || e.target.value === "") {
					value = null;
				} else {
					value = isNaN(Number(e.target.value)) ? "" : Number(e.target.value);
				}
				break;
			default:
				value = e.target.value;
		}

		switch (field.name) {
			case "brush_size":
				if (typeof value === "number") {
					sandboxAPIStore.brushConfigs.lineWidth = value;
				}
				break;
			case "placement_type":
				if (apiConfig.id === "ECOMMERCE_API_4") {
					handlePlacementType(value as string);
				}
				break;
			case "type":
				if (apiConfig.id === "ECOMMERCE_API_3") {
					handleShadowType(value as string);
				}
				break;
			case "fast":
				if (apiConfig.id === "IMG_MODIFICATIONS_API_4" || apiConfig.id === "ECOMMERCE_API_4") {
					handleGenerationMode(value as boolean);
				}
				break;
		}

		if (field.validationSchema) {
			field.validationMessage = validateField(field.validationSchema, value);
		}

		setPayload((prevPayload) => ({
			...prevPayload,
			[field.name]: value,
		}));
	};

	const handleTailoredModelChange = async (e: React.ChangeEvent<HTMLSelectElement>, selected: IGroupOption) => {
		const value = e.target.value;
		const apiConfig = sandboxAPIStore.config.apiConfig;
		if (!apiConfig) return;

		const modelField = sandboxAPIStore.config.apiConfig?.parameters.find((field) => field.name === "model");
		let { pathParameter } = apiConfig;
		if (pathParameter && modelField) {
			pathParameter.model = value.split("_")[0];
			if (pathParameter.model !== "base" && pathParameter.model !== "fast" && pathParameter.model !== "hd") {
				pathParameter.model = `tailored`;
			}
			modelField.defaultValue = value;
			pathParameter.model_id = selected.extraData?.model_id;
			pathParameter.selected_model = value;

			handelModelChange(pathParameter.model);
			const newPath = sandboxAPIStore.isConsole
				? `${RouterConstants.API.fullPath}/${subAPI}/${pathParameter.model}`
				: `/${RouterConstants.APPS.path}/${subAPI}/${pathParameter.model}`;
			navigate(newPath, { replace: true });
		} else {
			const modelField = sandboxAPIStore.config.apiConfig?.parameters.find(
				(field) => field.name === "tailored_model_id",
			);
			if (modelField) {
				modelField.defaultValue = value;
			}
			setPayload((prevPayload) => ({
				...prevPayload,
				["tailored_model_id"]: selected.extraData?.model_id,
			}));
		}
	};

	const generateResults = async (action?: string): Promise<void> => {
		const parameters = sandboxAPIStore.config.apiConfig?.parameters;
		let finalPayload: Record<string, any> = {};

		parameters?.forEach((field: APIParameter) => {
			const value = payload[field.name];

			const ruleSet = field.validationSchema;
			if (ruleSet) {
				if (field.type === "intArray") {
					(value as number[]).some((item) => {
						field.validationMessage = validateField(ruleSet, item);
						return !!field.validationMessage;
					});
				} else {
					field.validationMessage = validateField(ruleSet, value);
				}
			}

			if (field.type === "guidanceMethods" && sandboxAPIStore.config.apiConfig?.guidanceMethodsPayload) {
				let guidanceMethodsPayload = sandboxAPIStore.getGuidanceMethodsPayload();
				if (guidanceMethodsPayload.length) finalPayload["guidance_methods"] = guidanceMethodsPayload;
			}

			if (value === null || value === undefined || value === "" || field.hidden || field.type === "imageUpload") {
				return;
			}

			if (field.type === "intArray") {
				finalPayload[field.name] = (value as Array<number | null>).map((item) => (item === null ? 0 : item));
				return;
			}

			finalPayload[field.name] = value;
		});

		if (parameters?.some((item) => item?.validationMessage)) {
			return;
		}

		if (sandboxAPIStore.config.selectedSubAPI === ImageGenerationsSubAPIType.promptEnhancement) {
			action = ImageGenerationsSubAPIType.promptEnhancement;
		}

		await sandboxAPIStore.generateResults(JSON.stringify(finalPayload), action);
	};

	const isDisabled = (): boolean => {
		if (!playgroundStore.getSelectedImages().length && sandboxAPIStore.hasImageUpload) {
			return true;
		}

		let validationError = false;
		validationError = !!sandboxAPIStore.config.apiConfig?.parameters?.some((field: APIParameter) => {
			const value = payload[field.name];
			if (field.type === "intArray" && value) {
				return (value as number[]).some(() => {
					return !!field.validationMessage;
				});
			} else {
				return !!field.validationMessage;
			}
		});
		if (validationError) {
			return true;
		}

		const isLoading = playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);

		if (isLoading) {
			return true;
		}

		const pathParameter = sandboxAPIStore.config.apiConfig?.pathParameter;
		if (model === "tailored" && pathParameter && tailoredGenerationStore.isLoading) {
			return true;
		}

		const requiredFields = sandboxAPIStore.config.apiConfig?.parameters.filter((item) => item.required);
		return requiredFields?.find(
			(item) => payload[item.name] === null || payload[item.name] === undefined || payload[item.name] === "",
		)
			? true
			: false;
	};

	const handleButtonClick = async (action: string): Promise<void> => {
		switch (action) {
			case "erase_object":
				if (sandboxAPIStore.brushCanvasRefs.length > 0 && sandboxAPIStore.brushCanvasRefs[0].canvasRef) {
					const brushActions = new BrushActions(
						sandboxAPIStore.brushCanvasRefs[0].canvasRef.current,
						sandboxAPIStore.brushCanvasRefs[0].canvasOverlayRef.current,
					);
					const imageUrl = playgroundStore.playgroundResults[0].images[0].url;
					const maskFileBase64 = await brushActions.handleCanvasDownload();
					if (maskFileBase64 && playgroundStore.playgroundResults.length > 0) {
						const newImageUrl = await sandboxAPIStore.eraseImageObject(imageUrl, maskFileBase64);
						if (newImageUrl) {
							playgroundStore.playgroundResults[0].isFromUpload = false;
							playgroundStore.playgroundResults[0].images[0].url = newImageUrl;
						}
					}
				}
				break;

			case "reset_image":
				if (sandboxAPIStore.uploadImageSrc) {
					playgroundStore.playgroundResults[0].isFromUpload = true;
					playgroundStore.playgroundResults[0].images[0].url = sandboxAPIStore.uploadImageSrc;
				}
		}
	};

	const handleTiloredModelsSuccess = (_foundationModels: IGroupOption[], tailoredModels: IGroupOption[]) => {
		const pathParameter = sandboxAPIStore.config.apiConfig?.pathParameter;
		if (tailoredModels?.length && pathParameter) {
			pathParameter.model_id = tailoredModels[0]?.id;
		}
	};

	return (
		<Box key={sandboxAPIStore.config.selectedSubAPI} className={styles.sandboxConfigWrapper}>
			<Box className={styles.sandboxConfig}>
				<Box className={styles.sandboxConfigFields}>
					<APIFamilyDropdown />
					<Divider />
					{sandboxAPIStore.config.apiConfig?.parameters?.map((field: any) => {
						if (field.type === "imageUpload") return null;
						if (field.type === "button") {
							return (
								!field.hidden && (
									<InputLayout
										label={field.label}
										className={clsx({
											[styles[field.className]]: true,
										})}
										labelClassName={styles.inputLabel}
										info={field.info}
									>
										{sandboxAPIStore.isGeneratingResults ? (
											<BriaButton
												buttonType={field.buttonType}
												onClick={() => sandboxAPIStore.abortResultsGeneration()}
												className={styles.stopBtnWithIcon}
											>
												<StopIcon /> {t("stop")}
											</BriaButton>
										) : (
											<BriaButton
												buttonType={field.buttonType}
												onClick={() => {
													generateResults(field.action);
												}}
												disabled={isDisabled()}
											>
												{field.name}
											</BriaButton>
										)}
									</InputLayout>
								)
							);
						}
						const inputProps = {
							...field,
							onChange: (e: ChangeEvent<HTMLInputElement>) => {
								handleInputChange(field, e);
							},
							...(field.type === "color" && {
								onColorChange: (color: string) => handleColorChange(field, color),
							}),
							...(field.type === "intArray" && {
								onIntArrayChange: (index: number, value: number | null) =>
									handleIntArrayChange(field, index, value),
							}),
							...(field.type === "buttonsGroup" && {
								onButtonClick: (fieldName: string) => {
									handleButtonClick(fieldName);
								},
							}),
							...(field.type === "tailored_models" && {
								onTailoredModelChange: handleTailoredModelChange,
								onSuccess: handleTiloredModelsSuccess,
							}),
						};
						return (
							<GenericInput
								key={sandboxAPIStore.config.selectedSubAPI + field.name}
								value={payload[field.name]}
								{...inputProps}
							/>
						);
					})}
				</Box>
			</Box>
			{sandboxAPIStore.config.apiConfig &&
				!sandboxAPIStore.config.apiConfig.parameters?.some((field: any) => field.type === "button") && (
					<>
						{sandboxAPIStore.isGeneratingResults ? (
							<BriaButton
								className={clsx(styles.stopButton, styles.stopBtnWithIcon)}
								buttonType="primaryMedium"
								fullWidth
								onClick={() => sandboxAPIStore.abortResultsGeneration()}
							>
								<StopIcon /> {t("stop")}
							</BriaButton>
						) : (
							sandboxAPIStore.config.selectedSubAPI &&
							!isCanvasRequiredForThisApp() && (
								<BriaButton
									onClick={() => {
										generateResults();
									}}
									className={clsx({
										[styles.button]: true,
										[styles.bottomGenerate]: true,
									})}
									disabled={isDisabled()}
									buttonType="primaryMedium"
								>
									{!playgroundStore.singleSelectedImage && sandboxAPIStore.hasImageUpload
										? t("noImagesSelected")
										: t("generate")}
								</BriaButton>
							)
						)}
					</>
				)}
		</Box>
	);
};

export default observer(SandboxAPIConfig);
