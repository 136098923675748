import { Box, ToggleButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import BriaToggleButtonGroup from "../../components/common/BriaToggleButtonGroup/BriaToggleButtonGroup.tsx";
import { AspectRatio } from "../../components/common/DropDowns/StaticDropdown";
import { APPS } from "../../constants/AppsConstants.ts";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores";
import { ImageEditingViewMode } from "../../models/image-to-image.ts";
import { ImageGenerationsSubAPIType } from "../../models/sandboxAPI.ts";
import { TextToImageConfigType } from "../../models/text-to-image.ts";
import { isFoxApps } from "../../utils/index.ts";
import iframeStore from "../IframeNew/iframe-store.tsx";
import PlaygroundImages from "./Images/PlaygroundImages";
import styles from "./Playground.module.scss";
import PlaygroundTextResults from "./TextResults/PlaygroundTextResults";

interface IPlayground {
	selectedConfig?: APPS;
	location?: {
		state: {
			prompt?: string;
			aspectRatio?: AspectRatio;
		};
	};
}

const Playground = ({ location: propLocation, selectedConfig }: IPlayground) => {
	const [playgroundClass, setPlaygroundClass] = useState("");
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	const { t: appsTrans } = useTranslation("translation", { keyPrefix: "platformHub.Cards" });
	const { playgroundStore, uiStore, textToImageStore, sandboxAPIStore } = useAppStore();
	const location = propLocation || useLocation();
	const images = playgroundStore.playgroundResults.flatMap((results) =>
		results.images.filter((image) => !image.errorType),
	);
	const isSingleMode = playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE;
	const navigateOptions = location.state;
	const generateFromNavigation = !!(
		navigateOptions &&
		navigateOptions.prompt &&
		((textToImageStore.config.model && textToImageStore.config.model_version) || textToImageStore.config.model_url)
	);

	useEffect(() => {
		uiStore.hideSideBar();
		if (selectedConfig) {
			playgroundStore.selectedConfig = selectedConfig;
		}
		if (isFoxApps() || playgroundStore.selectedConfig !== APPS.IMAGE_TO_IMAGE) {
			playgroundStore.enablePlaygroundBrushMode = false;
			playgroundStore.imageEditingViewMode = ImageEditingViewMode.GALLERY;
		}

		if (window.location.pathname.includes(RouterConstants.CONSOLE.path)) {
			setPlaygroundClass(styles.apiConsleSandbox);
		} else if (playgroundStore?.isText2ImagePopupOpened) {
			setPlaygroundClass(styles.rootModal);
		} else {
			setPlaygroundClass(styles.root);
		}

		if (
			playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE ||
			(playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE && !textToImageStore.isTextToVector())
		) {
			// clear ImageToImage or TextToImage results if coming from TextToVector
			if (images.some((image) => (image.config as TextToImageConfigType)?.format === "svg")) {
				playgroundStore.clearResults();
			}
		} else if (playgroundStore.selectedConfig === APPS.TEXT_TO_VECTOR || textToImageStore.isTextToVector()) {
			// clear TextToVector results if coming from ImageToImage or TextToImage
			if (
				images.some(
					(image) =>
						image.type === APPS.IMAGE_TO_IMAGE || (image.config as TextToImageConfigType)?.format !== "svg",
				)
			) {
				playgroundStore.clearResults();
			}
		}

		const isImageToImage = playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && !isFoxApps();
		(playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE || isImageToImage) &&
			uiStore.showBackButton(
				RouterConstants.APPS.path,
				undefined,
				t(`${playgroundStore.selectedConfig}.backBtn`),
				undefined,
				undefined,
				undefined,
				isImageToImage
					? appsTrans(`imageToImage.title`)
					: textToImageStore.isTextToVector()
					? appsTrans(`textToVector.title`)
					: appsTrans(`textToImage.title`),
			);

		playgroundStore.selectedConfig === APPS.PRODUCT_PLACEMENT &&
			uiStore.showBackButton(
				`/${RouterConstants.PRODUCT_PLACEMENT_GALLERY.fullPath}`,
				undefined,
				t(`${playgroundStore.selectedConfig}.backBtn`),
				undefined,
				undefined,
				undefined,
				t(`${playgroundStore.selectedConfig}.title`),
			);

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, [playgroundStore.selectedConfig]);

	useEffect(() => {
		if (sandboxAPIStore.isConsole) {
			sandboxAPIStore.config.selectedSubAPI && uiStore.showBackButton("back", {}, t("sandboxAPI.back"));
		} else {
			sandboxAPIStore.config.selectedSubAPI &&
				uiStore.showBackButton(
					RouterConstants.APPS.path,
					undefined,
					t("sandboxAPI.allApplications"),
					undefined,
					undefined,
					undefined,
					t(`${sandboxAPIStore.config.selectedSubAPI}.title`),
				);
		}
	}, [sandboxAPIStore.config.selectedSubAPI]);

	useEffect(() => {
		if (generateFromNavigation) {
			textToImageStore.handleConfigChange("prompt", navigateOptions.prompt);
			navigateOptions.aspectRatio &&
				textToImageStore.handleConfigChange("aspect_ratio", navigateOptions.aspectRatio);
			textToImageStore.generateTextToImage();
			window.history.replaceState({}, "");
		} else if (navigateOptions?.prompt === "" || navigateOptions?.aspectRatio) {
			textToImageStore.handleConfigChange("prompt", "");
			textToImageStore.handleConfigChange("aspect_ratio", "4:3");
		}
	}, [generateFromNavigation]);

	const handleConfigToggle = (_e: any, selectedConfig: APPS) => {
		playgroundStore.selectedConfig = selectedConfig;
		isSingleMode && selectedConfig === APPS.TEXT_TO_IMAGE && playgroundStore.togglePlaygroundViewMode();
	};

	return (
		<Box className={playgroundClass}>
			<Box className={styles.configsWrapper}>
				{(playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE ||
					playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE) &&
					!textToImageStore.isTextToVector() &&
					(!iframeStore.isIframe() ||
						(iframeStore.iframe.config.enabled_pages.textToImage &&
							iframeStore.iframe.config.enabled_pages.imageToImage)) &&
					!images.some((image) => (image.config as TextToImageConfigType)?.format === "svg") &&
					!playgroundStore?.isText2ImagePopupOpened &&
					!isFoxApps() && (
						<BriaToggleButtonGroup
							className={styles.BriaToggleButtonGroup}
							value={playgroundStore.selectedConfig}
							onChange={handleConfigToggle}
						>
							<NavLink to={RouterConstants.TEXT_TO_IMAGE.fullPath} className={styles.fullSize}>
								<ToggleButton value="textToImage" className={styles.fullSize}>
									{t(`textToImage.toggleBtn`)}
								</ToggleButton>
							</NavLink>
							<NavLink to={RouterConstants.IMAGE_TO_IMAGE.fullPath} className={styles.fullSize}>
								<ToggleButton value="imageToImage" className={styles.fullSize}>
									{t(`imageToImage.toggleBtn`)}
								</ToggleButton>
							</NavLink>
						</BriaToggleButtonGroup>
					)}
				<Outlet />
			</Box>

			{sandboxAPIStore.config.selectedSubAPI === ImageGenerationsSubAPIType.promptEnhancement ? (
				<PlaygroundTextResults />
			) : (
				<PlaygroundImages />
			)}
		</Box>
	);
};

export default observer(Playground);
