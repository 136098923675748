export const useBriaTheme = () => {
	const getCssVariableValue = (variableName: string) => {
		const root = document.documentElement;
		const computedStyle = getComputedStyle(root);
		return computedStyle.getPropertyValue(variableName).trim();
	};

	const setCssVariableValue = (key: string, value: string) => {
		document.documentElement.style.setProperty(key, value);
	};

	const convertUnderscoreToCssVar = (key: string) => {
		return `--${key.replace(/_/g, "-")}`;
	};
	return {
		getCssVariableValue,
		setCssVariableValue,
		convertUnderscoreToCssVar,
	};
};
