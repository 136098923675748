import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import EyeIcon from "../../../assets/images/icons/EyeIcon";
import SearchIcon from "../../../assets/images/svgs/SearchIcon.svg";
import styles from "./BriaInput.module.scss";

interface IProps {
	value?: unknown;
	type?: string;
	optional?: boolean;
	height?: string;
	alignment?: string;
	searchField?: boolean;
	onEnterPress?: () => void;
	customEndAdornmentText?: string;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const BriaInput: React.FC<IProps & TextFieldProps> = ({
	value,
	searchField,
	alignment,
	type,
	optional,
	height,
	onEnterPress,
	onChange,
	customEndAdornmentText,
	onKeyDown,
	...rest
}) => {
	const { t } = useTranslation();
	const [hasInput, setHasInput] = React.useState<boolean>(false);
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleClick = () => {
		setShowPassword(!showPassword);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasInput(event.target.value.trim() !== "");
		if (onChange) {
			onChange(event);
		}
	};

	const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter") {
			onEnterPress?.();
		} else {
			onKeyDown && onKeyDown(event);
		}
	};

	return (
		<TextField
			variant="outlined"
			type={type === "password" ? (showPassword ? "text" : "password") : type}
			required={!optional}
			onChange={handleChange}
			InputProps={{
				style: {
					height,
					alignItems: alignment,
				},
				startAdornment: searchField && <img className={styles.searchIconStyle} src={SearchIcon} />,
				endAdornment: optional ? (
					!hasInput && <InputAdornment position="end">{t("optional")}</InputAdornment>
				) : type === "password" ? (
					<InputAdornment position="end">
						<EyeIcon onClick={handleClick} className={styles.icon}></EyeIcon>
					</InputAdornment>
				) : (
					customEndAdornmentText && <InputAdornment position="end">{customEndAdornmentText}</InputAdornment>
				),
				classes: {
					input: styles.textField,
					root: styles.inputContainer,
				},
			}}
			value={typeof value === "number" && isNaN(value) ? "" : value}
			onKeyDown={handleEnterPress}
			{...rest}
		/>
	);
};

export default BriaInput;
