import i18n from "i18next";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import App from "./App";
import "./index.module.scss";
import BriaThemeProvider from "./mobx/BriaThemeProvider";
import MobxProvider from "./mobx/store-provider";
import common_en from "./translations/en.json";

i18n.init({
	resources: {
		en: {
			translation: common_en,
		},
		// Add translations for other languages here
	},
	lng: "en", // Set the default language
	fallbackLng: "en",
	interpolation: {
		escapeValue: false, // Allows using HTML in translations
	},
	nsSeparator: false,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
	<MobxProvider>
		<I18nextProvider i18n={i18n}>
			<BriaThemeProvider>
				<App />
			</BriaThemeProvider>
		</I18nextProvider>
	</MobxProvider>,
);
