export const DEFAULT_STRUCTURE_REF_INFLUENCE = 0.5;
export const DEFAULT_TAILORED_MODEL_INFLUENCE = 1;

export const IMAGE_REFERENCE = {
	structure_ref_influence: DEFAULT_STRUCTURE_REF_INFLUENCE,
};

export const IMAGE_REFERENCE_DISABLED_MODELS = ["hd"];

export const IMAGE_REFERENCE_CONFIGS = {
	steps: {
		fast: {
			min: 4,
			max: 20,
			default: 12,
		},
		not_fast: {
			min: 20,
			max: 50,
			default: 30,
		},
	},
};
