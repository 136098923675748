import { ExpandMore, NavigateNext } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../../assets/images/svgs/AIEditor/dark-cloud-upload.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaSlider from "../../../../../components/common/BriaSlider/BriaSlider";
import { getModelDefaultLabel } from "../../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import ImageUploader from "../../../../../components/common/ImageUploader/ImageUploader";
import {
	DEFAULT_STRUCTURE_REF_INFLUENCE,
	IMAGE_REFERENCE_CONFIGS,
	IMAGE_REFERENCE_DISABLED_MODELS,
} from "../../../../../constants/TextToImageConstants.ts";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { ConfigValue } from "../../../../../models/image-to-image";
import iframeStore from "../../../../IframeNew/iframe-store.tsx";
import ImageReferenceGallery from "../ImageReferenceGallery/ImageReferenceGallery";
import styles from "./ImageReference.module.scss";

const ImageReference = () => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.imageReference",
	});
	const { textToImageStore } = useAppStore();
	const [isImageGalleryOpen, setIsImageGalleryOpen] = useState<boolean>(false);
	const [isImageReferenceOpen, setIsImageReferenceOpen] = useState<boolean>(true);
	const isDisabled = IMAGE_REFERENCE_DISABLED_MODELS.includes(textToImageStore.config.model);

	const handleConfigChange = (field: string, value: ConfigValue) => {
		textToImageStore.handleConfigChange("image_reference", {
			...textToImageStore.config.image_reference,
			[field]: value,
		});
	};

	const handleUploadImageReference = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
		textToImageStore.handleUploadImage(e);
		textToImageStore.handleConfigChange("tailored_model_influence", 0.5);
		textToImageStore.handleConfigChange(
			"steps_num",
			textToImageStore.config.fast
				? IMAGE_REFERENCE_CONFIGS.steps.fast.default
				: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
		);
		textToImageStore.handleConfigChange("image_reference", {
			...textToImageStore.config.image_reference,
			structure_ref_influence: DEFAULT_STRUCTURE_REF_INFLUENCE,
		});
	};

	const handleDeleteImageReference = async (): Promise<void> => {
		textToImageStore.handleDeleteImage();
		textToImageStore.handleConfigChange("prompt", "");
		textToImageStore.handleConfigChange("tailored_model_influence", 1);
		textToImageStore.handleConfigChange("steps_num", 8);
		textToImageStore.handleConfigChange("image_reference", {
			...textToImageStore.config.image_reference,
			structure_ref_influence: DEFAULT_STRUCTURE_REF_INFLUENCE,
		});
		textToImageStore.handleConfigChange(
			"steps_num",
			textToImageStore.config.fast
				? IMAGE_REFERENCE_CONFIGS.steps.fast.default
				: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
		);
	};

	const modelName =
		iframeStore?.iframe?.config.image_generation_config?.enabled_foundation_models?.["fast"]?.["2.3"] ??
		getModelDefaultLabel("fast", "2.3");
	return (
		<Box className={styles.ImageReferenceContainer}>
			<Accordion
				elevation={0}
				disableGutters
				className={styles.imageReferenceAccordion}
				expanded={isImageReferenceOpen}
				onChange={() => setIsImageReferenceOpen((prev) => !prev)}
			>
				<AccordionSummary
					className={clsx(styles.AccordionSummary, { [styles.isDisabled]: isDisabled })}
					color="initial"
					expandIcon={<ExpandMore />}
				>
					{t("structure")}
				</AccordionSummary>
				{isDisabled ? (
					<Box className={styles.disabledImageReference}>
						<Typography className={styles.disabledLabel}>{t("disabledMessage", { modelName })}</Typography>
					</Box>
				) : (
					<Box className={styles.advanced}>
						<InputLayout label={t("title")} className={styles.imageUploaderContainer}>
							<ImageUploader
								description={t("uploadImage")}
								icon={CloudUploadSVG}
								horizontalLayout
								largeIcon
								src={textToImageStore.imageReference?.url}
								onUpload={(e) => handleUploadImageReference(e)}
								onDelete={() => handleDeleteImageReference()}
							>
								<BriaButton
									className={styles.imageReferenceGallery}
									onClick={() => setIsImageGalleryOpen(!isImageGalleryOpen)}
									endIcon={<NavigateNext />}
								>
									{t("imageReferenceExamples")}
								</BriaButton>
							</ImageUploader>
						</InputLayout>
						<InputLayout label={t("imageInfluence")}>
							<BriaSlider
								value={textToImageStore.config.image_reference?.structure_ref_influence}
								onChange={(_, value) => handleConfigChange("structure_ref_influence", value as number)}
								step={0.05}
								min={0}
								max={1}
								marks={[
									{ value: 0, label: t("low") },
									{ value: 1, label: t("high") },
								]}
								valueLabelDisplay="auto"
								labelsAlwaysGray
							/>
						</InputLayout>
					</Box>
				)}
			</Accordion>
			{isImageGalleryOpen && <ImageReferenceGallery onClose={() => setIsImageGalleryOpen(false)} />}
		</Box>
	);
};

export default observer(ImageReference);
