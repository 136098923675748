import { Box, ButtonProps } from "@mui/material";
import React from "react";
import BriaBadge from "../BriaBadge/BriaBadge.tsx";
import styles from "./MenuCell.module.scss";

interface IProps {
	text: string;
	selected: boolean;
	icon?: any;
	badge?: { text: string; color?: string };
}

export default function MenuCell({ text, selected, icon, badge, ...rest }: IProps & ButtonProps) {
	const seclectedButtonStyle = selected ? styles.selectedStyle : "none";
	const selectedContainerStyle = selected ? styles.selectedContainerStyle : "none";

	return (
		<button {...rest} className={`${styles.container} ${selectedContainerStyle}`}>
			<Box className={`${styles.cellButton} ${seclectedButtonStyle}`}>
				{icon && (
					<div className={styles.iconStyle}>
						{React.createElement(icon, { stroke: selected ? "#5300C9" : "#5B5B5B" })}
					</div>
				)}
				{badge ? (
					<BriaBadge label={badge.text} color={badge.color}>
						{text}
					</BriaBadge>
				) : (
					text
				)}
			</Box>
		</button>
	);
}
