import { styled } from "@mui/material";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import checked from "../../../assets/images/svgs/checked.svg";

const BriaCheckbox = (props: CheckboxProps) => {
	return (
		<StyledCheckbox {...props} checked={!!props.checked} checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />
	);
};

export default BriaCheckbox;

const StyledCheckbox = styled(Checkbox)(() => ({
	padding: "9.5px 9px 8.5px 9px",
}));

const UncheckedIcon = styled("span")(({ theme }) => ({
	borderRadius: 3,
	width: 16,
	height: 16,
	boxShadow:
		theme.palette.mode === "dark"
			? "0 0 0 1px rgb(16 22 26 / 40%)"
			: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
	backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
	backgroundImage:
		theme.palette.mode === "dark"
			? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
			: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
	".Mui-focusVisible &": {
		outline: "2px auto rgba(19,124,189,.6)",
		outlineOffset: 2,
	},
	"input:hover ~ &": {
		backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
	},
	"input:disabled ~ &": {
		boxShadow: "none",
		background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
	},
}));

const CheckedIcon = styled(UncheckedIcon)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
	"&:before": {
		display: "block",
		width: 16,
		height: 16,
		backgroundImage: `url(${checked})`,
		backgroundPositionX: "center",
		backgroundPositionY: "-4.5px",
		content: '""',
	},
	"input:hover ~ &": {
		backgroundColor: theme.palette.primary.main,
	},
	"input:disabled ~ &": {
		boxShadow: "none",
		background: theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(0, 0, 0, 0.26)",
	},
}));
