import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { BriaImageProps, ImageErrorType } from "../../../../../../components/common/Galleries/BriaImage.tsx";
import LoadingPlaceholder from "../../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { APPS } from "../../../../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../../models/image-to-image.ts";
import PlaygroundImageOverlay from "../../../../../../pages/Playground/Images/Overlay/PlaygroundImageOverlay.tsx";
import useDesignEditorContext from "../../../../../hooks/useDesignEditorContext.ts";
import CampaignWizardGallery from "../FullScreenViewer/CampaignWizardGallery.tsx";
import styles from "./CampaignResult.module.scss";

const CampaignResult = () => {
	const { scenes } = useDesignEditorContext();
	const [isLoading, setIsLoading] = useState(true);
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const { designEditorStore, playgroundStore } = useAppStore();
	const playgroundImages = scenes.map((scene) => ({
		id: scene.id,
		url: scene.preview,
		type: APPS.CAMPAIGN,
	})) as (BriaImageProps & PlaygroundImage)[];

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 1500);

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			<Box className={styles.campaignGenerationResult}>
				<Box className={styles.campaignTitleContainer}>
					<Typography className={styles.campaignTitle}>{designEditorStore.campaignName}</Typography>
				</Box>
				<LoadingPlaceholder className={styles.loaderStyle} isLoading={isLoading}>
					<Box className={styles.adsContainer}>
						<CampaignWizardGallery
							images={playgroundImages.map((image) => ({
								ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
								displayOverlay: "hover",
								aspectRatio: undefined,
								selectable: false,
								htmlJsonData: image.id,
								async: false,
								onSuccessPulling: () => playgroundStore.onSuccessResult(image),
								onErrorPulling: (errorType: ImageErrorType) =>
									playgroundStore.onErrorResult(image, errorType),
								fullScreenProps: {
									fileName: playgroundStore.getFileName(image),
								},
								...image,
							}))}
							fullScreenProps={{
								open: openFullScreen,
								onClose: () => setOpenFullScreen(false),
								displayMode: "scroll",
							}}
							className={styles.gallery}
						/>
					</Box>
				</LoadingPlaceholder>
			</Box>
		</>
	);
};

const ObservedComponent = observer(CampaignResult);
export default ObservedComponent;
