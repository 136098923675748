import { Alert, Box, Snackbar, Stack, Switch, Typography } from "@mui/material";
import clsx from "clsx";
import i18n from "i18next";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import useDesignEditorUtils from "../../../DesignEditor/CustomUtils/UseDesignEditor.tsx";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor.tsx";
import { useEditor } from "../../../DesignEditor/hooks/useEditor.tsx";
import { IScene } from "../../../DesignEditor/types";
import {
	ObjectsEnum,
	SecondaryTabTypeEnum,
} from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems";
import { useSmartImageUtils } from "../../../DesignEditor/views/DesignEditor/utils/smartImageUtils.ts";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import FullScreenViewer from "../../../components/common/FullScreenViewer/FullScreenViewer";
import useSelectable from "../../../components/common/Selectable/useSelectable";
import { APPS } from "../../../constants/AppsConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import { ImageEditingViewMode, ImageToImageConfigType } from "../../../models/image-to-image";
import { ImageModificationsSubAPIType } from "../../../models/sandboxAPI";
import { isFoxApps } from "../../../utils";
import CodeGeneratorModal from "../SandboxAPI/CodeGeneratorModal/CodeGeneratorModal";
import { SaveImagesLoadingState } from "../playground-store.tsx";
import styles from "./PlaygroundActionsHeader.module.scss";

const PlaygroundActionsHeader = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.actionsHeader" });
	const { playgroundStore, sandboxAPIStore, imagesStore, imageToImageStore } = useAppStore();
	const { selectAll } = useSelectable(playgroundStore.getSelecto());
	const selectedImages = playgroundStore.getSelectedImages();
	const [clearWarningPopup, setClearWarningPopup] = useState(false);
	const [saveToMyCampaignLoading, setSaveToMyCampaignLoading] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const { addImageObjectToCanvas } = useDesignEditorUtils();
	const { isPopupView, scenes, setCurrentScene, setScenes } = useContext(DesignEditorContext);
	const editor = useEditor();
	const { uploadAndRegisterBackgroundImages, resetRectangleInAGroup } = useSmartImageUtils();
	const handleSelectAll = () => {
		selectAll();
		playgroundStore.handleSelectImagesElements(playgroundStore.getSelecto()?.getSelectedTargets());
	};
	const isSingleMode = playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE;
	const centerActions = playgroundStore.selectedConfig !== APPS.SANDBOX_API;

	const SaveTheImageToMyAssetsAndAdditToCanvas = async () => {
		await playgroundStore.saveMyImages(SaveImagesLoadingState.SaveAndAddToCanvas);
		for (const imageUrl of playgroundStore.uploadedImagesURLs) {
			addImageObjectToCanvas({
				url: imageUrl,
				id: uuidv4(),
				visual_hash: uuidv4(),
			});
		}
		setTimeout(() => {
			playgroundStore.showSuccessToastSavedImage = false;
			playgroundStore.closeModal();
		}, 1000);
		playgroundStore.uploadedImagesURLs = [];
	};

	const saveTextToImageResultToCampaign = async () => {
		setSaveToMyCampaignLoading(true);
		const selectedImages = playgroundStore.getSelectedImages();
		const firstSelectedImage = selectedImages[0];
		const url = firstSelectedImage.url;
		if (editor) {
			const _currentScene = editor.scene.exportToJSON();
			await uploadAndRegisterBackgroundImages({} as ChangeEvent<HTMLInputElement>, url, _currentScene);
			await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);

			const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
			const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
			const updatedScenes = scenes.map((scene: IScene) => {
				if (scene.id === updatedScene.id) {
					return { ...updatedScene, preview: updatedPreview };
				}
				return editor.scene.formalizeSceneAttributes(scene);
			}) as IScene[];
			setScenes(updatedScenes);
			setCurrentScene(updatedScene);
		}

		playgroundStore.showSuccessToastSavedImage = false;
		playgroundStore.closeModal();

		setSaveToMyCampaignLoading(false);
	};

	const hideToastHandler = () => {
		playgroundStore.showSuccessToastSavedImage = false;
	};

	return (
		<Box className={clsx(styles.root, { [styles.centerActions]: centerActions })}>
			<Box className={clsx(styles.actionsContainer)}>
				{playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE &&
					!isFoxApps() &&
					!playgroundStore?.isText2ImagePopupOpened && (
						<Stack
							className={clsx(styles.viewStackGroup, styles.rightBorder)}
							direction="row"
							component="label"
						>
							<Typography className={clsx(styles.switchLabel, { [styles.checked]: !isSingleMode })}>
								{t("galleryView")}
							</Typography>
							<Switch
								classes={{
									root: clsx(styles.switchBase),
								}}
								onChange={playgroundStore.togglePlaygroundViewMode}
								checked={isSingleMode}
							/>
							<Typography className={clsx(styles.switchLabel, { [styles.checked]: isSingleMode })}>
								{t("singleView")}
							</Typography>
						</Stack>
					)}

				<BriaButton
					className={clsx({ [styles.actionButton]: centerActions })}
					buttonType="textSmall"
					onClick={() =>
						(playgroundStore.playgroundResults.length || playgroundStore.textResults.length) &&
						setClearWarningPopup(true)
					}
				>
					{t("clear")}
				</BriaButton>
				<ConfirmationPopup
					open={clearWarningPopup}
					title={t("clearWarning.title")}
					description={t("clearWarning.description")}
					confirmButtonText={t("clearWarning.confirmBtn")}
					firstButtonText={t("clearWarning.closeBtn")}
					onClick={() => {
						playgroundStore.clearResults();
						setClearWarningPopup(false);
						if (playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && !isFoxApps()) {
							imageToImageStore.abortImageGeneration();
						}
					}}
					onClose={() => setClearWarningPopup(false)}
				/>
				{!isSingleMode && (
					<>
						{playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT &&
							!isPopupView &&
							playgroundStore.selectedConfig !== APPS.SANDBOX_API && (
								<BriaButton
									className={clsx(styles.actionButton)}
									buttonType="textSmall"
									onClick={handleSelectAll}
								>
									{t("selectAll")}
								</BriaButton>
							)}
						{/* {playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT &&
							playgroundStore.selectedConfig !== APPS.SANDBOX_API &&
							!playgroundStore?.isText2ImagePopupOpened &&
							selectedImages.length > 0 &&
							!isPopupView && (
								<BriaButton
									className={clsx(styles.actionButton)}
									buttonType="textSmall"
									onClick={playgroundStore.openSelected}
								>
									{t("openSelected", {
										countSelected: selectedImages.length,
										countAvailable: availableImages.length,
									})}
								</BriaButton>
							)} */}
						{playgroundStore.selectedConfig !== APPS.SANDBOX_API && selectedImages.length > 0 && (
							<BriaButton
								className={clsx(styles.actionButton)}
								buttonType="textSmall"
								onClick={() => setIsViewerOpen(true)}
							>
								{t("fullScreenView.button")}
							</BriaButton>
						)}
					</>
				)}
				{playgroundStore?.isText2ImagePopupOpened &&
					!isPopupView &&
					imagesStore.secondaryTabType !== SecondaryTabTypeEnum.SMART_IMAGE && (
						<>
							<BriaButton
								buttonType="textSmall"
								onClick={async () => {
									await playgroundStore.saveMyImages(SaveImagesLoadingState.Save);
									playgroundStore.uploadedImagesURLs = [];
								}}
								loading={playgroundStore?.loadingSaveToMyImages}
								className={clsx(styles.actionButton)}
								disabled={selectedImages.length == 0}
							>
								{t("saveToMyAssets")}
							</BriaButton>
							<BriaButton
								buttonType="textSmall"
								onClick={SaveTheImageToMyAssetsAndAdditToCanvas}
								loading={playgroundStore?.loadingSaveToMyImagesAndInsertImageToAd}
								className={clsx(styles.actionButton)}
								disabled={selectedImages.length == 0}
							>
								{t("insertAndClose")}
							</BriaButton>
						</>
					)}
				{((playgroundStore?.isText2ImagePopupOpened && isPopupView) ||
					(playgroundStore?.isText2ImagePopupOpened &&
						imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE)) && (
					<BriaButton
						buttonType="textSmall"
						onClick={saveTextToImageResultToCampaign}
						loading={saveToMyCampaignLoading}
						className={clsx(styles.actionButton)}
						disabled={selectedImages.length == 0 || saveToMyCampaignLoading}
					>
						{t("saveToMyCampaign")}
					</BriaButton>
				)}
				{playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && (
					<>
						{playgroundStore.imageEditingViewMode === ImageEditingViewMode.SINGLE && (
							<BriaButton
								id={"eraseObjectButton"}
								className={clsx(styles.actionButton, {
									[styles.active]: playgroundStore.enablePlaygroundBrushMode,
								})}
								buttonType="textSmall"
								onClick={() => {
									playgroundStore.togglePlaygroundBrushMode();
								}}
							>
								{t("eraseObject")}
							</BriaButton>
						)}
					</>
				)}
				{playgroundStore.selectedConfig === APPS.SANDBOX_API && (
					<>
						{sandboxAPIStore.config.selectedSubAPI === ImageModificationsSubAPIType.Eraser &&
							playgroundStore.playgroundResults.length !== 0 && (
								<CodeGeneratorModal playgroundResult={playgroundStore.playgroundResults[0]} />
							)}

						{sandboxAPIStore.hasImageUpload && (
							<FileUploader
								buttonText={t("uploadOneImage")}
								buttonType="secondaryMedium"
								onUpload={sandboxAPIStore.handleUploadImages}
								inputProps={{ accept: "image/png, image/jpeg, image/jpg", multiple: false }}
								buttonProps={{ className: styles.uploadBtn }}
								maxFilesLimit={sandboxAPIStore.MAX_FILES_LIMIT}
								maxFileSize={sandboxAPIStore.MAX_FILE_SIZE}
								disabled={sandboxAPIStore.isUploadingImage || sandboxAPIStore.isLoading}
							/>
						)}
					</>
				)}
			</Box>
			<Snackbar
				autoHideDuration={2000}
				onClose={hideToastHandler}
				open={playgroundStore.showSuccessToastSavedImage}
				className={styles.saveSuccessToast}
			>
				<Alert onClose={hideToastHandler} severity="success">
					{t("saveImageSuccess")}
				</Alert>
			</Snackbar>
			{isViewerOpen && (
				<FullScreenViewer
					open={isViewerOpen}
					images={playgroundStore.getSelectedImages().map((image) => ({
						src: image.url,
						fileName: playgroundStore.getFileName(image),
						compareTo: (image.config as ImageToImageConfigType)?.original_image?.url,
						compareLabel:
							image.type === "imageToImage"
								? `${i18n.t("playground.imageToImage.result.gallery.variationLabel", {
										variationNum: image.variationNum?.toString().padStart(2, "0"),
								  })}`
								: t(`fullScreenView.${image.type}.compareLabel`, { defaultValue: "" }),
						compareToLabel: t(`fullScreenView.${image.type}.compareToLabel`),
					}))}
					onClose={() => setIsViewerOpen(false)}
					displayMode="scroll"
				/>
			)}
		</Box>
	);
};

export default observer(PlaygroundActionsHeader);
