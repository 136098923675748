import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import { toast } from "react-toastify";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";

export function showErrorToast(errorMessage?: string, toastId?: string) {
	const taostId = toast.error(
		<Box>
			<Typography className="toastHeader">{i18n.t("ohSomethingWentWrong")}</Typography>
			<Typography className="toastMessage">{errorMessage ?? i18n.t("pleaseTryAgainLater")}</Typography>
		</Box>,
		{
			toastId,
			type: toast.TYPE.ERROR,
			autoClose: 10000,
			position: "bottom-left",
			style: {
				width: "fit-content",
				background: "white",
				borderTop: `5px ${
					iframeStore?.iframe.config.custom_style?.cssVars?.["primary_main_color"] ?? "#8800ff"
				} solid`,
				boxShadow: "3px 3px 15px lightgrey",
				color: "#495057",
				textAlign: "left",
			},
			closeButton: (
				<button
					className="Toastify__close-button Toastify__close-button--error"
					style={{ color: "#495057" }}
					type="button"
					aria-label="close"
				>
					<svg aria-hidden="true" viewBox="0 0 14 16">
						<path
							fillRule="evenodd"
							d="M7.71 8.23l3.75 3.75-1.48 1.48-3.75-3.75-3.75 3.75L1 11.98l3.75-3.75L1 4.48 2.48 3l3.75 3.75L9.98 3l1.48 1.48-3.75 3.75z"
						></path>
					</svg>
				</button>
			),
		},
	);
	return taostId.toString();
}
