function Select({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width={size} height={size}>
			<circle cx="50" cy="50" r="45" fill="#5300C9" />

			<path
				d="M35 50 L45 60 L65 40"
				fill="none"
				stroke="white"
				stroke-width="8"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default Select;
