import { Close } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../analytics-store";
import {
	APPS,
	IMAGES_FORMATS,
	IMAGES_FORMATS_LIST,
	IMAGES_RESOLUTIONS,
	IMAGES_RESOLUTIONS_LIST,
} from "../../../constants/AppsConstants";
import { useImageToImageConfig } from "../../../hooks/useImageToImageConfig";
import { useAppStore } from "../../../hooks/useStores";
import InputLayout from "../../../layout/InputLayout/InputLayout";
import { PlaygroundImage } from "../../../models/image-to-image";
import { modelTypes } from "../../../models/sandboxAPI";
import { TextToImageConfigType } from "../../../models/text-to-image";
import { hasOrganization, isFoxApps } from "../../../utils";
import useImageUtils from "../../../utils/useImageUtils";
import BriaButton from "../BriaButton/BriaButton";
import BriaDropDown from "../BriaDropDown/BriaDropDown";
import BriaIconButton from "../BriaIconButton/BriaIconButton";
import BriaInput from "../BriaInput/BriaInput";
import styles from "./ExportImagePopup.module.scss";

type ExportImagePopupProps = {
	open: boolean;
	onClose: () => void;
	downloadProps?: { fileName: string };
	onDownload?: () => void;
	onDownloadPsd?: () => void;
};

export default function ExportImagePopup({
	open,
	onClose,
	downloadProps,
	onDownload,
	onDownloadPsd,
}: ExportImagePopupProps) {
	const { playgroundStore, textToImageStore, analyticsStore, authStore } = useAppStore();
	const { downloadImage, uploadImageAndExportPsd } = useImageUtils();
	const { t } = useTranslation("translation", { keyPrefix: "playground.exportImagesPopup" });
	const {
		canExpandBackground,
		canRemoveBackground,
		canApplySolidBackground,
		canGenerateBackgroundByText,
		canBlurBackground,
		canApplyAspectRatio,
		canAppleCustomSize,
		canAppleMediumDestination,
		canGenerateForegroundPlacement,
	} = useImageToImageConfig();

	const [isDownloading, setIsDownloading] = useState(false);
	const [selectedImages, setSelectedImages] = useState<PlaygroundImage[]>([]);
	const isTextToVector = textToImageStore.isTextToVector();
	const defaultFileType = isTextToVector ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG;

	const filteredFormatsList = useMemo(
		() =>
			IMAGES_FORMATS_LIST.filter(
				(format) =>
					format.value !== (defaultFileType === IMAGES_FORMATS.PNG ? IMAGES_FORMATS.SVG : IMAGES_FORMATS.PNG),
			),
		[defaultFileType],
	);

	const [exportConfig, setExportConfig] = useState({
		fileName: downloadProps?.fileName || "",
		fileType: defaultFileType as string,
		resolution: IMAGES_RESOLUTIONS.X1 as string,
	});

	useEffect(() => {
		handleSelectedImages();
	}, []);

	const handleSelectedImages = async () => {
		const [selectedImages, availableImages] = await Promise.all([
			playgroundStore?.getSelectedImages(),
			playgroundStore.getAvailableImages(),
		]);
		const images = selectedImages.length > 0 ? selectedImages : availableImages;
		setSelectedImages(images);
		if (images.length > 0) {
			const firstImage = images[0];
			setExportConfig({
				fileName: playgroundStore.getFileName(firstImage),
				fileType: ((firstImage?.config as TextToImageConfigType)?.format?.toUpperCase() ||
					defaultFileType) as string,
				resolution: IMAGES_RESOLUTIONS.X1,
			});
		}
	};

	const logDownloadEvents = (resolution: number) => {
		const { config } = textToImageStore;
		const { logEvent } = analyticsStore;
		const additionalParams = { fileType: exportConfig.fileType };

		if (playgroundStore.selectedConfig === APPS.TEXT_TO_IMAGE) {
			logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_GENERAL, additionalParams);

			if (config.tailored_model_id) {
				logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_TAILORED_MODELS, {
					tailoredModelId: config.tailored_model_id,
					...additionalParams,
				});
				return;
			}

			const modelEventMap = {
				[modelTypes.Fast]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_FAST,
				[modelTypes.Base]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_BASE,
				[modelTypes.Hd]: ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_BRIA_HD,
			};

			const modelEvent = modelEventMap[config.model as keyof typeof modelEventMap];
			if (modelEvent) {
				logEvent(modelEvent, additionalParams);
			}
			if (textToImageStore.isImageReferenceEnabled()) {
				logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_DOWNLOAD_IMAGE_REFERENCE, additionalParams);
			}
			if (resolution > 1) {
				logEvent(ANALYTICS_EVENTS.IMAGE_GENERATION_INCREASE_RESOLUTION, additionalParams);
			}
		} else if (playgroundStore.selectedConfig === APPS.IMAGE_TO_IMAGE && !isFoxApps()) {
			// Background configs events
			canExpandBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_EXPAND_IMAGE, additionalParams);
			canRemoveBackground() &&
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_REMOVE_BACKGROUND, additionalParams);
			canBlurBackground() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_BLUR_BACKGROUND, additionalParams);
			canApplySolidBackground() &&
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_SOLID_BACKGROUND, additionalParams);
			canGenerateBackgroundByText() &&
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_GENERATE_BACKGROUND_BY_TEXT, additionalParams);

			// Size configs events
			canApplyAspectRatio() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_ASPECT_RATIO, additionalParams);
			canAppleCustomSize() && logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_CUSTOM_SIZE, additionalParams);
			canAppleMediumDestination() &&
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_MEDIUM_DESTINATION, additionalParams);

			// Foreground placement configs events
			canGenerateForegroundPlacement() &&
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_DOWNLOAD_FOREGROUND, additionalParams);

			if (resolution > 1) {
				logEvent(ANALYTICS_EVENTS.IMAGE_EDITING_INCREASE_RESOLUTION, additionalParams);
			}
		}
	};

	const exportImage = useCallback(
		async (resolution: number) => {
			setIsDownloading(true);
			try {
				if (selectedImages.length > 1) {
					await playgroundStore.downloadImages(selectedImages, undefined, resolution);
				} else {
					await downloadImage(selectedImages[0].url, exportConfig.fileName, resolution);
				}
			} catch (error) {
				console.error("Failed to export image:", error);
			} finally {
				setIsDownloading(false);
				onDownload?.();
			}
		},
		[selectedImages, exportConfig.fileName, exportConfig.resolution, downloadImage, playgroundStore, onDownload],
	);

	const exportImageAsPsd = useCallback(
		async (resolution: number) => {
			setIsDownloading(true);
			try {
				await Promise.all(
					selectedImages.map((image) =>
						uploadImageAndExportPsd(image.url, exportConfig.fileName, resolution),
					),
				);
			} catch (error) {
				console.error("Failed to upload image as PSD:", error);
			} finally {
				setIsDownloading(false);
				onDownloadPsd?.();
			}
		},
		[selectedImages, uploadImageAndExportPsd, exportConfig.fileName, onDownloadPsd],
	);

	const handleExportImage = async (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		try {
			const resolution = parseInt(exportConfig.resolution.replace("X", ""));
			if (exportConfig.fileType === IMAGES_FORMATS.PSD) {
				await exportImageAsPsd(resolution);
			} else {
				await exportImage(resolution);
			}
			logDownloadEvents(resolution);
		} catch (e) {
			console.error("Error exporting image:", e);
		} finally {
			onClose();
		}
	};

	return (
		<Dialog classes={{ paper: styles.paper }} onClose={onClose} open={open} maxWidth={false}>
			<Box className={styles.container}>
				<Box className={styles.dialogHeader}>
					<Typography variant="h6" className={styles.title}>
						{t("export")}
					</Typography>
					<BriaIconButton onClick={onClose} className={styles.closeButton}>
						<Close />
					</BriaIconButton>
				</Box>
				{selectedImages?.length === 1 && (
					<InputLayout label={t("fileName")}>
						<BriaInput
							type="text"
							className={styles.inputField}
							height="40px"
							value={exportConfig.fileName}
							onChange={(e) => setExportConfig((prev) => ({ ...prev, fileName: e.target.value }))}
						/>
					</InputLayout>
				)}

				<Box className={styles.inputsRow}>
					<InputLayout label={t("fileType")} className={styles.inputField}>
						<BriaDropDown
							items={filteredFormatsList}
							value={exportConfig.fileType}
							placeholder={exportConfig.fileType}
							className={styles.dropDown}
							loading={authStore.isLoadingOrgSubscriptions}
							disabled={authStore.orgPassedFreeLimit}
							onChange={(e) => setExportConfig((prev) => ({ ...prev, fileType: e.target.value }))}
						/>
					</InputLayout>
					<InputLayout label={t("increaseResolution")} className={styles.inputField}>
						<BriaDropDown
							items={IMAGES_RESOLUTIONS_LIST}
							disabled={
								!hasOrganization() ||
								authStore.orgPassedFreeLimit ||
								exportConfig.fileType === IMAGES_FORMATS.SVG
							}
							placeholder={exportConfig.resolution}
							loading={authStore.isLoadingOrgSubscriptions}
							value={exportConfig.resolution}
							className={styles.dropDown}
							onChange={(e) => setExportConfig((prev) => ({ ...prev, resolution: e.target.value }))}
						/>
					</InputLayout>
				</Box>
				<Box className={styles.actionsContainer}>
					<BriaButton onClick={onClose} buttonType="textMedium" className={styles.actionBtn}>
						{t("cancel")}
					</BriaButton>
					<BriaButton
						onClick={handleExportImage}
						buttonType="primary"
						className={styles.actionBtn}
						disabled={isDownloading || selectedImages.length === 0}
					>
						{t("export")}
						{isDownloading && (
							<CircularProgress color="primary" size={15} className={styles.loadingProgress} />
						)}
					</BriaButton>
				</Box>
			</Box>
		</Dialog>
	);
}
