import image4 from "../assets/images/pngs/HubGalleryExampleImages/image 4.png";
import image1 from "../assets/images/pngs/HubGalleryExampleImages/image1.png";
import image10 from "../assets/images/pngs/HubGalleryExampleImages/image10.png";
import image2 from "../assets/images/pngs/HubGalleryExampleImages/image2.png";
import image3 from "../assets/images/pngs/HubGalleryExampleImages/image3.png";
import image5 from "../assets/images/pngs/HubGalleryExampleImages/image5.png";
import image6 from "../assets/images/pngs/HubGalleryExampleImages/image6.png";
import image7 from "../assets/images/pngs/HubGalleryExampleImages/image7.png";
import image8 from "../assets/images/pngs/HubGalleryExampleImages/image8.png";
import image9 from "../assets/images/pngs/HubGalleryExampleImages/image9.png";

import { LazyLoadImageProps } from "react-lazy-load-image-component";
import image11 from "../assets/images/pngs/HubGalleryExampleImages/image11.png";
import image12 from "../assets/images/pngs/HubGalleryExampleImages/image12.png";
import image13 from "../assets/images/pngs/HubGalleryExampleImages/image13.png";
import image14 from "../assets/images/pngs/HubGalleryExampleImages/image14.png";
import image15 from "../assets/images/pngs/HubGalleryExampleImages/image15.png";
import image16 from "../assets/images/pngs/HubGalleryExampleImages/image16.png";
import image17 from "../assets/images/pngs/HubGalleryExampleImages/image17.png";
import image18 from "../assets/images/pngs/HubGalleryExampleImages/image18.png";
import image19 from "../assets/images/pngs/HubGalleryExampleImages/image19.png";
import image20 from "../assets/images/pngs/HubGalleryExampleImages/image20.png";
import image21 from "../assets/images/pngs/HubGalleryExampleImages/image21.png";
import image22 from "../assets/images/pngs/HubGalleryExampleImages/image22.png";
import image23 from "../assets/images/pngs/HubGalleryExampleImages/image23.png";
import image24 from "../assets/images/pngs/HubGalleryExampleImages/image24.png";
import image25 from "../assets/images/pngs/HubGalleryExampleImages/image25.png";
import image26 from "../assets/images/pngs/HubGalleryExampleImages/image26.png";
import image27 from "../assets/images/pngs/HubGalleryExampleImages/image27.png";
import image28 from "../assets/images/pngs/HubGalleryExampleImages/image28.png";
import image29 from "../assets/images/pngs/HubGalleryExampleImages/image29.png";
import image30 from "../assets/images/pngs/HubGalleryExampleImages/image30.png";
import image31 from "../assets/images/pngs/HubGalleryExampleImages/image31.png";

export interface HomeCard {
	title: string;
	description: string;
	image: string;
	onClick: () => void;
	link?: string;
}

export interface HubgalleryImage {
	image: string;
	prompt: string;
	vhash: string;
	url: string;
	lazyImageProps?: LazyLoadImageProps;
}

export const galleryExampleImages: HubgalleryImage[] = [
	{
		image: image1,
		prompt: "imagesPrompt.image1.prompt",
		vhash: "bc0947163069cfdb",
		url: "https://images.bria.ai/images_resized/bc0947163069cfdb.png",
	},
	{
		image: image2,
		prompt: "imagesPrompt.image2.prompt",
		vhash: "96306dc97bb69461",
		url: "https://images.bria.ai/images_resized/96306dc97bb69461.png",
	},
	{
		image: image3,
		prompt: "imagesPrompt.image3.prompt",
		vhash: "b19cc2d6a7f09f40",
		url: "https://images.bria.ai/images_resized/b19cc2d6a7f09f40.png",
	},
	{
		image: image4,
		prompt: "imagesPrompt.image4.prompt",
		vhash: "9430344d5fd76167",
		url: "https://images.bria.ai/images_resized/9430344d5fd76167.png",
	},
	{
		image: image5,
		prompt: "imagesPrompt.image5.prompt",
		vhash: "866a29733ead982e",
		url: "https://images.bria.ai/images_resized/866a29733ead982e.png",
	},
	{
		image: image6,
		prompt: "imagesPrompt.image6.prompt",
		vhash: "84b73b684d9c9663",
		url: "https://images.bria.ai/images_resized/84b73b684d9c9663.png",
	},
	{
		image: image7,
		prompt: "imagesPrompt.image7.prompt",
		vhash: "89557a56a5ac50f9",
		url: "https://images.bria.ai/images_resized/89557a56a5ac50f9.png",
	},
	{
		image: image8,
		prompt: "imagesPrompt.image8.prompt",
		vhash: "fb18b76564999988",
		url: "https://images.bria.ai/images_resized/fb18b76564999988.png",
	},
	{
		image: image9,
		prompt: "imagesPrompt.image9.prompt",
		vhash: "98db672e3593621c",
		url: "https://images.bria.ai/images_resized/98db672e3593621c.png",
	},
	{
		image: image10,
		prompt: "imagesPrompt.image10.prompt",
		vhash: "c907ee25dc07f28c",
		url: "https://images.bria.ai/images_resized/c907ee25dc07f28c.png",
	},

	{
		image: image11,
		prompt: "imagesPrompt.image11.prompt",
		vhash: "99442ebb42ce5c9b",
		url: "https://images.bria.ai/images_resized/99442ebb42ce5c9b.jpg",
	},
	{
		image: image12,
		prompt: "imagesPrompt.image12.prompt",
		vhash: "e1ce4ea40dc95b5a",
		url: "https://images.bria.ai/images_resized/e1ce4ea40dc95b5a.png",
	},
	{
		image: image13,
		prompt: "imagesPrompt.image13.prompt",
		vhash: "b6e46868d269f853",
		url: "https://images.bria.ai/images_resized/b6e46868d269f853.jpg",
	},
	{
		image: image14,
		prompt: "imagesPrompt.image14.prompt",
		vhash: "d81f62665ed08d2e",
		url: "https://images.bria.ai/images_resized/d81f62665ed08d2e.jpg",
	},
	{
		image: image15,
		prompt: "imagesPrompt.image15.prompt",
		vhash: "c9da302afd704ccd",
		url: "https://images.bria.ai/images_resized/c9da302afd704ccd.png",
	},
	{
		image: image16,
		prompt: "imagesPrompt.image16.prompt",
		vhash: "c76b2cb732b26314",
		url: "https://images.bria.ai/images_resized/c76b2cb732b26314.jpg",
	},
	{
		image: image17,
		prompt: "imagesPrompt.image17.prompt",
		vhash: "b5566912eee99146",
		url: "https://images.bria.ai/images_resized/b5566912eee99146.jpg",
	},
	{
		image: image18,
		prompt: "imagesPrompt.image18.prompt",
		vhash: "d4f061b41bbc7e03",
		url: "https://images.bria.ai/images_resized/d4f061b41bbc7e03.jpg",
	},
	{
		image: image19,
		prompt: "imagesPrompt.image19.prompt",
		vhash: "9013c7e649ccce57",
		url: "https://images.bria.ai/images_resized/9013c7e649ccce57.jpg",
	},
	{
		image: image20,
		prompt: "imagesPrompt.image20.prompt",
		vhash: "b4b3239bcf369034",
		url: "https://images.bria.ai/images_resized/b4b3239bcf369034.jpg",
	},

	{
		image: image21,
		prompt: "imagesPrompt.image21.prompt",
		vhash: "ab8e4067d0b72ef0",
		url: "https://images.bria.ai/images_resized/ab8e4067d0b72ef0.jpg",
	},
	{
		image: image22,
		prompt: "imagesPrompt.image22.prompt",
		vhash: "95ca80696b75653e",
		url: "https://images.bria.ai/images_resized/95ca80696b75653e.jpg",
	},
	{
		image: image23,
		prompt: "imagesPrompt.image23.prompt",
		vhash: "8ce273a92259ce8f",
		url: "https://images.bria.ai/images_resized/8ce273a92259ce8f.jpg",
	},
	{
		image: image24,
		prompt: "imagesPrompt.image24.prompt",
		vhash: "b35cdc0b8b570da2",
		url: "https://images.bria.ai/images_resized/b35cdc0b8b570da2.jpg",
	},
	{
		image: image25,
		prompt: "imagesPrompt.image25.prompt",
		vhash: "fa0e61992e72b5c8",
		url: "https://images.bria.ai/images_resized/fa0e61992e72b5c8.jpg",
	},
	{
		image: image26,
		prompt: "imagesPrompt.image26.prompt",
		vhash: "e4b459b23243b3d3",
		url: "https://images.bria.ai/images_resized/e4b459b23243b3d3.jpg",
	},
	{
		image: image27,
		prompt: "imagesPrompt.image27.prompt",
		vhash: "a6ee352d668c8395",
		url: "https://images.bria.ai/images_resized/a6ee352d668c8395.jpg",
	},
	{
		image: image28,
		prompt: "imagesPrompt.image28.prompt",
		vhash: "9a0cfc13eb1d8c72",
		url: "https://images.bria.ai/images_resized/9a0cfc13eb1d8c72.png",
	},
	{
		image: image29,
		prompt: "imagesPrompt.image29.prompt",
		vhash: "fec13926193ace91",
		url: "https://images.bria.ai/images_resized/fec13926193ace91.jpg",
	},
	{
		image: image30,
		prompt: "imagesPrompt.image30.prompt",
		vhash: "9fe695c1da480eb4",
		url: "https://images.bria.ai/images_resized/9fe695c1da480eb4.jpg",
	},
	{
		image: image31,
		prompt: "imagesPrompt.image31.prompt",
		vhash: "b71f1a773803c724",
		url: "https://images.bria.ai/images_resized/b71f1a773803c724.jpg",
	},
];
export const galleryExampleImagesColumns: HubgalleryImage[][] = [
	[
		{
			image: image1,
			prompt: "imagesPrompt.image1.prompt",
			vhash: "bc0947163069cfdb",
			url: "https://images.bria.ai/images_resized/bc0947163069cfdb.png",
		},
		{
			image: image5,
			prompt: "imagesPrompt.image5.prompt",
			vhash: "866a29733ead982e",
			url: "https://images.bria.ai/images_resized/866a29733ead982e.png",
		},
		{
			image: image9,
			prompt: "imagesPrompt.image9.prompt",
			vhash: "98db672e3593621c",
			url: "https://images.bria.ai/images_resized/98db672e3593621c.png",
		},
		{
			image: image13,
			prompt: "imagesPrompt.image13.prompt",
			vhash: "b6e46868d269f853",
			url: "https://images.bria.ai/images_resized/b6e46868d269f853.jpg",
		},
		{
			image: image17,
			prompt: "imagesPrompt.image17.prompt",
			vhash: "b5566912eee99146",
			url: "https://images.bria.ai/images_resized/b5566912eee99146.jpg",
		},
		{
			image: image21,
			prompt: "imagesPrompt.image21.prompt",
			vhash: "ab8e4067d0b72ef0",
			url: "https://images.bria.ai/images_resized/ab8e4067d0b72ef0.jpg",
		},
		{
			image: image25,
			prompt: "imagesPrompt.image25.prompt",
			vhash: "fa0e61992e72b5c8",
			url: "https://images.bria.ai/images_resized/fa0e61992e72b5c8.jpg",
		},
	],
	[
		{
			image: image2,
			prompt: "imagesPrompt.image2.prompt",
			vhash: "96306dc97bb69461",
			url: "https://images.bria.ai/images_resized/96306dc97bb69461.png",
		},
		{
			image: image6,
			prompt: "imagesPrompt.image6.prompt",
			vhash: "84b73b684d9c9663",
			url: "https://images.bria.ai/images_resized/84b73b684d9c9663.png",
		},
		{
			image: image10,
			prompt: "imagesPrompt.image10.prompt",
			vhash: "c907ee25dc07f28c",
			url: "https://images.bria.ai/images_resized/c907ee25dc07f28c.png",
		},
		{
			image: image14,
			prompt: "imagesPrompt.image14.prompt",
			vhash: "d81f62665ed08d2e",
			url: "https://images.bria.ai/images_resized/d81f62665ed08d2e.jpg",
		},
		{
			image: image18,
			prompt: "imagesPrompt.image18.prompt",
			vhash: "d4f061b41bbc7e03",
			url: "https://images.bria.ai/images_resized/d4f061b41bbc7e03.jpg",
		},
		{
			image: image22,
			prompt: "imagesPrompt.image22.prompt",
			vhash: "95ca80696b75653e",
			url: "https://images.bria.ai/images_resized/95ca80696b75653e.jpg",
		},
		{
			image: image26,
			prompt: "imagesPrompt.image26.prompt",
			vhash: "e4b459b23243b3d3",
			url: "https://images.bria.ai/images_resized/e4b459b23243b3d3.jpg",
		},
		{
			image: image29,
			prompt: "imagesPrompt.image29.prompt",
			vhash: "fec13926193ace91",
			url: "https://images.bria.ai/images_resized/fec13926193ace91.jpg",
		},
	],
	[
		{
			image: image3,
			prompt: "imagesPrompt.image3.prompt",
			vhash: "b19cc2d6a7f09f40",
			url: "https://images.bria.ai/images_resized/b19cc2d6a7f09f40.png",
		},
		{
			image: image7,
			prompt: "imagesPrompt.image7.prompt",
			vhash: "89557a56a5ac50f9",
			url: "https://images.bria.ai/images_resized/89557a56a5ac50f9.png",
		},
		{
			image: image11,
			prompt: "imagesPrompt.image11.prompt",
			vhash: "99442ebb42ce5c9b",
			url: "https://images.bria.ai/images_resized/99442ebb42ce5c9b.jpg",
		},
		{
			image: image15,
			prompt: "imagesPrompt.image15.prompt",
			vhash: "c9da302afd704ccd",
			url: "https://images.bria.ai/images_resized/c9da302afd704ccd.png",
		},
		{
			image: image19,
			prompt: "imagesPrompt.image19.prompt",
			vhash: "9013c7e649ccce57",
			url: "https://images.bria.ai/images_resized/9013c7e649ccce57.jpg",
		},
		{
			image: image23,
			prompt: "imagesPrompt.image23.prompt",
			vhash: "8ce273a92259ce8f",
			url: "https://images.bria.ai/images_resized/8ce273a92259ce8f.jpg",
		},
		{
			image: image27,
			prompt: "imagesPrompt.image27.prompt",
			vhash: "a6ee352d668c8395",
			url: "https://images.bria.ai/images_resized/a6ee352d668c8395.jpg",
		},
		{
			image: image30,
			prompt: "imagesPrompt.image30.prompt",
			vhash: "9fe695c1da480eb4",
			url: "https://images.bria.ai/images_resized/9fe695c1da480eb4.jpg",
		},
	],
	[
		{
			image: image4,
			prompt: "imagesPrompt.image4.prompt",
			vhash: "9430344d5fd76167",
			url: "https://images.bria.ai/images_resized/9430344d5fd76167.png",
		},
		{
			image: image8,
			prompt: "imagesPrompt.image8.prompt",
			vhash: "fb18b76564999988",
			url: "https://images.bria.ai/images_resized/fb18b76564999988.png",
		},
		{
			image: image12,
			prompt: "imagesPrompt.image12.prompt",
			vhash: "e1ce4ea40dc95b5a",
			url: "https://images.bria.ai/images_resized/e1ce4ea40dc95b5a.png",
		},
		{
			image: image16,
			prompt: "imagesPrompt.image16.prompt",
			vhash: "c76b2cb732b26314",
			url: "https://images.bria.ai/images_resized/c76b2cb732b26314.jpg",
		},
		{
			image: image20,
			prompt: "imagesPrompt.image20.prompt",
			vhash: "b4b3239bcf369034",
			url: "https://images.bria.ai/images_resized/b4b3239bcf369034.jpg",
		},
		{
			image: image24,
			prompt: "imagesPrompt.image24.prompt",
			vhash: "b35cdc0b8b570da2",
			url: "https://images.bria.ai/images_resized/b35cdc0b8b570da2.jpg",
		},
		{
			image: image28,
			prompt: "imagesPrompt.image28.prompt",
			vhash: "9a0cfc13eb1d8c72",
			url: "https://images.bria.ai/images_resized/9a0cfc13eb1d8c72.png",
		},
		{
			image: image31,
			prompt: "imagesPrompt.image31.prompt",
			vhash: "b71f1a773803c724",
			url: "https://images.bria.ai/images_resized/b71f1a773803c724.jpg",
		},
	],
];
