function TextAlignJustify({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
			<path
				d="M3.33203 5C8.53902 5 11.4584 5 16.6654 5M3.33203 10C8.48254 10 11.3702 10 16.5207 10M3.33203 15C7.88815 15 12.3622 15 16.9183 15"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default TextAlignJustify;
