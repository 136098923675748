import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../../assets/images/icons/PlusIcon.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import BriaButton from "../BriaButton/BriaButton.tsx";
import GuidanceMethod from "./GuidanceMethod.tsx";
import styles from "./GuidanceMethods.module.scss";

interface IProps {
	label?: string;
	info?: string;
}

const GuidanceMethods: React.FC<IProps> = ({ label, info }) => {
	const { t } = useTranslation("translation", { keyPrefix: "guidanceMethods" });
	const { sandboxAPIStore } = useAppStore();

	const isDisabled = () => {
		return Number(sandboxAPIStore.config.apiConfig?.guidanceMethods?.length) >= 4 ? true : false;
	};

	const renderInput = () => {
		return (
			<InputLayout className={styles.guidanceMethods} label={label} info={info} validateOn={true}>
				{sandboxAPIStore.config.apiConfig?.guidanceMethods?.map((guidanceMethod, index) => (
					<GuidanceMethod
						key={guidanceMethod.id}
						id={guidanceMethod.id}
						index={index}
						onDelete={() => sandboxAPIStore.removeGuidanceMethod(guidanceMethod.id, index)}
					/>
				))}

				<BriaButton
					className={styles.addMethod}
					buttonType={"textSmall"}
					onClick={sandboxAPIStore.addGuidanceMethod}
					disabled={isDisabled()}
				>
					{isDisabled() ? <PlusIcon fillColor="#1a0638" /> : <PlusIcon fillColor="#5300c9" />}
					{t("addMethod")}
				</BriaButton>
			</InputLayout>
		);
	};

	return renderInput();
};

const ObservedComponent = observer(GuidanceMethods);
export default ObservedComponent;
