import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SubMenuHeader } from "../../../../../components/common/SubMenuHeader/SubMenuHeader";
import { ImageReferenceGalleryConstants } from "../../../../../constants/ImageReferenceGalleryConstants";
import GalleryItem from "./GalleryItem";
import styles from "./ImageReferenceGallery.module.scss";
type ImageReferenceGalleryProps = {
	onClose?: () => void;
};

const ImageReferenceGallery = ({ onClose }: ImageReferenceGalleryProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.imageReference",
	});
	const panelRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as Node;

			if (panelRef.current && !panelRef.current.contains(target)) {
				onClose?.();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onClose]);

	return (
		<Box className={styles.ImageReferenceGalleryContainer} ref={panelRef}>
			<SubMenuHeader
				title={t("referenceGallery")}
				subTitle={t("referenceGallerySubtitle")}
				noBorder
				onClose={onClose}
			/>
			<Box>
				<GalleryItem
					title={t("imageGallery.titles.sketch_to_illustration")}
					images={ImageReferenceGalleryConstants.sketch_to_illustration}
					onClose={onClose}
				/>
				<GalleryItem
					title={t("imageGallery.titles.photo_variations")}
					images={ImageReferenceGalleryConstants.photo_variations}
					onClose={onClose}
				/>
				<GalleryItem
					title={t("imageGallery.titles.illustration_variations")}
					images={ImageReferenceGalleryConstants.illustration_variations}
					onClose={onClose}
				/>
				<GalleryItem
					title={t("imageGallery.titles.typography_logo_variations")}
					images={ImageReferenceGalleryConstants.typography_logo_variations}
					onClose={onClose}
				/>
			</Box>
		</Box>
	);
};

export default observer(ImageReferenceGallery);
