import { Close } from "@mui/icons-material";
import { IconButton, debounce } from "@mui/material";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React, { useState } from "react";
import SearchIcon from "../../../assets/images/svgs/SearchIcon.svg";
import styles from "./DebouncedInput.module.scss";

interface DebouncedInputProps {
	debounceDuration?: number;
	searchIcon?: boolean;
	height?: string;
	placeHolder?: string;
}

const DebouncedInput: React.FC<DebouncedInputProps & TextFieldProps> = ({
	debounceDuration = 500,
	searchIcon = false,
	height,
	value,
	onChange,
	placeHolder,
	...rest
}) => {
	const [localValue, setLocalValue] = useState(value || "");

	const eraseSearch = () => {
		handleChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleDebouncedChange = debounce((e) => onChange?.(e), debounceDuration);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(e.target.value);
		handleDebouncedChange(e);
	};

	return (
		<TextField
			placeholder={placeHolder ? placeHolder : ""}
			value={localValue}
			onChange={handleChange}
			InputProps={{
				style: {
					height,
					paddingLeft: "8px",
					fontSize: "14px",
				},
				startAdornment: searchIcon && <img className={styles.searchIconStyle} src={SearchIcon} />,
				endAdornment: localValue && (
					<IconButton size="small" onClick={eraseSearch}>
						<Close sx={{ fontSize: "16px" }} />
					</IconButton>
				),
			}}
			{...rest}
		/>
	);
};

export default DebouncedInput;
