import React from "react";
import { ContextMenuSceneRequest, ContextMenuTimelineRequest, IDesign } from "../interfaces/DesignEditor";
import { IScene } from "../types";

interface ISceneEditorContext {
	scenes: IScene[];
	setScenes: (value: ((prevState: IScene[]) => IScene[]) | IScene[]) => void;
	currentScene: IScene | null;
	setCurrentScene: (scene: IScene | null, invokeSave?: boolean) => void;
	currentDesign: IDesign;
	setCurrentDesign: React.Dispatch<React.SetStateAction<IDesign>>;
	isSidebarOpen: boolean;
	setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
	displayPlayback: boolean;
	setDisplayPlayback: React.Dispatch<React.SetStateAction<boolean>>;
	displayPreview: boolean;
	setDisplayPreview: React.Dispatch<React.SetStateAction<boolean>>;
	currentPreview: string;
	setCurrentPreview: React.Dispatch<React.SetStateAction<string>>;
	maxTime: number;
	setMaxTime: React.Dispatch<React.SetStateAction<number>>;
	contextMenuTimelineRequest: ContextMenuTimelineRequest;
	setContextMenuTimelineRequest: React.Dispatch<React.SetStateAction<ContextMenuTimelineRequest>>;
	contextMenuSceneRequest: ContextMenuTimelineRequest;
	setContextMenuSceneRequest: React.Dispatch<React.SetStateAction<ContextMenuTimelineRequest>>;
	isPopupView?: boolean;
	invokeSave: boolean;
	setInvokeSave: (value: boolean) => void;
}

export const DesignEditorContext = React.createContext<ISceneEditorContext>({
	scenes: [],
	setScenes: () => {},
	currentScene: null,
	setCurrentScene: () => {},
	currentDesign: {
		id: "",
		frame: {
			width: 1,
			height: 1,
		},
		metadata: {},
		name: "",
		preview: "",
		scenes: [],
	},
	setCurrentDesign: () => {},
	isSidebarOpen: true,
	setIsSidebarOpen: () => {},
	displayPlayback: false,
	setDisplayPlayback: () => {},
	displayPreview: false,
	setDisplayPreview: () => {},
	currentPreview: "",
	setCurrentPreview: () => {},
	maxTime: 0,
	setMaxTime: () => {},
	contextMenuTimelineRequest: {
		id: "",
		left: 0,
		top: 0,
		visible: false,
	},
	setContextMenuTimelineRequest: () => {},
	contextMenuSceneRequest: {
		id: "",
		left: 0,
		top: 0,
		visible: false,
	},
	setContextMenuSceneRequest: () => {},
	isPopupView: false,
	invokeSave: true,
	setInvokeSave: () => {},
});

export const DesignEditorProvider = ({
	children,
	isPopupView = false,
}: {
	children: React.ReactNode;
	isPopupView?: boolean;
}) => {
	const [scenes, setScenes] = React.useState<IScene[]>([]);
	const [currentScene, _setCurrentScene] = React.useState<IScene | null>(null);
	const [currentDesign, setCurrentDesign] = React.useState<IDesign>({
		id: "",
		frame: {
			width: 1,
			height: 1,
		},
		metadata: {},
		name: "",
		preview: "",
		scenes: [],
	});
	const [isSidebarOpen, setIsSidebarOpen] = React.useState(true);
	const [displayPlayback, setDisplayPlayback] = React.useState<boolean>(false);
	const [displayPreview, setDisplayPreview] = React.useState<boolean>(false);
	const [currentPreview, setCurrentPreview] = React.useState<string>("");
	const [maxTime, setMaxTime] = React.useState(5000);
	const [contextMenuTimelineRequest, setContextMenuTimelineRequest] = React.useState<ContextMenuTimelineRequest>({
		id: "",
		left: 0,
		top: 0,
		visible: false,
	});
	const [contextMenuSceneRequest, setContextMenuSceneRequest] = React.useState<ContextMenuSceneRequest>({
		id: "",
		left: 0,
		top: 0,
		visible: false,
	});
	const [invokeSave, setInvokeSave] = React.useState<boolean>(true);

	const setCurrentScene = (scene: IScene | null, invokeSave: boolean = true) => {
		_setCurrentScene(scene);
		setInvokeSave(invokeSave);
	};

	const context = {
		scenes,
		setScenes,
		currentScene,
		setCurrentScene,
		currentDesign,
		setCurrentDesign,
		isSidebarOpen,
		setIsSidebarOpen,
		displayPlayback,
		setDisplayPlayback,
		displayPreview,
		setDisplayPreview,
		currentPreview,
		setCurrentPreview,
		maxTime,
		setMaxTime,
		contextMenuTimelineRequest,
		setContextMenuTimelineRequest,
		contextMenuSceneRequest,
		setContextMenuSceneRequest,
		isPopupView,
		invokeSave,
		setInvokeSave,
	};
	return <DesignEditorContext.Provider value={context}>{children}</DesignEditorContext.Provider>;
};
