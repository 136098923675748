import line1 from "../assets/svgs/Line1.svg";
// TODO: all commented-out lines will be used later
// import line7 from "../assets/svgs/line 7.svg";
// import line10 from "../assets/svgs/line10.svg";
// import line11 from "../assets/svgs/line11.svg";
// import line12 from "../assets/svgs/line12.svg";
// import line13 from "../assets/svgs/line13.svg";
// import line14 from "../assets/svgs/line14.svg";

// import line15 from "../assets/svgs/line15.svg";
import line2 from "../assets/svgs/Line2.svg";
import line3 from "../assets/svgs/line3.svg";
// import line4 from "../assets/svgs/line4.svg";
// import line5 from "../assets/svgs/line5.svg";
// import line6 from "../assets/svgs/line6.svg";
// import line8 from "../assets/svgs/line8.svg";
// import line9 from "../assets/svgs/line9.svg";

import { nanoid } from "nanoid";
import ArrowLeft from "../assets/svgs/Arrow Left.svg";
import ArrowRight from "../assets/svgs/Arrow Right.svg";
import Blob1 from "../assets/svgs/Blob1.svg";
import Blob2 from "../assets/svgs/Blob2.svg";
import Blob3 from "../assets/svgs/Blob3.svg";
import Blob4 from "../assets/svgs/Blob4.svg";
import Circle from "../assets/svgs/Circle.svg";
import Hexagon from "../assets/svgs/Hexagon.svg";
import Pentagon from "../assets/svgs/Pentagon.svg";
import RoundSquare from "../assets/svgs/Round Square.svg";
import Square from "../assets/svgs/Square.svg";
import Star from "../assets/svgs/Star.svg";
import Sticker from "../assets/svgs/Sticker.svg";
import Triangle2 from "../assets/svgs/Triangle 2.svg";
import Triangle from "../assets/svgs/Triangle.svg";
import rhombus from "../assets/svgs/rhombus.svg";

export const vectors = [
	line1,
	line2,
	line3,
	// line4,
	// line5,
	// line6,
	// line7,
	// line8,
	// line9,
	// line10,
	// line11,
	// line12,
	// line13,
	// line14,
	// line15,
];
export const shapes = [
	Triangle2,
	rhombus,
	Pentagon,
	Hexagon,
	ArrowRight,
	ArrowLeft,
	Sticker,
	Star,
	Blob1,
	Blob2,
	Blob3,
	Blob4,
];

export const fabricShapes = [
	{
		src: Square,
		options: {
			id: nanoid(),
			fill: "#B3B3B9",
			width: 100,
			height: 100,
			rx: 0,
			ry: 0,
			type: "StaticRect",
			originX: "left",
			originY: "top",
			stroke: "#000",
		},
	},
	{
		src: RoundSquare,
		options: {
			id: nanoid(),
			fill: "#B3B3B9",
			width: 100,
			height: 100,
			rx: 20,
			ry: 20,
			type: "StaticRect",
			originX: "left",
			originY: "top",
			stroke: "#000",
		},
	},
	{
		src: Circle,
		options: {
			id: nanoid(),
			fill: "#B3B3B9",
			radius: 28,
			type: "StaticCircle",
			stroke: "#000",
		},
	},
	{
		src: Triangle,
		options: {
			id: nanoid(),
			fill: "#B3B3B9",
			width: 100,
			height: 100,
			type: "StaticTriangle",
			stroke: "#000",
		},
	},
];
