function InsertImage() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
			<path
				d="M10.8826 6.28166H10.8893M3.54932 10.9483L6.21598 8.28166C6.52002 7.9891 6.86491 7.83508 7.21598 7.83508C7.56706 7.83508 7.91194 7.9891 8.21598 8.28166L11.5493 11.615M10.216 10.2817L10.8826 9.615C11.1867 9.32244 11.5316 9.16842 11.8826 9.16842C12.2337 9.16842 12.5786 9.32244 12.8826 9.615L14.216 10.9483M5.54932 3.61499H12.216C13.3206 3.61499 14.216 4.51042 14.216 5.61499V12.2817C14.216 13.3862 13.3206 14.2817 12.216 14.2817H5.54932C4.44475 14.2817 3.54932 13.3862 3.54932 12.2817V5.61499C3.54932 4.51042 4.44475 3.61499 5.54932 3.61499Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default InsertImage;
