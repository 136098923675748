import { UserOrganization } from "../models/organization";
import iframeStore from "../pages/IframeNew/iframe-store.tsx";

const getSelectedOrgKey = () => {
	return `${iframeStore?.isIframe() ? iframeStore?.iframe?.id + "_" : ""}selected_org`;
};

export const getSelectedOrganization = () => {
	const selectedOrg = localStorage.getItem(getSelectedOrgKey());
	return selectedOrg ? (JSON.parse(selectedOrg) as UserOrganization) : null;
};

export const setSelectedOrganization = (selectedOrg: UserOrganization) => {
	return localStorage.setItem(getSelectedOrgKey(), JSON.stringify(selectedOrg));
};

export const clearSelectedOrganization = () => {
	return localStorage.removeItem(getSelectedOrgKey());
};
