import { NavigateNext } from "@mui/icons-material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import { ExpandableMenuButtonProps } from "../../../models/expandable-menu";
import styles from "./ExpandableMenuButton.module.scss";

const ExpandableMenuButton = observer(
	({ children, onClick, buttonType = "textMedium", isActive, className, ...rest }: ExpandableMenuButtonProps) => {
		return (
			<BriaButton
				disableRipple
				className={clsx(styles.expandableMenuButton, { [styles.active]: isActive }, className)}
				buttonType={buttonType}
				fullWidth
				endIcon={<NavigateNext />}
				{...rest}
				onClick={onClick}
			>
				{children}
			</BriaButton>
		);
	},
);

ExpandableMenuButton.displayName = "ExpandableMenuButton";

export default ExpandableMenuButton;
