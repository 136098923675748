import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import FileUploader from "../../../../components/common/FileUploader/FileUploader";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import { Layout, defaultLayout } from "../../../../models/layout";
import LayoutPreview from "../Preview/LayoutPreview";
import LayoutForm from "./Form/LayoutForm";
import styles from "./LayoutEditor.module.scss";

export type EditLayoutLocationState = {
	layoutToEdit?: Layout;
	templateId?: number;
};

const LayoutEditor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layoutEditor" });
	const location = useLocation();
	const navigate = useSecureNavigate();
	const params = useParams();
	const { layoutsStore, uiStore } = useAppStore();
	const [layoutForm, setLayoutForm] = useState<Layout>(location.state?.layoutToEdit ?? defaultLayout);
	const [graphicImagesToUpload, setGraphicImagesToUpload] = useState<File[]>([]);
	const [graphicImagesToDelete, setGraphicImagesToDelete] = useState<string[]>([]);

	const handleFormChange = <K extends keyof Layout>(key: K, value: Layout[K]) => {
		setLayoutForm((prevLayout) => ({ ...prevLayout, [key]: value }));
	};

	useEffect(() => {
		uiStore.showBackButton(
			layoutForm.template_id
				? `${RouterConstants.TEMPLATE_EDITOR.editPath}/${layoutForm.template_id}`
				: RouterConstants.NEW_TEMPLATE_EDITOR.fullPath,
			undefined,
			"Back to Template",
			true,
			layoutsStore.isLoading,
			saveLayout,
		);
	}, [layoutForm]);

	useEffect(() => {
		uiStore.hideSideBar();

		if (!location.state?.layoutToEdit && params && !isNaN(Number(params.id))) {
			layoutsStore.getLayout(Number(params.id)).then(setLayoutForm);
		} else if (location.state?.templateId) {
			handleFormChange("template_id", location.state?.templateId);
		}

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, [params.id]);

	const uploadImgExample = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];

		if (file) {
			const imgExample = await layoutsStore.uploadImgExample(layoutForm.id, layoutForm.template_id, file);
			handleFormChange("image_example", imgExample);
		}
	};

	const deleteImgExample = async () => {
		const fileName = layoutForm.image_example?.split("/").pop();
		if (fileName) {
			await layoutsStore.deleteImgExample(layoutForm.id, layoutForm.template_id, fileName);
			handleFormChange("image_example", undefined);
		}
	};
	const saveLayout = async () => {
		if (layoutsStore.validate(layoutForm)) {
			let newLayoutId: number | undefined;
			if (layoutForm.id) {
				graphicImagesToDelete.length &&
					(await layoutsStore.deleteGraphics(graphicImagesToDelete, layoutForm.id, layoutForm.template_id));
				await layoutsStore.updateLayout(layoutForm);
			} else {
				newLayoutId = await layoutsStore.createLayout(layoutForm);
			}
			graphicImagesToUpload.length && (await uploadGraphicImages(newLayoutId));
			navigate(`${RouterConstants.TEMPLATE_EDITOR.editPath}/${layoutForm.template_id}`);
		}
	};

	const uploadGraphicImages = async (newLayoutId?: number) => {
		const filesWithIndexes = graphicImagesToUpload
			.map((file) => ({
				file,
				graphicIndex: layoutForm.graphical_elements?.findIndex(({ element }) => element === file.name) ?? -1,
			}))
			.filter(({ graphicIndex }) => graphicIndex !== -1);

		if (filesWithIndexes.length > 0) {
			await layoutsStore.uploadGraphics(filesWithIndexes, newLayoutId ?? layoutForm.id, layoutForm.template_id);
		}
	};

	return (
		<EditorLayout
			loading={layoutsStore.isLoadingEditor}
			name={layoutForm.name}
			onNameChange={(e) => handleFormChange("name", e.target.value)}
			errorName={!!layoutsStore.configErrors.name}
			primaryButton={t("saveBtn")}
			loadingPrimaryButton={layoutsStore.isLoadingSave}
			primaryButtonClick={saveLayout}
			secondaryButton={
				!!layoutForm.id && (
					<FileUploader
						fileName={layoutForm.image_example?.split("/").pop()}
						buttonText={t("uploadImg")}
						loading={layoutsStore.isLoadingImgExample}
						onUpload={uploadImgExample}
						onDelete={deleteImgExample}
						inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
					/>
				)
			}
		>
			<Box className={styles.content}>
				<LayoutForm
					{...{
						layoutForm,
						handleFormChange,
						graphicImagesToUpload,
						setGraphicImagesToUpload,
						graphicImagesToDelete,
						setGraphicImagesToDelete,
					}}
				/>
				<LayoutPreview
					layout={layoutForm}
					loading={layoutsStore.isLoadingEditor}
					onUpload={uploadImgExample}
					onDelete={deleteImgExample}
				/>
			</Box>
		</EditorLayout>
	);
};

export default observer(LayoutEditor);
