import { styled } from "baseui";
import React, { useContext } from "react";
import { useAppStore } from "../../../../../hooks/useStores";
import { PopUpSteps } from "../../../../constants/mock-data";
import { DesignEditorContext } from "../../../../contexts/DesignEditor";
import { useActiveObject } from "../../../../hooks/useActiveObject";
import useAppContext from "../../../../hooks/useAppContext";
import { useEditor } from "../../../../hooks/useEditor";
import useSmartImageHeader from "../../../../hooks/useSmartImageHeader.tsx";
import { LayerType, type ILayer } from "../../../../types";
import getSelectionType from "../../../../utils/get-selection-type";
import Items from "./Items";

const DEFAULT_TOOLBOX = "Canvas";

interface ToolboxState {
	toolbox: string;
}

const Toolbox = () => {
	const editor = useEditor();
	const { setActiveSubMenu } = useAppContext();
	const { isSmartImageHeaderVisible } = useSmartImageHeader();
	const activeObject = useActiveObject() as ILayer;
	const { isPopupView } = useContext(DesignEditorContext);
	const [state, setState] = React.useState<ToolboxState>({ toolbox: "Text" });
	const { designEditorStore } = useAppStore();

	const Container = styled("div", (_props) => ({
		minHeight: isPopupView ? "0px" : "105px",
	}));

	React.useEffect(() => {
		const selectionType = getSelectionType(activeObject, isPopupView);
		if (selectionType) {
			if (selectionType.length > 1) {
				setState({ toolbox: "Multiple" });
			} else {
				if (isPopupView && designEditorStore.activeStep === PopUpSteps.indexOf("text")) {
					setState({ toolbox: LayerType.STATIC_TEXT });
				} else {
					setState({ toolbox: selectionType[0] });
				}
			}
		} else {
			// To show the Canvas selected when Pressing outside the canvas (except when dealing with smart images)
			if (!isSmartImageHeaderVisible && !isPopupView) {
				editor?.objects.select("frame");
			}
			// To Show text toolbar always when we are in edit text step in the wizard
			if (isPopupView && designEditorStore.activeStep === PopUpSteps.indexOf("text")) {
				setState({ toolbox: LayerType.STATIC_TEXT });
			} else {
				setState({ toolbox: DEFAULT_TOOLBOX });
			}
			setState({ toolbox: DEFAULT_TOOLBOX });
			setActiveSubMenu("");
		}
		// To have always at least one text as active object in edit text tab inthe wizard
		if (
			editor &&
			isPopupView &&
			activeObject?.type !== LayerType.STATIC_TEXT &&
			designEditorStore.activeStep === PopUpSteps.indexOf("text")
		) {
			const staticTextObject = editor.canvas.canvas
				.getObjects()
				.find((object: fabric.Object) => object.type === LayerType.STATIC_TEXT);
			editor.state.setActiveObject(staticTextObject);
			staticTextObject && editor.canvas.canvas.setActiveObject(staticTextObject);
		}
	}, [activeObject]);

	// Committing this code as it impacts the appearance of all toolbars' popovers.
	// Keeping it here for easy reversion in case any issues arise from its removal

	// React.useEffect(() => {
	// 	let watcher = async () => {
	// 		if (activeObject) {
	// 			// @ts-ignore
	// 			const selectionType = getSelectionType(activeObject, isPopupView) as any;

	// 			if (selectionType.length > 1) {
	// 				setState({ toolbox: "Multiple" });
	// 			} else {
	// 				setState({ toolbox: selectionType[0] });
	// 			}
	// 		}
	// 	};
	// 	if (editor) {
	// 		editor.on("history:changed", watcher);
	// 	}
	// 	return () => {
	// 		if (editor) {
	// 			editor.off("history:changed", watcher);
	// 		}
	// 	};
	// }, [editor, activeObject]);

	// @ts-ignore
	const Component = state.toolbox == "Frame" ? Items["Canvas"] : Items[state.toolbox];

	return <Container>{Component ? <Component /> : state.toolbox}</Container>;
};

export default Toolbox;
