import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import { IMAGE_REFERENCE_CONFIGS } from "../../../../../constants/TextToImageConstants";
import { useAppStore } from "../../../../../hooks/useStores";
import { GalleryItemProps, SyntheticEventProps } from "../../../../../models/text-to-image";
import { createFileList, urlToFile } from "../../../../../utils";
import styles from "./ImageReferenceGallery.module.scss";

const GalleryItem = ({ title, images, onClose }: GalleryItemProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.imageReference",
	});
	const { textToImageStore } = useAppStore();

	const handleImageClick = async (imageUrl: string, prompt: string, influence: number) => {
		try {
			const filename = imageUrl.split("/").pop() || "image";
			const file = await urlToFile(imageUrl, filename);

			const syntheticEvent: SyntheticEventProps = {
				target: {
					files: file ? createFileList([file]) : null,
					value: "",
				},
			};
			textToImageStore.handleConfigChange("prompt", prompt);
			textToImageStore.handleConfigChange("image_reference", {
				...textToImageStore.config.image_reference,
				structure_ref_influence: influence,
			});
			textToImageStore.handleUploadImage(syntheticEvent);
			textToImageStore.handleConfigChange("tailored_model_influence", 0.5);
			textToImageStore.handleConfigChange(
				"steps_num",
				textToImageStore.config.fast
					? IMAGE_REFERENCE_CONFIGS.steps.fast.default
					: IMAGE_REFERENCE_CONFIGS.steps.not_fast.default,
			);

			onClose?.();
		} catch (error) {
			console.error("Error processing the image:", error);
		}
	};

	return (
		<Box className={styles.ImageReferenceGalleryItem}>
			<Typography className={styles.title}>{title}</Typography>
			<Box className={styles.images}>
				{images.map((image, index) => (
					<BriaButton
						key={index}
						className={clsx(styles.imageBtn, {
							[styles.active]:
								textToImageStore.imageReference?.file?.name &&
								image.url.includes(textToImageStore.imageReference?.file?.name || ""),
						})}
						onClick={() => handleImageClick(image.url, t(image.prompt), image.influence)}
					>
						<LazyLoadImage src={image.url} className={styles.imageItem} effect="blur" threshold={300} />
					</BriaButton>
				))}
			</Box>
		</Box>
	);
};

export default observer(GalleryItem);
