import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { UserOrganization } from "../../../models/organization.ts";
import BriaButton from "../BriaButton/BriaButton.tsx";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder.tsx";
import OrganizationsDropdown from "../OrganizationsDropdown/OrganizationsDropdown.tsx";
import styles from "./SelectOrganizationPopup.module.scss";

interface IProps {
	open: boolean;
	onClose: (selectedOrg: UserOrganization | null) => void;
}

const SelectOrganizationPopup = ({ open, onClose }: IProps) => {
	const { authStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "selectOrganization" });
	const [selectedOrg, setSelectedOrg] = useState<UserOrganization | null>(getSelectedOrganization());

	const handleContinue = () => {
		onClose(selectedOrg);
	};

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={() => onClose(null)}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<IconButton onClick={() => onClose(null)} className={styles.closeButton}>
					<Close />
				</IconButton>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("header")}</Typography>
					<Typography className={styles.description}>{t("descriptionCheckout")}</Typography>
					<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoadingUserOrganizations}>
						<Box className={styles.form}>
							<OrganizationsDropdown
								reload={false}
								placeholder={t("dropdownPlaceholder")}
								onOrgChange={(value) => {
									setSelectedOrg(value);
								}}
								className={styles.orgsDropdown}
							/>
							<BriaButton
								buttonType="primary"
								onClick={handleContinue}
								className={styles.primaryBtn}
								fullWidth={true}
								disabled={!selectedOrg}
							>
								{t("primaryBtn")}
							</BriaButton>
						</Box>
					</LoadingPlaceholder>
				</Box>
			</Box>
		</Dialog>
	);
};

export default observer(SelectOrganizationPopup);
