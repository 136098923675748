import { TFunction } from "i18next";
import BottomCenter from "../assets/images/icons/BottomCenter";
import Center from "../assets/images/icons/Center";
import LeftBottom from "../assets/images/icons/LeftBottom";
import LeftCenter from "../assets/images/icons/LeftCenter";
import LeftTop from "../assets/images/icons/LeftTop";
import RightBottom from "../assets/images/icons/RightBottom";
import RightCenter from "../assets/images/icons/RightCenter";
import RightTop from "../assets/images/icons/RightTop";
import TopCenter from "../assets/images/icons/TopCenter";

export interface PlacementType {
	value: string;
	label: string;
	icon: any; // please note that any is used here for a reason that there is an issue passing the svg method
}

export const API_PLACEMENT_TYPES = (t: TFunction): PlacementType[] => [
	{
		value: "upper_left",
		label: t("placements.leftTop"),
		icon: LeftTop,
	},
	{
		value: "upper_center",
		label: t("placements.centerTop"),
		icon: TopCenter,
	},
	{
		value: "upper_right",
		label: t("placements.rightTop"),
		icon: RightTop,
	},
	{
		value: "left_center",
		label: t("placements.leftCenter"),
		icon: LeftCenter,
	},
	{
		value: "center",
		label: t("placements.center"),
		icon: Center,
	},
	{
		value: "right_center",
		label: t("placements.rightCenter"),
		icon: RightCenter,
	},
	{
		value: "bottom_left",
		label: t("placements.leftBottom"),
		icon: LeftBottom,
	},
	{
		value: "bottom_center",
		label: t("placements.centerBottom"),
		icon: BottomCenter,
	},
	{
		value: "bottom_right",
		label: t("placements.rightBottom"),
		icon: RightBottom,
	},
];
