import { AspectRatio } from "../components/common/DropDowns/StaticDropdown";
import { Status } from "../components/common/DropDowns/useStaticDropdown";
import { APPS } from "../constants/AppsConstants.ts";
import RouterConstants from "../constants/RouterConstants.ts";
import { IframeImageGenerationFoundationModels } from "../pages/IframeNew/IframeEditor/Form/IframeImageGenerationConfig/IframeImageGenerationFoundationModels.tsx";
import { ImageEditingViewMode } from "./image-to-image.ts";
import { Organization } from "./organization";

export type IframePages = APPS;

export type ImageEditingIframeConfig = {
	default_view_mode: ImageEditingViewMode;
};

export type ImageGenerationIframeConfig = {
	enabled_foundation_models?: IframeImageGenerationFoundationModels;
	enabled_tg_models?: string[];
	default_aspect_ratio?: AspectRatio;
	enable_structure_guidance?: boolean;
};

export type IframeCustomStyle = {
	logo?: string;
	cssVars?: { [varKey: string]: string };
};

export type GeneralIframeConfig = {
	enable_close_window_btn?: boolean;
	enable_save?: boolean;
	enable_download?: boolean;
	enable_upload?: boolean;
	save_label?: string;
};

export type CampaignIframeConfig = {
	enable_admin_mode?: boolean;
	enable_public_templates?: boolean;
	enable_org_campaigns?: boolean;
};

export type IframeConfig = {
	allowed_domains: string[];
	enabled_pages: Partial<{ [page in IframePages]: boolean }>;
	landing_page: IframePages;
	custom_style?: IframeCustomStyle;
	general_config?: GeneralIframeConfig;
	image_generation_config?: ImageGenerationIframeConfig;
	image_editing_config?: ImageEditingIframeConfig;
	campaign_config?: CampaignIframeConfig;
};

export type Iframe = {
	id: string;
	name: string;
	description: string;
	status: Status;
	config: IframeConfig;
	org_id?: string;
	created_at: Date;
	updated_at: Date;
	organization: Organization;
	username: string;
	password: string;
};

export const getDefaultIframe = (orgId: string) =>
	({
		id: "",
		name: "Untitled - Iframe Name",
		description: "",
		status: "Pending",
		config: {
			allowed_domains: [],
			enabled_pages: {
				textToImage: true,
				textToVector: true,
				imageToImage: true,
				campaign: true,
			},
			landing_page: APPS.TEXT_TO_IMAGE,
			custom_style: {
				cssVars: {
					primary_main_color: "#8800FF",
				},
			},
			general_config: {
				enable_close_window_btn: false,
				enable_download: true,
				enable_save: true,
				enable_upload: true,
			},
			image_generation_config: {
				enable_structure_guidance: true,
			},
			image_editing_config: {
				default_view_mode: ImageEditingViewMode.GALLERY,
			},
			campaign_config: {
				enable_admin_mode: false,
				enable_org_campaigns: true,
				enable_public_templates: true,
			},
		},
		created_at: new Date(),
		updated_at: new Date(),
		organization: {
			id: orgId,
			allowed_pages: {
				textToImage: true,
				textToVector: true,
				imageToImage: true,
				campaign: true,
			},
			is_blocked: false,
		},
		org_id: orgId,
		username: "",
		password: "",
	}) as Iframe;

export enum IframePostMessageTypes {
	InitPreview = "Bria_InitPreview",
	InitialLoadComplete = "Bria_InitialLoadComplete",
	CloseClicked = "Bria_CloseClicked",
	Navigate = "Bria_Navigate",
	Action = "Bria_Action",
	AuthFailed = "Bria_AuthFailed",
}

export enum IframePostMessageActionTypes {
	Upload = "upload",
	Generate = "generate",
	EraseObject = "erase_object",
	Download = "download",
	Save = "save",
}

export enum IframePostMessageItemTypes {
	Campaign = "campaign",
	Template = "template",
	Image = "image",
	Psd = "psd",
}

export const iframeRoutes: Partial<{ [landingPage in IframePages]: string }> = {
	textToImage: `${RouterConstants.TEXT_TO_IMAGE.fullPath}`,
	textToVector: `${RouterConstants.TEXT_TO_VECTOR.fullPath}`,
	imageToImage: `${RouterConstants.IMAGE_TO_IMAGE.fullPath}`,
	campaign: `${RouterConstants.DESIGN_EDITOR.fullPath}`,
};
