// @ts-ignore
function GenerateBackground({ size, onClick }: { size: number; onClick?: () => void }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			onClick={onClick}
		>
			<path
				d="M10.8828 4.94824L12.8828 6.94824M4.88281 14.9482L14.8828 4.94824L12.8828 2.94824L2.88281 12.9482L4.88281 14.9482ZM6.88281 2.94824C6.88281 3.30186 7.02329 3.641 7.27334 3.89105C7.52339 4.1411 7.86252 4.28158 8.21615 4.28158C7.86252 4.28158 7.52339 4.42205 7.27334 4.6721C7.02329 4.92215 6.88281 5.26129 6.88281 5.61491C6.88281 5.26129 6.74234 4.92215 6.49229 4.6721C6.24224 4.42205 5.9031 4.28158 5.54948 4.28158C5.9031 4.28158 6.24224 4.1411 6.49229 3.89105C6.74234 3.641 6.88281 3.30186 6.88281 2.94824ZM13.5495 9.61491C13.5495 9.96853 13.69 10.3077 13.94 10.5577C14.1901 10.8078 14.5292 10.9482 14.8828 10.9482C14.5292 10.9482 14.1901 11.0887 13.94 11.3388C13.69 11.5888 13.5495 11.928 13.5495 12.2816C13.5495 11.928 13.409 11.5888 13.159 11.3388C12.9089 11.0887 12.5698 10.9482 12.2161 10.9482C12.5698 10.9482 12.9089 10.8078 13.159 10.5577C13.409 10.3077 13.5495 9.96853 13.5495 9.61491Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default GenerateBackground;
