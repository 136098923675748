import { makeAutoObservable, runInAction } from "mobx";

import useErrorToast from "../hooks/useErrorToast";
import { defaultBrandDefinition } from "../models/brandDefinition";
import { CampaignEntityStatus, PaginatedItems } from "../models/common";
import { Font } from "../models/font";
import QueryService from "../utils/QueryService";
import { IDesign } from "./interfaces/DesignEditor";
import { BrandDefinition } from "./models/brandDefinition";

export interface IBrandsDefinitionStore {
	isLoading: boolean;
	isCreating: boolean;
	isUpdating: boolean;
	isError: boolean;
	isApplyingBrandConfigurations: boolean;
	isResettingBrandConfigurations: boolean;
	orgFonts: Font[];
	brands: PaginatedItems<BrandDefinition>;
	selectedBrand: BrandDefinition;
	loadingUpload: boolean;
	isCreatingColorPallet: boolean;
	isUploadingBrandLogo: boolean;
	hasBrandReset: boolean;
	templateScenesBeforeApplyBrand?: IDesign;
	templateScenesBeforeApplyBrandInWizard: IDesign | undefined;
	formErrors: { invalidName?: boolean };
	loadBrandsDefinition(): Promise<void>;
	handleBrandChange: <K extends keyof BrandDefinition>(key: K, value: BrandDefinition[K]) => void;
	handleCreateNewBrand(): Promise<void>;
	handleUpdateBrand(): Promise<void>;
	handleDeleteBrand(brand: BrandDefinition): Promise<void>;
	createColorPallete(colors: any): Promise<string>;
	getAllOrgFonts(includePublic?: boolean): Promise<void>;
	uploadFont(newFont: Font, fontFile: File): Promise<number>;
	uploadBrandLogo(brandDefinitionId: number, type: string, file?: File, id?: number): Promise<string>;
	setProperty<K extends keyof BrandsDefinitionStore>(key: K, value: BrandsDefinitionStore[K]): void;
}

export default class BrandsDefinitionStore implements IBrandsDefinitionStore {
	private brandsQueryService: QueryService = new QueryService("/brands-definition");
	private brandsFontsQueryService: QueryService = new QueryService("/brands-definition/fonts");
	private brandsLogosQueryService: QueryService = new QueryService("/brands-definition/logos");

	errorToast = useErrorToast();
	isLoading: boolean = false;
	isCreating: boolean = false;
	isUpdating: boolean = false;
	isError: boolean = false;
	isApplyingBrandConfigurations: boolean = false;
	isResettingBrandConfigurations: boolean = false;
	brands: PaginatedItems<BrandDefinition> = { total: 0, items: [] };
	selectedBrand: BrandDefinition = defaultBrandDefinition;
	orgFonts: Font[] = [];
	loadingUpload: boolean = false;
	isCreatingColorPallet: boolean = false;
	isUploadingBrandLogo: boolean = false;
	hasBrandReset: boolean = false;
	templateScenesBeforeApplyBrand?: IDesign = undefined;
	templateScenesBeforeApplyBrandInWizard: IDesign | undefined = undefined;
	formErrors: { invalidName?: boolean } = {};
	constructor() {
		makeAutoObservable(this);
	}

	setProperty<K extends keyof BrandsDefinitionStore>(key: K, value: BrandsDefinitionStore[K]): void {
		runInAction(() => {
			(this as any)[key] = value;
		});
	}

	loadBrandsDefinition = async (): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const brands: PaginatedItems<BrandDefinition> = await this.brandsQueryService.get("/", {});

			runInAction(() => {
				this.brands = brands;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw e;
		}
	};

	handleBrandChange = <K extends keyof BrandDefinition>(key: K, value: BrandDefinition[K]): void => {
		try {
			runInAction(() => {
				this.selectedBrand = {
					...this.selectedBrand,
					[key]: value,
				};
			});
		} catch (e) {
			console.log("Error ", e);
		}
	};

	handleCreateNewBrand = async (): Promise<void> => {
		try {
			this.isCreating = true;

			const newBrand: any = {
				name: this.selectedBrand.name,
				description: this.selectedBrand.description,
				color_pallete_id: this.selectedBrand.color_pallete_id,
				org_id: this.selectedBrand.org_id,
				user_id: this.selectedBrand.user_id,
				status: CampaignEntityStatus.ACTIVE,
				brand_texts: this.selectedBrand.brand_texts?.map((brandText, index) => {
					return {
						type: `FONT_${index + 1}`,
						font_id: brandText.font_id !== 0 ? brandText.font_id : null,
					};
				}),
			};

			const response = await this.brandsQueryService.post("/", newBrand);

			runInAction(() => {
				this.isCreating = false;
				this.isError = false;
			});
			return Promise.resolve(response);
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			this.isCreating = false;
			this.isError = true;
			return Promise.reject(`Error creating new Brand: ${e.message || e.toString()}`);
		}
	};

	handleUpdateBrand = async (): Promise<void> => {
		try {
			this.isUpdating = true;
			this.isLoading = true;
			const updatedBrand: any = {
				...this.selectedBrand,
				brand_texts: this.selectedBrand.brand_texts?.map((brandText, _index) => {
					return {
						id: brandText.id,
						font_id: brandText.font_id !== 0 ? brandText.font_id : null,
						type: brandText.type,
					};
				}),
			};
			const response = await this.brandsQueryService.put("/", updatedBrand);
			runInAction(() => {
				this.isUpdating = false;
				this.isLoading = false;
				this.isError = false;
			});
			return Promise.resolve(response);
		} catch (e: any) {
			if (e.response?.status === 409) {
				this.formErrors.invalidName = true;
			}
			this.isUpdating = false;
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	handleDeleteBrand = async (brand: BrandDefinition): Promise<void> => {
		try {
			this.isUpdating = true;
			const updatedBrand: any = {
				...brand,
				status: CampaignEntityStatus.DELETED,
			};
			const response = await this.brandsQueryService.put("/", updatedBrand);
			runInAction(() => {
				this.brands.items = this.brands.items.filter((brand) => brand.id !== updatedBrand.id);
				this.isUpdating = false;
				this.isError = false;
			});
			return Promise.resolve(response);
		} catch (e) {
			this.isUpdating = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	createColorPallete = async (colors: any): Promise<string> => {
		try {
			this.isCreatingColorPallet = true;
			const res = await this.brandsQueryService.post(`/color_pallete`, colors);
			this.isCreatingColorPallet = false;
			return res;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isCreatingColorPallet = false;
			});
			return Promise.reject(`Error uploading PSD file: ${e.message || e.toString()}`);
		}
	};

	getAllOrgFonts = async (includePublic: boolean = false): Promise<void> => {
		try {
			this.isError = false;

			const response = await this.brandsFontsQueryService.get("/all", {
				params: {
					include_public: includePublic,
				},
			});

			const fonts: Font[] = response;

			runInAction(() => {
				this.orgFonts = fonts;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	uploadFont = async (newFont: Font, fontFile: File): Promise<number> => {
		this.isError = false;
		this.loadingUpload = true;
		try {
			const formData = new FormData();
			formData.append("file", fontFile);
			formData.append("new_font", JSON.stringify(newFont));
			const font = await this.brandsFontsQueryService.post("/upload", formData, {
				"Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.isError = false;
				this.loadingUpload = false;
			});
			return font;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.loadingUpload = false;
			});
			return Promise.reject(`Error creating new font: ${e.message || e.toString()}`);
		}
	};

	uploadBrandLogo = async (brandDefinitionId: number, type: string, file?: File, id?: number): Promise<string> => {
		try {
			const formData = new FormData();
			if (file) {
				formData.append("file", file);
			}
			if (id) {
				formData.append("logo_id", id.toString());
			}
			formData.append("brand_definition_id", brandDefinitionId.toString());
			formData.append("type", type.toString());
			const res = await this.brandsLogosQueryService.post(`/`, formData, {
				"Content-Type": "multipart/form-data",
			});
			return res;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(`Error uploading brand logo: ${e.message || e.toString()}`);
		}
	};
}
