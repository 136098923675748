import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import BriaImage from "../../Galleries/BriaImage";
import FullScreenImageOverlay from "../Overlay/FullScreenImageOverlay";
import styles from "./FullScreenImage.module.scss";

export type FullScreenImageType = {
	src: string;
	openCompare?: boolean;
	compareTo?: string;
	compareToLabel?: string;
	compareLabel?: string;
	fileName?: string;
	format?: "png" | "svg";
	hide?: boolean;
};

type FullScreenImageProps = {
	image: FullScreenImageType;
	index: number;
	displayMode: "arrows" | "scroll";
	displayedImages: FullScreenImageType[];
	setDisplayedImages: (images: FullScreenImageType[]) => void;
	onZoom?: (zoomed: boolean) => void;
	hide?: boolean;
	onDownload?: () => void;
	onCompare?: () => void;
	hideDownloadIcon?: boolean;
};

const FullScreenImage = ({
	image,
	index,
	displayMode,
	displayedImages,
	setDisplayedImages,
	onZoom,
	hide,
	onDownload,
	onCompare,
	hideDownloadIcon,
}: FullScreenImageProps) => {
	const [zoomSrc, setZoomSrc] = useState<string>();

	const onImageZoom = (zoomed: boolean, zoomSrc: string) => {
		if (zoomed) {
			setZoomSrc(zoomSrc);
		} else {
			setZoomSrc(undefined);
		}
		onZoom && onZoom(zoomed);
	};

	return (
		<Box
			className={clsx(styles.viewer, {
				[styles.sideBySideView]: image.openCompare,
				[styles.hidden]: image.hide,
				[styles.hiddenVisibility]: hide,
			})}
		>
			{image.openCompare && image.compareTo && (
				<Box
					className={clsx(styles.imageContainer, {
						[styles.hiddenVisibility]: !!zoomSrc && zoomSrc !== "compare",
					})}
				>
					<BriaImage
						url={image.compareTo}
						className={styles.image}
						zoomable
						onZoom={(zoomed) => {
							onImageZoom(zoomed, "compare");
						}}
						displayOverlay={zoomSrc ? "off" : undefined}
						ImageOverlay={
							<FullScreenImageOverlay
								image={{ src: image.compareTo }}
								{...{ index, displayMode, displayedImages, setDisplayedImages }}
								onDownload={onDownload}
								onCompare={onCompare}
								hideDownloadIcon={hideDownloadIcon}
							/>
						}
						hideFullScreenButton
						onDownload={onDownload}
						{...image}
						image={image}
					/>
					<Typography>{image.compareToLabel}</Typography>
				</Box>
			)}
			<Box
				className={clsx(styles.imageContainer, {
					[styles.hiddenVisibility]: !!zoomSrc && zoomSrc !== "original",
				})}
			>
				<BriaImage
					url={image.src}
					className={styles.image}
					zoomable
					onZoom={(zoomed) => {
						onImageZoom(zoomed, "original");
					}}
					displayOverlay={zoomSrc ? "off" : undefined}
					ImageOverlay={
						<FullScreenImageOverlay
							{...{ image, index, displayMode, displayedImages, setDisplayedImages }}
							onDownload={onDownload}
							onCompare={onCompare}
							hideDownloadIcon={hideDownloadIcon}
						/>
					}
					hideFullScreenButton
					onDownload={onDownload}
					{...image}
					image={image}
					hideDownloadIcon={hideDownloadIcon}
				/>
				<Typography>{image.compareLabel}</Typography>
			</Box>
		</Box>
	);
};
export default observer(FullScreenImage);
