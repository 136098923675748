import { Feedback } from "../pages/Playground/Images/Overlay/PlaygroundImageOverlay.tsx";
import QueryService from "../utils/QueryService.ts";

class ImagesService {
	queryService = new QueryService("/images/");
	imageModificationQueryService = new QueryService("/image_modifications/");

	exportPsd = async (imageUrl: string): Promise<string> => {
		const { url: psdUrl } = await this.queryService.post("export_psd", {
			url: imageUrl,
		});
		return psdUrl;
	};

	sendFeedback = (newFeedback: Feedback): Promise<any> => {
		return this.queryService.post("feedback", newFeedback);
	};

	increaseResolution = async (imageUrl: string, resolution: number): Promise<any> => {
		const response = await this.imageModificationQueryService.post("image/increase_resolution", {
			image_url: imageUrl,
			desired_increase: resolution,
		});
		const result = response[0];
		return result.result_url;
	};
}

export default ImagesService;
