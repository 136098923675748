import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { FilterByOptions } from "../../../../../../../components/common/BriaTable/BriaTable";
import DebouncedInput from "../../../../../../../components/common/DebouncedInput/DebouncedInput";
import LoadingPlaceholder from "../../../../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { BRIA_PUBLIC_ORG_ID } from "../../../../../../../constants/OrgConstants";
import { useAppStore } from "../../../../../../../hooks/useStores";
import { Font } from "../../../../../../../models/font";
import { convertS3UriToHttpsUrl } from "../../../../../../../pages/Campaign/Brands/utils";
import { loadFont } from "../../../../../../../utils";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText, LayerType } from "../../../../../../types";
import { loadFonts } from "../../../../../../utils/fonts";
import styles from "./FontSelector.module.scss";

interface FontOption {
	key: string;
	value: number;
	src: string;
	orgId: string;
	label?: string;
}

interface FontGroup {
	name: string;
	options: FontOption[];
}

const FontSelector = () => {
	const { brandFontStore, campaignStore } = useAppStore();
	const editor = useEditor();
	const [fontsOptions, setFontsOptions] = useState<FontGroup[]>([]);
	const { EditAllAds } = useDesignEditorUtils();
	const activeObject = useActiveObject() as Required<IStaticText>;
	useEffect(() => {
		brandFontStore.getAllOrgBrandFonts(true).then(() => {
			groupFonts();
		});
	}, []);

	useEffect(() => {
		fontsOptions && fontsOptions?.forEach((group: any) => group.options.forEach(loadFont));
	}, [fontsOptions]);

	const handleFontFamilyChange = async (fnt: any) => {
		if (editor) {
			const font = {
				name: fnt.key,
				url: convertS3UriToHttpsUrl(fnt.src),
			};
			await loadFonts([font]);
			editor.objects.update(
				{
					fontFamily: font.name,
					fontURL: font.url,
					brandId: undefined,
				},
				undefined,
				!campaignStore.isEditingAllAds,
			);
			EditAllAds(LayerType.STATIC_TEXT, activeObject?.textType, FeatureType.FONT, font);
		}
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			search: { text: e.target.value, fields: ["name", "updated_at"] },
		};
		brandFontStore.loadBrandFonts(true, updatedFilterBy).then(() => {
			groupFonts();
		});
	};

	const groupFonts = () => {
		const fontOptions = brandFontStore?.brandOrgFonts?.map((font: Font) => ({
			key: font.name,
			value: font.id,
			src: font.src,
			orgId: font.org_id,
		}));

		const groupedFontOptions = fontOptions.reduce(
			(groupedFontOptions: Record<string, FontOption[]>, font: FontOption) => {
				const orgId = font.orgId;
				if (!groupedFontOptions[orgId]) {
					groupedFontOptions[orgId] = [];
				}
				groupedFontOptions[orgId].push({
					key: font.key,
					label: font.key,
					value: font.value,
					src: font.src,
					orgId: font.orgId,
				});
				return groupedFontOptions;
			},
			{} as Record<string, FontOption[]>,
		);

		const privateFonts = Object.entries(groupedFontOptions)
			.filter(([orgId]) => orgId !== BRIA_PUBLIC_ORG_ID)
			.map(([, options]) => ({ name: "Private Fonts", options }));

		// Step 2: Get public fonts
		const publicFonts = Object.entries(groupedFontOptions)
			.filter(([orgId]) => orgId === BRIA_PUBLIC_ORG_ID)
			.map(([, options]) => ({ name: "Public Fonts", options }));

		// Combine private and public fonts
		const orgFonts = [...privateFonts, ...publicFonts];
		setFontsOptions(orgFonts);
	};

	return (
		<Box className={styles.fontSelectorContainer}>
			<MainTabHeader title="Fonts" />
			<Box className={`${styles.tabs} ${styles.fullHeight}`}>
				<Box className={styles.fontTabContent}>
					<Box className={styles.searchBoxContainer}>
						<DebouncedInput
							onChange={handleSearchChange}
							className={styles.customBriaInput}
							onKeyDown={(e) => e.stopPropagation()}
							height="40px"
							placeHolder={"Search"}
						/>
					</Box>
					<LoadingPlaceholder className={styles.loading} isLoading={brandFontStore.isLoading}>
						<Box className={styles.fontOptionsContainer}>
							{fontsOptions.map((group, groupIndex) => (
								<React.Fragment key={groupIndex}>
									<Box>
										<Box className={styles.fontGroupTitle}>{group.name}</Box>
										{group.options.map((font: any, index: number) => (
											<Box
												className={styles.fontOption}
												style={{ fontFamily: font.label }}
												key={index}
												onClick={() => handleFontFamilyChange(font)}
												id={font.value}
											>
												{font.key}
											</Box>
										))}
									</Box>
									{groupIndex !== fontsOptions.length - 1 && (
										<Divider key={`divider_${groupIndex}`} className={styles.divider} />
									)}
								</React.Fragment>
							))}
						</Box>
					</LoadingPlaceholder>
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(FontSelector);
export default ObservedComponent;
