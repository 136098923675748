import { Box } from "@mui/material";
import ColorPicker from "react-best-gradient-color-picker";
import styles from "./SolidGradientColorPicker.module.scss";
export type Iprops = {
	onChange: (color: string) => void;
	color: string;
	width?: number;
	hideColorTypeBtns?: boolean;
	hideOpacity?: boolean;
};

const SolidGradientColorPicker = ({ onChange, color, width = 284, hideColorTypeBtns = false, hideOpacity = false }: Iprops) => {
	return (
		<Box className={styles.container}>
			<ColorPicker hideColorTypeBtns={hideColorTypeBtns} hideOpacity={hideOpacity} width={width} value={color} onChange={onChange} />
		</Box>
	);
};

export default SolidGradientColorPicker;
