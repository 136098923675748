import { ReactNode } from "react";
import { BrandConfigType } from "../pages/Playground/ImageToImage/Config/Features/Brand/BrandConfig";
import { StyleConfigType } from "../pages/Playground/ImageToImage/Config/Features/Style/StyleConfig";
import { PlaygroundImage } from "./playground"
// TODO: Remove the re-export and update the imports where these types are used
export type { PlaygroundImage, PlaygroundResult, imageDimensions } from "./playground";

export type ImageToImageConfigType = {
	original_image: PlaygroundImage;
	style?: StyleConfigType;
	background?: BackgroundConfigType;
	brand?: BrandConfigType;
	size?: SizeConfigType;
	num_results: number;
	foreground_placement?: ForegroundPlacementType;
};

export enum ImageEditingViewMode {
	SINGLE = "single",
	GALLERY = "gallery",
}

export type ITIStyleProps = {
	url: string;
	face_seed?: number;
	face_sd_edit?: number;
	face_mask_steps?: number;
	num_inference_steps?: number;
	is_normalize_face?: boolean;
	canny_scale?: number;
	body_seed?: number;
	body_sd_edit?: number;
	logo_enhance?: boolean;
	box_threshold?: number;
	text_threshold?: number;
	max_size_height?: number;
	max_size_width?: number;
	min_size?: number;
};

export type ITIBgProps = {
	remove?: RemoveBgRes;
	blur?: BlurBgRes;
	replace?: ReplaceBgRes;
};

type RemoveBgRes = {
	result_url: string;
};

type BlurBgRes = {
	result_url: string;
	sid: string;
};

type ReplaceBgRes = {
	result_url: string;
	seed: string;
	sid: string;
};

export type OfflineProps = {
	prefix_id: string;
	variation: number;
	original_size?: {
		width?: number;
		height?: number;
	};
};

export type ReplaceBgConfig = {
	bg_prompt?: string;
	refine_prompt?: boolean;
};

export type ConfigProps = {
	title: string;
	checked?: boolean;
	onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
	isActive?: boolean;
	setIsActive?: (config: string) => void;
	titleOnly?: boolean;
	children?: React.ReactNode;
};

export type BackgroundConfigType = {
	remove?: boolean;
	replace?: ReplaceBgConfig;
	expand?: boolean;
	solid?: { selected?: boolean; color_code?: string };
	generateByText?: {
		selected?: boolean;
		model_version?: string;
		prompt?: string;
		num_results?: number;
		fast_version?: boolean;
	};
	generatedBackgrounds?: {
		selected?: boolean;
		image?: PlaygroundImage;
	};
	original?: boolean;
	is_fox?: boolean;
	blur?: boolean;
};

export type ForegroundPlacementType = {
	placements?: {
		selected?: boolean;
		values?: string[];
	};
};

export type SizeConfigType = {
	crop?: boolean;
	aspect_ratio?: {
		selected?: boolean;
		value?: string[];
	};
	custom_size?: {
		selected?: boolean;
		width?: number;
		height?: number;
	};
	medium_destination?: {
		selected?: boolean;
		platform?: Platform;
		destinations?: PlatformDestination[];
	};
};

export type ResponsePlatformDestination = {
	name: string;
	width: number;
	height: number;
	aspectRatioHeight: number;
	aspectRatioWidth: number;
	index: number;
	resize: { width: number; height: number };
};

export type ResponsePlatform = {
	key: string;
	value?: ResponsePlatformDestination[];
};

export type PlatformDestination = {
	name: string;
	width: number;
	height: number;
};

export type Platform = {
	key: string;
	value?: PlatformDestination[];
	get?: (platform: string) => ResponsePlatformDestination[] | undefined;
};

export type ConfigValue = boolean | string | number | object | File;

export interface ConfigDisplayProps {
	config: any;
	onDelete: (configKey: string, value: any) => void;
	onClick?: () => void;
	expandTo?: ReactNode;
	subMenuPanelProps?: Record<string, any>;
}

export interface ConfigItemProps {
	configKey: string;
	onDelete: (configKey: string, value: any) => void;
	label?: string;
	value: any;
	onClick?: () => void;
	children?: React.ReactNode;
}

export enum ConfigKeys {
	Selected = "selected",
	Prompt = "prompt",
	TailoredModelId = "tailored_model_id",
	Solid = "solid",
	GenerateByText = "generateByText",
	AspectRatio = "aspect_ratio",
	CustomSize = "custom_size",
	MediumDestination = "medium_destination",
	Placements = "placements",
}

export type SelectedConfigsProps = {
	background?: string[];
	size?: string[];
	foreground_placement?: string[];
	image_reference?: string[];
};
