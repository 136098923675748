import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HubgalleryImage } from "../../../constants/HubGalleryImagesConstants";
import RouterConstants from "../../../constants/RouterConstants";
import useSecureNavigate from "../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../models/image-to-image.ts";
import BriaButton from "../BriaButton/BriaButton";
import styles from "./HubImageOverlay.module.scss";

export type HubImageOverlayProps = {
	image: HubgalleryImage;
};

const HubImageOverlay = ({ image }: HubImageOverlayProps) => {
	const { playgroundStore } = useAppStore();
	const navigate = useSecureNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "galleryHub" });

	return (
		<Box className={styles.overlay}>
			<Box className={styles.overlayContent}>
				<Typography className={styles.prompt}>{t(image?.prompt)}</Typography>
				<Box className={styles.buttonsContainer}>
					<BriaButton
						onClick={(e) => {
							navigate(RouterConstants.TEXT_TO_IMAGE.path, {
								state: { prompt: t(image?.prompt) },
							});
							e.stopPropagation();
						}}
						className={styles.button}
					>
						{t("Generate")}
					</BriaButton>
					<BriaButton
						onClick={(e) => {
							const playgroundImage = {
								id: image?.vhash ?? "",
								vhash: image?.vhash ?? "",
								url: image?.url ?? "",
							} as PlaygroundImage;
							playgroundStore.playgroundResults = [
								{
									id: "",
									images: [playgroundImage],
								},
							];
							playgroundStore.onAiEditorButtonClick(playgroundImage, navigate);
							e.stopPropagation();
						}}
						className={styles.button}
					>
						{t("editUsingAI")}
					</BriaButton>
				</Box>
			</Box>
		</Box>
	);
};

export default HubImageOverlay;
