import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ErrorIcon from "../../../assets/images/svgs/face-id-error.svg";
import { ORG_SUBSCRIPTION_PLANS } from "../../../constants/billing.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import iframeStore from "../../../pages/IframeNew/iframe-store.tsx";
import BriaButton from "../BriaButton/BriaButton.tsx";
import styles from "./ApiLimitReachedBanner..module.scss";

const ApiLimitReachedBanner = () => {
	const { t } = useTranslation("translation", { keyPrefix: "reachedFreeLimitsBanner" });
	const { authStore, pricingStore, uiStore } = useAppStore();
	const orgUsage = authStore.orgUsage?.apiUsage ?? 0;
	const freeLimit = authStore.orgUsage?.apiFreeCallsLimit ?? 1000;
	const freeLimitWarningThreshold = freeLimit - freeLimit * 0.2;
	const isAboutToBreakLimit = orgUsage >= freeLimitWarningThreshold && orgUsage < freeLimit;

	return (
		<>
			{!iframeStore.isIframe() &&
				(authStore.orgPassedFreeLimit || isAboutToBreakLimit) &&
				!authStore.isLoadingOrgSubscriptions &&
				authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name &&
				uiStore.ApiLimitReachedBanner && (
					<Box className={styles.apiLimitReachedBannerContainer}>
						<Box className={styles.content}>
							<img src={ErrorIcon} />
							<Typography className={styles.title}>
								{t(authStore.orgPassedFreeLimit ? "title" : "warningTitle")}
							</Typography>
							<Typography className={styles.description}>{t("description")}</Typography>
						</Box>
						<BriaButton
							buttonType={"secondaryMedium"}
							onClick={() => {
								pricingStore.openPricingFlow();
							}}
						>
							{t("buttonText")}
						</BriaButton>
						<CloseIcon
							className={styles.closeIcon}
							onClick={() => {
								uiStore.setProperty("ApiLimitReachedBanner", false);
							}}
						/>
					</Box>
				)}
		</>
	);
};

export default observer(ApiLimitReachedBanner);
