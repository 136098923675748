import { Box, Grid, Link as MuiLink, Paper, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { APIS_REQUIRE_ORG, SandboxSubAPITypes } from "../../models/sandboxAPI.ts";
import { hasOrganization } from "../../utils";
import styles from "./APICardComponent.module.scss";

export interface IAPICardsProps {
	type?: string;
	subType?: SandboxSubAPITypes;
	title: string;
	description: string;
	link?: any;
	image?: string;
	badges?: string[];
	demoLink?: string;
	cardClickable?: boolean;
}

export const APICardComponent = ({
	title,
	subType,
	description,
	link,
	image,
	badges,
	demoLink,
	cardClickable = false,
}: IAPICardsProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "apis_page" });

	const location = useLocation();

	const linkWithParams = () => {
		const searchParams = new URLSearchParams(location.search);
		return link ? link : `?${searchParams.toString()}`;
	};

	const cardContent = (
		<Paper elevation={0} className={styles.apiCard}>
			<Box className={styles.card}>
				<Box>
					<Box className={styles.imgContainer}>
						<img className={styles.image} src={image} alt={t(title)} />
					</Box>
				</Box>
				<Box
					className={clsx(styles.textAndButtonContainer, {
						[styles.alignCenter]: cardClickable,
					})}
				>
					<Typography className={styles.title}>{t(title)}</Typography>
					<Typography
						className={clsx(styles.description, {
							[styles.largeDescription]: cardClickable,
						})}
					>
						{t(description)}
					</Typography>

					<Box className={styles.apiDocButton}>
						{!cardClickable && (
							<>
								<MuiLink
									className={styles.apiDocLink}
									component={RouterLink}
									to={link}
									target={"_blank"}
								>
									{t("apiDocumentation")}
								</MuiLink>

								{demoLink && (!subType || hasOrganization() || !APIS_REQUIRE_ORG.includes(subType)) && (
									<MuiLink className={styles.apiDemoLink} component={RouterLink} to={demoLink}>
										{t("apiDemo")}
									</MuiLink>
								)}
							</>
						)}
						{badges && (
							<Box className={styles.badges}>
								{badges?.map((badge: string, index: number) => (
									<Box key={index} className={styles.badge}>
										{t(badge)}
									</Box>
								))}
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Paper>
	);

	return (
		<Grid item xs={12} sm={12} md={12} lg={6}>
			{cardClickable ? (
				<MuiLink component={RouterLink} to={linkWithParams()} underline="none" className={styles.cardLink}>
					{cardContent}
				</MuiLink>
			) : (
				cardContent
			)}
		</Grid>
	);
};
