import { Box, Divider, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextTypeClass, textStyles } from "../../constants/TextTypes";

const useStyles = makeStyles((_theme: Theme) => ({
	...textStyles,
	textTypeBox: {
		padding: "12px 10px",
		margin: "4px -14px 6px -10px",
		"&:hover": {
			background: "rgba(83, 0, 201, 0.05)",
			boxShadow: "0px 4px 6px 0px rgba(83, 0, 201, 0.05)",
		},
	},
}));

interface IProps {
	textTypeClass: TextTypeClass;
	label: string;
	onClick: () => void;
}
const TextBoxType = ({ textTypeClass, label, onClick }: IProps) => {
	const classes: any = useStyles();

	return (
		<>
			<Box className={classes.textTypeBox} onClick={onClick}>
				<Typography className={classes[textTypeClass]}>{label}</Typography>
			</Box>
			<Divider />
		</>
	);
};

export default TextBoxType;
