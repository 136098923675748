import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import RouterConstants from "../../constants/RouterConstants";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const NewIframes = () => {
	const { t } = useTranslation("translation");
	const subTabs = [RouterConstants.NEW_IFRAMES_CONFIG.path];

	return (
		<>
			<TabsLayout title={t("IframesTab.title")} subTabs={subTabs}>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(NewIframes);
