import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STEP } from "../../constants/TGConstants.ts";
import useSecureNavigate from "../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import styles from "./TabsLayout.module.scss";

export type ITabLayout = {
	disabled: boolean;
	name: string;
	link: string;
};

interface IProps {
	children: React.ReactNode;
	title: string;
	subTabs: any;
	isLoading?: boolean;
	primaryButtonLabel?: string;
	primaryButtonClickHandler?: (event: any) => void;
	hideTitle?: boolean;
}

const TabsLayout = ({
	children,
	title,
	subTabs,
	isLoading = false,
	primaryButtonLabel,
	primaryButtonClickHandler,
	hideTitle = false,
}: IProps) => {
	const navigate = useSecureNavigate();
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tabsLayout" });

	function isInstanceOfITabLayout(tab: any): tab is ITabLayout {
		return typeof tab === "object" && tab !== null && "name" in tab && "link" in tab;
	}

	return (
		<Box className={styles.container}>
			{primaryButtonLabel && (
				<Box className={styles.titleContainer}>
					<Typography className={styles.titleStyle}>{t(title)}</Typography>
					<Box className={styles.primaryButtonContainer}>
						<BriaButton onClick={primaryButtonClickHandler} buttonType="primaryMedium">
							{primaryButtonLabel}
						</BriaButton>
					</Box>
				</Box>
			)}

			<Box className={styles.headingContainer}>
				<Box>{!hideTitle && <h1 className={styles.titleStyle}>{title}</h1>}</Box>
				<Box className={styles.buttonsGroup}>
					{uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_MODELS &&
						tailoredGenerationStore.models.length > 0 && (
							<BriaButton
								className={styles.primaryButton}
								buttonType="primary"
								onClick={() => {
									navigate(RouterConstants.TG_CREATE_MODEL.fullPath);
								}}
								requiresApiAccess={true}
							>
								{t("buttons.createModel")}
							</BriaButton>
						)}
					{uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_MODELS && (
						<BriaButton
							className={styles.secondaryBtn}
							onClick={() => {
								uiStore.showDialog("GuidelinesForTailoredModelsDialog");
							}}
							buttonType="textMedium"
						>
							{t("buttons.datasetBestPractices")}
						</BriaButton>
					)}
				</Box>
			</Box>

			<Box className={styles.tabsContainer}>
				{subTabs.map((tab: any, index: number) => {
					return (
						<NavLink
							key={index}
							to={isInstanceOfITabLayout(tab) ? tab.link : tab}
							className={clsx({
								[styles.tabContainer]: true,
								[styles.disabled]: isInstanceOfITabLayout(tab) ? tab.disabled : false,
							})}
						>
							{({ isActive }: { isActive: boolean }) => (
								<>
									<h3 className={isActive ? styles.boldText : ""}>
										{t(isInstanceOfITabLayout(tab) ? tab.name : tab, { keyPrefix: false })}
									</h3>
									{isActive && <div />}
								</>
							)}
						</NavLink>
					);
				})}
			</Box>
			<LoadingPlaceholder isLoading={isLoading} className={styles.loadingPlaceholder}>
				<Box className={styles.contentContainer}>{children}</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

const ObservedComponent = observer(TabsLayout);
export default ObservedComponent;
