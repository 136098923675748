import { Box } from "@mui/material";
import styles from "./SandboxAPIs.module.scss";

export interface CustomTabPanelProps {
	activeTab: string;
	index: string;
	children: React.ReactNode;
}

const CustomTabPanel = ({ activeTab, index, children }: CustomTabPanelProps) => {
	const isActiveTab = index === activeTab;
	return (
		<Box
			role="tabpanel"
			hidden={!isActiveTab}
			className={styles.tabPanelContainer}
		>
			{isActiveTab && <Box sx={{ p: 3 }}>{children}</Box>}
		</Box>
	);
};

export default CustomTabPanel;
