import { useEffect } from "react";
import RouterConstants from "../constants/RouterConstants";
import { getSelectedOrganization } from "../helpers/localStorage";
import useSecureNavigate from "../hooks/useSecureNavigate.tsx";
import Container from "./Container";
import Provider from "./Provider";
import DesignEditor from "./views/DesignEditor";

const BriaDesignEditor = (props: { isPopupView?: boolean }) => {
	const navigate = useSecureNavigate();
	// TODO: This useEffect ensures that users cannot access the campaign editor without selecting an organization.
	// Once Ori opens a ticket to handle user access to campaigns without an organization, this useEffect can be removed.
	useEffect(() => {
		if (!getSelectedOrganization()?.organization.uid) {
			navigate(RouterConstants.CONSOLE.path);
		}
	}, []);

	return (
		<Provider isPopupView={props.isPopupView}>
			<Container>
				<DesignEditor isPopup={props.isPopupView} />
			</Container>
		</Provider>
	);
};

export default BriaDesignEditor;
