import { getSummerHost } from "../config/env";

class LinkConstants {
	public static readonly BRIA_WEBSITE: string = "https://bria.ai/";
	public static readonly PRIVACY_POLICY: string = "https://bria.ai/privacy-policy";
	public static readonly BRIA_API: string = "https://bria-ai-api-docs.redoc.ly/";
	public static readonly IFRAME_DOCS: string = "https://github.com/Bria-AI/iframe-doc";
	public static readonly IFRAME_v2_DOCS: string = "https://github.com/Bria-AI/iframe-doc/tree/iframe-v2";
	public static readonly CONTACT_US_FORM_LINK: string = "https://bria.ai/contact-us";
	public static readonly TERMS_AND_CONDITIONS_LINK: string = "https://bria.ai/terms-and-conditions/";
	public static readonly BACKGROUND_GENERATION: string = `${getSummerHost()}/gallery/b3cccc93673198cc`;
	public static readonly IMAGE_EXPANSION: string = `${getSummerHost()}/gallery/86c309b4e3995ce7`;
	public static readonly PRESENTER_RECASTING: string = `${getSummerHost()}/gallery/ade0c69399697896?selectedTab=presenters`;
	public static readonly OBJECT_ERASURE_AND_REPLACMENT: string = `${getSummerHost()}/gallery/86f9986390596e8f?selectedTab=objects`;
	public static readonly IMAGE_TO_PSD: string = `${getSummerHost()}/gallery/f2dc4c7031c70df2`;
	public static readonly INCREASE_RESOLUTION: string = `${getSummerHost()}/gallery/e6b5944a989992f3`;

	public static readonly TEXT_TO_IMAGE: string = `${getSummerHost()}/gallery?surprise_me=true`;
	public static readonly CAMPAIGN_GENERATION: string = `${getSummerHost()}/gallery/b3cccc93673198cc?selectedTab=brand`;
	public static readonly BRIA_API_TEXT_TO_IMAGE: string = `${this.BRIA_API}tag/Image-Generation#operation/text-to-image/base`;
	public static readonly BRIA_API_TEXT_TO_VECTOR: string = `${this.BRIA_API}tag/Image-Generation#operation/text-to-vector/base`;
	public static readonly BRIA_API_INCREASE_RESOLUTION: string = `${this.BRIA_API}tag/Image-Modifications#operation/increase_resolution`;
	public static readonly BRIA_API_IMAGE_TO_PSD: string = `${this.BRIA_API}tag/Image-Modifications#operation/image_to_psd`;
	public static readonly BRIA_API_BACKGROUND_REMOVAL: string = `${this.BRIA_API}tag/Image-Modifications#operation/background/remove`;
	public static readonly BRIA_API_BACKGROUND_BLUR: string = `${this.BRIA_API}tag/Image-Modifications#operation/blur_bg`;
	public static readonly BRIA_API_BACKGROUND_GENERATION: string = `${this.BRIA_API}tag/Image-Modifications#operation/background/replace`;
	public static readonly BRIA_API_IMAGE_EXPANSTION: string = `${this.BRIA_API}tag/Image-Modifications#operation/image_expansion`;
	public static readonly BRIA_API_CROP_OUT_FOREGROUND: string = `${this.BRIA_API}tag/Image-Modifications#operation/crop`;
	public static readonly BRIA_API_MASK_GENERATOR: string = `${this.BRIA_API}tag/Image-Modifications#operation/objects/mask_generator`;
	public static readonly BRIA_API_REMOVE_OBJECT: string = `${this.BRIA_API}tag/Image-Modifications#operation/eraser`;
	public static readonly BRIA_API_ERASER_FOREGROUND: string = `${this.BRIA_API}tag/Image-Modifications#operation/erase_foreground`;
	public static readonly BRIA_API_GENERATE_BACKGROUND: string = `${this.BRIA_API}tag/Image-Modifications#operation/background/replace`;
	public static readonly BRIA_API_MODIFY_PRESENTER: string = `${this.BRIA_API}tag/Image-Modifications#operation/create`;
	public static readonly BRIA_API_LABELESS_AI_IMAGE_SEARCH: string = `${this.BRIA_API}tag/AI-Search#operation/search`;
	public static readonly BRIA_API_PRODUCT_CUTOUTS: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productCutout`;
	public static readonly BRIA_API_PRODUCT_PACK_SHOTS: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productPackshot`;
	public static readonly BRIA_API_PRODUCT_SHADOW: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productShadow`;
	public static readonly BRIA_API_TEXT_PROMPTS_LIFESTYLE: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productLifestyleShotByText`;
	public static readonly BRIA_API_LIFESTYLE_PRODUCT_SHOT_BY_IMAGE: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productLifestyleShotByImage`;
	public static readonly BRIA_API_CONSISTENT_STUDIO_SHOTS: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/consistentProductShots`;
	public static readonly BRIA_API_CONTEXTUAL_KEYWORD_EXTRACTION: string = `${this.BRIA_API}tag/Product-Shots-Generation#operation/productContextualKeywordExtraction`;
	public static readonly BRIA_API_BRANDED_MODELS_MANAGEMENT: string = `${this.BRIA_API}tag/Tailored-Generation#operation/getModels`;
	public static readonly BRIA_API_DATASET_MANAGMENT: string = `${this.BRIA_API}tag/Tailored-Generation#operation/createDataset`;
	public static readonly BRIA_API_TRAIN_MODELS: string = `${this.BRIA_API}tag/Tailored-Generation#operation/startModelTraining`;
	public static readonly BRIA_API_GENERATE_IMAGES: string = `${this.BRIA_API}tag/Tailored-Generation#operation/text-to-image/tailored`;
	public static readonly BRIA_HUGGING_FACE: string = "https://huggingface.co/briaai";
	public static readonly BRIA_AGENT: string = "https://github.com/Bria-AI/agent";
	public static readonly AI_EDITOR: string = `${getSummerHost()}/gallery/8679a552aa79cc96/?from_platform_hub=true&openUploadPopup=true`;
	public static readonly AI_IMAGE_SEARCH: string = `${getSummerHost()}/gallery/?from_platform_hub=true`;
	public static readonly BRIA_IMAGES_URL: string = "https://images.bria.ai/";
	public static readonly BRIA_USER_ASSETS_CLOUDFRONT: string = "https://user-assets.bria.ai/";
	public static readonly BRIA_ORG_ASSETS_CLOUDFRONT: string = "https://org-assets.bria.ai/";
	public static readonly BRIA_API_TEXT_TO_IMAGE_FAST: string = `${this.BRIA_API}tag/Image-Generation#operation/text-to-image/fast`;
	public static readonly BRIA_API_TEXT_TO_IMAGE_HD: string = `${this.BRIA_API}tag/Image-Generation#operation/text-to-image/hd`;
	public static readonly BRIA_API_TEXT_TO_VECTOR_FAST: string = `${this.BRIA_API}tag/Image-Generation#operation/text-to-vector/fast`;
	public static readonly BRIA_API_IMAGE_REFERENCE: string = `${this.BRIA_API}tag/Image-Generation#operation/reimagine`;
	public static readonly BRIA_API_PROMPT_ENHANCE: string = `${this.BRIA_API}tag/Image-Generation#operation/prompt_enhancer`;
}

export default LinkConstants;
