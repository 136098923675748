import { ButtonProps, FormControlLabel, Radio, RadioGroup, RadioProps, styled } from "@mui/material";
import { useEffect } from "react";
import styles from "./BriaRadioButton.module.scss";

interface IProps {
	buttons: any;
	onClick: (value: any) => void;
	defaultValue?: string;
}

const BpIcon = styled("span")(({ theme }) => ({
	borderRadius: "50%",
	width: 16,
	height: 16,
	border: `1px solid ${theme.palette.primary.light}`,
	backgroundColor: "#5300C912",
	"input:disabled ~ &": {
		border: "1px solid #1A063840",
		background: "unset",
	},
}));

const BpCheckedIcon = styled(BpIcon)(({ theme }) => ({
	backgroundColor: theme.palette.primary.light,
	// backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
	"&:before": {
		display: "block",
		width: 16,
		height: 16,
		backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 40%)",
		content: '""',
	},
}));

function BpRadio(props: RadioProps) {
	return <Radio disableRipple color="default" checkedIcon={<BpCheckedIcon />} icon={<BpIcon />} {...props} />;
}

export default function BriaRadioButton({ defaultValue, buttons, onClick }: IProps & ButtonProps) {
	useEffect(() => {
		onClick(defaultValue);
	}, []);
	return (
		<RadioGroup defaultValue={defaultValue} className={styles.container}>
			{buttons.map((button: any) => {
				return (
					<FormControlLabel
						onClick={() => {
							if (!button.disabled) {
								onClick(button.label);
							}
						}}
						value={button.label}
						disabled={button.disabled}
						className={styles.controlStyle}
						control={<BpRadio disabled={button.disabled} />}
						label={button.label}
					/>
				);
			})}
		</RadioGroup>
	);
}
