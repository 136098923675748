import { useContext } from "react";
import { DesignEditorContext } from "../contexts/DesignEditor";

function useDesignEditorContext() {
	const {
		displayPlayback,
		setDisplayPlayback,
		setDisplayPreview,
		displayPreview,
		currentScene,
		setCurrentScene,
		setInvokeSave,
		invokeSave,
		setScenes,
		scenes,
		maxTime,
		setMaxTime,
		contextMenuTimelineRequest,
		setContextMenuTimelineRequest,
		contextMenuSceneRequest,
		setContextMenuSceneRequest,
		currentDesign,
		setCurrentDesign,
	} = useContext(DesignEditorContext);
	return {
		displayPlayback,
		setDisplayPlayback,
		setDisplayPreview,
		displayPreview,
		currentScene,
		setCurrentScene,
		setInvokeSave,
		invokeSave,
		setScenes,
		scenes,
		maxTime,
		setMaxTime,
		contextMenuTimelineRequest,
		setContextMenuTimelineRequest,
		contextMenuSceneRequest,
		setContextMenuSceneRequest,
		currentDesign,
		setCurrentDesign,
	};
}

export default useDesignEditorContext;
