import React, { CSSProperties, ReactElement, ReactNode, cloneElement } from "react";

interface ShowAtProps {
	condition?: boolean;
	disabled?: boolean;
	children: ReactNode;
}

const ShowAt: React.FC<ShowAtProps> = ({ condition = true, disabled = false, children }) => {
	if (!condition) return null;

	const style: CSSProperties = disabled ? { pointerEvents: "none", opacity: 0.38 } : {};

	if (React.isValidElement(children)) {
		return cloneElement(children as ReactElement<any>, {
			// Added any type here to support all types of React elements
			style: { ...children.props.style, ...style },
		});
	}

	return <>{children}</>;
};

export default ShowAt;
