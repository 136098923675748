class ShortcutManager {
	isCtrlOrCmd(event: KeyboardEvent) {
		return event.ctrlKey || event.metaKey;
	}

	isDelete(event: KeyboardEvent) {
		return event.key === "Delete" || event.key === "Backspace";
	}

	// save or update template
	isCtrlOrCmdS(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyS";
	}

	// select all
	isCtrlOrCmdA(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyA";
	}

	// copy
	isCtrlOrCmdC(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyC";
	}

	// paste
	isCtrlOrCmdV(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyV";
	}
	// redo
	isCtrlOrCmdY(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyY";
	}

	// cut
	isCtrlOrCmdX(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.code === "KeyX";
	}

	// nudge
	isArrowUp(event: KeyboardEvent) {
		return event.code === "ArrowUp";
	}

	// nudge
	isArrowDown(event: KeyboardEvent) {
		return event.code === "ArrowDown";
	}

	// nudge
	isArrowLeft(event: KeyboardEvent) {
		return event.code === "ArrowLeft";
	}

	// nudge
	isArrowRight(event: KeyboardEvent) {
		return event.code === "ArrowRight";
	}

	// modifier
	isShift(event: KeyboardEvent) {
		return event.shiftKey;
	}

	// lineHeight--
	isAltDown(event: KeyboardEvent) {
		return event.altKey && event.code === "ArrowDown";
	}

	// lineHeight++
	isAltUp(event: KeyboardEvent) {
		return event.altKey && event.code === "ArrowUp";
	}

	// charSpacing++
	isAltRight(event: KeyboardEvent) {
		return event.altKey && event.code === "ArrowRight";
	}
	// charSpacing--
	isAltLeft(event: KeyboardEvent) {
		return event.altKey && event.code === "ArrowLeft";
	}

	// redo
	isCtrlOrCmdShiftZ(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.shiftKey && event.code === "KeyZ";
	}

	// undo
	isCtrlOrCmdZ(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && !event.shiftKey && event.code === "KeyZ";
	}

	// zoom reset
	isCtrlOrCmdOne(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.key === "1";
	}

	// zoom in
	isCtrlOrCmdMinus(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.key === "-";
	}

	// zoom out
	isCtrlOrCmdEqual(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.key === "=";
	}

	// zoom to fit
	isCtrlOrCmdZero(event: KeyboardEvent) {
		return this.isCtrlOrCmd(event) && event.key === "0";
	}
}

export default new ShortcutManager();
