import { Box, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import LoadingAnimation from "../../../../../../components/common/LoadingAnimation/LoadingAnimation.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import { CampaignEntityStatus } from "../../../../../../models/common.ts";
import useCampaignHeaderUtils from "../../../../../CustomUtils/CampaignHeaderUtils.tsx";
import { DEFAULT_SCENE_PROCESSING_TIME } from "../../../../../constants/contants.ts";
import useDesignEditorContext from "../../../../../hooks/useDesignEditorContext.ts";
import { useEditor } from "../../../../../hooks/useEditor.tsx";
import { useSmartImageGeneration } from "../../../utils/smartImageUtils.ts";
import styles from "./CampaignInformation.module.scss";

const CampaignInformation = () => {
	const editor = useEditor();
	const { generateCampaignSmartImages } = useSmartImageGeneration();
	const { scenes } = useDesignEditorContext();
	const { designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor" });
	const { buildUpdatedTemplate } = useCampaignHeaderUtils();

	return (
		<Box
			className={clsx({
				[styles.campaignGenerationForm]: true,
			})}
		>
			<Box className={styles.content}>
				<Typography className={styles.title}>{t("popup.campaign.title")}</Typography>
				<TextField
					value={designEditorStore.campaignName}
					onChange={(e) => designEditorStore.setProperty("campaignName", e.target.value)}
					variant="standard"
					placeholder={t("popup.campaign.input")}
					fullWidth
					InputProps={{ classes: { root: styles.textField } }}
					className={styles.input}
					disabled={designEditorStore.isGenerateAdsLoading}
				/>

				{designEditorStore.isGenerateAdsLoading ? (
					<Box className={styles.loadingContainer}>
						<LoadingAnimation
							loading={designEditorStore.hideCampaignResultContent}
							progressBarTime={DEFAULT_SCENE_PROCESSING_TIME * scenes.length}
							showAnimation={false}
							loadingStringsPosition={"left"}
							showLoadingStrings={false}
						/>
						<Typography className={styles.content}>
							{t("popup.campaign.loading")}{" "}
							<span className={styles.colored}>
								{designEditorStore.numberOfProcessedAdvertisements} / {scenes.length ?? 1}
							</span>
						</Typography>
					</Box>
				) : (
					<BriaButton
						buttonType="primary"
						type="submit"
						variant="contained"
						color="secondary"
						fullWidth={true}
						className={styles.button}
						disabled={designEditorStore.campaignName.trim() === ""}
						onClick={async () => {
							if (!editor) return;
							designEditorStore.setProperty("isGenerateAdsLoading", true);
							const updatedScenes = await generateCampaignSmartImages();
							const updatedTemplate = await buildUpdatedTemplate(
								updatedScenes,
								CampaignEntityStatus.DRAFT,
								true,
								designEditorStore.campaignName,
							);

							designEditorStore.setProperty("campaignUpdatedTemplate", updatedTemplate);
							designEditorStore.setProperty("hideCampaignResultContent", false);
							designEditorStore.setProperty("isGenerateAdsLoading", false);
						}}
					>
						{t("popup.buttons.createCampaign", { count: scenes.length })}
					</BriaButton>
				)}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(CampaignInformation);
export default ObservedComponent;
