import { Box, Container, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import BriaImage from "../../../../components/common/Galleries/BriaImage";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../../../constants/RouterConstants";
import useSecureNavigate from "../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../hooks/useStores";
import ProductImageOverlay from "../ProductImageOverlay/ProductImageOverlay";
import styles from "./ProductGallery.module.scss";

const ProductGallery = () => {
	const numberOfColumns = 6;
	const imageContainerRef = useRef<HTMLDivElement>(null);
	const [imagesList, setImagesList] = useState<any[]>([]);
	const { productPlacementStore } = useAppStore();
	const navigate = useSecureNavigate();

	useEffect(() => {
		setImagesList(productPlacementStore.images);
	}, [productPlacementStore.images]);

	const createColumnLayout = () => {
		const columns: any[][] = Array.from({ length: numberOfColumns }, () => []);
		imagesList.forEach((image, index) => {
			columns[index % numberOfColumns].push(image);
		});
		return columns;
	};

	const handleSelectImage = async (e: any, image: any) => {
		await productPlacementStore.setPlaygroundResult(image);
		navigate(`/${RouterConstants.PRODUCT_PLACEMENT.fullPath}`);
		e?.stopPropagation();
	};

	const columns = createColumnLayout();

	return (
		<Grid item className={styles.GalleryGrid}>
			<Box className={styles.galleyImagesBox} ref={imageContainerRef}>
				<Container disableGutters maxWidth={false} className={styles.gallery}>
					<LoadingPlaceholder className={styles.loadingWrapper} isLoading={productPlacementStore.isLoading}>
						<Grid container spacing={3}>
							{columns.map((column, colIndex) => (
								<Grid item xs key={colIndex}>
									{column.map((image, index) => (
										<Box className={styles.imageWrapper} key={index}>
											<BriaImage
												{...image}
												url={image.url}
												handleClick={(e) => handleSelectImage(e, image)}
												hideFullScreenButton
												displayOverlay="customHover"
												className={styles.productPlacementImage}
												image={image}
											/>
											<ProductImageOverlay image={image} />
										</Box>
									))}
								</Grid>
							))}
						</Grid>
					</LoadingPlaceholder>
				</Container>
			</Box>
		</Grid>
	);
};

export default ProductGallery;
