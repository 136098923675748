import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Divider, Link, Modal, Tab, Tabs, Typography } from "@mui/material";
import { ClientId, HTTPSnippet, HarRequest, TargetId } from "httpsnippet-lite";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import CopyToClipboardButton from "../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import { PlaygroundResult } from "../../../../models/image-to-image";
import { ApiRequestData, SandboxAPIConfigType } from "../../../../models/sandboxAPI.ts";

import hljs from "highlight.js/lib/core";
import csharp from "highlight.js/lib/languages/csharp";
import javascript from "highlight.js/lib/languages/javascript";
import php from "highlight.js/lib/languages/php";
import python from "highlight.js/lib/languages/python";
import ruby from "highlight.js/lib/languages/ruby";
import shell from "highlight.js/lib/languages/shell";
import "highlight.js/styles/a11y-dark.min.css";
import styles from "./CodeGeneratorModal.module.scss";

hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("python", python);
hljs.registerLanguage("csharp", csharp);
hljs.registerLanguage("ruby", ruby);
hljs.registerLanguage("php", php);
hljs.registerLanguage("shell", shell);

type codeSnippetType = string | string[] | null;
type Props = {
	playgroundResult: PlaygroundResult;
};

const CodeGeneratorModal = ({ playgroundResult }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.sandboxAPI.result" });
	const [openCodeModal, setOpenCodeModal] = useState(false);
	const [codeSnippet, setCodeSnippet] = useState<codeSnippetType>();
	const [highlightedSnippet, setHighlightedSnippet] = useState<string>();
	const [apiData, setApiData] = useState<ApiRequestData>();
	const [tabIndex, setTabIndex] = useState(0);
	const codeTabs = [
		{ label: "Python", targetId: "python", clientId: "requests" },
		{ label: "Node", targetId: "node" },
		{ label: "JavaScript", targetId: "javascript" },
		{ label: "C#", targetId: "csharp" },
		{ label: "PHP", targetId: "php" },
		{ label: "Ruby", targetId: "ruby" },
		{ label: "cURL", targetId: "shell" },
	];

	useEffect(() => {
		const updateSnippet = async () => {
			if (apiData) {
				const snippet = await generateCodeSnippet(
					apiData,
					codeTabs[tabIndex].targetId as TargetId,
					codeTabs[tabIndex].clientId,
				);
				setCodeSnippet(snippet);
			}
		};
		updateSnippet();
	}, [tabIndex]);

	const generateCodeSnippet = async (
		apiRequestData: ApiRequestData,
		codeTarget: TargetId,
		codeClient?: ClientId,
	): Promise<codeSnippetType> => {
		if (!apiRequestData) return null;
		const { method, url, headers, postData } = apiRequestData;
		const request: HarRequest = {
			method: method.toUpperCase(),
			url,
			headers,
			postData,
			httpVersion: "HTTP/1.1",
			cookies: [],
			queryString: [],
			headersSize: -1,
			bodySize: -1,
		};
		const snippet = new HTTPSnippet(request);
		const code = await snippet.convert(codeTarget, codeClient);
		const highlightedCode = hljs.highlight(code as string, {
			language: codeTarget === "node" ? "javascript" : codeTarget,
		}).value;
		setHighlightedSnippet(highlightedCode);
		return code;
	};

	const handleOpenCodeModal = async (apiRequestData: ApiRequestData) => {
		setApiData(apiRequestData);
		const snippet = await generateCodeSnippet(
			apiRequestData,
			codeTabs[tabIndex].targetId as TargetId,
			codeTabs[tabIndex].clientId,
		);
		setCodeSnippet(snippet);
		setOpenCodeModal(true);
	};

	const handleClose = () => setOpenCodeModal(false);

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<>
			{playgroundResult && playgroundResult.apiRequestData && !playgroundResult.isFromUpload && (
				<BriaButton
					onClick={() =>
						playgroundResult.apiRequestData ? handleOpenCodeModal(playgroundResult.apiRequestData) : false
					}
					className={styles.viewCodeButton}
					buttonType="primaryMedium"
				>
					{t("viewCode")}
				</BriaButton>
			)}
			<Modal className={styles.CodeModal} open={openCodeModal} onClose={handleClose}>
				<Box className={styles.ModalCenter}>
					<CloseIcon onClick={handleClose} className={styles.closeIcon} />
					<Typography className={styles.ModalHeader} variant="h6" component="h2">
						{t("code")}
					</Typography>
					{codeSnippet && highlightedSnippet && (
						<Box className={styles.ModalContent}>
							<Tabs value={tabIndex} onChange={handleTabChange} className={styles.codeTabs}>
								{codeTabs.map((tab, index) => (
									<Tab key={index} label={tab.label} />
								))}
							</Tabs>
							<Box>
								<pre>
									<code dangerouslySetInnerHTML={{ __html: highlightedSnippet }} />
								</pre>
							</Box>
							<Divider className={styles.bottomDivider} />
							<Box className={styles.modalFooter}>
								<Link
									className={styles.apiDocLink}
									href={(playgroundResult.config as SandboxAPIConfigType)?.apiConfig?.docLink}
									target={"_blank"}
									rel="noopener"
								>
									{t("apiDoc")}
								</Link>
								<CopyToClipboardButton
									className={styles.CopyButton}
									textToCopy={typeof codeSnippet === "string" ? codeSnippet : codeSnippet?.join("\n")}
									buttonText={t("copyCode")}
									buttonType="primaryMedium"
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default observer(CodeGeneratorModal);
