import QueryService from "../utils/QueryService.ts";

class UploadImageService {
	queryService = new QueryService("/upload-image");

	getVhash = async (imageUrl: string): Promise<string> => {
		return await this.queryService.post("/url", { image_url: imageUrl });
	};
}

export default UploadImageService;
