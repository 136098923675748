import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { PUBLIC_GALLERY_ID, PUBLIC_GALLERY_ORG_ID } from "../constants/OrgConstants.ts";
import { IRootStore } from "../mobx/root-store.tsx";
import { BrandLogo } from "../models/brandLogo.ts";
import { PaginatedImages, PaginatedItems } from "../models/common.ts";
import QueryService from "../utils/QueryService.ts";
import { Image } from "./models/image.ts";
import { GalleryData, imagesPerPage } from "./views/DesignEditor/components/Panels/panelItems/Images/Images.tsx";
import {
	BackgroundOption,
	BackgroundOptionsEnum,
	BackgroundRoute,
	IBackgroundObject,
	IBoundingBox,
	ISmartImageParams,
	ISmartImageSize,
	ImageSize,
	ImageType,
	SecondaryTabTypeEnum,
	defaultSmartForm,
} from "./views/DesignEditor/components/Panels/panelItems/index.ts";

export interface IImagesStore {
	isError: boolean;
	isLoading: boolean;
	isImageUploading: boolean;
	isUploadingSmartImageMainObject: boolean;
	openUploadPopup: boolean;
	uploadToUserGallery: boolean;
	isLogoUploading: boolean;
	uploadProcessDone: boolean;
	unsplashImages: PaginatedImages<Image>;
	search_result_images: any;
	user_images: PaginatedImages<Image>;
	org_images: PaginatedImages<Image>;
	galleryOrgData: GalleryData;
	galleryUserData: GalleryData;
	unsplashGallery: GalleryData;
	galleryLogoData: GalleryData;
	tabGallery: GalleryData;
	smartImageForm: ISmartImageParams;
	smartImagesHistory: Image[];
	smartImageBackgroundOptions: BackgroundOption[];
	isGeneratingImages: boolean;
	logos: PaginatedImages<Image>;
	secondaryTabType: SecondaryTabTypeEnum | null;
	deletedImageVhash: string;
	isDeletingImage: boolean;
	removeImageBackground(imageUrl: string): Promise<IBackgroundObject>;

	generateOrExpandImageBackground(
		operation: BackgroundRoute,
		smartImageSize: ISmartImageSize,
		smartImageForm?: ISmartImageParams,
	): Promise<Image>;

	uploadImageViaAPI(file: File | undefined, addToUserAssets: string, url?: string): Promise<any>;

	uploadLogo(file: File | undefined): Promise<void>;

	getUserImages(images_per_page: number, page_number: number): Promise<void>;

	getLogos(images_per_page: number, page_number: number): Promise<void>;

	getOrgImages(imagesPerPage: number, pageNumber: number, unsplashGalleryOrgId: string): Promise<void>;

	searchImages(images_per_page: number, page_number: number, query: string, embedding_name: string): Promise<void>;

	loadMoreImages(
		secondaryTabType: SecondaryTabTypeEnum | null,
		imagesPerPage: number,
		pageNumber: number,
		searchTerm?: string,
	): Promise<void>;

	updateGalleryOrgData(): Promise<void>;

	updateGalleryUserData(): Promise<void>;

	updateGalleryUnsplashData(): Promise<void>;

	getVhashFromImageUrl(imageUrl: string): Promise<string | undefined>;

	registerImageAndGetUrl(event: ChangeEvent<HTMLInputElement>): Promise<ImageType>;

	handleSmartImageChange: <K extends keyof ISmartImageParams>(key: K, value: ISmartImageParams[K]) => void;

	updateLogoData(): Promise<void>;

	resetSmartImage(): Promise<void>;
	deleteUserImage(vhash: string): Promise<void>;
	deleteOrgImage(vhash: string): Promise<void>;

	setProperty<K extends keyof IImagesStore>(key: K, value: IImagesStore[K]): void;
	clearSmartImageForm: () => void;
}

export default class ImagesStore implements IImagesStore {
	rootStore: IRootStore;
	isError: boolean = false;
	isLoading: boolean = false;
	isImageUploading: boolean = false;
	isUploadingSmartImageMainObject: boolean = false;
	openUploadPopup: boolean = false;
	uploadToUserGallery: boolean = false;
	isLogoUploading: boolean = false;
	uploadProcessDone: boolean = false;
	unsplashImages: PaginatedImages<Image> = { total_pages: 0, total_images: 0, images: [] };
	search_result_images: any;
	user_images: PaginatedImages<Image> = { total_pages: 0, total_images: 0, images: [] };
	org_images: PaginatedImages<Image> = { total_pages: 0, total_images: 0, images: [] };
	galleryOrgData: GalleryData = initialGalleryData;
	galleryUserData: GalleryData = initialGalleryData;
	unsplashGallery: GalleryData = initialGalleryData;
	tabGallery: GalleryData = initialGalleryData;
	smartImageForm = defaultSmartForm;
	smartImagesHistory: Image[] = [];
	smartImageBackgroundOptions: BackgroundOption[] = [];
	isGeneratingImages: boolean = false;
	logos: PaginatedImages<Image> = { total_pages: 0, total_images: 0, images: [] };
	galleryLogoData: GalleryData = initialGalleryData;
	secondaryTabType = null;
	deletedImageVhash: string = "";
	isDeletingImage: boolean = false;

	private queryService: QueryService = new QueryService("/ai-editor/background");
	private uploadImageQueryService: QueryService = new QueryService("/upload-image");
	private imageQueryService: QueryService = new QueryService("/images");

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setProperty = async <K extends keyof ImagesStore>(key: K, value: ImagesStore[K]) => {
		runInAction(() => ((this as ImagesStore)[key] = value));
	};

	handleSmartImageChange = <K extends keyof ISmartImageParams>(key: K, value: ISmartImageParams[K]) => {
		this.smartImageForm[key] = value;
	};

	getVhashFromImageUrl = async (imageUrl: string): Promise<string | undefined> => {
		return await this.uploadImageQueryService.post("/url", { image_url: imageUrl });
	};

	registerImageAndGetUrl = async (event: ChangeEvent<HTMLInputElement>): Promise<ImageType> => {
		try {
			runInAction(() => {
				this.isLoading = true;
				this.isError = false;
			});

			const imageUrlString = await this.rootStore.aiEditorStore.handleUploadImageToEngine(event, true);
			const imageUrl = new URL(imageUrlString).href;
			return {
				imageUrl: imageUrl,
				visualHash: "",
			};
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isLoading = false;
				this.isUploadingSmartImageMainObject = false;
			});
			return Promise.reject(`Error registering the image: ${e.message || e.toString()}`);
		}
	};

	generateOrExpandImageBackground = async (
		operation: BackgroundRoute,
		smartImageSize: ISmartImageSize,
		smartImageForm?: ISmartImageParams,
	): Promise<Image> => {
		try {
			this.isGeneratingImages = true;
			const backgroundToExpand: any = {
				smartImageSize: smartImageSize,
				smartImageForm: smartImageForm ? smartImageForm : this.smartImageForm,
			};
			if (operation === BackgroundRoute.Expand) {
				backgroundToExpand.smartImageForm.backgroundDescription = "";
			}

			const imageObj = await this.queryService.post(operation, backgroundToExpand);
			const formattedImage: Image = {
				id: uuidv4(),
				visual_hash: "",
				url: imageObj.image_res,
				seed: imageObj.seed,
				sid: imageObj.sid,
				input_params: {
					seed: imageObj.seed ?? this.smartImageForm.seed,
					sid: imageObj.sid ?? this.smartImageForm.sid,
					originalImage: {
						imageUrl: imageObj.new_original_image_url ?? this.smartImageForm.originalImage.imageUrl,
						boundingBox: imageObj.object_bounding_box ?? this.smartImageForm.originalImage.boundingBox,
						size: imageObj.size ?? this.smartImageForm.originalImage.size,
					},
					backgroundOption: this.smartImageForm.backgroundOption,
					backgroundDescription: this.smartImageForm.backgroundDescription,
					colorCode: this.smartImageForm.colorCode,
				},
			};
			runInAction(() => {
				this.smartImagesHistory.unshift(formattedImage);
				this.isGeneratingImages = false;
				this.isError = false;
			});
			return formattedImage;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isGeneratingImages = false;
			});
			return Promise.reject(`Error expanding/generating background: ${e.message || e.toString()}`);
		}
	};

	removeImageBackground = async (imageUrl: string): Promise<IBackgroundObject> => {
		try {
			this.isLoading = true;
			const backgroundToRemove: IBackgroundObject = {
				imageUrl: imageUrl,
				boundingBox: { left: 0, top: 0, width: 1, height: 1 },
				size: { width: 1, height: 1 },
			};
			const imageObject: IBackgroundObject = await this.queryService.post(
				"/remove_background_and_detect_object",
				backgroundToRemove,
			);
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
			});
			return imageObject;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isLoading = false;
			});
			return Promise.reject(`Error removing background: ${e.message || e.toString()}`);
		}
	};

	uploadImageViaAPI = async (file: File, addToUserAssets: string, url: string): Promise<any> => {
		try {
			this.isImageUploading = true;
			const formData = new FormData();
			file && formData.append("file", file);
			url && formData.append("image_url", url);
			formData.append("add_to_user_assets", addToUserAssets);
			const response = await this.imageQueryService.post("/upload_image_via_api", formData, {
				"Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.isImageUploading = false;
				this.isError = false;
			});
			return response;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isImageUploading = false;
			});
			return Promise.reject(`Error uploading image: ${e.message || e.toString()}`);
		}
	};

	getOrgImages = async (imagesPerPage: number, pageNumber: number, unsplashGalleryOrgId: string): Promise<void> => {
		try {
			this.isError = false;
			const images: PaginatedImages<Image> = await this.imageQueryService.get("/org_images", {
				params: {
					images_per_page: imagesPerPage,
					page_number: pageNumber,
					unsplash_gallery_org_id: unsplashGalleryOrgId,
				},
			});
			runInAction(() => {
				if (unsplashGalleryOrgId) {
					this.unsplashImages = images;
				} else {
					this.org_images = images;
				}

				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	getUserImages = async (images_per_page: number, page_number: number): Promise<void> => {
		try {
			this.isError = false;
			const images: PaginatedImages<Image> = await this.imageQueryService.get("/user_images", {
				params: {
					images_per_page: images_per_page,
					page_number: page_number,
				},
			});
			runInAction(() => {
				this.user_images = images;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};
	searchImages = async (
		images_per_page: number,
		page_number: number,
		query: string,
		embedding_name: string,
	): Promise<void> => {
		try {
			this.isError = false;
			const images: PaginatedImages<Image> = await this.imageQueryService.get("/search_images", {
				params: {
					num_results_per_page: images_per_page,
					page_number: page_number,
					query: query,
					embedding_name: embedding_name,
				},
			});
			runInAction(() => {
				this.search_result_images = { images: images };
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	loadMoreImages = async (
		type: SecondaryTabTypeEnum,
		imagesPerPage: number,
		pageNumber: number,
		searchTerm?: string,
	): Promise<void> => {
		try {
			let mergedImgs: any = [];
			const currImages = this.tabGallery?.showroomImages?.images ?? [];
			if (type == SecondaryTabTypeEnum.SHARED_IMAGES) {
				await this.getOrgImages(imagesPerPage, pageNumber, "");
				mergedImgs = [...currImages, ...this.org_images.images];
			} else if (type === SecondaryTabTypeEnum.USER_IMAGES) {
				await this.getUserImages(imagesPerPage, pageNumber);
				mergedImgs = [...currImages, ...this.user_images.images];
			} else if (type === SecondaryTabTypeEnum.LOGOS) {
				await this.getLogos(imagesPerPage, pageNumber);
				mergedImgs = [...currImages, ...this.logos.images];
			} else if (type === SecondaryTabTypeEnum.UNSPLASH) {
				if (searchTerm) {
					await this.searchImages(imagesPerPage, pageNumber, searchTerm, PUBLIC_GALLERY_ID);
				} else {
					await this.getOrgImages(imagesPerPage, pageNumber, PUBLIC_GALLERY_ORG_ID);
				}
				mergedImgs = [
					...currImages,
					...(searchTerm ? this.search_result_images.images : this.unsplashImages.images),
				];
			}

			const mappedImages = mergedImgs.map((img: Image) => {
				return {
					...img,
					url: img.url ?? img.medium_url ?? img.source_url ?? img.source_url,
				};
			});
			runInAction(() => {
				this.tabGallery.showroomImages.images = mappedImages;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	updateGalleryOrgData = async () => {
		await this.getOrgImages(imagesPerPage, 1, "");
		runInAction(() => {
			this.galleryOrgData.showroomImages.images = this.org_images.images;
		});
	};
	updateGalleryUserData = async () => {
		await this.getUserImages(imagesPerPage, 1);
		runInAction(() => {
			this.galleryUserData.showroomImages.images = this.user_images.images;
		});
	};

	updateGalleryUnsplashData = async () => {
		await this.getOrgImages(imagesPerPage, 1, PUBLIC_GALLERY_ORG_ID);
		runInAction(() => {
			this.unsplashGallery.showroomImages.images = this.unsplashImages.images;
		});
	};

	updateLogoData = async () => {
		await this.getLogos(imagesPerPage, 1);
		runInAction(() => {
			this.galleryLogoData.showroomImages.images = this.logos.images;
		});
	};

	getLogos = async (images_per_page: number, page_number: number): Promise<void> => {
		try {
			this.isError = false;
			const images: PaginatedItems<BrandLogo> = await this.rootStore.logoStore.getLogos(
				images_per_page,
				page_number,
			);
			runInAction(() => {
				const totalPages = Math.ceil(images?.total / imagesPerPage);
				const mappedImages: Image[] = images?.items.map((logo: BrandLogo) => ({
					id: String(logo.id),
					url: logo.src,
					medium_url: "",
					source_url: "",
					org_image_key: "",
					visual_hash: String(logo.id),
				}));
				this.logos = { images: mappedImages, total_pages: totalPages, total_images: images.total };
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	uploadLogo = async (file: File | undefined): Promise<void> => {
		try {
			this.isImageUploading = true;
			await this.rootStore.logoStore.uploadLogo(file);
			runInAction(() => {
				this.isImageUploading = false;
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isImageUploading = false;
			});
			return Promise.reject(`Error uplaoding image: ${e.message || e.toString()}`);
		}
	};

	resetSmartImage = async (): Promise<void> => {
		try {
			runInAction(() => {
				this.isLoading = false;
				this.isError = false;
				this.smartImageBackgroundOptions = [];
				this.secondaryTabType = null;
				this.smartImagesHistory = [];
				this.smartImageForm = defaultSmartForm;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isLoading = false;
			});
			return Promise.reject(`Error Resetting Canvas: ${e.message || e.toString()}`);
		}
	};
	clearSmartImageForm = () => {
		this.smartImageForm = defaultSmartImageForm;
	};

	deleteUserImage = async (vhash: string): Promise<void> => {
		try {
			this.deletedImageVhash = vhash;
			this.isError = false;
			this.isDeletingImage = true;
			await this.imageQueryService.delete(`/delete_user_image/${vhash}`);
			runInAction(async () => {
				this.isDeletingImage = false;
				this.galleryUserData.showroomImages.images = this.user_images.images.filter(
					(image: Image) => image.visual_hash !== vhash,
				);
				this.user_images.images = this.user_images.images.filter((image: Image) => image.visual_hash !== vhash);
				if (this.secondaryTabType === SecondaryTabTypeEnum.USER_IMAGES) {
					this.tabGallery.showroomImages.images = this.tabGallery.showroomImages.images.filter(
						(image: Image) => image.visual_hash !== vhash,
					);
				}
			});
		} catch (e) {
			runInAction(() => {
				this.isDeletingImage = false;
				this.isError = true;
			});
		}
	};

	deleteOrgImage = async (vhash: string): Promise<void> => {
		try {
			this.deletedImageVhash = vhash;
			this.isError = false;
			this.isDeletingImage = true;
			await this.imageQueryService.delete(`/delete_org_image/${vhash}`);
			runInAction(async () => {
				this.isDeletingImage = false;
				this.galleryOrgData.showroomImages.images = this.org_images.images.filter(
					(image: Image) => image.visual_hash !== vhash,
				);

				this.org_images.images = this.org_images.images.filter((image: Image) => image.visual_hash !== vhash);

				if (this.secondaryTabType === SecondaryTabTypeEnum.SHARED_IMAGES) {
					this.tabGallery.showroomImages.images = this.tabGallery.showroomImages.images.filter(
						(image: Image) => image.visual_hash !== vhash,
					);
				}
			});
		} catch (e) {
			runInAction(() => {
				this.isDeletingImage = false;
				this.isError = true;
			});
		}
	};
}

const initialGalleryData: GalleryData = {
	showroomImages: { totalPages: 0, images: [] },
};

const defaultSmartImageForm = {
	seed: 0,
	sid: "",
	originalImage: {
		imageUrl: "",
		boundingBox: { left: 0, top: 0, width: 1, height: 1 } as IBoundingBox,
		size: { width: 1, height: 1 } as ImageSize,
	},
	backgroundOption: BackgroundOptionsEnum.EXPAND_BACKGROUND,
	backgroundDescription: "",
	colorCode: "",
};
