export const GENERAL_ORG_ID = "6f9007a7-cfd4-49b2-bf4b-be3a11e869a7";
export const PUBLICIS_ORG_ID = "81ae0fe2-72e8-4554-884a-70fa085d23c3";
export const AMANA_ORG_ID = "e46c976a-b1e9-47c9-9706-8f8d2bf0e878";
export const NVIDIA_GAMING_ORG_ID = "95655057-f746-47b5-8458-0bd994a39ea9";
export const BRIA_ORG_ID = "96c241f8-dd6a-11eb-ba80-0242ac130004";
export const ACCENTURE_ORG_ID = "6925c9b0-7dc7-4da3-b2df-5f6b26d14894";
export const TAG_WORLDWIDE_ORG_ID = "77d3c43e-96be-4ecf-b102-4acf0d1abe09";
export const PURINA_ORG_ID = "bdd9e219-9075-4ba1-b60d-ff7a01c90287";
export const PAMPERS_ORG_ID = "dbd9e219-9075-4ba1-b60d-ff7a01c90286";
export const FOX_ORG_ID = "ffe14a90-1161-42f1-b937-29900f4753c8";
export const PUBLIC_GALLERY_ORG_ID = "d8c6299f-774a-4f3b-adb1-74f250e19407";
export const PUBLIC_GALLERY_ID = "425";
export const PUBLICIS_LE_PONT_UNILEVER_ORG_ID = "74b30f4d-99af-4f94-a742-4f3eb2325065";
export const MCCANN_ORG_ID = "7c188e4a-2e0b-4d62-9b42-d049f3b3fbe8";
export const BRIA_DEMOS_ORG_ID = "f359c476-394f-46c8-899e-eaeac3cba3bc";
export const BRIA_PUBLIC_ORG_ID = "44184870-b2c9-42a4-85f3-908eb4418cbc";
export const WPP_POC_ORG_ID = "a20ec961-4be2-453d-89e8-182bdcdf6353";
export const AWS_ORG_ID = "87551752-c806-4da8-b76d-bd8b5247ad01";
export const LOBLAW_ORG_ID = "5e890acc-010d-40ec-8089-fc4916d51e9f";
export const RAKUTEN_ORG_ID = "233c48a0-863e-4d4d-838f-b5eacd985c5e";
