import { fabric } from "fabric";
export class StaticTriangleObject extends fabric.Triangle {
	static type = "StaticTriangle";
	// @ts-ignore
	public colorNumber: number;
	public brandId?: number = undefined;
	public shapeType = "";
	// @ts-ignore
	public strokeColorNumber;
	initialize(options: StaticTriangleOptions) {
		const { ...triangleOptions } = options;
		//@ts-ignore
		super.initialize({ ...triangleOptions });

		return this;
	}

	toObject(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}

	toJSON(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}
	static fromObject(options: any, callback?: (obj: StaticTriangleObject) => void) {
		const instance = new fabric.StaticTriangle(options);
		if (callback) {
			callback(new fabric.StaticTriangle(options));
		}
		return instance;
	}
}

fabric.StaticTriangle = fabric.util.createClass(StaticTriangleObject, {
	type: StaticTriangleObject.type,
});
fabric.StaticTriangle.fromObject = StaticTriangleObject.fromObject;

export type StaticTriangleOptions = fabric.ITriangleOptions;

declare module "fabric" {
	namespace fabric {
		class StaticTriangle extends StaticTriangleObject {
			constructor(options: StaticTriangleOptions);
		}
	}
}
