import { Box, Dialog, Paper, PaperProps, Theme, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import "react-lazy-load-image-component/src/effects/blur.css";
import styles from "./EraserDraggablePopup.module.scss";

import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import BriaSlider from "../../../../components/common/BriaSlider/BriaSlider.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { IBrushSettings } from "../../../../models/sandboxAPI.ts";

type IProps = {
	onClose: () => void;
	open: boolean;
	brushConfig: IBrushSettings;
	handleEraseClick: () => void;
};

function PaperComponent(props: PaperProps) {
	return (
		<Draggable handle="#draggable" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

interface StyleProps {
	top: number;
	left: number;
}

const EraserDraggablePopup = ({ open, onClose, handleEraseClick, brushConfig }: IProps) => {
	const { imageToImageStore } = useAppStore();
	const useStyles = makeStyles<Theme, StyleProps>((_theme: Theme) => ({
		dialog: (props: StyleProps) => ({
			left: `${props.left}px`,
			top: `${props.top}px`,
		}),
	}));

	const { t } = useTranslation("translation", { keyPrefix: "eraserDraggablePopup" });
	const [left, setLeft] = useState(0);
	const [top, setTop] = useState(0);
	const classes = useStyles({ top: top, left: left });

	useEffect(() => {
		const eraseObjectButtonElement = document.getElementById("eraseObjectButton");
		if (open && eraseObjectButtonElement) {
			setLeft(eraseObjectButtonElement.offsetLeft);
			setTop(eraseObjectButtonElement.offsetTop);
		}
	}, [open]);

	return (
		<Dialog
			classes={{
				paper: clsx(classes.dialog, styles.dialog),
				root: styles.root,
			}}
			onClose={(_e, reason) => {
				if (reason !== "backdropClick") {
					onClose();
				}
			}}
			hideBackdrop={true}
			open={open}
			maxWidth={false}
			PaperComponent={PaperComponent}
		>
			<Box className={styles.container}>
				<Box className={styles.header} id="draggable">
					<CloseIcon onClick={onClose} className={styles.closeIcon} />
				</Box>
				<Box className={styles.contentContainer}>
					<Typography className={styles.title} id="draggable">
						{t("title")}
					</Typography>
					<Box className={styles.sliderContainer}>
						<BriaSlider
							className={styles.slider}
							value={brushConfig.lineWidth}
							onChange={(e: any) => {
								brushConfig.lineWidth = Number(e.target.value ?? 0);
							}}
							min={0}
							max={100}
							valueLabelDisplay={"on"}
							fieldLabels={[t("minimum"), t("maximum")]}
						/>
					</Box>
					<Box className={styles.buttonsContainer} id="draggable">
						<BriaButton
							className={styles.resetButton}
							onClick={() => {
								imageToImageStore.brushConfigs.reset = true;
							}}
							buttonType="textMedium"
						>
							{t("resetMask")}
						</BriaButton>
						<BriaButton
							onClick={() => {
								handleEraseClick();
							}}
							buttonType="primaryMedium"
						>
							{t("erase")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};

export default observer(EraserDraggablePopup);
