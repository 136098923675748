import { Box, Popover, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import FlipHorizontal from "../../../../../../assets/svgs/flip-horizontal.svg";
import FlipVertical from "../../../../../../assets/svgs/flip-vertical.svg";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { LayerType } from "../../../../../../types";
import styles from "./Flip.module.scss";

export default function FlipPopover() {
	const editor = useEditor();
	const activeObject = useActiveObject() as any;
	const [flipState, setFlipState] = useState({ flipX: false, flipY: false });
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.flipControllers" });
	const { EditAllAds } = useDesignEditorUtils();
	const { campaignStore } = useAppStore();

	useEffect(() => {
		if (activeObject) {
			setFlipState({
				flipX: activeObject.flipX,
				flipY: activeObject.flipY,
			});
		}
	}, [activeObject]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const flipHorizontally = useCallback(() => {
		editor?.objects.update({ flipX: !flipState.flipX }, undefined, !campaignStore.isEditingAllAds);
		setFlipState({ ...flipState, flipX: !flipState.flipX });
		EditAllAds(LayerType.STATIC_IMAGE, activeObject.imageType, FeatureType.FLIPX, !flipState.flipX);
	}, [editor, flipState]);

	const flipVertically = useCallback(() => {
		editor?.objects.update({ flipY: !flipState.flipY }, undefined, !campaignStore.isEditingAllAds);
		setFlipState({ ...flipState, flipY: !flipState.flipY });
		EditAllAds(LayerType.STATIC_IMAGE, activeObject.imageType, FeatureType.FLIPY, !flipState.flipY);
	}, [editor, flipState]);

	return (
		<>
			<BriaTooltip title={t("Flip")} placement="bottom">
				<BriaButton
					className={`${styles.flipButton} ${Boolean(anchorEl) ? styles.activeButton : ""}`}
					onClick={handleClick}
				>
					<Box className={styles.iconTitle}>
						<img className={styles.FlipHorizontal} src={FlipHorizontal} alt={t("flipHorizontally")} />
						<Typography className={styles.flipTitle}>{t("Flip")}</Typography>
					</Box>
				</BriaButton>
			</BriaTooltip>
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 44,
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: 33,
				}}
				className={styles.popoverBody}
			>
				<Box className={styles.flipControlsContent}>
					<Box>
						<BriaButton onClick={flipHorizontally} className={styles.buttonFlip}>
							<img src={FlipHorizontal} alt={t("flipHorizontally")} />
							<Typography className={styles.flipTitle}>{t("flipHorizontally")}</Typography>
						</BriaButton>
					</Box>
					<BriaButton onClick={flipVertically} className={styles.buttonFlip}>
						<img src={FlipVertical} alt={t("flipVertically")} />
						<Typography className={styles.flipTitle}>{t("flipVertically")}</Typography>
					</BriaButton>
				</Box>
			</Popover>
		</>
	);
}
