import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
	Box,
	CircularProgress,
	FormControl,
	InputBase,
	MenuItem,
	Select,
	SelectChangeEvent,
	SelectProps,
	Theme,
	Typography,
	styled,
} from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../BriaCheckbox/BriaCheckbox.tsx";
import DebouncedInput from "../DebouncedInput/DebouncedInput.tsx";
import EditableChip from "../EditableChip/EditableChip.tsx";
import styles from "./BriaDropDown.module.scss";

export type DropDownItem<T = any> = {
	key: string;
	value: T;
	icon?: any;
};

export type BriaDropDownProps<T> = {
	value?: T;
	handleDelete?: (key: any) => void;
	handleEditItem?: (key: any, value: any) => void;
	onSearchChange?: (event: any) => void;
	onItemsLoad?: () => void;
	loading?: boolean;
	items: DropDownItem<T>[];
	height?: string;
	maxHeight?: string;
	menuMaxHeight?: string;
	menuMaxWidth?: string;
	width?: string;
	className?: string;
	disabled?: boolean;
	hideArrowIcon?: boolean;
	border?: string;
	icon?: any;
	searchable?: boolean;
	debounceSearch?: boolean;
	labelFontWeight?: number;
	labelFontSize?: string;
	SearchPlaceholder?: string;
	searchIcon?: boolean;
	minHeight?: string;
	selectMultipleCheckbox?: boolean;
	activeItems?: string[];
	addNoneOption?: boolean;
} & SelectProps<T>;

const BriaDropdown = <T,>({
	value,
	handleDelete,
	handleEditItem,
	onSearchChange,
	onItemsLoad,
	loading,
	items,
	width,
	height,
	maxHeight,
	menuMaxHeight,
	menuMaxWidth,
	className,
	disabled,
	hideArrowIcon,
	border,
	icon,
	searchable = false,
	debounceSearch = false,
	labelFontWeight,
	labelFontSize,
	SearchPlaceholder = "",
	searchIcon = false,
	minHeight = "",
	selectMultipleCheckbox = false,
	activeItems = [],
	addNoneOption = false,
	...rest
}: BriaDropDownProps<T>) => {
	const [selectedValue, setSelectedValue] = useState<any>();
	const inputRef = useRef<HTMLInputElement>(null);
	const { t } = useTranslation("translation", { keyPrefix: "account.usage" });

	const changeHandler = (e: SelectChangeEvent<T>, child: ReactNode) => {
		if (e.target.value === "noneOption") {
			e.target.value = "";
		}
		rest.onChange && rest.onChange(e, child);
	};

	useEffect(() => {
		value !== null && value !== undefined && setSelectedValue(getSelectedValue);
	}, [value]);

	useEffect(() => {
		if (items.length) {
			onItemsLoad?.();
			setSelectedValue(getSelectedValue);
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}
	}, [items]);

	const getSelectedValue = () =>
		Array.isArray(value)
			? items.filter((item) => value.includes(item.value))
			: items.find((item) => item.value === value);

	const hasSelectedValue = Array.isArray(selectedValue)
		? selectedValue.length > 0
		: selectedValue !== undefined && selectedValue !== null;

	return (
		<StyledFormControl
			className={clsx(className, styles.formControl)}
			width={width}
			height={selectedValue?.length > 0 ? "auto" : height}
			minHeight={minHeight}
			maxHeight={maxHeight}
		>
			<StyledSelect
				displayEmpty={!!rest.placeholder}
				value={typeof value === "number" && isNaN(value) ? "" : value ?? ""}
				IconComponent={loading ? DropDownLoading : ExpandMoreRoundedIcon}
				hideArrowIcon={hideArrowIcon || (disabled && !loading)}
				disabled={disabled}
				renderValue={() =>
					disabled ? (
						rest.placeholder
					) : hasSelectedValue ? (
						<>
							{Array.isArray(selectedValue) ? (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selectedValue.map((item: DropDownItem<T>) => (
										<EditableChip
											key={item.key}
											label={item.key}
											onEdit={
												handleEditItem
													? (newKey) => {
															handleEditItem?.(item.value as any, newKey);
															setSelectedValue(
																selectedValue.map((selectedItem) =>
																	selectedItem.value === item.value
																		? { ...selectedItem, key: newKey }
																		: selectedItem,
																),
															);
													  }
													: undefined
											}
											onDelete={() => {
												handleDelete?.(item.value as any);
												setSelectedValue(
													selectedValue.filter(
														(selectedItem) => selectedItem.value !== item.value,
													),
												);
											}}
										/>
									))}
								</Box>
							) : (
								<Box className={styles.inputStyle}>
									{icon}
									<Typography
										style={{
											fontWeight: labelFontWeight ? labelFontWeight : "",
											fontSize: labelFontSize ? labelFontSize : "",
										}}
										className={styles.lableStyle}
									>
										{selectedValue.key}
									</Typography>
								</Box>
							)}
						</>
					) : (
						rest.placeholder
					)
				}
				MenuProps={{
					style: {
						marginTop: 4,
						maxHeight: menuMaxHeight,
						maxWidth: menuMaxWidth,
					},
				}}
				input={border ? <SelectInputBase border={border} /> : undefined}
				classes={{
					root: disabled ? styles.disabledSelect : "",
				}}
				{...rest}
				onChange={changeHandler}
			>
				{searchable && (
					<DebouncedInput
						onChange={onSearchChange}
						className={styles.customBriaInput}
						debounceDuration={debounceSearch ? 500 : 0}
						onKeyDown={(e) => e.stopPropagation()}
						height="36px"
						searchIcon={searchIcon}
						placeHolder={SearchPlaceholder}
						inputRef={inputRef}
					/>
				)}
				{loading && <StyledMenuItem disabled>Loading..</StyledMenuItem>}
				{(!loading && !rest.multiple && !selectMultipleCheckbox && addNoneOption
					? [
							{
								key: "None",
								value: "noneOption",
								icon: null,
							},
							...items,
					  ]
					: items
				).map((item) => {
					const isNotActive =
						activeItems.length > 0 && !activeItems.includes(item.key) && item.key !== t("selectAll");
					const menuItemProps = {
						key: item.key,
						value: item.value as any,
						disabled: disabled,
					};

					const content = (
						<>
							{selectMultipleCheckbox && (
								<BriaCheckbox
									checked={value && Array.isArray(value) && value.indexOf(item.value as string) > -1}
								/>
							)}
							{item.icon ? (
								<Box className={styles.itemContainer}>
									{item.icon}
									{item.key}
								</Box>
							) : (
								<span className={styles.itemText} dangerouslySetInnerHTML={{ __html: item.key }} />
							)}
						</>
					);

					return isNotActive ? (
						<HiddenMenuItem {...menuItemProps}>{item.key}</HiddenMenuItem>
					) : (
						<StyledMenuItem {...menuItemProps}>{content}</StyledMenuItem>
					);
				})}
			</StyledSelect>
		</StyledFormControl>
	);
};

export default observer(BriaDropdown);

const StyledFormControl = styled(FormControl)(
	({
		width,
		height,
		maxHeight,
		minHeight,
	}: {
		width?: string;
		height?: string;
		maxHeight: string | undefined;
		minHeight?: string;
	}) => ({
		width: width ?? "auto",
		"& .MuiInputBase-root": {
			height: height ?? "40px",
			maxHeight: maxHeight ?? "unset",
			minWidth: "120px",
			fontSize: "14px",
			fontWeight: "400",
			justifyContent: "center",
		},
		"& .MuiInputBase-input": {
			minHeight: minHeight,
		},
		"& .MuiSelect-select": {
			minHeight: "16px",
			padding: "12px 14px",
		},
	}),
);

const SelectInputBase = styled(InputBase)(({ border }: { border?: string }) => ({
	border: border ? border : "initial",
	"& > div": {
		padding: "16.5px 14px",
	},
}));

type CustomSelectProps = SelectProps<any> & {
	hideArrowIcon?: boolean;
};

const StyledSelect = styled(Select<any>, { shouldForwardProp: (props) => props != "hideArrowIcon" })<CustomSelectProps>(
	({ theme, hideArrowIcon }: { theme: Theme; hideArrowIcon?: boolean }) => ({
		width: "auto",
		"&.Mui-focused": {
			".MuiSvgIcon-root": {
				fill: theme.palette.primary.light,
			},
		},
		".MuiSelect-icon": {
			display: hideArrowIcon ? "none" : "block",
		},
	}),
);

const StyledMenuItem = styled(MenuItem)({
	fontWeight: 500,
	paddingTop: 8,
	paddingBottom: 8,
	fontSize: "14px",
	color: "#5B5B5B",
	textWrap: "wrap",
	"&.Mui-selected": {
		color: "#1A0638",
		fontWeight: "bold",
	},
});

const DropDownLoading = styled(CircularProgress)(({ theme }) => ({
	color: `${theme.palette.primary.light} !important`,
	width: "1em !important",
	height: "1em !important",
	right: "13px !important",
}));

const HiddenMenuItem = styled(MenuItem)({
	display: "none",
});
