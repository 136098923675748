import { Avatar, Box, FormControlLabel, FormGroup, Menu, MenuItem, Switch, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../../analytics-store.tsx";
import Logo from "../../../../../assets/images/svgs/Bria-logo.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaIconButton from "../../../../../components/common/BriaIconButton/BriaIconButton";
import ConfirmationPopup from "../../../../../components/common/ConfirmationPopup/ConfirmationPopup";
import EditableChip from "../../../../../components/common/EditableChip/EditableChip.tsx";
import ExportButton from "../../../../../components/common/ExportButton/ExportButton.tsx";
import IframeCloseButton from "../../../../../components/common/IframeCloseButton/IframeCloseButton.tsx";
import IframeHeaderNavigationDropdown from "../../../../../components/common/IframeHeaderNavigationDropdown/IframeHeaderNavigationDropdown.tsx";
import OrganizationsDropdown from "../../../../../components/common/OrganizationsDropdown/OrganizationsDropdown";
import RouterConstants from "../../../../../constants/RouterConstants";
import { USER_DEFAULT_AVATAR } from "../../../../../constants/UserConstants";
import { getSelectedOrganization } from "../../../../../helpers/localStorage.ts";
import useSecureNavigate from "../../../../../hooks/useSecureNavigate.tsx";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout.tsx";
import { OrgFeatures } from "../../../../../models/billing.ts";
import { CampaignEntityStatus, CampaignViewModes } from "../../../../../models/common";
import iframeStore from "../../../../../pages/IframeNew/iframe-store.tsx";
import useCampaignHeaderUtils from "../../../../CustomUtils/CampaignHeaderUtils.tsx";
import useScenesUtils from "../../../../CustomUtils/ScenesUtils.tsx";
import useTemplateUtils from "../../../../CustomUtils/UseTemplateUtils.tsx";
import { TemplatePreviewBtn } from "../../../../campaign-store.tsx";
import Icons from "../../../../components/Icons";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext.ts";
import { useEditor } from "../../../../hooks/useEditor.tsx";
import { defaultTemplate } from "../../../../models/template.ts";
import { SecondaryTabTypeEnum } from "../Panels/panelItems/index.ts";
import styles from "./CampaignHeader.module.scss";

const CampaignHeader: React.FC = () => {
	const { setScenes } = useContext(DesignEditorContext);
	const { updateScenes } = useScenesUtils();
	const { setCurrentScene } = useDesignEditorContext();
	const { saveCampaign, saveTemplate, saveNewCampaign, saveNewTemplate } = useCampaignHeaderUtils();
	const {
		campaignStore,
		designEditorStore,
		authStore: authStore,
		authStore: { user, logout },
		textToImageStore,
		playgroundStore,
		imagesStore,
		analyticsStore,
	} = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "editor.header" });
	const { t: campaignTranslation } = useTranslation("translation", { keyPrefix: "editor.popup.campaign" });
	const { t: templateTranslation } = useTranslation("translation", { keyPrefix: "editor.popup.template" });
	const { t: t1 } = useTranslation();
	const [viewMode, setViewMode] = useState<CampaignViewModes>(campaignStore.viewMode);
	const { handleDownloadTemplate, handleIframeSaveTemplate } = useTemplateUtils();
	const [isTemplateDownloading, setIsTemplateDownloading] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isAdminMode = campaignStore.isAdminMode;
	const editor = useEditor();
	const [isValidTemplateName, setIsValidTemplateName] = useState<boolean>(true);
	const [isCampaignOrTemplateCreating, setIsCampaignOrTemplateCreating] = useState<boolean>(false);
	const [isCampaignOrTemplateUpdating, setIsCampaignOrTemplateUpdating] = useState<boolean>(false);

	const displayedTemplateName =
		campaignStore?.selectedTemplate && campaignStore.selectedTemplate.name?.length > 0
			? campaignStore.selectedTemplate.name
			: isAdminMode
			? templateTranslation("untitled")
			: campaignTranslation("untitled");

	const undoButtonDisabled =
		!editor?.history?.getUndos?.()?.length ||
		editor.history.getUndos().length <= 1 ||
		imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE;

	const redoButtonDsiabled =
		!editor?.history?.getRedos?.()?.length ||
		editor.history.getRedos().length == 0 ||
		imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE;
	editor?.history?.setScenesFunc(setScenes);
	editor?.history.getUpdatedScenes(updateScenes);
	editor?.history.setCurrentScenesFunc(setCurrentScene);

	const handleAdminModeChange = (e: ChangeEvent<HTMLInputElement>) => {
		campaignStore.handleAdminModeChange(e.target.checked);
	};
	const navigate = useSecureNavigate();
	const handleViewModeChange = (viewMode: CampaignViewModes) => {
		setViewMode(viewMode);
		campaignStore.handleViewModeChange(viewMode);
	};
	const [isUndoing, setIsUndoing] = useState(false);
	const [isRedoing, setIsRedoing] = useState(false);

	useEffect(() => {
		const selectedTemplate = {
			...defaultTemplate,
			status: campaignStore.isAdminMode ? CampaignEntityStatus.ACTIVE : CampaignEntityStatus.DRAFT,
		};
		campaignStore.handleSetSelectedTemplate(selectedTemplate);
	}, []);

	const undo = () => {
		if (isUndoing) return;
		setIsUndoing(true);
		editor?.history.undo();
		setTimeout(() => {
			setIsUndoing(false);
		}, 350);
	};

	const redo = () => {
		if (isRedoing) return;
		setIsRedoing(true);
		editor?.history.redo();
		setTimeout(() => {
			setIsRedoing(false);
		}, 350);
	};
	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNewTemplateOrCampaignClick = (saveNewTemplateClicked: boolean) => {
		campaignStore.setProperty("templateName", "");
		campaignStore.setProperty("openDuplicateCampaignOrTemplateModal", true);
		campaignStore.setProperty("saveNewTemplateClicked", saveNewTemplateClicked);
	};

	const handleSaveTemplateOrCampaign = async (source: SaveSourceType) => {
		setIsCampaignOrTemplateUpdating(true);
		if (source === SaveSourceType.Template) {
			await saveTemplate();
		} else {
			await saveCampaign();
		}

		setIsCampaignOrTemplateUpdating(false);
	};

	const onDownload = async () => {
		if (campaignStore.selectedTemplate) {
			setIsTemplateDownloading(true);
			await handleDownloadTemplate(campaignStore.selectedTemplate);
			setIsTemplateDownloading(false);
			analyticsStore.logEvent(ANALYTICS_EVENTS.EXPORT_CAMPAIGN);
		}
	};

	const onIframeSave = async () => {
		if (campaignStore.selectedTemplate) {
			setIsTemplateDownloading(true);
			await handleIframeSaveTemplate(campaignStore.selectedTemplate);
			setIsTemplateDownloading(false);
		}
	};

	return (
		<Box
			className={clsx(styles.CampaignHeaderContainer, {
				[styles.isAdminMode]: isAdminMode,
				[styles.iframe]: iframeStore.isIframe(),
			})}
		>
			{!isPopupView && (
				<Box className={clsx(styles.logoContainer, { [styles.iframe]: iframeStore.isIframe() })}>
					{(!iframeStore.isIframe() || !authStore.isFeatureEnabled(OrgFeatures.IFRAME_WHITE_LABEL)) && (
						<img className={styles.logo} src={Logo} />
					)}
					<IframeHeaderNavigationDropdown />
					<Box className={styles.projectName}>
						<EditableChip
							key={displayedTemplateName}
							label={displayedTemplateName}
							onEdit={(newValueL: string) => {
								if (campaignStore && campaignStore?.selectedTemplate) {
									campaignStore.selectedTemplate.name = newValueL;
								}
							}}
							onDelete={() => {}}
							className={styles.backgroundColor}
							enableOnMouseEnter
							enableOnMouseLeave
							hideButtons
						/>
					</Box>
				</Box>
			)}
			<Box
				className={clsx(styles.rightSide, {
					[styles.toCenter]: isPopupView,
				})}
			>
				<Box className={styles.CampaignHeaderWrapper}>
					{isAdminMode && (
						<Typography className={styles.adminModeNode}>
							<span className={styles.bold}>{t("adminMode")}</span> - {t("adminModeNote")}
						</Typography>
					)}
					{((!iframeStore.isIframe() && authStore.user?.isSuperAdminOrAdminOrOwner()) ||
						iframeStore.iframe?.config.campaign_config?.enable_admin_mode) && (
						<FormGroup>
							<FormControlLabel
								className={clsx(styles.FormControlLabel, {
									[styles.adminModeFormControlLabel]: isAdminMode,
								})}
								control={
									<Switch
										checked={isAdminMode}
										onChange={handleAdminModeChange}
										classes={{
											root: clsx(styles.switchBase, {
												[styles.selected]: isAdminMode,
											}),
										}}
									/>
								}
								label={t("adminMode")}
								labelPlacement="start"
								value={isAdminMode}
								classes={{ label: styles.adminModeLabel }}
							/>
						</FormGroup>
					)}
					{/* TODO: to add the other functionalities later */}
					<Box className={styles.hide}>
						<Box className={styles.verticalDivider} />

						<BriaIconButton
							title={t("gridView")}
							buttonType="secondaryLarge"
							onClick={() => handleViewModeChange(CampaignViewModes.GRID_VIEW)}
							className={clsx(styles.iconButton, {
								[styles.selectedView]: viewMode === CampaignViewModes.GRID_VIEW,
							})}
						>
							<Icons.GridView size={24} />
						</BriaIconButton>

						<BriaIconButton
							title={t("fullView")}
							buttonType="secondaryLarge"
							onClick={() => handleViewModeChange(CampaignViewModes.FULL_VIEW)}
							className={clsx(styles.iconButton, {
								[styles.selectedView]: viewMode === CampaignViewModes.FULL_VIEW,
							})}
						>
							<Icons.FullView size={24} />
						</BriaIconButton>
					</Box>
					<Box className={styles.verticalDivider} />

					<BriaIconButton
						className={clsx({
							[styles.disabled]: undoButtonDisabled,
						})}
						disabled={undoButtonDisabled}
						title={t("undo")}
						buttonType="secondaryLarge"
						onClick={undo}
					>
						<Icons.Undo size={24} />
					</BriaIconButton>
					<BriaIconButton
						className={clsx({
							[styles.disabled]: redoButtonDsiabled,
						})}
						disabled={redoButtonDsiabled}
						title={t("redo")}
						buttonType="secondaryLarge"
						onClick={redo}
					>
						<Icons.Redo size={24} />
					</BriaIconButton>
					{((!iframeStore.isIframe() && authStore.user?.isSuperAdminOrAdminOrOwner()) ||
						iframeStore.iframe?.config.campaign_config?.enable_admin_mode) &&
						!isAdminMode && <Box className={styles.verticalDivider} />}

					{isAdminMode ? (
						<>
							<BriaButton
								buttonType="secondaryMedium"
								onClick={() => {
									handleNewTemplateOrCampaignClick(true);
								}}
								className={clsx(styles.secondaryMedium, styles.btn)}
								disabled={!campaignStore.selectedTemplate?.id}
							>
								{t("saveNewTemplate")}
							</BriaButton>

							{((campaignStore?.selectedTemplate &&
								campaignStore.selectedTemplate.status !== CampaignEntityStatus.DRAFT) ||
								!campaignStore?.selectedTemplate) && (
								<BriaButton
									onClick={async () => {
										await handleSaveTemplateOrCampaign(SaveSourceType.Template);
									}}
									buttonType="primaryMedium"
									className={clsx(styles.primaryMedium, styles.btn)}
									loading={isCampaignOrTemplateUpdating}
									disabled={
										campaignStore.isTemplateUpdating ||
										campaignStore.isCreatingTemplate ||
										!!(
											campaignStore.selectedTemplate &&
											campaignStore.selectedTemplate?.org_id !==
												getSelectedOrganization()?.organization.uid
										)
									}
								>
									{t("saveTemplate")}
								</BriaButton>
							)}
							<ExportButton
								onDownload={onDownload}
								onIframeSave={onIframeSave}
								loading={isTemplateDownloading}
								disabled={
									!campaignStore.selectedTemplate ||
									designEditorStore.isLoadingTemplate ||
									isTemplateDownloading
								}
							/>
							<IframeCloseButton />
						</>
					) : (
						<>
							<BriaButton
								buttonType="textMedium"
								className={styles.btn}
								disabled={campaignStore.isLoadingTemplates}
								onClick={() => {
									designEditorStore.setProperty("isTemplateEmptyState", true);
									designEditorStore.setProperty("designEditorPopup", true);
									campaignStore.setProperty("isEditingAllAds", true);
									campaignStore.handleSetSelectedTemplatePreviewButton(
										TemplatePreviewBtn.ORGANIZATION,
									);
								}}
							>
								{t("new")}
							</BriaButton>
							{((campaignStore?.selectedTemplate &&
								campaignStore.selectedTemplate.status !== CampaignEntityStatus.ACTIVE) ||
								!campaignStore?.selectedTemplate) && (
								<BriaButton
									onClick={async () => {
										await handleSaveTemplateOrCampaign(SaveSourceType.Campaign);
									}}
									buttonType="textMedium"
									className={styles.btn}
									loading={isCampaignOrTemplateUpdating}
								>
									{t("save")}
								</BriaButton>
							)}

							<BriaButton
								buttonType="textMedium"
								onClick={() => {
									handleNewTemplateOrCampaignClick(false);
								}}
								className={styles.btn}
								disabled={!campaignStore.selectedTemplate?.id}
							>
								{t("saveNewCampaign")}
							</BriaButton>
							<ExportButton
								onDownload={onDownload}
								onIframeSave={onIframeSave}
								loading={isTemplateDownloading}
								disabled={
									!campaignStore.selectedTemplate ||
									designEditorStore.isLoadingTemplate ||
									isTemplateDownloading
								}
							/>
							<IframeCloseButton />
						</>
					)}
				</Box>
				{!isPopupView && !iframeStore.isIframe() && (
					<>
						<OrganizationsDropdown />
						{authStore.user && (
							<Box className={styles.userIconConainter}>
								<BriaButton
									onClick={handleAvatarClick}
									aria-label={t("accountOfCurrentUser")}
									className={styles.avatarButton}
									disableRipple
								>
									<Avatar
										src={user?.profilePicture ?? USER_DEFAULT_AVATAR}
										className={styles.avatarStyle}
									/>
								</BriaButton>
							</Box>
						)}
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "center",
							}}
							PaperProps={{
								style: {
									width: 150,
								},
							}}
						>
							<MenuItem
								onClick={() => {
									logout().finally(() => {
										playgroundStore.clearResults();
										textToImageStore.clearConfig();
										textToImageStore.errorMessage = "";
										handleClose();
										const queryParams = new URLSearchParams(location.search);
										const continueUrl = queryParams.get("continueUrl") || undefined;

										if (
											(location.pathname && location.pathname.includes("apps")) ||
											(continueUrl && continueUrl.includes("apps"))
										) {
											if (
												(location.pathname && location.pathname.includes("fox")) ||
												(continueUrl && continueUrl.includes("fox"))
											) {
												navigate(RouterConstants.FOX_APPS.path);
											} else {
												navigate("/" + RouterConstants.CONSOLE.path, { replace: true });
											}
										} else {
											navigate(RouterConstants.LOGIN.path);
										}
									});
								}}
							>
								<Typography variant="inherit">{t1("logout")}</Typography>
							</MenuItem>
						</Menu>
					</>
				)}
			</Box>

			<ConfirmationPopup
				onClose={() => {
					campaignStore.setProperty("openDuplicateCampaignOrTemplateModal", false);
				}}
				paperClassName={clsx(styles.campaignConfirmationPopup)}
				title={
					campaignStore.saveNewTemplateClicked ? templateTranslation("title") : campaignTranslation("title")
				}
				description={
					<Box>
						<InputLayout
							showLabel={false}
							validateOn={!isValidTemplateName}
							validationMessage={
								campaignStore.saveNewTemplateClicked
									? templateTranslation("teamplateValidationMessage")
									: campaignTranslation("campaignValidationMessage")
							}
						>
							<TextField
								value={campaignStore.templateName}
								onChange={(e) => {
									campaignStore.setProperty("templateName", e.target.value);
								}}
								placeholder={
									campaignStore.saveNewTemplateClicked
										? templateTranslation("input")
										: campaignTranslation("input")
								}
								fullWidth
								InputProps={{ classes: { root: styles.textFieldDataSetCreation } }}
							/>
						</InputLayout>
					</Box>
				}
				confirmButtonText={
					campaignStore.saveNewTemplateClicked ? templateTranslation("saveNewTemplate") : t("saveNewCampaign")
				}
				loading={isCampaignOrTemplateCreating}
				open={campaignStore.openDuplicateCampaignOrTemplateModal}
				hideCancel={true}
				onClick={async () => {
					const templateName = campaignStore.templateName;
					setIsCampaignOrTemplateCreating(true);
					if (campaignStore.saveNewTemplateClicked) {
						await saveNewTemplate({ templateName, setIsValidTemplateName });
					} else {
						await saveNewCampaign({ templateName, setIsValidTemplateName });
					}
					setIsCampaignOrTemplateCreating(false);
				}}
			/>
		</Box>
	);
};

enum SaveSourceType {
	Template = "template",
	Campaign = "campaign",
}

const ObservedComponent = observer(CampaignHeader);
export default ObservedComponent;
