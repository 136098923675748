import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../../../hooks/useStores";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { useEditor } from "../../../../../../hooks/useEditor";
import { useObjects } from "../../../../../../hooks/useObjects";
import type { ILayer } from "../../../../../../types";
import DraggableList from "./DraggableList/DraggableList";
import styles from "./Layers.module.scss";

export default function Layers() {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.layers" });
	const { campaignStore } = useAppStore();
	const editor = useEditor();
	const objects = useObjects() as ILayer[];
	const [layerObjects, setLayerObjects] = React.useState<ILayer[]>([]);

	React.useEffect(() => {
		if (objects) {
			setLayerObjects(objects);
		}
	}, [objects]);

	React.useEffect(() => {
		const watcher = async () => {
			if (objects) {
				const updatedLayerObjects = [...layerObjects, ...objects.filter((obj) => !layerObjects.includes(obj))];
				setLayerObjects(updatedLayerObjects);
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, objects, layerObjects]);

	const handleSelect = (id: string) => {
		editor?.objects.select(id);
	};

	const handleToggleLock = (id: string, locked: boolean) => {
		if (locked) {
			editor?.objects.unlock(id);
		} else {
			editor?.objects.lock(id);
		}
	};

	const handleToggleVisibility = (id: string, visible?: boolean) => {
		editor?.objects.update({ visible: !visible }, id);
	};

	const handleRemove = (id: string) => {
		editor?.objects.remove(id);
	};

	const handleDragEnd = (newLayerObjects: ILayer[]) => {
		setLayerObjects(newLayerObjects);
		campaignStore.orderedLayers = newLayerObjects;
	};
	const reversedLayerObjects = [...layerObjects].reverse();
	return (
		<Box className={styles.layersTabContainer}>
			<MainTabHeader title={t("title")} />
			<Box className={styles.tabs}>
				<Box className={styles.fullWidth}>
					<DraggableList
						layerObjects={reversedLayerObjects}
						onSelect={handleSelect}
						onToggleLock={handleToggleLock}
						onToggleVisibility={handleToggleVisibility}
						onRemove={handleRemove}
						onDragEnd={handleDragEnd}
					/>
				</Box>
			</Box>
		</Box>
	);
}
