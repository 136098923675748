import styled from "@emotion/styled";
import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import styles from "./BriaButton.module.scss";

export type ButtonTypes =
	| "primary"
	| "outlined"
	| "tertiaryMedium"
	| "secondarySmall"
	| "secondaryMedium"
	| "primaryMedium"
	| "textSmall"
	| "textMedium";

export type BriaButtonProps = {
	buttonType?: ButtonTypes;
	style?: CSSProperties;
	className?: any;
	disabledColor?: string;
	loading?: boolean;
	requiresApiAccess?: boolean;
	loadingPosition?: "start" | "no-text" | "end";
	size?: string;
} & ButtonProps;

const StyledBriaButton = styled(Button)<BriaButtonProps>`
	&.Mui-disabled {
		color: ${({ disabledColor }) => disabledColor || "unset"};
	}
`;

const BriaButton = ({
	children,
	buttonType,
	style,
	className,
	disabledColor,
	loading,
	loadingPosition = "end",
	size = "medium",
	requiresApiAccess,
	...rest
}: BriaButtonProps) => {
	const { t } = useTranslation("translation");
	const { authStore } = useAppStore();
	const isLoading = loading || (requiresApiAccess && authStore.isLoadingOrgSubscriptions);
	const isDisabled =
		rest.disabled ||
		(requiresApiAccess &&
			(authStore.isLoadingOrgSubscriptions || authStore.orgPassedFreeLimit || authStore.isOrgBlocked));

	return (
		<StyledBriaButton
			{...rest}
			style={style}
			disabledColor={disabledColor}
			className={clsx(`${buttonType ? styles[buttonType] : ""}`, styles.textCommonStyle, className, {
				[styles.smallBtn]: size === "small",
			})}
			disabled={isDisabled}
			// className={`${buttonType ? styles[buttonType] : ""} ${styles.textCommonStyle} ${className}`}
			endIcon={
				isLoading && loadingPosition === "end" ? (
					<>
						{rest.endIcon}
						<CircularProgress color="inherit" size={18} />
					</>
				) : (
					rest.endIcon
				)
			}
			startIcon={
				isLoading && loadingPosition === "start" ? (
					<>
						<CircularProgress color="inherit" size={18} />
						{rest.startIcon}
					</>
				) : (
					rest.startIcon
				)
			}
		>
			{isLoading && loadingPosition === "no-text" ? (
				<>
					<CircularProgress sx={{ position: "absolute" }} color="inherit" size={18} />
					<Box sx={{ opacity: 0 }}>{children}</Box>
				</>
			) : requiresApiAccess && authStore.orgPassedFreeLimit ? (
				t("upgrade")
			) : (
				children
			)}
		</StyledBriaButton>
	);
};

export default observer(BriaButton);
