import { fabric } from "fabric";

export class StaticRectObject extends fabric.Rect {
	static type = "StaticRect";
	// @ts-ignore
	public colorNumber: number;
	public brandId?: number = undefined;
	public shapeType = "";
	// @ts-ignore
	public strokeColorNumber;

	initialize(options: StaticRectOptions) {
		const { ...rectOptions } = options;
		//@ts-ignore
		super.initialize({ ...rectOptions });

		return this;
	}

	toObject(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}

	toJSON(propertiesToInclude = []) {
		return fabric.util.object.extend(super.toObject.call(this, propertiesToInclude), {
			colorNumber: this.colorNumber,
			brandId: this.brandId,
			shapeType: this.shapeType,
			strokeColorNumber: this.strokeColorNumber,
		});
	}

	static fromObject(options: any, callback?: (obj: StaticRectObject) => void) {
		const instance = new fabric.StaticRect(options);
		if (callback) {
			callback(new fabric.StaticRect(options));
		}
		return instance;
	}
}

fabric.StaticRect = fabric.util.createClass(StaticRectObject, {
	type: StaticRectObject.type,
});
fabric.StaticRect.fromObject = StaticRectObject.fromObject;

export type StaticRectOptions = fabric.IRectOptions;

declare module "fabric" {
	namespace fabric {
		class StaticRect extends StaticRectObject {
			constructor(options: StaticRectOptions);
		}
	}
}
